import Card from "components/Card/Card";
import PageLoader from "components/Loader/PageLoader";
import React from "react";
import { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { emailNotificationListThArray } from "variables/Variables";
import api from "api";

function EmailNotification() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    let {data: users} = await api.get('getUsers')
    users = users.filter(i => i?.emails_permissions)
    setLoading(false);
    setData(users);
  };

  const handleRemovePermissions = (id) => {
    api.post('updateUser', {
      id,
      emails_permissions: null,
    })
      .then(() => {
        fetchData();
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Email Notifications"
              category="User notifications list"
              ctTableFullWidth
              ctTableResponsive
              button={
                <Link
                  to={`notifications/add`}
                  className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"
                >
                  {" "}
                  Add User
                </Link>
              }
              loader={isLoading && <PageLoader />}
              content={
                <Table striped hover id="list-datatable">
                  <thead>
                    <tr>
                      {emailNotificationListThArray.map((val, key) => {
                        return <th key={key}>{val}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((val) => {
                      return (
                        <tr key={val.id}>
                          <td>
                            {val?.emails_permissions.map((item, index, arr) => (
                              <span key={item.key}>
                                {item?.val}
                                {index + 1 === arr.length ? "" : ", "}
                              </span>
                            ))}
                          </td>
                          <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                          <td>{val.email}</td>
                          <td>{val.approved ? "Approved" : "Pending"}</td>
                          <td>
                            <Link to={`/admin/notifications/edit/${val.id}`}>
                              <i className="fa fa-edit" />
                            </Link>
                            &nbsp;
                            <Link
                              to={`#`}
                              onClick={() => {
                                handleRemovePermissions(val.id);
                              }}
                            >
                              <i className="fa fa-times" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EmailNotification;
