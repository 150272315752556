import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
  // FormControl
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { Link } from "react-router-dom"; 
import * as fb from '../../firebase';
import {addAdminLog} from "../../components/Helper";

class EditInviteUser extends Component {
  constructor(props){
    super(props);
    this.state = {
      id:"",
      name:"",
      number:"",
      disabled : false,
      error:"",
      errors:{}
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'name') {
      this.setState({name:value});
    }else if(name === 'number'){
      this.setState({number:value});
    }
  };

  handleValidation = () => {
    let {name, number} = this.state;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!name){
      formIsValid = false;
      errors["name"] = "Name is required";
    }

    //Number
    if(!number){
      formIsValid = false;
      errors["number"] = "Number is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  componentDidMount = async () => {
    const  url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    await fb.InvitedUsersCollection.doc(segment).get().then(res => {
      const dataObj = res.data();
      this.setState({
        id:res.id,
        name:dataObj.name,
        number:dataObj.number,
      });
    });
  }

  

  handleSubmit = async (event) => {
    event.preventDefault();
    this._isMounted = true;
    this.setState({disabled: true});
    if(this.handleValidation(this.state.errors)){
      await fb.InvitedUsersCollection.doc(this.state.id).update({
        name:this.state.name,
        number:this.state.number,
      }).then(snapShot => {
        addAdminLog(
          'updated_invite',
          `Updated the invite code ${this.state.id}`, 
          this.state.id,
          this.state.id,
          this.state.name
        )
        this.props.history.push('/admin/invited')
      }).catch(err=> {
        this.setState({
          error:`Something went wrong-${err}`,
          disabled: false
        });
      });
    }else{
      this.setState({
        disabled: false
      });
    }
  }

  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Edit Invite Code"
                button={
                  <Link to={`/admin/invited`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Name</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            value= {this.state.name}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.name}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Number</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="number"
                            name="number"
                            value= {this.state.number}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.number}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    
                    <Button 
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                    {this.state.disabled ? 'Loading...' : 'Submit'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditInviteUser;
