import React, { Component } from "react";

import { Link } from "react-router-dom";
import * as fb from "../../firebase";
import { staticWebImages } from "../../components/Utils/images";
import OtpInput from 'react-otp-input';
import {langText} from "../../components/Utils/helper";
import Loading from "./Loading";

class Invitation extends Component { 
  constructor(props){
    super(props);
    this.state = {
      code:"",
      userId:"",
      errors:{},
      disabled : false,
      errorMsg : "",
      titleText: '',
      contentText: '',
      submitLabel: '',
      linkLabel: '',
      isLoading: false,
    }
  }

  handleChange = code => this.setState({ code });

  componentDidMount = async () => {
    let langTextObj = await langText();
    this.setState({
      titleText: langTextObj.auth_invitation_title,
      contentText: langTextObj.auth_invitation_detail,
      submitLabel: langTextObj.auth_invitation_bottom_button,
      linkLabel: langTextObj.auth_invitation_continue_button
    });
    //get current user
    const userId = localStorage.getItem('userId');
    if (userId) {
      fb.usersCollection.doc(userId).get().then(doc => {
        if (doc.exists) {
          if (doc.data().status !== 'waiting_list') {
            this.props.history.push('/')
          }
        } else {
          this.props.history.push('/')
        }
      });
      this.setState({
        userId: userId
      });
    } else {
      this.props.history.push('/')
    }
    this.setState({
      isLoading: true
    });
  }

  handleValidation = () => {
    let {code} = this.state;
    let errors = {};
    let formIsValid = true;

    if(!code || code.length < 6){
      formIsValid = false;
      errors["code"] = "Please enter correct invite code";
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if(this.handleValidation()){
      this.setState({disabled: true});
      const {code} = this.state;
      const invite_code = fb.functions.httpsCallable('invite_code');
      invite_code({ code: code })
      .then(async (result) => {
        console.log('result', result)
        if(result.data.valid_code==="true"){
          const changeUserStatus = fb.functions.httpsCallable('changeUserStatus');
          await changeUserStatus({uid:this.state.userId,status:'questionnaire1'}).then(async res => {
            //parse questionnaire
            const invite_code = fb.functions.httpsCallable('intro_questionnaire');
            invite_code()
            .then(() => {
              this.props.history.push('/questionnaire')
            }).catch((error) => { 
              this.setState({
                disabled: false,
                errorMsg:"Something went wrong! Please try again after some time"
              })
            });
          }).catch(err=> {
            this.setState({
              disabled: false
            });
          });
        }else{
          this.setState({
            disabled: false,
            errorMsg:"You have entered invalid invite code!"
          })
        }
      }).catch((error) => {
        this.setState({
          disabled: false,
          errorMsg:"Something went wrong! Please try again after some time"
        })
      });
    }
  }

  componentWillUnmount(){
    this.setState({reload:true})
  }

  render() {
    console.log('render', this.state)
    return (
        <section className="Subscription" style={{background: 'rgba(238, 221, 211, 0.6)'}}>
          {this.state.isLoading ?
          <div className="container_5-fluid">
            <div className="row_5">
              <div className="col_5-lg-6 col_5-12 hidden-xs hidden-sm" style={{padding: 0}}>
                <div className="Subscription_cust" style={{background: 'transparent'}}>
                  <img src={staticWebImages.depost} alt="" style={{width:"100%",maxWidth:"530px"}}/>
                </div>
              </div>
              <div className="col_5-lg-6 col_5-12">
                <div className="subs_mainBox">
                  <div className="subsRightBoxWrapper">
                    <div className="subsRightBox">
                      <div className="main-logo">
                        <img src={staticWebImages.logo} alt="" className="img-responsive center-block web-logo-hide" />
                      </div>
                      <h2 className="main_txt_sign text-center" style={{paddingBottom: '20px'}}>{this.state.titleText}</h2>
                      <p className="shiping_para">{this.state.contentText}</p>
                      <div className="form-section_add">
                        <div className="formOTP">
                          <div className="formItems">
                            <form>
                              <OtpInput
                                  value={this.state.code}
                                  onChange={this.handleChange}
                                  inputStyle="OTP-C"
                                  numInputs={6}
                                  separator={<span></span>}
                                  isInputNum={true}
                              />
                            </form>
                          </div>
                        </div>
                        {
                          this.state.errors && this.state.errors.code &&
                          <p className="alert">
                            <img src={staticWebImages.alertCircle}/> {this.state.errors.code }
                          </p>
                        }
                        {
                          this.state.errorMsg &&
                          <p className="alert">
                            <img src={staticWebImages.alertCircle}/> {this.state.errorMsg }
                          </p>
                        }
                        <form onSubmit={this.handleSubmit} className="d-flex justify-content-center d-lg-none d-md-none">
                          <input
                              type="submit"
                              className="form-control custom-btn-web"
                              disabled={this.state.disabled}
                              value={this.state.disabled ? 'Loading...' : this.state.submitLabel}
                          />
                        </form>
                        <p className="info_text text-center ">
                          <Link to="/waiting-list" className="text-decoration">{this.state.linkLabel}</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
                </div>
              </div>
            </div>
          </div>
              : <Loading />}
        </section>
    );
  }
}

export default Invitation;
