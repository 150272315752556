import React, { Component } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Table,  
  Tab,
  Tabs,
  Alert
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card.jsx";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import { 
  shipmentListThArray, 
  shipmentTransitListThArray ,
  shipmentProcessedListThArray
} from "../../variables/Variables.jsx";
import Variables from "../../variables/Variables.jsx";
import * as fb from "../../firebase";
import {
  convertFirebseSecondsToDate,
} from "components/Helper/index";

//Datatable Modules
import $ from 'jquery'; 
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import './Shipment.css';
import api from "api.js";
import { convertMilisecondsToDate } from "components/Helper/index.jsx";

class ShipmentList extends Component {

  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      futureShipments:[],
      waitingShipmentConfirmed:[],
      waitingShipments:[],
      areShipped:[],
      areDelivered:[],
      delivered_confirmed:[],
      deleted:[],
      testFutureShipments:[],
      testWaitingShipments:[],
      testAreShipped:[],
      testAreDelivered:[],
      testDelivered_confirmed:[],
      testWaitingShipmentConfirmed:[],
      testDeleted:[],
      allShipments:[],
      userData:{},
      isOpenUsersModel:false,
      isOpenUserModel:false,
      errors:{},
      error:""
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    await this.testpackages();

    $('#future_shipment').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#package_waiting_shipment').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#package_shipped').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#package_delivered').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#delivered_confirmed').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#deleted').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#preparation_confirmed').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#all_shipment').DataTable({
      "order": [[ 0, "desc" ]]
    });

    $('#test_future_shipment').DataTable({
      "order": [[ 0, "desc" ]]
    });

    //test
    $('#test_package_waiting_shipment').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#test_package_shipped').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#test_package_delivered').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#test_delivered_confirmed').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#test_deleted').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#test_preparation_confirmed').DataTable({
      "order": [[ 0, "desc" ]]
    });

    $('#all_production_test_shipment').DataTable({
      "order": [[ 0, "desc" ]]
    });
  }

  fetchData = async () => {
    try { 
      this.setState({isLoading: true});
      let {data: packages} = await api.get('getPackages')
      packages = packages?.filter(i => !i?.test)

      let futureShipments = [];
      let waitingShipments = [];
      let areShipped = [];
      let areDelivered = [];
      let delivered_confirmed = [];
      let deleted = [];
      let allShipments = [];
      let waitingShipmentConfirmed = [];

      const maxDays = await fb.metaCollection.doc('shipment').get().then((doc) => {
        if (!doc.exists) return 7
        let data = doc.data()
        if(data.hasOwnProperty('treatment_request_packages_expiration_days')){
          return data.treatment_request_days
        }else{
          return 7
        }
      });

      packages.forEach(dataObj => {
        if(!dataObj.test && dataObj.test !== true){
          if (dataObj.status === "waiting_shipment" && dataObj.deleted != true /*&& dataObj.risk !== "red"*/) {
            this.closeTreatmentRequestPackage(dataObj,maxDays)
            let dateOver = "";
            const hour24 = 24 * 1000 * 60 * 60;
            const hourago24 = Date.now() - hour24;
            const targetDate = new Date(hourago24);
            const createdDate = new Date(dataObj.created).getTime(); 
            if (targetDate < createdDate) {
              // console.log('The yourDate time is less than 1 days from now')
              dateOver = false;
            }else{
              // console.log('The yourDate time is more than 1 days from now')
              dateOver = true;
            }
            waitingShipments.push({
              id: dataObj.id,
              userId: dataObj.user_id,
              type: dataObj.type || 'rb',
              name: dataObj.userName,
              email: dataObj.userEmail,
              risk: dataObj.risk || "",
              dateOver: dateOver,
              created: convertMilisecondsToDate(new Date(dataObj.created).getTime()),
              ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
              package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000,
              ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
              package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
              shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
              deleted_status: dataObj.deleted
            });
          }else if (dataObj.status === "shipped" && dataObj.deleted != true) {
            let dateOver = "";
            const hour24 = 24 * 1000 * 60 * 60;
            const hourago24 = Date.now() - hour24;
            const targetDate = new Date(hourago24);
            console.log("dataObj.shipped",dataObj.shipped);
            const createdDate = new Date(dataObj?.shipped).getTime(); 
            if (targetDate < createdDate) {
              // console.log('The yourDate time is less than 1 days from now')
              dateOver = false;
            }else{
              // console.log('The yourDate time is more than 1 days from now')
              dateOver = true;
            }
            areShipped.push({
              id: dataObj.id,
              userId: dataObj.user_id,
              type: dataObj.type || 'rb',
              name: dataObj.userName,
              email: dataObj.userEmail,
              risk: dataObj.risk || "",
              dateOver: dateOver,
              shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
              ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
              package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
              package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
              shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
              deleted_status: dataObj.deleted
            });
          }else if (dataObj.status === "waiting_shipment_confirmed" && dataObj.deleted != true) {
            waitingShipmentConfirmed.push({
              id: dataObj.id,
              userId: dataObj.user_id,
              type: dataObj.type || 'rb',
              name: dataObj.userName,
              email: dataObj.userEmail,
              risk: dataObj.risk || "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
              shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
              package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
              delivered: dataObj.delivered ? convertMilisecondsToDate(new Date(dataObj.delivered).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000,
              ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
              package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
              shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
              delivered_seconds: new Date(dataObj.delivered).getTime() / 1000,
              deleted_status: dataObj.deleted
            });
          } else if (dataObj.status === "delivered" && dataObj.deleted != true) {
            let dateOver = "";
            if (
              new Date(dataObj.shipped).getTime() > new Date(dataObj.ship_by).getTime() || 
              new Date(dataObj.delivered).getTime() > new Date(dataObj.package_due).getTime()
              ) {
              console.log('The yourDate time is more than 1 days from now')
              dateOver = "shipment-danger";
            }else{
              console.log('The yourDate time is less than 1 days from now')
              dateOver = "";
            }
            areDelivered.push({
              id: dataObj.id,
              userId: dataObj.user_id,
              type: dataObj.type || 'rb',
              name: dataObj.userName,
              email: dataObj.userEmail,
              risk: dataObj.risk || "",
              deleted: dataObj.deleted ? "deleted-shipment" : "",
              dateOver:dateOver,
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
              shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
              package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
              delivered: dataObj.delivered ? convertMilisecondsToDate(new Date(dataObj.delivered).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000,
              ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
              package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
              shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
              delivered_seconds: new Date(dataObj.delivered).getTime() / 1000,
              deleted_status:dataObj.deleted
            });
          } else if (dataObj.status === "confirmed" && dataObj.deleted != true) {
            let dateOver = "";
            if (
              new Date(dataObj.shipped).getTime() > new Date(dataObj.ship_by).getTime() || 
              new Date(dataObj.delivered).getTime() > new Date(dataObj.package_due).getTime()
              ) {
              dateOver = "shipment-danger";
            }else{
              dateOver = "";
            }

            delivered_confirmed.push({
              id: dataObj.id,
              userId: dataObj.user_id,
              type: dataObj.type || 'rb',
              name: dataObj.userName,
              email: dataObj.userEmail,
              risk: dataObj.risk || "",
              deleted: dataObj.deleted ? "deleted-shipment" : "",
              dateOver:dateOver,
              created: dataObj.created? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
              shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
              package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
              delivered: dataObj.delivered ? convertMilisecondsToDate(new Date(dataObj.delivered).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000,
              ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
              package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
              shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
              delivered_seconds: new Date(dataObj.delivered).getTime() / 1000,
              deleted_status: dataObj.deleted
            });
          }else if (dataObj.deleted === true) {
            deleted.push({
              id: dataObj.id,
              userId: dataObj.user_id,
              type: dataObj.type || 'rb',
              name: dataObj.userName,
              email: dataObj.userEmail,
              risk: dataObj.risk || "",
              deleted: dataObj.deleted ? "deleted-shipment" : "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
              shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
              package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
              delivered: dataObj.delivered ? convertMilisecondsToDate(new Date(dataObj.delivered).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000,
              ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
              package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
              shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
              delivered_seconds: new Date(dataObj.delivered).getTime() / 1000,
              deleted_status: dataObj.deleted
            });
          }
          //future and shipment tabs
          if ((dataObj.risk === "yellow" ||  dataObj.risk === "red") && dataObj.deleted != true) {
            let dateOver = "";
            const hour24 = 24 * 1000 * 60 * 60;
            const hourago24 = Date.now() - hour24;
            const targetDate = new Date(hourago24);
            const createdDate = new Date(dataObj.created).getTime(); 
            if (targetDate < createdDate) {
              // console.log('The yourDate time is less than 1 days from now')
              dateOver = false;
            }else{
              // console.log('The yourDate time is more than 1 days from now')
              dateOver = true;
            }

            futureShipments.push({
              id: dataObj.id,
              userId: dataObj.user_id,
              type: dataObj.type || 'rb',
              name: dataObj.userName,
              email: dataObj.userEmail,
              risk: dataObj.risk || "",
              dateOver:dateOver,
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              delivered_seconds: new Date(dataObj.delivered).getTime() / 1000,
              ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
              package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000,
              ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
              package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
              deleted_status: dataObj.deleted
            });
          }
        }
        //all shipments
        if (dataObj.deleted != true) {
          let dateOver = "";
          const hour24 = 24 * 1000 * 60 * 60;
          const hourago24 = Date.now() - hour24;
          const targetDate = new Date(hourago24);
          const createdDate = new Date(dataObj.created).getTime(); 
          if (targetDate < createdDate) {
            // console.log('The yourDate time is less than 1 days from now')
            dateOver = false;
          }else{
            // console.log('The yourDate time is more than 1 days from now')
            dateOver = true;
          }

          allShipments.push({
            id: dataObj.id,
            userId: dataObj.user_id,
            type: dataObj.type || 'rb',
            name: dataObj.userName,
            email: dataObj.userEmail,
            risk: dataObj.risk || "",
            dateOver:dateOver,
            created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
            ship_by: dataObj.ship_by  ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
            package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
            created_seconds: new Date(dataObj.created).getTime() / 1000,
            ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
            package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
            deleted_status: dataObj.deleted
          });
        }
      })
      this.setState({
        allShipments:allShipments,
        waitingShipmentConfirmed:waitingShipmentConfirmed,
        waitingShipments: waitingShipments,
        futureShipments: futureShipments,
        areShipped: areShipped,
        areDelivered: areDelivered,
        delivered_confirmed:delivered_confirmed,
        deleted:deleted,
        isLoading: false
      });
    }catch(err){
      console.log('err', err);
      this.setState({error:err.message})
    }
  }

  closeTreatmentRequestPackage = async (packageData,maxDays) => {
    let diff_days = Math.floor(new Date().getTime() / (24 * 60 * 60 * 1000)) - Math.floor(packageData.created?.seconds / (24 * 60 * 60))
    if(diff_days >= maxDays){
      await api.post('updatePackage', {
        id: packageData.id,
        deleted: true
      });
    }
  }

  deletePackage = async (data) => {
    //deleted_status
    if(data.deleted_status){
      if (window.confirm("Do you want to delete the item completely?")) {
        await api.post('deletePackage', {id: data.id}).then(() => {
          window.location.reload();
        })
      }
    }else{
      if (window.confirm("Do you want to delete the item?")) {
        await api.post('updatePackage', {
          id: data.id,
          deleted:true
        }).then(async () => {
          // await this.fetchData();
          // await this.testpackages(); // this function write error about update of deleted element
          window.location.reload();
        })
        // window.location.reload();
      }
    }
  }

  testpackages = async() => { 
    try { 
      this.setState({isLoading: true});
      let {data: packages} = await api.get('getPackages')
      packages = packages?.filter(i => i?.test)
          
      let testFutureShipments = [];
      let waitingShipments = [];
      let areShipped = [];
      let areDelivered = [];
      let delivered_confirmed = [];
      let deleted = [];
      let waitingShipmentConfirmed = [];

      const maxDays = await fb.metaCollection.doc('shipment').get().then((doc) => {
        if (!doc.exists) return 7
        let data = doc.data()
        if(data.hasOwnProperty('treatment_request_packages_expiration_days')){
          return data.treatment_request_days
        }else{
          return 7
        }
      });

      packages.forEach(dataObj => {
        if (dataObj.status === "waiting_shipment" && dataObj.deleted != true /*&& dataObj.risk !== "red"*/) {
          this.closeTreatmentRequestPackage(dataObj,maxDays)
          let dateOver = "";
          const hour24 = 24 * 1000 * 60 * 60;
          const hourago24 = Date.now() - hour24;
          const targetDate = new Date(hourago24);
          const createdDate = new Date(dataObj.created).getTime(); 
          if (targetDate < createdDate) {
            // console.log('The yourDate time is less than 1 days from now')
            dateOver = false;
          }else{
            // console.log('The yourDate time is more than 1 days from now')
            dateOver = true;
          }

          waitingShipments.push({
            id: dataObj.id,
            userId: dataObj.userId,
            name: dataObj.userName,
            email: dataObj.userEmail,
            risk: dataObj.risk || "",
            type: dataObj.type || 'rb',
            dateOver:dateOver,
            created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
            ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
            package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
            created_seconds: new Date(dataObj.created).getTime() / 1000,
            ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
            package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
            shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
            deleted_status: dataObj.deleted
          });
        } else if (dataObj.status === "waiting_shipment_confirmed" && dataObj.deleted != true) {
            waitingShipmentConfirmed.push({
              id: dataObj.id,
              userId: dataObj.userId,
              name: dataObj.userName,
              email: dataObj.userEmail,
              risk: dataObj.risk || "",
              type: dataObj.type || 'rb',
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
              shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
              package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
              delivered: dataObj.delivered ? convertMilisecondsToDate(new Date(dataObj.delivered).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000,
              ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
              package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
              shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
              delivered_seconds: new Date(dataObj.delivered).getTime() / 1000,
              deleted_status: dataObj.deleted
            });
          }else if (dataObj.status === "shipped" && dataObj.deleted != true) {
          let dateOver = "";
          const hour24 = 24 * 1000 * 60 * 60;
          const hourago24 = Date.now() - hour24;
          const targetDate = new Date(hourago24);
          const createdDate = new Date(dataObj.shipped).getTime(); 
          if (targetDate < createdDate) {
            // console.log('The yourDate time is less than 1 days from now')
            dateOver = false;
          }else{
            // console.log('The yourDate time is more than 1 days from now')
            dateOver = true;
          }

          areShipped.push({
            id: dataObj.id,
            userId: dataObj.userId,
            name: dataObj.userName,
            email: dataObj.userEmail,
            risk: dataObj.risk || "",
            type: dataObj.type || 'rb',
            dateOver:dateOver,
            shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
            ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
            package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
            package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
            shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
            deleted_status: dataObj.deleted
          });
        } else if (dataObj.status === "delivered" && dataObj.deleted != true) {
          let dateOver = "";
          if (
            new Date(dataObj.shipped).getTime() > new Date(dataObj.ship_by).getTime() || 
            new Date(dataObj.delivered).getTime() > new Date(dataObj.package_due).getTime()
            ) {
            console.log('The yourDate time is more than 1 days from now')
            dateOver = "shipment-danger";
          }else{
            console.log('The yourDate time is less than 1 days from now')
            dateOver = "";
          }

          areDelivered.push({
            id: dataObj.id,
            userId: dataObj.userId,
            name: dataObj.userName,
            email: dataObj.userEmail,
            risk: dataObj.risk || "",
            type: dataObj.type || 'rb',
            deleted: dataObj.deleted ? "deleted-shipment" : "",
            dateOver:dateOver,
            created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
            ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
            shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
            package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
            delivered: dataObj.delivered ? convertMilisecondsToDate(new Date(dataObj.delivered).getTime()) : "",
            created_seconds: new Date(dataObj.created).getTime() / 1000,
            ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
            package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
            shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
            delivered_seconds: new Date(dataObj.delivered).getTime() / 1000,
            deleted_status: dataObj.deleted
          });
        } else if (dataObj.status === "confirmed" && dataObj.deleted != true) {
          let dateOver = "";
          if (
            new Date(dataObj.shipped).getTime() > new Date(dataObj.ship_by).getTime() || 
            new Date(dataObj.delivered).getTime() > new Date(dataObj.package_due).getTime()
            ) {
            dateOver = "shipment-danger";
          }else{
            dateOver = "";
          }

          delivered_confirmed.push({
            id: dataObj.id,
            userId: dataObj.userId,
            name: dataObj.userName,
            email: dataObj.userEmail,
            risk: dataObj.risk || "",
            type: dataObj.type || 'rb',
            deleted: dataObj.deleted ? "deleted-shipment" : "",
            dateOver:dateOver,
            created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
            ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
            shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
            package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
            delivered: dataObj.delivered ? convertMilisecondsToDate(new Date(dataObj.delivered).getTime()) : "",
            created_seconds: new Date(dataObj.created).getTime() / 1000,
            ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
            package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
            shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
            delivered_seconds: new Date(dataObj.delivered).getTime() / 1000,
            deleted_status: dataObj.deleted
          });
        }else if (dataObj.deleted === true) {
          deleted.push({
            id: dataObj.id,
            userId: dataObj.userId,
            name: dataObj.userName,
            email: dataObj.userEmail,
            risk: dataObj.risk || "",
            type: dataObj.type || 'rb',
            deleted: dataObj.deleted ? "deleted-shipment" : "",
            created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
            ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
            shipped: dataObj.shipped ? convertMilisecondsToDate(new Date(dataObj.shipped).getTime()) : "",
            package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
            delivered: dataObj.delivered ? convertMilisecondsToDate(new Date(dataObj.delivered).getTime()) : "",
            created_seconds: new Date(dataObj.created).getTime() / 1000,
            ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
            package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
            shipped_seconds: new Date(dataObj.shipped).getTime() / 1000,
            delivered_seconds: new Date(dataObj.delivered).getTime() / 1000,
            deleted_status: dataObj.deleted
          });
        }

        //test future and shipment tabs
        if ((dataObj.risk === "yellow" ||  dataObj.risk === "red") && dataObj.deleted != true) {
          let dateOver = "";
          const hour24 = 24 * 1000 * 60 * 60;
          const hourago24 = Date.now() - hour24;
          const targetDate = new Date(hourago24);
          const createdDate = new Date(dataObj.created).getTime(); 
          if (targetDate < createdDate) {
            // console.log('The yourDate time is less than 1 days from now')
            dateOver = false;
          }else{
            // console.log('The yourDate time is more than 1 days from now')
            dateOver = true;
          }

          testFutureShipments.push({
            id: dataObj.id,
            userId: dataObj.userId,
            name: dataObj.userName,
            email: dataObj.userEmail,
            risk: dataObj.risk || "",
            type: dataObj.type || 'rb',
            dateOver:dateOver,
            created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
            ship_by: dataObj.ship_by ? convertMilisecondsToDate(new Date(dataObj.ship_by).getTime()) : "",
            package_due: dataObj.package_due ? convertMilisecondsToDate(new Date(dataObj.package_due).getTime()) : "",
            created_seconds: new Date(dataObj.created).getTime() / 1000,
            ship_by_seconds: new Date(dataObj.ship_by).getTime() / 1000,
            package_due_seconds: new Date(dataObj.package_due).getTime() / 1000,
            deleted_status: dataObj.deleted
          });
        }
      })
      this.setState({
        testFutureShipments:testFutureShipments,
        testWaitingShipments: waitingShipments,
        testAreShipped: areShipped,
        testAreDelivered: areDelivered,
        testDelivered_confirmed:delivered_confirmed,
        testDeleted:deleted,
        testWaitingShipmentConfirmed:waitingShipmentConfirmed,
        isLoading: false
      });
    }catch(err){
      this.setState({error:err.message})
    }
  }

  renderRiskColor = (risk) => {
    let color= "green";
    if(risk==="yellow"){
      color= "yellow";
      return(
        <i className="fa fa-circle" aria-hidden="true"  style={{color:color}}></i>
      )
    }else if(risk==="red"){
      color= "red";
      return(
        <i className="fa fa-circle" aria-hidden="true"  style={{color:color}}></i>
      )
    }else {
      return(
        <i className="fa fa-circle" aria-hidden="true"  style={{color:color}}></i>
      )
    }
  }

  render() {
    // console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title=""
                category=""
                ctTableFullWidth
                ctTableResponsive
                button={
                  <div className="notification-button">
                    <Link to={`shipment/add`} className="btn btn-success libi-admin-btn">
                      Add Package
                    </Link>
                  </div>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <div>
                  <h4><b>Production Packages</b></h4>
                  <Tabs defaultActiveKey="package_waiting_shipment" id="uncontrolled-tab-example">
                    {Object.entries({
                      package_waiting_shipment: {title: 'Order', header: 'shipmentListThArray', data: 'waitingShipments'},
                      preparation_confirmed: {title: 'Order Confirmed', header: 'shipmentListThArray', data: 'waitingShipmentConfirmed'},
                      package_shipped: {title: 'Transit', header: 'shipmentTransitListThArray', data: 'areShipped'},
                      package_delivered: {title: 'Delivered', header: 'shipmentProcessedListThArray', data: 'areDelivered'},
                      delivered_confirmed: {title: 'Confirmed', header: 'shipmentProcessedListThArray', data: 'delivered_confirmed'},
                      deleted: {title: 'Deleted', header: 'shipmentProcessedListThArray', data: 'deleted'},
                      // future_shipment: {title: 'Future Shipment', header: 'shipmentListThArray', data: 'futureShipments'},
                      all_shipment: {title: 'All shipments', header: 'shipmentListThArray', data: 'allShipments'},
                    }).map((tabVal, tabKey) => {
                      return <Tab eventKey={tabVal[0]} title={tabVal[1].title} key={tabKey}>
                        <Table striped hover id={tabVal[0]}>
                          <thead>
                          <tr>
                            {Variables[tabVal[1].header].map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                          </thead>
                          <tbody>
                          {this.state[tabVal[1].data].map((val, key) => {
                            return (
                              <tr key={key} id={key} className={ val.dateOver ? 'shipment-danger' : ''}>
                                {['shipmentListThArray','shipmentProcessedListThArray'].includes(tabVal[1].header) &&
                                <td><span style={{display:"none"}}>{val.created_seconds}</span>{val.created}</td>}
                                {['shipmentListThArray','shipmentProcessedListThArray'].includes(tabVal[1].header) &&
                                <td><span style={{display:"none"}}>{val.ship_by_seconds}</span>{val.ship_by}</td>}
                                {['shipmentTransitListThArray','shipmentProcessedListThArray'].includes(tabVal[1].header) &&
                                <td><span style={{display:"none"}}>{val.shipped_seconds}</span>{val.shipped}</td>}
                                <td><span style={{display:"none"}}>{val.package_due_seconds}</span>{val.package_due}</td>
                                {['shipmentProcessedListThArray'].includes(tabVal[1].header) &&
                                <td><span style={{display:"none"}}>{val.delivered_seconds}</span>{val.delivered}</td>}
                                <td>{val.type}</td>
                                <td><Link target="_blank" to={`users/view/${val.userId}`}>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</Link></td>
                                <td>{val.email}</td>
                                <td><span style={{display:"none"}}>{val.risk}</span>{ this.renderRiskColor(val.risk) }</td>
                                <td>
                                  <Link to={`shipment/view/${val.id}`}> <i className="fa fa-eye" /></Link>&nbsp;&nbsp;
                                  <Link to={`#`} onClick={() => {this.deletePackage(val)}}><i className="fa fa-times" /></Link>
                                </td>
                              </tr>
                            );
                          })}
                          </tbody>
                        </Table>
                      </Tab>
                    })}
                  </Tabs>
                  <h4><b>Test Packages</b></h4>
                  <Tabs defaultActiveKey="test_package_waiting_shipment" id="uncontrolled-tab-example">
                    {Object.entries({
                      test_package_waiting_shipment: {title: 'Order', header: 'shipmentListThArray', data: 'testWaitingShipments'},
                      test_preparation_confirmed: {title: 'Order Confirmed', header: 'shipmentListThArray', data: 'testWaitingShipmentConfirmed'},
                      test_package_shipped: {title: 'Transit', header: 'shipmentTransitListThArray', data: 'testAreShipped'},
                      test_package_delivered: {title: 'Delivered', header: 'shipmentProcessedListThArray', data: 'testAreDelivered'},
                      test_delivered_confirmed: {title: 'Confirmed', header: 'shipmentProcessedListThArray', data: 'testDelivered_confirmed'},
                      test_deleted: {title: 'Deleted', header: 'shipmentProcessedListThArray', data: 'testDeleted'},
                      // test_future_shipment: {title: 'Future Shipment', header: 'shipmentListThArray', data: 'testFutureShipments'},
                    }).map((tabVal, tabKey) => {
                      return <Tab eventKey={tabVal[0]} title={tabVal[1].title} key={tabKey}>
                        <Table striped hover id={tabVal[0]}>
                          <thead>
                          <tr>
                            {Variables[tabVal[1].header].map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                          </thead>
                          <tbody>
                          {this.state[tabVal[1].data].map((val, key) => {
                            return (
                              <tr key={key} id={key} className={ val.dateOver ? 'shipment-danger' : ''}>
                                {['shipmentListThArray','shipmentProcessedListThArray'].includes(tabVal[1].header) &&
                                <td><span style={{display:"none"}}>{val.created_seconds}</span>{val.created}</td>}
                                {['shipmentListThArray','shipmentProcessedListThArray'].includes(tabVal[1].header) &&
                                <td><span style={{display:"none"}}>{val.ship_by_seconds}</span>{val.ship_by}</td>}
                                {['shipmentTransitListThArray','shipmentProcessedListThArray'].includes(tabVal[1].header) &&
                                <td><span style={{display:"none"}}>{val.shipped_seconds}</span>{val.shipped}</td>}
                                <td><span style={{display:"none"}}>{val.package_due_seconds}</span>{val.package_due}</td>
                                {['shipmentProcessedListThArray'].includes(tabVal[1].header) &&
                                <td><span style={{display:"none"}}>{val.delivered_seconds}</span>{val.delivered}</td>}
                                <td>{val.type}</td>
                                <td><Link target="_blank" to={`users/view/${val.userId}`}>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</Link></td>
                                <td>{val.email}</td>
                                <td><span style={{display:"none"}}>{val.risk}</span>{ this.renderRiskColor(val.risk) }</td>
                                <td>
                                  <Link to={`shipment/view/${val.id}`}> <i className="fa fa-eye" /></Link>&nbsp;&nbsp;
                                  <Link to={`#`} onClick={() => {this.deletePackage(val)}}><i className="fa fa-times" /></Link>
                                </td>
                              </tr>
                            );
                          })}
                          </tbody>
                        </Table>
                      </Tab>
                    })}
                  </Tabs>
                  <div className="typography-line">
                    <span>To be Shipped: </span>
                    <span className="text-success">Packages due and need to be shipped</span>
                  </div>
                  <div className="typography-line">
                    <span>In transit: </span>
                    <span className="text-success">Packages shipped and are in transit</span>
                  </div>
                  <div className="typography-line">
                    <span>Delivered: </span>
                    <span className="text-success">Packages delivered and waiting confirmation from customers</span>
                  </div>
                  <div className="typography-line">
                    <span>Confirmed: </span>
                    <span className="text-success">Packages delivery confirmed by customers</span>
                  </div><p></p>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ShipmentList;
