import api from "api";
import * as fb from "../../firebase";

export const countries = () => {
    return  [
        {
        "value":"Andorra",
        "name":"Andorra"
        },
        {
        "value":"Austria",
        "name":"Austria"
        },
        {
        "value":"Belgium",
        "name":"Belgium"
        },
        {
        "value":"Bulgaria",
        "name":"Bulgaria"
        },
        {
        "value":"Croatia",
        "name":"Croatia"
        },
        {
        "value":"Cyprus",
        "name":"Cyprus"
        },
        {
        "value":"Czech_Republic",
        "name":"Czech Republic"
        },
        {
        "value":"Denmark",
        "name":"Denmark"
        },
        {
        "value":"Estonia",
        "name":"Estonia"
        },
        {
        "value":"Finland",
        "name":"Finland"
        },
        {
        "value":"France",
        "name":"France"
        },
        {
        "value":"Germany",
        "name":"Germany"
        },
        {
        "value":"Greece",
        "name":"Greece"
        },
        {
        "value":"Hungary",
        "name":"Hungary"
        },
        {
        "value":"Iceland",
        "name":"Iceland"
        },
        {
        "value":"Ireland_Republic_of",
        "name":"Ireland, Republic of"
        },
        {
        "value":"Israel",
        "name":"Israel"
        },
        {
        "value":"Italy",
        "name":"Italy"
        },
        {
        "value":"Latvia",
        "name":"Latvia"
        },
        {
        "value":"Liechtenstein",
        "name":"Liechtenstein"
        },
        {
        "value":"Lithuania",
        "name":"Lithuania"
        },
        {
        "value":"Luxembourg",
        "name":"Luxembourg"
        },
        {
        "value":"Malta",
        "name":"Malta"
        },
        {
        "value":"Monaco",
        "name":"Monaco"
        },
        {
        "value":"Netherlands",
        "name":"Netherlands"
        },
        {
        "value":"Norway",
        "name":"Norway"
        },
        {
        "value":"Poland",
        "name":"Poland"
        },
        {
        "value":"Portugal",
        "name":"Portugal"
        },
        {
        "value":"Romania",
        "name":"Romania"
        },
        {
        "value":"San_Marino",
        "name":"San Marino"
        },
        {
        "value":"Slovakia",
        "name":"Slovakia"
        },
        {
        "value":"Slovenia",
        "name":"Slovenia"
        },
        {
        "value":"Spain",
        "name":"Spain"
        },
        {
        "value":"Sweden",
        "name":"Sweden"
        },
        {
        "value":"Switzerland",
        "name":"Switzerland"
        },
        {
        "value":"Ukraine",
        "name":"Ukraine"
        },
        {
        "value":"United_Kingdom",
        "name":"United Kingdom"
        },
        {
        "value":"Vatican_City",
        "name":"Vatican City"
        }
    ];
}
export const countryCods = () => {
    return {
        "Andorra": "AD",
        "Austria": "AT",
        "Belgium": "BE",
        "Bulgaria": "BG",
        "Croatia": "HR",
        "Cyprus": "CY",
        "Czech_Republic": "CZ",
        "Denmark": "DK",
        "Estonia": "EE",
        "Finland": "FI",
        "France": "FR",
        "Germany": "DE",
        "Greece": "GR",
        "Hungary": "HU",
        "Iceland": "IS",
        "Ireland_Republic_of": "IE",
        "Israel": "IL",
        "Italy": "IT",
        "Latvia": "LV",
        "Liechtenstein": "LI",
        "Lithuania": "LT",
        "Luxembourg": "LU",
        "Malta": "MT",
        "Monaco": "MC",
        "Netherlands": "NL",
        "Norway": "NO",
        "Poland": "PL",
        "Portugal": "PT",
        "Romania": "RO",
        "San_Marino": "SM",
        "Slovakia": "SK",
        "Slovenia": "SI",
        "Spain": "ES",
        "Sweden": "SE",
        "Switzerland": "CH",
        "Ukraine": "UA",
        "United_Kingdom": "GB",
        "Vatican_City": "VA"
    };
}
export const months = (lang) => {
    if(lang === 'ru'){
        return {
            short: ['Янв','Фев','Мар','Апр','Май','Июн','Июл','Авг','Сен','Окт','Ноя','Дек'],
            long: ['Января','Февраля','Марта','Апреля','Мая','Июня','Июля','Августа','Сентября','Окрября','Ноября','Декабря']
        }
    }else{
        return {
            short: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
            long: ['January','February','March','April','May','June','July','August','September','October','November','December']
        }
    }


}
export const langText = async (userLang = '') => {
    if(!userLang && fb.firebase.auth().currentUser) {
        const {data: user_data} = await api.get('userByID', {params: {userId: fb.firebase.auth().currentUser.uid}})

        if(user_data && user_data.hasOwnProperty('language') && user_data.language){
            userLang = user_data.language
            console.log('DB lang',userLang);
        }else{
            console.log('Firebase user language is not set');
        }
    }
    if(!userLang && window.localStorage.getItem('language')){
        userLang = window.localStorage.getItem('language')
        console.log('local lang',userLang);
    }
    if(!userLang){
        userLang = navigator.language || navigator.userLanguage;
        console.log('Browser lang',userLang);
    }
    return await api.get("s/textes?lang=" + (userLang.toLowerCase() === 'ru' ? 'ru' : 'en'))
        .then((result) => {
            return result.data
        });
}
export const actionCodeSettings = () => {

    if(process.env.REACT_APP_ENV === 'development'){
        return {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: 'https://l-d-control.web.app/accept-privacy',
            // This must be true.
            handleCodeInApp: true,
            iOS: {
            bundleId: 'com.example.ios'
            },
            android: {
            packageName: 'com.example.android',
            installApp: true,
            minimumVersion: '12'
            },
            dynamicLinkDomain:'libi.page.link'
        };
    }else{
        return {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: 'https://app.libi.com/accept-privacy',
            // This must be true.
            handleCodeInApp: true,
            iOS: {
            bundleId: 'com.example.ios'
            },
            android: {
            packageName: 'com.example.android',
            installApp: true,
            minimumVersion: '12'
            },
            dynamicLinkDomain:'ldskincare.page.link'
        };
    }
}

export const actionCodeForgotPasswordSettings = () => {

    if(process.env.REACT_APP_ENV === 'development'){
        return {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: 'https://l-d-control.web.app/admins',
            // This must be true.
            handleCodeInApp: true,
            iOS: {
            bundleId: 'com.example.ios'
            },
            android: {
            packageName: 'com.example.android',
            installApp: true,
            minimumVersion: '12'
            },
            dynamicLinkDomain:'libi.page.link'
        };
    }else{
        return {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: 'https://app.libi.com/admins',
            // This must be true.
            handleCodeInApp: true,
            iOS: {
            bundleId: 'com.example.ios'
            },
            android: {
            packageName: 'com.example.android',
            installApp: true,
            minimumVersion: '12'
            },
            dynamicLinkDomain:'ldskincare.page.link'
        };
    }
}











