import React, { Component } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Table, 
  Tab,
  Tabs  
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card.jsx";
import { expertListThArray } from "../../variables/Variables.jsx";
import * as fb from '../../firebase';  
import PageLoader from "../../components/Loader/PageLoader.jsx";

//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables.js"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import moment from "moment";
import { convertFirebseSecondsToDate } from "components/Helper/index.jsx";
import api from "api.js";
import { convertMilisecondsToDate } from "components/Helper/index.jsx";

class ExpertList extends Component {

  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      expertList:[],
      inactiveExpertList:[],
      errors:{}
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#active-expert-datatable').DataTable({
      "bStateSave": true
    });
    $('#inactive-expert-datatable').DataTable({
      "bStateSave": true
    });
  }

  fetchData = async() => {
    this.setState({ isLoading: true });

    const { data: experts } = await api.get('expertsList')
    let allExperts = []
    experts.forEach(dataObj => {
      allExperts.push({
        id: dataObj.id,
        firstName: dataObj.firstName,
        lastName: dataObj.lastName,
        email: dataObj.email,
        approved: dataObj.approved,
        deleted: dataObj.deleted,
        agreementID: dataObj.agreementID,
        created_seconds: new Date(dataObj.created).getTime(),
        created: convertMilisecondsToDate(new Date(dataObj.created).getTime())
      })
    })

    await fb.docsCollection.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        allExperts.forEach((expert, index) => {
          if(expert.agreementID === doc.id) {
            allExperts[index].pdf = doc.data().pdf
          }
        })
      });
    });

    const expertArr = allExperts.filter(expert => !expert.deleted);
    const inactiveExpertArr = allExperts.filter(expert => expert.deleted);

    this.setState({
      expertList:expertArr,
      inactiveExpertList:inactiveExpertArr,
      isLoading:false
    });
  }

  deleteUser = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await api.post('updateUser', {
        id,
        deleted:true
      });
      window.location.reload();
      // await this.fetchData(); 
    }
  }

  activeUser = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await api.post('updateUser', {
        id,
        deleted:false
      });
      window.location.reload();
      // await this.fetchData(); 
    }
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Experts"
                category="All Expert List"
                ctTableFullWidth
                ctTableResponsive
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Tabs defaultActiveKey="active-experts" id="uncontrolled-tab-example">
                    <Tab eventKey="active-experts" title="Active" >
                      <Table striped hover id="active-expert-datatable">
                        <thead>
                          <tr>
                            {expertListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.expertList.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{++key}</td>
                                <td><span style={{display:"none"}}>{val.created_seconds}</span>{val.created}</td>
                                <td>{(val.firstName && val.lastName) ? val.firstName + ' ' + val.lastName : '-'}</td>
                                <td>{val.email || '-'}</td>
                                <td>
                                  {(val.approved === true) && 'Approved'}
                                  {(val.approved === false) && 'Disapproved'}
                                  {(val.approved === undefined) && 'Not approved'}
                                </td>
                                <td>
                                  <span style={{ display: 'inline-block', width: '20px' }}>
                                    {val.pdf && <a href={val.pdf} target="_blank"><i class="fa fa-file-pdf-o" /></a>}
                                  </span>&nbsp;
                                  <Link to={`experts/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;
                                  <Link to={`#`} onClick={() => {this.deleteUser(val.id)}}><i className="fa fa-times" /></Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Tab>
                    <Tab eventKey="inactive-experts" title="Inactive" >
                      <Table striped hover id="inactive-expert-datatable">
                        <thead>
                          <tr>
                            {expertListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.inactiveExpertList.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{++key}</td>
                                <td>{val.created}</td>
                                <td>{(val.firstName && val.lastName) ? val.firstName + ' ' + val.lastName : '-'}</td>
                                <td>{val.email || '-'}</td>
                                <td>{(val.approved === true) ? 'Approved' : 'Pending'}</td>
                                <td>
                                  <span style={{ display: 'inline-block', width: '20px' }}>
                                    {val.pdf && <a href={val.pdf} target="_blank"><i class="fa fa-file-pdf-o" /></a>}
                                  </span>&nbsp;
                                  <Link to={`experts/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;
                                  <Link to={`#`} onClick={() => {this.activeUser(val.id)}}><i className="fa fa-times" /></Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ExpertList;
