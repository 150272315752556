import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import {
  convertFirebseSecondsToDate, convertMilisecondsToDate,
} from "../../components/Helper/index";
import { Link } from "react-router-dom";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables.js"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import api from "api.js";

const url = window.location.href;
const segment = url.substring(url.lastIndexOf('/') + 1);

class PayExpert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isLoading: true,
      eurCommissions: [],
      gbpCommissions: [],
      eurSelectedRows: [],
      gbpSelectedRows: [],
    }
  }

  componentDidMount = async () => {
    try {
      this.setState({ isLoading: true })

      let {data: paymentsData} = await api.get('paymentsByExpertID', {params: {expertId: segment, status: 'complete'}})

      paymentsData.filter(i => ['shopify', 'customer'].includes(i.payment_type) && !i.paid_out_time)

      const data = paymentsData.map(i => {
        const commission = i.consumerTotal - i.total

        return {
          ...i,
          created_seconds: new Date(i.time).getTime(),
          created: convertMilisecondsToDate(new Date(i.time).getTime()),
          consumerTotal: i.consumerTotal,
          commissions: commission,
          cpo: 0,
          cd: commission,
        }
      })

      const eurCommissions = []
      const gbpCommissions = []

      data
        .sort((a, b) => a.time.seconds < b.time.seconds ? 1 : -1)
        .forEach(i => {
          if(i.currency && i.currency === 'eur') {
            eurCommissions.push(i)
          } else {
            gbpCommissions.push(i)
          }
        })

      this.setState({ eurCommissions: eurCommissions, gbpCommissions: gbpCommissions })

      $('#eurCommissions').DataTable({
        "bStateSave": true
      });
      $('#gbpCommissions').DataTable({
        "bStateSave": true
      });
    } catch (err) {
      console.log('err', err)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  selectEurHandler = id => {
    if (this.state.eurSelectedRows.includes(id)) {
      this.setState({ eurSelectedRows: this.state.eurSelectedRows.filter(i => i !== id) })
    } else {
      this.setState({ eurSelectedRows: [...this.state.eurSelectedRows, id] })
    }
  }

  selectGbpHandler = id => {
    if (this.state.gbpSelectedRows.includes(id)) {
      this.setState({ gbpSelectedRows: this.state.gbpSelectedRows.filter(i => i !== id) })
    } else {
      this.setState({ gbpSelectedRows: [...this.state.gbpSelectedRows, id] })
    }
  }

  selectAllEurHandler = () => {
    if (this.state.eurCommissions.every(i => this.state.eurSelectedRows.includes(i.id))) {
      this.setState({ eurSelectedRows: [] })
    } else {
      this.setState({ eurSelectedRows: this.state.eurCommissions.map(i => i.id) })
    }
  }

  selectAllGbpHandler = () => {
    if (this.state.gbpCommissions.every(i => this.state.gbpSelectedRows.includes(i.id))) {
      this.setState({ gbpSelectedRows: [] })
    } else {
      this.setState({ gbpSelectedRows: this.state.gbpCommissions.map(i => i.id) })
    }
  }

  payEurHandler = async () => {
    let adminId = ''
    fb.auth.onAuthStateChanged((user) => {
      if (user) {
        adminId = user.uid;
      }
    });
    this.state.eurSelectedRows.forEach(async (id) => {
      await api.post('updateExpertPayment', {
        id,
        paid_out_time: fb.timestamp.serverTimestamp(),
        paid_out_user: adminId,
      })
    });
    await api.post('createExpertPayment', {
      expertId: segment,
      paid_ids: this.state.eurSelectedRows,
      paid_out_time: fb.timestamp.serverTimestamp(),
      paid_out_user: adminId,
      payment_type: "paid_out",
      ref: `LD${Math.round(new Date().getTime() / 1000)}`,
      status: "complete",
      time: fb.timestamp.serverTimestamp(),
      total: this.state.eurCommissions
        .filter(i => this.state.eurSelectedRows.includes(i.id))
        .reduce((prev, curr) => prev + curr.cd, 0),
      currency: 'eur'
    })
    
    window.location.href = `/admin/experts/edit/${segment}`
  }

  payGbpHandler = async () => {
    let adminId = ''
    fb.auth.onAuthStateChanged((user) => {
      if (user) {
        adminId = user.uid;
      }
    });
    this.state.gbpSelectedRows.forEach(async (id) => {
      await api.post('updateExpertPayment', {
        id,
        paid_out_time: fb.timestamp.serverTimestamp(),
        paid_out_user: adminId,
      })
    });
    await api.post('createExpertPayment', {
      expertId: segment,
      paid_ids: this.state.gbpSelectedRows,
      paid_out_time: fb.timestamp.serverTimestamp(),
      paid_out_user: adminId,
      payment_type: "paid_out",
      ref: `LD${Math.round(new Date().getTime() / 1000)}`,
      status: "complete",
      time: fb.timestamp.serverTimestamp(),
      total: this.state.gbpCommissions
        .filter(i => this.state.gbpSelectedRows.includes(i.id))
        .reduce((prev, curr) => prev + curr.cd, 0),
      currency: 'gbp'
    })
    
    window.location.href = `/admin/experts/edit/${segment}`
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Pay Expert"
                button={
                  <Link to={`/admin/experts/edit/${segment}`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                loader={this.state.isLoading && <PageLoader />}
                content={
                  <div>
                    <div style={{ marginTop: '40px' }}>
                    <Tabs defaultActiveKey="gbp" id="uncontrolled-tab-example">
                      <Tab eventKey="eur" title="EUR" >
                        <Table striped hover id="eurCommissions">
                          <thead>
                            <tr>
                              <th style={{ textAlign: 'center' }}>
                                <input
                                  type="checkbox"
                                  checked={this.state.eurCommissions.every(i => this.state.eurSelectedRows.includes(i.id))}
                                  onChange={this.selectAllEurHandler}
                                />
                              </th>
                              <th>Created</th>
                              <th>Value</th>
                              <th>ACR</th>
                              <th>Earned</th>
                              <th>Paid Out</th>
                              <th>Due</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.eurCommissions.map((val) => (
                              <tr key={val.id}>
                                <td style={{ textAlign: 'center', padding: '12px 20px 12px 8px' }}>
                                  <input
                                    type="checkbox"
                                    checked={this.state.eurSelectedRows.includes(val.id)}
                                    onChange={() => this.selectEurHandler(val.id)}
                                  />
                                </td>
                                <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                                <td>{val.total ? (val.total / 100).toFixed(2) : '-'}</td>
                                <td>{val.acr + '%' || '-'}</td>
                                <td>{val.commissions ? (val.commissions / 100).toFixed(2) : '-'}</td>
                                <td>{val.cpo ? (val.cpo / 100).toFixed(2) : '-'}</td>
                                <td>{val.cd ? (val.cd / 100).toFixed(2) : '-'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div style={{ marginBottom: '20px' }}>Total:&nbsp;
                          <b>€
                            {this.state.eurSelectedRows.length
                              ? (this.state.eurCommissions
                                .filter(i => this.state.eurSelectedRows.includes(i.id))
                                .reduce((prev, curr) => prev + curr.cd, 0) / 100).toFixed(2)
                              : '0.00'
                            }
                          </b>
                        </div>
                        <Button
                          className="btn-fill libi-admin-btn"
                          bsStyle="info"
                          fill
                          disabled={!this.state.eurSelectedRows.length}
                          onClick={this.payEurHandler}
                        >
                          Pay
                        </Button>
                      </Tab>
                      <Tab eventKey="gbp" title="GBP">
                        <Table striped hover id="gbpCommissions">
                          <thead>
                            <tr>
                              <th style={{ textAlign: 'center' }}>
                                <input
                                  type="checkbox"
                                  checked={this.state.gbpCommissions.every(i => this.state.gbpSelectedRows.includes(i.id))}
                                  onChange={this.selectAllGbpHandler}
                                />
                              </th>
                              <th>Created</th>
                              <th>Value</th>
                              <th>ACR</th>
                              <th>Earned</th>
                              <th>Paid Out</th>
                              <th>Due</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.gbpCommissions.map((val) => (
                              <tr key={val.id}>
                                <td style={{ textAlign: 'center', padding: '12px 20px 12px 8px' }}>
                                  <input
                                    type="checkbox"
                                    checked={this.state.gbpSelectedRows.includes(val.id)}
                                    onChange={() => this.selectGbpHandler(val.id)}
                                  />
                                </td>
                                <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                                <td>{val.total ? (val.total / 100).toFixed(2) : '-'}</td>
                                <td>{val.acr + '%' || '-'}</td>
                                <td>{val.commissions ? (val.commissions / 100).toFixed(2) : '-'}</td>
                                <td>{val.cpo ? (val.cpo / 100).toFixed(2) : '-'}</td>
                                <td>{val.cd ? (val.cd / 100).toFixed(2) : '-'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div style={{ marginBottom: '20px' }}>Total:&nbsp;
                          <b>£
                            {this.state.gbpSelectedRows.length
                              ? (this.state.gbpCommissions
                                .filter(i => this.state.gbpSelectedRows.includes(i.id))
                                .reduce((prev, curr) => prev + curr.cd, 0) / 100).toFixed(2)
                              : '0.00'
                            }
                          </b>
                        </div>
                        <Button
                          className="btn-fill libi-admin-btn"
                          bsStyle="info"
                          fill
                          disabled={!this.state.gbpSelectedRows.length}
                          onClick={this.payGbpHandler}
                        >
                          Pay
                        </Button>
                      </Tab>
                    </Tabs>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PayExpert;
