import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { Link } from "react-router-dom"; 
import * as fb from '../../firebase';
import {addAdminLog} from "../../components/Helper";

class EditShipping extends Component {

  constructor(props){
    super(props);
    this.state = {
      package_due_shift:"",
      ship_by_shift:"",
      shipmentAfterDays:"",
      disabled : false,
      error:"",
      errors:{}
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'package_due_shift') {
      this.setState({package_due_shift:value});
    }else if(name === 'ship_by_shift'){
        this.setState({ship_by_shift:value});
    }else if(name === 'shipmentAfterDays'){
        this.setState({shipmentAfterDays:value});
    }
  };

  componentDidMount = async () => {
    const shipment = await fb.metaCollection.doc('shipment').get();
    if(shipment.data()){
        let oneDay = 86400;
        this.setState({
            package_due_shift:shipment.data().package_due_shift/oneDay,
            ship_by_shift:shipment.data().ship_by_shift/oneDay,
            shipmentAfterDays:shipment.data().shipmentAfterDays,
            isLoading:false
        });
    }
  }

  handleValidation = () => {
    let {package_due_shift, ship_by_shift, shipmentAfterDays} = this.state;
    let errors = {};
    let formIsValid = true;

    //Package Due Shift
    if(!package_due_shift){
      formIsValid = false;
      errors["package_due_shift"] = "Package Due Shift is required";
    }

    //Ship By Shift
    if(!ship_by_shift){
      formIsValid = false;
      errors["ship_by_shift"] = "Ship By Shift is required";
    }

    //Shipment After Days
    if(!shipmentAfterDays){
        formIsValid = false;
        errors["shipmentAfterDays"] = "Shipment After Days is required";
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({disabled: true});
    if(this.handleValidation()){
        let oneDay = 86400;
        await fb.metaCollection.doc('shipment').update({
            package_due_shift: this.state.package_due_shift*oneDay,
            ship_by_shift: this.state.ship_by_shift*oneDay,
            shipmentAfterDays: Number(this.state.shipmentAfterDays)
        }).then(snapShot => {
          addAdminLog('meta_changed',
            `Changed the shipment meta data`)
            this.props.history.push('/admin/meta')
        }).catch(err=> {
            this.setState({
                error:`Something went wrong-${err}`,
                disabled: false
            });
        });
    }else{
        this.setState({
            disabled: false
        });
    }
  }

  

  render() {
    console.log('render', this.state)
    let obj = {
        array: []
      };
      for (var l=0;l<50;l++){
        obj.array[l] = l+1;
      }
      let optionItems = obj.array.map((item) =>
        <option key={item}>{item}</option>
      );
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Edit Shipment Text"
                button={
                  <Link to={`/admin/meta`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                            <FormLabel>Package Due Shift</FormLabel>
                            <select
                                className="form-control"
                                name="package_due_shift"
                                value={this.state.package_due_shift} 
                                onChange={(event)=>this.onChangeHandler(event)}
                            >
                                <option value="">Select Days</option>
                                {optionItems}
                            </select>
                            <span className="help-block">{this.state.errors.package_due_shift}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                            <FormLabel>Ship By Shift</FormLabel>
                            <select
                                className="form-control"
                                name="ship_by_shift"
                                value={this.state.ship_by_shift} 
                                onChange={(event)=>this.onChangeHandler(event)}
                            >
                                <option value="">Select Days</option>
                                {optionItems}
                            </select>
                            <span className="help-block">{this.state.errors.ship_by_shift}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                            <FormLabel>Shipment After Days</FormLabel>
                            <select
                                className="form-control"
                                name= "shipmentAfterDays"
                                value={this.state.shipmentAfterDays} 
                                onChange={(event)=>this.onChangeHandler(event)}
                            >
                                <option value="">Select Days</option>
                                {optionItems}
                            </select>
                            <span className="help-block">{this.state.errors.shipmentAfterDays}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading...' : 'Update'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditShipping;
