import React, { Component } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Table,  
  Tab,
  Tabs,
  Alert
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card.jsx";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import {userTreatmentThArray} from "../../variables/Variables";
import {convertFirebseSecondsToDate, convertMilisecondsToDate} from "../../components/Helper";
import $ from "jquery";
import api from "api.js";

class AssessmentView extends Component {

    constructor(props){
        super(props)          
        this.state = {
            userID:"",
            userVersion: null,
            language:"",
            currentTreatmentID:"",
            backendDomain:"",
            treatmentVersion:false,
            isLoading:true,
            isLoadingIframe:true,
            errors:{},
            error:"",
            baseResult:"",
            baseSkincare:"",
            baseProduct:"",
            newResult: '',
            userTreatment: [],
            domain: process.env.REACT_APP_API_URL
        }
    }

    componentDidMount = async () => {
        await this.fetchData();
        $('#skincare-datatable').DataTable({
            "bStateSave": true,
            "order": [[ 0, "desc" ]]
        });
    }
  
    fetchData = async () => {
        const url = window.location.href;
        let urlArr = url.split("/");
        const userId = urlArr[urlArr.length - 2];
        const currentTreatmentID = urlArr[urlArr.length - 1];
        try {
            const {data: userByID} = await api.get('userByID', {params: { userId }})

            this.setState({
                // domain: CRM_URL,
                userID: userByID.id,
                userVersion: userByID.version,
                userName: userByID.name,
                currentTreatmentID: userByID.currentTreatmentID,
                language: userByID.language
            })
            await this.loadIframeLinks()
            const { data: treatmentsByUserId } = await api.get('getTreatmentsByUserId', { params: { userId } })

            const userTreatmentArr = [];
            treatmentsByUserId.forEach(i => {
                if(i.answerId && i.matched && i.matched !== false){
                    let confirmed_time = i.hasOwnProperty('treatmentConfirmedBy') ? i.treatmentConfirmedBy.time : i.time
                    userTreatmentArr.push({
                        id:i.id,
                        confirmed_by: i.hasOwnProperty('treatmentConfirmedBy') && i.treatmentConfirmedBy.adminName,
                        confirmed: convertMilisecondsToDate(new Date(confirmed_time).getTime()),
                        confirmed_seconds: confirmed_time,
                        time: convertMilisecondsToDate(new Date(i.time).getTime()),
                        seconds: new Date(i.time).getTime()
                    })
                    this.setState({
                        i:userTreatmentArr
                    });
                }
            });
        }catch(err) {
            this.setState({error:err.message})
        }
        this.setState({isLoading: false})
    }

    loadIframeLinks = async () => {
        this.setState({
            baseResult: '',
            baseSkincare: '',
            baseProduct: '',
            isLoadingIframe: true
        })
        const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: this.state.userID } })
        const data = treatmentsByUserId.find(i => i.id === this.state.currentTreatmentID)
        
        if(data) {
            let baseResult, baseSkincare, baseProduct, newResult
            if (data.version && data.version === 2) {
                baseResult = `${this.state.domain}s/diagmustache/${this.state.userID}/${this.state.currentTreatmentID}/baseResults`
                baseSkincare = `${this.state.domain}s/diagmustache/${this.state.userID}/${this.state.currentTreatmentID}/basePlan`
                baseProduct = `${this.state.domain}s/diagmustache/${this.state.userID}/${this.state.currentTreatmentID}/baseProducts`
            } else if (data.version && data.version === 3) {
                newResult = `${this.state.domain}s/af_html/${this.state.userID}/description?treatmentId=${this.state.currentTreatmentID}`
            } else {
                baseResult = `${this.state.domain}s/diaghtml2/description/${this.state.userID}?lang=${this.state.language}&treatmentId=${this.state.currentTreatmentID}`
                baseSkincare = `${this.state.domain}s/diaghtml2/skinCarePlan/${this.state.userID}?lang=${this.state.language}&treatmentId=${this.state.currentTreatmentID}`
                baseProduct = `${this.state.domain}s/diaghtml2/products/${this.state.userID}?lang=${this.state.language}&treatmentId=${this.state.currentTreatmentID}`
            }
            this.setState({
                baseResult: baseResult,
                baseSkincare: baseSkincare,
                baseProduct: baseProduct,
                newResult: newResult,
                isLoadingIframe: true
            })
        }
    }

    showCurrentTreatmentAssessment = (treatment_id) => {
        this.setState({
            currentTreatmentID:treatment_id
        })
        this.loadIframeLinks()
    }

    render() {
        console.log('render', this.state)
        return (
            <div className="content">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            {this.state.error &&
                            <Alert bsStyle="danger">
                                <span>{this.state.error}</span>
                            </Alert>
                            }
                            <Card
                                title="Assessment Details"
                                category=""
                                ctTableFullWidth
                                ctTableResponsive
                                loader={ this.state.isLoading && <PageLoader />}
                                button={
                                    <div>
                                        {window.history.length > 1 &&
                                            <div
                                              onClick={(event) => {
                                                  window.history.back()
                                              }}
                                              className="btn btn-success libi-admin-btn"
                                              style={{float: "right !important"}}
                                            >Back</div>
                                        }
                                    </div>
                                }
                                content={
                                    <Row>
                                        <Col md={6}>
                                            <Card
                                              title="Treatment Plan"
                                              category=""
                                              ctTableFullWidth
                                              ctTableResponsive
                                              loader={ this.state.isLoading && <PageLoader />}
                                              content={
                                                  <Table striped hover id="skincare-datatable">
                                                      <thead>
                                                      <tr>
                                                          {userTreatmentThArray.map((val, key) => {
                                                              return <th key={key}>{val}</th>;
                                                          })}
                                                      </tr>
                                                      </thead>
                                                      <tbody>
                                                      {this.state.userTreatment.map((val, key) => {
                                                          return (
                                                            <tr key={key}>
                                                                <td data-sort={val.seconds}>{val.time}</td>
                                                                <td data-sort={val.confirmed_seconds}><Link target="_blank" to={`/admin/users/${this.state.userVersion == 2 ? 'assessment' : 'diagnosis'}/${this.state.userID}/${val.id}?type=t`}>{val.confirmed}</Link></td>
                                                                <td style={{wordBreak:"break-all"}}>{val.confirmed_by}</td>
                                                                <td>
                                                                    <Link target="_blank" to={`/admin/users/${this.state.userVersion == 2 ? 'assessment' : 'diagnosis'}/${this.state.userID}/${val.id}?type=t`}><i className="pe-7s-note2" /></Link>
                                                                    <div className={`fake_link ${this.state.currentTreatmentID === val.id ? 'active' : ''}`} onClick={() => {this.showCurrentTreatmentAssessment(val.id)}}>
                                                                        {this.state.currentTreatmentID === val.id ? <i className="fa fa-arrow-right" /> : <i className="fa fa-eye" />}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                          );
                                                      })}
                                                      </tbody>
                                                  </Table>
                                              }
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Card
                                              title={<a href={`/admin/users/view/${this.state.userID}`}>{this.state.userName}</a>}
                                              loader={ this.state.isLoadingIframe && <PageLoader />}
                                              content={
                                                this.state.newResult ?
                                                    <div className="skincareContent">
                                                        <iframe
                                                            src={this.state.newResult}
                                                            onLoad={() => {
                                                                this.setState({isLoadingIframe: false})
                                                            }}
                                                        />
                                                    </div> :
                                                    <Tabs defaultActiveKey="result" id="uncontrolled-tab-example">
                                                        <Tab eventKey="result" title="Assessment">
                                                            <div className="skincareContent">
                                                                <iframe
                                                                    src={this.state.baseResult}
                                                                    onLoad={() => {
                                                                        this.setState({isLoadingIframe: false})
                                                                    }}
                                                                />
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="skincare" title="Skincare">
                                                            <div className="skincareContent">
                                                                <iframe src={this.state.baseSkincare}/>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="product" title="Product">
                                                            <div className="skincareContent">
                                                                <iframe src={this.state.baseProduct}/>
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                }
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default AssessmentView;
