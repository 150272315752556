import React, { Component } from "react";
import {staticImages} from "components/Helper/index";
import ImageView from "components/Model/ImageView";
import VideoView from "components/Model/VideoView";
import * as fb from '../../firebase';
import {
    convertFirebseSecondsToDate,
    convertFirebseSecondsToTime
} from "../Helper/index";
import { Link } from "react-router-dom";
import AddAdminToChatView from "components/Model/AddAdminToChatView";
import axios from 'axios';
import {Alert} from "react-bootstrap";
import SelectSearch from "react-select-search";
import api from "api";

class ChatBoard extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading:true,
            _setuser:true,
            isOpen:false,
            currentUser:"",
            content:"",
            chats:[],
            currentPeerUser:this.props.currentPeerUser,
            createdOn:"",
            error:"",
            openImage:"",
            type:"",
            isAddAdmin:false,
            selectedAdmins:[],
            fakeChatAdmins:[],
            fakeChatAdmin:"",
            initChat:true,
            staticImages: {},
            admin: {}
        }
    }

    handleShowDialog = (status, src, type) => {
        this.setState({ isOpen: status, openImage: src, type:type})
    };
    
    listenNewData = async () => {
        let chatArr = [];

        await fb.chatRoomCollection.doc(this.state.currentPeerUser.userID).get().then(messageStatusAdminRef=> {

            if(messageStatusAdminRef.data().messageStatusAdmin===false){

                const chatRoomsRef = fb.chatRoomCollection
                .doc(this.state.currentPeerUser.userID)
                chatRoomsRef.update({
                    messageStatusAdmin:true,
                    messageStatusAdminId: this.state.admin.uid,
                    messageStatusAdminName: this.state.admin.email,
                    messageStatusAdminReadTime: fb.timestamp.serverTimestamp(),
                    lastEmailNotification:fb.firebase.firestore.FieldValue.delete()
                }).then(res => {
                });
            }
        })


        //shown current thread admin
        if(this.state.currentPeerUser.admins){
            try{
                const {data: userByID} = await api.get('userByID', {params: {userId: this.state.currentPeerUser.admins}})
                
                const chatAdmins = [];
                const allUserIds = [];
                userByID.forEach(dataObj1 => {
                    allUserIds.push(dataObj1.id);
                    if(allUserIds.includes(this.state.currentUser.userID)){
                        this.setState({
                            initChat:false
                        });
                    } else {
                        this.setState({initChat:true});
                    }
                    chatAdmins.push({
                        id:dataObj1.id,
                        name:dataObj1.name
                    })
                })
                this.setState({
                    selectedAdmins:chatAdmins
                });
            }catch(err){
                this.setState({error:err.message})
            }
        }

        //fetch chat admins
        const data = await fb.chatAdminsCollection.get();
        const fakeChatAdminsArr = [];
        data.docs.forEach(doc=> {
            const dataObj = doc.data();
            fakeChatAdminsArr.push({
                id:dataObj.id,
                value:dataObj.id,
                name:dataObj.name,
                picture:dataObj.picture
            });
        })
        this.setState({
            fakeChatAdmins:fakeChatAdminsArr
        });

        //get rooms
        fb.chatsCollection.doc(this.state.currentPeerUser.userID)
        .collection('messages')
        .orderBy("createdOn", "asc")
        .onSnapshot(snapshot => {
            snapshot.docChanges().forEach(change => {
                if (change.type === "added") {
                    const dataObj = change.doc.data();

                    //update last seen for only user's chat
                    if(dataObj.chatId === dataObj.userId){
                        fb.chatsCollection
                        .doc(this.state.currentPeerUser.userID)
                        .collection('messages')
                        .doc(dataObj.id)
                        .update({ seen: true });
                    }
                    let content = "";
                    if(dataObj.type===1){
                        content = this.urlify(dataObj.content.replace(/<(?!br\s*\/?)[^>]+>/g, ''));
                    }else{
                        content = dataObj.content;
                    }
                    chatArr.push({
                        id:dataObj.id,
                        chatId:dataObj.chatId,
                        userName:dataObj.userName,
                        picture:dataObj.picture,
                        content:content,
                        type:dataObj.type,
                        sent_by:dataObj.sent_by,
                        userId:dataObj.userId,
                        createdOn:dataObj.createdOn
                    })
                }
            })
            this.setState({
                chats:chatArr,
                isLoading:false
            });
        });
    }

    urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex)
          .map((part, k) => {
            if (part.match(urlRegex)) {
              return `<a href=${part} target="_blank">${part}</a>`;
            }
            return part;
          }).join('');
      }

    scrollToBottom = () => {
        if (this.messagesEnd) {
          this.messagesEnd.scrollIntoView({})
        }
    }

    onKeyboardPress = event => {
        if (event.which === 13 && !event.shiftKey) {
            event.preventDefault();
            this.handleSubmit(this.state.content, 1)
        }
        this.textareaAutoHeight(event.target)
    }

    textareaAutoHeight = target => {
        target.style.height = 'inherit';
        let scrollHeight = target.scrollHeight;
        target.style.height = `${Math.min(scrollHeight, 130)}px`;
    }

    onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        if(name === 'content') {
            localStorage.setItem('text_to_'+this.state.currentPeerUser.userID,value)
          this.setState({content:value});
        }
        if(name === 'fakeChatAdmin') {
            this.setState({fakeChatAdmin:value});
        }
    };

    uploadPhoto = (file) => {
        if (file && file.name) {
            const timestamp = Math.floor(Date.now() / 1000);
            const uploadTask = fb.storage.child(`chats/${timestamp}-${file.name}`).put(file)
            uploadTask.on('state_changed',null, err => {
                this.setState({isLoading: false})
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                this.handleSubmit(downloadURL, 2)
                })
            })
        } else {
            this.setState({isLoading: false})
        }
    }

    uploadVideo = (file) => {
        if (file && file.name) {
            const timestamp = Math.floor(Date.now() / 1000);
            const uploadTask = fb.storage.child(`chats/${timestamp}-${file.name}`).put(file)
            uploadTask.on('state_changed',null, err => {
                this.setState({isLoading: false})
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                this.handleSubmit(downloadURL, 3)
                })
            })
        } else {
            this.setState({isLoading: false})
        }
    }
    
    onChoosePhoto = event => {
        if(event.target.files && event.target.files[0]) {
            // Check this file is an image?
            const prefixFiletype = event.target.files[0].type.toString()
            if (prefixFiletype.indexOf('image/') === 0) {
                this.uploadPhoto(event.target.files[0]);
            } else if(prefixFiletype.indexOf('video/') === 0){
                this.uploadVideo(event.target.files[0]);
            }
        }else {
            this.setState({isLoading: false})
        }
    }

    handleSubmit = async (content, type) => {
        //blank input
        localStorage.setItem('text_to_'+this.state.currentPeerUser.userID,'')
        this.setState({ content: ''})
        if (content.trim() === '') {
          return
        }
        content = content.replace(/(?:\r\n|\r|\n)/g, '<br>')
        const chatRoomRef = fb.chatRoomCollection.doc(this.state.currentPeerUser.userID);
        await chatRoomRef.update({
            id: chatRoomRef.id,
            lastUserID:this.state.currentUser.userID,
            lastMessage:content,
            type: type,
            lastName:this.state.currentUser.name,
            peerName:this.state.currentPeerUser.name || "",
            lastUserPic:this.state.currentPeerUser.userPic ? this.state.currentPeerUser.userPic : "",
            createdOn:fb.timestamp.serverTimestamp(),
            messageStatusAdmin:true,
            messageStatusClient:false,
            messageStatusClientReadTime: null
        }).then(res => {
            //check for fake admin

            let userName = "";
            let picture = "";
            if(this.state.fakeChatAdmin !== ""){
                var item = this.state.fakeChatAdmins.find(item => item.id === this.state.fakeChatAdmin);
                userName = item.name;
                picture = item.picture;
            }else{
                userName = this.state.currentUser.name;
                picture = this.state.currentUser.picture ? this.state.currentUser.picture : "";
            }
            const chatRef = fb.chatsCollection.doc(this.state.currentPeerUser.userID)
            .collection('messages')
            .doc();
            chatRef.set({
                id: chatRef.id,
                chatId: chatRoomRef.id,
                content: content,
                type: type,
                sent_by: "Admin",
                seen: false,
                userId:this.state.currentUser.userID,
                userName:userName,
                picture:picture,
                peerName:this.state.currentPeerUser.name || "",
                createdOn:fb.timestamp.serverTimestamp()
            }).then(res => {
            }); 
        });

        //send notification
        try{
            let title=encodeURIComponent("New Message");
            let message = encodeURIComponent(content);
            let data = "chat";
            const result = await api.get(`s/send?topic=${topic}&title=${title}&message=${message}&data=${data}`)
            console.log('s/send result', result);
            let topic = `${this.state.currentPeerUser.userID}c`;
        }catch (e) {
            console.error('s/send result', e);
        }
    }

    getCurrentUserData = async (id) => {
        const {data: userByID} = await api.get('userByID', {params: {userId: id}})
        this.setState({
            currentUser: userByID
        }, ()=> {
            this.listenNewData();//listen new data 
        })
    }

    setCurrentUser =  async () => {
        fb.auth.onAuthStateChanged(async (user) => {
            if (user != null) {
                await this.getCurrentUserData(user.uid);
            }
        });
    }


    componentDidMount = async () => {
        await this.setState({ admin: fb.firebase.auth().currentUser });
        const si = await staticImages()
        this.setState({staticImages: si})
        await this.setCurrentUser();
        if (this.props.currentPeerUser) {
            this.setState({content: localStorage.getItem('text_to_'+this.props.currentPeerUser.userID) || ''})
            this.setState({currentPeerUser:this.props.currentPeerUser});
        }
    }

    componentWillReceiveProps = async (newProps) => {
        if (newProps.currentPeerUser) {
            this.setState({content: localStorage.getItem('text_to_'+newProps.currentPeerUser.userID) || ''})
            this.setState({currentPeerUser:newProps.currentPeerUser}, () => {
                this.listenNewData();//listen new data 
            });
        }
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    deleteMeFromChat = async(id) => {
        //shown current thread admin
        if (window.confirm("Do you want to remove yourself from this chat ?")) {
            if(this.state.currentPeerUser.admins){
                const chatRoomRef = fb.chatRoomCollection
                .doc(this.state.currentPeerUser.userID)
                .update({   
                    admins: this.state.currentPeerUser.admins.filter(post => post !== id)
                }).catch(function(error) {
                    console.error("Error removing document: ", error);
                });
            }
        }
    }

    removeMessage = (id) => {
        if (window.confirm("Do you want to remove this message?")) {
            fb.chatsCollection.doc(this.state.currentPeerUser.userID)
              .collection('messages')
              .doc(id)
              .delete().then(doc => {
                this.listenNewData();
            })
        }
    }

    renderListMessage = () => {
        let viewListMessage = [];
        this.state.chats.forEach((chat, key) => {
          if(chat.sent_by === "Admin"){
            if(chat.content && chat.type===1){
              viewListMessage.push(
                <div className="outgoing_msg" key={key}>
                    <div className="outgoing_msg_img">
                        <img src={chat.picture ? chat.picture : this.state.staticImages.defaultAdminAvtar} alt="" />
                     </div>
                    <div className="sent_msg">
                        <div className="sent_msg_content" dangerouslySetInnerHTML={{__html: chat.content}}></div>
                        <div className="time_date">{chat.userName} | {chat.createdOn ? convertFirebseSecondsToDate(chat.createdOn.seconds) : ""} | {chat.createdOn ? convertFirebseSecondsToTime(chat.createdOn.seconds) : ""}</div>
                    </div>
                    <div className="message_action_btns">
                        <div onClick={() => {this.removeMessage(chat.id)}}><i className="fa fa-trash-o" aria-hidden="true"></i></div>
                    </div>
                </div>
              )
            }else if(chat.type === 2) {
              viewListMessage.push(
                <div className="outgoing_msg" key={key}>
                    <div className="outgoing_msg_img"> <img src={chat.picture ? chat.picture : this.state.staticImages.defaultAdminAvtar} alt="" /> </div>
                    <div className="sent_msg">
                        <img
                            className="chat-file"
                            src={chat.content}
                            alt=""
                            onClick={ () => { this.handleShowDialog(true, chat.content, chat.type) }}
                        />
                        <div className="time_date">{chat.userName} | {chat.createdOn ? convertFirebseSecondsToDate(chat.createdOn.seconds) : ""} | {chat.createdOn ? convertFirebseSecondsToTime(chat.createdOn.seconds) : ""}</div>
                    </div>
                </div>
              )
            }else if(chat.type === 3) {
                viewListMessage.push(
                  <div className="outgoing_msg" key={key}>
                      <div className="outgoing_msg_img"> <img src={chat.picture ? chat.picture : this.state.staticImages.defaultAdminAvtar} alt="" /> </div>
                      <div className="sent_msg" onClick={ () => { this.handleShowDialog(true, chat.content, chat.type) }}>
                          <video src={chat.content} width="270" height="200" controls/>
                          <div className="time_date">{chat.userName} | {chat.createdOn ? convertFirebseSecondsToDate(chat.createdOn.seconds) : ""} | {chat.createdOn ? convertFirebseSecondsToTime(chat.createdOn.seconds) : ""}</div>
                      </div>
                  </div>
                )
              }
          }else{
            if(chat.type===1){
                viewListMessage.push(
                    <div className="incoming_msg" key={key}>
                        <div className="incoming_msg_img"> <img src={chat.picture ? chat.picture : this.state.staticImages.defaultAvtar} alt="" /> </div>
                        <div className="received_msg">
                            <div className="received_withd_msg">
                                <div className="received_withd_msg_content" dangerouslySetInnerHTML={{__html: chat.content}}></div>
                                <div className="time_date">{chat.userName} | {chat.createdOn ? convertFirebseSecondsToDate(chat.createdOn.seconds) : ""} | {chat.createdOn ? convertFirebseSecondsToTime(chat.createdOn.seconds) : ""}</div>
                            </div>
                        </div>
                    </div>
                )
            }else if(chat.type===2){
              viewListMessage.push(
                <div className="incoming_msg" key={key}>
                    <div className="incoming_msg_img"> <img src={chat.picture ? chat.picture : this.state.staticImages.defaultAvtar} alt="" /> </div>
                    <div className="received_msg">
                        <div className="received_withd_msg">
                            <img
                                className="chat-file" 
                                src={chat.content} 
                                alt=""  
                                onClick={ () => {this.handleShowDialog(true, chat.content, chat.type)}}
                            />
                            <div className="time_date">{chat.userName} | {chat.createdOn ? convertFirebseSecondsToDate(chat.createdOn.seconds) : ""} | {chat.createdOn ? convertFirebseSecondsToTime(chat.createdOn.seconds) : ""}</div>
                        </div>
                    </div>
                </div>
              )
            }else if(chat.type===3){
                viewListMessage.push(
                  <div className="incoming_msg" key={key}>
                      <div className="incoming_msg_img"> <img src={chat.picture ? chat.picture : this.state.staticImages.defaultAvtar} alt="" /> </div>
                      <div className="received_msg">
                          <div className="received_withd_msg" onClick={ () => { this.handleShowDialog(true, chat.content, chat.type) }}>
                              <video src={chat.content} width="270" height="200" controls/>
                              <div className="time_date">{chat.userName} | {chat.createdOn ? convertFirebseSecondsToDate(chat.createdOn.seconds) : ""} | {chat.createdOn ? convertFirebseSecondsToTime(chat.createdOn.seconds) : ""}</div>
                          </div>
                      </div>
                  </div>
                )
            }
          }
        })
        return viewListMessage;
    }

    handleAddAdminDialog = (status) => {
        this.setState({ isAddAdmin: status})
    };

    renderAdminSelect (props, option, snapshot, className) {
        const imgStyle = {
            borderRadius: '50%',
            verticalAlign: 'middle',
            marginRight: 10,
        };

        return (
          <button {...props} className={className} type="button">
              <span><img alt="" style={imgStyle} width="32" height="32" src={option.picture} /><span className="custom_select_title">{option.name}</span></span>
          </button>
        );
    }
    renderAdminSelectView(valueProps, snapshot, className) {
        const { value } = snapshot;
        const imgStyle = {
            borderRadius: '50%',
            verticalAlign: 'middle',
            marginRight: 10,
        };
        return (
          <span style={{display:'flex',alignItems:'center'}}>
              <img alt="" style={imgStyle} width="32" height="32" src={value ? value.picture : this.state.staticImages.defaultAdminAvtar}/>
              <input {...valueProps} className={className} />
          </span>
        );
    }


    render() {
        console.log('chat state', this.state)
        return (
            <div className="mesgs">
                {this.state.error && <Alert bsStyle="danger">
                    <span>{this.state.error}</span>
                    </Alert>
                }
                {this.state.isOpen &&  (
                    this.state.type===2 ?
                        <ImageView
                        show={this.state.isOpen}
                        src={this.state.openImage}
                        onHide={() => this.handleShowDialog(false, this.state.openImage, this.state.type)}
                    />
                        :
                        <VideoView
                        show={this.state.isOpen}
                        src={this.state.openImage}
                        onHide={() => this.handleShowDialog(false, this.state.openImage, this.state.type)}
                    />                                        
                )}
                { 
                    this.state.isAddAdmin &&  
                    <AddAdminToChatView
                        show={this.state.isAddAdmin}
                        data={{currentPeerUser:this.state.currentPeerUser, currentUser:this.state.currentUser}}
                        onHide={() => this.handleAddAdminDialog(false)}
                    />
                }
                <div className="mainTopDiv">
                    <span className="group-admin">
                        <span className="chat_back" onClick={() => {this.props.onExit()}}><i className="fa fa-chevron-left" aria-hidden="true"/></span>
                        <Link to={`#`} onClick={() => {this.handleAddAdminDialog(true)}} className="btn btn-success btn-small btn-fill">View Participants</Link>
                    </span>
                    <div className="group-users">
                        <SelectSearch
                          options={this.state.fakeChatAdmins}
                          value={this.state.fakeChatAdmin}
                          renderOption={this.renderAdminSelect}
                          renderValue={this.renderAdminSelectView.bind(this)}
                          name="fakeChatAdmin"
                          placeholder="Select Admin"
                          onChange={(event)=>
                            this.onChangeHandler({currentTarget:{name:'fakeChatAdmin',value:event}})}
                        />
                    </div>
                </div>
                <div className="msg_history">
                    { this.state.chats && this.renderListMessage() }
                    <div
                    style={{float: 'left', clear: 'both'}}
                    ref={el => {
                      this.messagesEnd = el
                    }}
                  />
                </div>
                <form>
                    <div className="type_msg">
                    { this.state.initChat ? <p className="not-added">Please add yourself to Start the Chat!</p> :
                        <div className="input_msg_write">
                            <div className="share-file"
                                 onClick={() => this.refInput.click()}
                                 disabled={this.state.initChat}
                            ><i className="fa fa-plus" aria-hidden="true"></i></div>
                            <input
                                style={{
                                    opacity: 0,
                                    position: "absolute",
                                    zIndex: -1,
                                    left: "0px",
                                    width: "0px",
                                }}
                                ref={el => { this.refInput = el }}
                                accept="image/video/*"
                                className="viewInputGallery"
                                type="file"
                                onChange={this.onChoosePhoto}
                                disabled={this.state.initChat}
                            />
                            <textarea
                                type="text"
                                className="write_msg_chat"
                                placeholder="Type a message"
                                name="content"
                                value={this.state.content}
                                rows="1"
                                id="textarea_content"
                                onChange={(event)=>this.onChangeHandler(event)}
                                onKeyDown={(event)=>this.onKeyboardPress(event)}
                                disabled={this.state.initChat}
                            />
                            <button
                                className="msg_send_btn"
                                type="button"
                                onClick={() => this.handleSubmit(this.state.content, 1)}
                                disabled={this.state.initChat}
                            >
                                <i className="fa fa-paper-plane-o" aria-hidden="true" />
                            </button>
                        </div>
                        }
                    </div>
                </form>
            </div>
        );
    }
}

export default ChatBoard;
