import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Alert
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { Link } from "react-router-dom"; 
import * as fb from '../../firebase';
import './Email.css';
import { convertFirebseSecondsToDate } from "components/Helper";

class ViewEmail extends Component {
  constructor(props){
    super(props);
    this.state = {
      id:"",
      userId:"",
      body:"",
      recipients:"",
      subject:"",
      created : ""
    }
  }

  componentDidMount = async () => {
    const  url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    await fb.emailsCollection.doc(segment).get().then(res => {
      const dataObj = res.data();
      this.setState({
        id:dataObj.id,
        userId:dataObj.userId,
        body:dataObj.body,
        recipients:dataObj.recipients,
        subject:dataObj.subject,
        created: dataObj.sent ? convertFirebseSecondsToDate(dataObj.sent.seconds) : 'N/A'
      });
    });
  }

  

  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="View Recipient"
                category=""
                button={
                  <Link to={`/admin/emails`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <div>
                    <div className="">
                        <span className="category text-primary">Recipients:</span> {this.state.recipients}
                    </div>
                    <div className="">
                        <span className="category text-primary">Subject:</span> {this.state.subject}
                    </div>
                    <div className="">
                        <span className="category text-primary">Body:</span> {this.state.body}
                    </div>
                    <div className="">
                        <span className="category text-primary">Sent:</span> {this.state.created}
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ViewEmail;
