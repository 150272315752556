import React, { Component } from "react";
import * as fb from '../../firebase';

class UploadAdapter {
  
  constructor(loader) {
    this.loader = loader
  }

  upload() {
    return this.loader.file.then((file) =>
      new Promise((resolve, reject) => {
        //firebase reference
        console.log('file.name', file.name)
        let uploadTask = fb.storage.child(`images/${file.name}`).put(file)
        uploadTask.on(fb.firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            /* snapshot with info about 
            the upload progress & metadata */
          },
          (error) => {
            // error handling
          },
          () => {
            // upload successful
            uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              resolve({
                default: downloadURL
              })
            })
          }
        )
      })
    )
  }
}

export default UploadAdapter;
