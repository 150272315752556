import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
  Table,
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { Link } from "react-router-dom";
import * as fb from "../../firebase";
import {
  addAdminLog,
  removeHTMLTags,
  treatmentTimeCases,
} from "components/Helper/index";
import AddProductView from "components/Model/AddProductView";
import PageLoader from "components/Loader/PageLoader.jsx";
import "./User.css";
import axios from "axios";
import phoneTransparent from "assets/img/phone_transparrent.png";
import CustomNumberField from "../../components/CustomNumberField/CustomNumberField";
import TemplateComponent from 'react-mustache-template-component'
import './UserExternal.scss';
import Switch from "react-switch";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import UploadAdapter from "../../components/image-adaptor/UploadAdapter";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import AnswersView from "./AnswersList";
import UserAssessmentForm, { assessmentFields } from "./UserAssessmentForm";
import {Multiselect} from "multiselect-react-dropdown";
import {areaOptions} from "../rules/AddRule";
import api from "api";
import { convertMilisecondsToDate } from "components/Helper";

class UserDiagnosisNew extends Component {
  constructor(props){
    super(props);
    this.state = {
      admin: {},
      userId:"",
      answerId:"",
      id:"",
      ref:"",
      disabled : false,
      sendDisabled : false,
      options: [],
      products: {},
      selectedProducts: [],
      isAddProduct: false,
      isLoading: false,
      isTreatment: false,
      treatment_answer: "",
      successMessage: "",
      errorMessage: "",
      error: "",
      errors: {},
      t_ref: "",
      language: "EN",
      u_language: "EN",
      allProducts: [],
      allMustachesTemplates: [],
      allMustachesRecommendationTemplates: [],
      allMustachesProcedureTemplates: [],
      mustachesRecommendationOpened: false,
      mustachesProcedureOpened: false,
      mustacheCardGroups: [],
      mustacheCardUIGroups: [],
      skincareTab: "results",
      additional_comment_results: "",
      additional_comment_skincare: "",
      backendDomain: '',
      matched: false,
      baseResults: "",
      basePlan: "",
      baseProducts: "",
      userObj:{},
      mustaches:{},
      diaghtmlObj:{},
      showAdditionalOptions: false,
      treatmentNotExist: false,
      isAssessment: window.location.href.includes('assessment'),
      answersList: [],
      initialAssessment: {
        ['box_composed_by_ld']: 'yes',
        ['questionnaire_passed']: 'offline'
      },
      assessmentIsDisabled: false,
      minProductsQty: 0,
      maxProductsQty: 0,
      productsIsDisabled: window.location.href.includes('assessment')
    };
  }

  onChangeHandler = async (event, ref = 0) => {
    const {name, value} = event.currentTarget;
    let dataname = ''
    if (event.target) {
      dataname = event.target.getAttribute("data-name");
    }
    let masterProduct
    if (name === "product_" + ref) {
      masterProduct = this.state.allProducts.find(
        (item) => item.ref === ref
      );
      let {products} = this.state;
      //check check or uncheck
      if (Object.keys(products).includes(ref)) {
        delete products[ref];
      } else {
        products[ref] = {
          ref: ref,
          title: masterProduct.title,
          title_ru: masterProduct.title_ru,
          description: masterProduct.description,
          description_ru: masterProduct.description_ru,
          order: masterProduct.order,
          rule: '',
          time: '',
          frequency: '',
          qty: 1,
          area: []
        };
      }
      this.setState({products: products});
    } else if (name === "rule_" + ref) {
      masterProduct = this.state.allProducts.find(
        (item) => item.ref === ref
      );
      let {products} = this.state;
      products[ref] = {
        ref: ref,
        title: masterProduct.title,
        title_ru: masterProduct.title_ru,
        description: masterProduct.description,
        description_ru: masterProduct.description_ru,
        order: masterProduct.order,
        rule: value,
        time: '',
        frequency: this.state.products[ref] ? this.state.products[ref].frequency : "",
        qty: this.state.products[ref] ? this.state.products[ref].qty : 1,
        area: this.state.products[ref] ? this.state.products[ref].area : [],
      };
      this.setState({products: products});
    } else if (name === "time_" + ref) {
      masterProduct = this.state.allProducts.find(
        (item) => item.ref === ref
      );
      let {products} = this.state;
      products[ref] = {
        ref: ref,
        title: masterProduct.title,
        title_ru: masterProduct.title_ru,
        description: masterProduct.description,
        description_ru: masterProduct.description_ru,
        order: masterProduct.order,
        rule: '',
        time: value,
        frequency: this.state.products[ref] ? this.state.products[ref].frequency : "",
        qty: this.state.products[ref] ? this.state.products[ref].qty : 1,
        area: this.state.products[ref] ? this.state.products[ref].area : [],
      };
      this.setState({products: products});
    } else if (name === "frequency_" + ref) {
      masterProduct = this.state.allProducts.find(
        (item) => item.ref === ref
      );
      let {products} = this.state;
      products[ref] = {
        ref: ref,
        title: masterProduct.title,
        title_ru: masterProduct.title_ru,
        description: masterProduct.description,
        description_ru: masterProduct.description_ru,
        order: masterProduct.order,
        rule: this.state.products[ref] ? this.state.products[ref].rule : "",
        time: this.state.products[ref] ? this.state.products[ref]?.time : "",
        frequency: value,
        qty: this.state.products[ref] ? this.state.products[ref].qty : 1,
        area: this.state.products[ref] ? this.state.products[ref].area : [],
      };
      this.setState({products: products});
    } else if (name === "qty_" + ref) {
      masterProduct = this.state.allProducts.find(
        (item) => item.ref === ref
      );
      let {products} = this.state;
      products[ref] = {
        ref: ref,
        title: masterProduct.title,
        title_ru: masterProduct.title_ru,
        description: masterProduct.description,
        description_ru: masterProduct.description_ru,
        order: masterProduct.order,
        qty: value,
        rule: this.state.products[ref] ? this.state.products[ref].rule : "",
        time: this.state.products[ref] ? this.state.products[ref]?.time : "",
        frequency: this.state.products[ref] ? this.state.products[ref].frequency : "",
        area: this.state.products[ref] ? this.state.products[ref].area : [],
      };
      this.setState({products: products});
    } else if (dataname === "mustaches_template_" + ref) {
      this.onChangeHandlerMustacheTemplate(ref)
    } else if (name === "mustaches_group_" + ref) {
      let selected = this.checkSelectedGroup(ref, 'groupId', 'card')
      let mustaches = this.state.mustaches
      const tempsOfGroup = this.state.allMustachesTemplates.filter(temp => {
        return (temp.groupId === ref && temp.type.includes('card') && temp.type.includes(this.state.skincareTab))
      })
      for (const temp of tempsOfGroup) {
        if (selected) {
          if (mustaches.hasOwnProperty(temp.ref)) delete mustaches[temp.ref]
        } else {
          mustaches[temp.ref] = temp
        }
      }
      this.setState({mustaches: mustaches})
    } else if (name === "mustaches_group_ui_" + ref) {
      let selected = this.checkSelectedGroup(ref, 'groupUIId', 'simple')
      let mustaches = this.state.mustaches
      const tempsOfGroup = this.state.allMustachesTemplates.filter(temp => {
        return (temp.groupUIId === ref && temp.type.includes(this.state.skincareTab))
      })
      for (const temp of tempsOfGroup) {
        this.onChangeHandlerMustacheTemplate(temp.ref, selected)
      }
      this.setState({mustaches: mustaches})
    } else if (["rec_" + ref, "proc_" + ref].includes(name)) {
      let template = this.state.allMustachesTemplates.find(
        (item) => item.ref === ref
      )
      let mustaches = this.state.mustaches
      if (Object.keys(this.state.mustaches).includes(ref)) {
        delete mustaches[ref]
      } else {
        mustaches[ref] = {...template, column: template.recommend || false}
      }
      await this.setState({mustaches: mustaches})
    } else if (["rec_s_" + ref, "proc_s_" + ref].includes(name)) {
      if (Object.keys(this.state.mustaches).includes(ref)) {
        let mustaches = this.state.mustaches
        mustaches[ref].column = value
        await this.setState({mustaches: mustaches})
      }
    } else if (name === "language") {
      this.setState({language: value});
    }
    let mustaches = this.state.mustaches
    mustaches['show_recommend_table'] = this.checkShowRecommendTable()
    mustaches['show_procedure_table'] = this.checkShowProcedureTable()
    this.setState({mustaches: mustaches})
  };

  onChangeArea = (value, ref) => {
    let masterProduct = this.state.allProducts.find(
      (item) => item.ref === ref
    );
    let {products} = this.state;
    products[ref] = {
      ref: ref,
      title: masterProduct.title,
      title_ru: masterProduct.title_ru,
      description: masterProduct.description,
      description_ru: masterProduct.description_ru,
      order: masterProduct.order,
      qty: this.state.products[ref] ? this.state.products[ref].qty : 1,
      rule: this.state.products[ref] ? this.state.products[ref].rule : "",
      time: this.state.products[ref] ? this.state.products[ref]?.time : "",
      frequency: this.state.products[ref] ? this.state.products[ref].frequency : "",
      area: value
    };
    this.setState({products: products});
  };

  onChangeHandlerMustacheTemplate = (ref,selected) => {
    // console.log('ref',ref)
    let template = this.state.allMustachesTemplates.find(
      (item) => item.ref === ref
    )
    let mustaches = this.state.mustaches
    if(typeof selected === 'undefined'){
      if(this.state.mustaches.hasOwnProperty(ref)){
        delete mustaches[ref]
        selected = true
      }else{
        mustaches[ref] = template
        selected = false
      }
    }else {
      if(selected){
        if(this.state.mustaches.hasOwnProperty(ref)) delete mustaches[ref]
      }else{
        mustaches[ref] = template
      }
    }

    // console.log('type',template)
    // console.log('selected',selected)
    if(['simple_results','simple_skincare'].includes(template.type)){
      let subTemplates = this.state.allMustachesTemplates.filter(temp => {
        return (temp.groupId && temp.groupId.split(",").includes(template.groupId) &&
          (temp.type.includes(this.state.skincareTab) || ['recommendation','procedure'].includes(temp.type)) &&
          !temp.type.includes('simple') &&
          temp.ref !== template.ref)
      })
      for (const temp of subTemplates){
        if(selected){
          if(mustaches.hasOwnProperty(temp.ref)) delete mustaches[temp.ref]
        }else{
          if(['recommendation','procedure'].includes(temp.type)){
            mustaches[temp.ref] = {...temp,column:temp.recommend || false}
          }else{
            mustaches[temp.ref] = temp
          }
        }
      }
    }
    this.setState({ mustaches: mustaches})
  };

  onChangeHandlerAdditionalCommentResults = (value) => {
    this.setState({additional_comment_results:value});
  };

  onChangeHandlerAdditionalCommentSkincare = (value) => {
    this.setState({additional_comment_skincare:value});
  };

  componentDidMount = async () => {
    const p1 = this.listenNewData()
    const p2 = this.fetchAnswersData()
    const p3 = this.fetchAssessmentById()

    Promise.all([p1, p2, p3]).then(async () => {
      if(!this.state.answerId) {
        const url = window.location.href;
        let urlArr = url.indexOf('?') !== -1 ? url.slice(0, url.indexOf('?')).split('/') : url.split('/');
        const userId = (urlArr[urlArr.length - 2] === 'assessment' || urlArr[urlArr.length - 2] === 'diagnosis')
          ? urlArr[urlArr.length - 1]
          : urlArr[urlArr.length - 2];

        try {
          const {data: answersArr} = await api.get('getAnswersByUserId', {params: {userId}})
          const answer = answersArr?.sort((a, b) => new Date(a?.time).getTime() < new Date(b?.time).getTime() ? -1 : 1)?.[0]
          this.setState({answerId: answer})
        } catch (err) {
          console.log('err', err)
        }
      }
    });

    fb.metaCollection.doc('assessment_form').get().then(res => {
      this.setState({
        minProductsQty: res.data().min_products_quantity,
        maxProductsQty: res.data().max_products_quantity,
      })
    })    
  };

  listenNewData = async () => {
    this.setState({ backendDomain: fb.CRM_URL, admin: fb.firebase.auth().currentUser });

    this.setState({ isLoading: true });
    const url = window.location.href;
    let urlArr = url.indexOf('?') !== -1 ? url.slice(0, url.indexOf('?')).split('/') : url.split('/');
    const userId = (urlArr[urlArr.length - 2] === 'assessment' || urlArr[urlArr.length - 2] === 'diagnosis')
      ? urlArr[urlArr.length - 1]
      : urlArr[urlArr.length - 2];
    const answerId = (urlArr[urlArr.length - 2] === 'assessment' || urlArr[urlArr.length - 2] === 'diagnosis')
      ? ''
      : urlArr[urlArr.length - 1]

    //checking if admins is coming for treatment
    let treatment_id = "";
    if (url.includes('type')){
      treatment_id = answerId;
      if(!this.state.isAssessment && (!treatment_id || treatment_id === 'undefined' || typeof treatment_id === 'undefined')){
        this.setState({ errorMessage: 'Treatment undefined' });
        return
      }
    }
    // console.log('slug', userId, answerId, treatment_id);

    const diaghtmlObj = await fb.metaCollection.doc('diaghtml').get().then((doc) => {
      if (!doc.exists) return false;
      return doc.data();
    });
    this.setState({ diaghtmlObj: diaghtmlObj });

    //fetch template
    this.fetchTemplate();
    //fetch products
    this.fetchProducts();

    if(treatment_id){
      await api.get('userByID', {params: {userId}}).then((res) => {
        const dataObj = res.data
        this.setState({
          userId: dataObj.id,
          name: dataObj.name,
          email: dataObj.email,
          u_language: dataObj.language ? dataObj.language : this.state.u_language,
          treatment_id: treatment_id,
          userObj:dataObj
        });
      }).catch((err) => {
        console.log('err',err)
        this.setState({ errorMessage: err.message });
      });

      api.get('getTreatmentsByUserId', { params: { userId } }).then(async (res) => {
        const userTreatment = res.data?.find(i => i.id === treatment_id)

        if (userTreatment) {
          if (!userTreatment.hasOwnProperty('version')) {
            alert('User has an old version of treatment. You need to setup the template.')
          }
          this.setState({
            currentTreatmentID: userTreatment.id,
          });
          let head = [];
          let answer = [];
          let requiredHead = [];
          const result = await fb.metaCollection.doc("TreatmentMatrix").get();
          if (result.data() && userTreatment.answer) {
            requiredHead = result.data().csv.split(",p1");
            head = requiredHead[0].split(",");
            answer = userTreatment.answer.slice(0, -1).split(",");
          }
          if (
            // userTreatment.answerId &&
            userTreatment.matched &&
            userTreatment.matched !== false
          ) {
            let ordered = {};
            ordered = userTreatment.products && Object.keys(userTreatment.products)
              .sort()
              .reduce((obj, key) => {
                obj[key] = userTreatment.products[key];
                return obj;
              }, {});
            const pro = {};
            if (ordered) {
              for (const ref of Object.keys(ordered)) {
                const key = Object.keys(ordered).indexOf(ref);
                if (ordered[ref].title_ru && ordered[ref].description_ru) {
                  pro[ref] = {
                    ref: ref,
                    title: ordered[ref].title,
                    title_ru: ordered[ref].title_ru,
                    order: Number(ordered[ref].order) || 1,
                    description: ordered[ref].description,
                    description_ru: ordered[ref].description_ru,
                    rule: ordered[ref].rule || "",
                    time: ordered[ref]?.time || "",
                    qty: ordered[ref].qty || 1,
                    frequency: ordered[ref].frequency || '',
                    area: ordered[ref].area || [],
                  };
                } else {
                  const data1 = await fb.productsCollection
                    .where("ref", "==", ref)
                    .get();
                  if (data1.docs.length > 0) {
                    const dataObj1 = data1.docs[0].data();

                    pro[ref] = {
                      ref: ref,
                      title: dataObj1.title,
                      title_ru: dataObj1.title_ru,
                      order: Number(dataObj1.order) || 1,
                      rule: ordered[ref].rule || "",
                      time: ordered[ref]?.time || "",
                      qty: ordered[ref].qty || 1,
                      description: this.getProductDescription(dataObj1)['en'],
                      description_ru: this.getProductDescription(dataObj1)['ru'],
                      frequency: ordered[ref].frequency || '',
                      area: ordered[ref].area || [],
                    };
                  }
                }
              }
            }
            
            this.setState({
              id: userTreatment.id,
              answerId: userTreatment.answerId ? userTreatment.answerId : this.state.answerId,
              products: pro,
              additional_comment_results: userTreatment.additional_comment_results || this.state.additional_comment_results,
              additional_comment_skincare: userTreatment.additional_comment_skincare || this.state.additional_comment_skincare,
              mustaches: userTreatment.mustaches || this.state.mustaches,
              matched: userTreatment.matched,
              t_ref: userTreatment.ref || "",
              head: head,
              answer: answer,
              isLoading: false,
              treatment: userTreatment.treatment
                ? userTreatment.treatment
                : this.state.treatment,
              treatment_answer: userTreatment.answer
                ? userTreatment.answer
                : this.state.treatment_answer,
              isTreatment: userTreatment.isTreatment
                ? userTreatment.isTreatment
                : this.state.isTreatment,
              // errorMessage: "",
              treatmentTime: userTreatment?.time,
            });
          } else {
            this.setState({
              id: userTreatment.id,
              matched: userTreatment.matched,
              head: head,
              answer: answer,
              isLoading: false,
              treatmentTime: userTreatment?.time,
              // errorMessage: "",
            });
          }
        } else {
          if (!this.state.isAssessment) {
            this.setState({
              errorMessage:
                "You don't have any treatment yet, Please try again after some time",
              isLoading: false,
            })
          }
          this.setState({
            isLoading: false,
          });
        }
      }).catch((err) => {
        console.log('err', err)
        this.setState({ errorMessage: err.message });
      });
    } else {
      await api.get('userByID', {params: {userId}}).then((res) => {
        const dataObj = res.data
        this.setState({
          userId: dataObj.id,
          name: dataObj.name,
          email: dataObj.email,
          u_language: dataObj.language ? dataObj.language : this.state.u_language,
          answerId: answerId,
          userObj:dataObj
        });
      }).catch((err) => {
        console.log('err',err)
        // this.setState({ errorMessage: err.message });
      });
        
      api.get('getTreatmentsByUserId', { params: { userId } }).then(async (res) => {
        const userTreatment = res.data
          ?.filter(i => i?.answerId === answerId)
          ?.sort((a, b) => new Date(a?.time).getTime() < new Date(b?.time).getTime() ? -1 : 1)
          ?.[0]

        if (userTreatment) {
          if (!userTreatment.hasOwnProperty('version')) {
            alert('User has an old version of treatment. You need to setup the template.')
          }
          this.setState({
            currentTreatmentID: userTreatment.id,
          });
          let head = [];
          let answer = [];
          let requiredHead = [];
          const result = await fb.metaCollection.doc("TreatmentMatrix").get();
          if (result.data() && userTreatment.answer) {
            requiredHead = result.data().csv.split(",p1");
            head = requiredHead[0].split(",");
            answer = userTreatment.answer.slice(0, -1).split(",");
          }
          if (
            userTreatment.answerId &&
            userTreatment.matched &&
            userTreatment.matched !== false
          ) {
            let ordered = {};
            ordered = userTreatment.products && Object.keys(userTreatment.products)
              .sort()
              .reduce((obj, key) => {
                obj[key] = userTreatment.products[key];
                return obj;
              }, {});
            const pro = {};
            if (ordered) {
              for (const ref of Object.keys(ordered)) {
                const key = Object.keys(ordered).indexOf(ref);
                if (ordered[ref].title_ru && ordered[ref].description_ru) {
                  pro[ref] = {
                    ref: ref,
                    title: ordered[ref].title,
                    title_ru: ordered[ref].title_ru,
                    order: Number(ordered[ref].order) || 1,
                    description: ordered[ref].description,
                    description_ru: ordered[ref].description_ru,
                    rule: ordered[ref].rule || "",
                    time: ordered[ref]?.time || "",
                    qty: ordered[ref].qty || 1,
                    frequency: ordered[ref].frequency || '',
                    area: ordered[ref].area || [],
                  };
                } else {
                  const data1 = await fb.productsCollection
                    .where("ref", "==", ref)
                    .get();
                  if (data1.docs.length > 0) {
                    const dataObj1 = data1.docs[0].data();

                    pro[ref] = {
                      ref: ref,
                      title: dataObj1.title,
                      title_ru: dataObj1.title_ru,
                      order: Number(dataObj1.order) || 1,
                      rule: ordered[ref].rule || "",
                      time: ordered[ref]?.time || "",
                      qty: ordered[ref].qty || 1,
                      frequency: ordered[ref].frequency || '',
                      area: ordered[ref].area || [],
                      description: this.getProductDescription(dataObj1)['en'],
                      description_ru: this.getProductDescription(dataObj1)['ru'],
                    };
                  }
                }
              }
            }

            this.setState({
              id: userTreatment.id,
              answerId: userTreatment.answerId,
              products: pro,
              additional_comment_results: userTreatment.additional_comment_results || this.state.additional_comment_results,
              additional_comment_skincare: userTreatment.additional_comment_skincare || this.state.additional_comment_skincare,
              mustaches: userTreatment.mustaches || this.state.mustaches,
              matched: userTreatment.matched,
              t_ref: userTreatment.ref || "",
              head: head,
              answer: answer,
              isLoading: false,
              treatment: userTreatment.treatment
                ? userTreatment.treatment
                : this.state.treatment,
              treatment_answer: userTreatment.answer
                ? userTreatment.answer
                : this.state.treatment_answer,
              isTreatment: userTreatment.isTreatment
                ? userTreatment.isTreatment
                : this.state.isTreatment,
              // errorMessage: "",
              treatmentTime: userTreatment?.time,
            });
          } else {
            this.setState({
              id: userTreatment.id,
              matched: userTreatment.matched,
              head: head,
              answer: answer,
              isLoading: false,
              treatmentTime: userTreatment?.time,
              // errorMessage: "",
            });
          }
        } else {
          if (!this.state.isAssessment) {
            this.setState({ treatmentNotExist: true })
            this.setState({
              errorMessage:
                "You don't have any treatment yet, Please try again after some time",
            });
          }
          this.setState({
            isLoading: false,
          });
        }
      }).catch((err) => {
        console.log('err', err)
        this.setState({ errorMessage: err.message });
      });
    }
    if (this.state.answerId) {
      const {data: answersArr} = await api.get('getAnswersByUserId', {params: {userId: this.state.userId}})
      const answer = answersArr?.find(i => i.id === this.state.answerId)
      
      this.setState({answerTime: new Date(answer?.time).getTime() / 1000})
    }
    
    const {data: packages} = await api.get('getPackages')
    const userPackage = packages
      ?.filter(i => i?.userId === this.state.userId && i?.status === 'confirmed')
      ?.sort((a, b) => new Date(a?.created).getTime() < new Date(b?.created).getTime() ? -1 : 1)
      ?.[0]

    if(userPackage) {
      this.setState({
        packageTime: new Date(userPackage.created).getTime() / 1000,
        lastPackageId: userPackage.id
      })
    }
  };

  getProductDescription = (product_data) => {
    // console.log('product_data',product_data)
    let picture_slider = ''
    if((product_data.hasOwnProperty('pictures') && product_data.pictures.length > 0) ||
      (product_data.hasOwnProperty('picture') && product_data.picture !== '')){
      picture_slider += '<div class="picture_slider">';
      if (product_data.hasOwnProperty('pictures')) {
        for (const picture of product_data.pictures.sort((a, b) => {
          return a.order - b.order
        })) {
          if (picture.type && picture.type.includes('video')) {
            picture_slider += `<div><video controls><source src="${picture.url}" type="${picture.type}"/></video></div>`;
          } else {
            picture_slider += `<div><img src="${picture.url}" /></div>`;
          }
        }
      } else {
        picture_slider += `<div><img src="${product_data.picture}" /></div>`;
      }
      picture_slider += '</div>';
    }
    return {
      en: `${picture_slider} ${product_data.description}<p><b>Instruction:</b></p>${product_data.instruction}`,
      ru: `${picture_slider} ${product_data.description_ru}<p><b>Инструкция:</b></p>${product_data.instruction_ru}`
    }
  };

  handleValidation = () => {
    let { products } = this.state;
    let errors = {};
    let formIsValid = true;

    if(this.state.isAssessment && !Object.keys(this.state.initialAssessment).length) {
      formIsValid = false;
      errors[`af`] = "Please, submit the assessment form first";
      errors["mainError"] = "Please, submit the assessment form first";
      this.setState({ errors: errors });
      return formIsValid;
    }

    Object.keys(products).forEach((val, key) => {
      if (!this.state.isAssessment && (products[val].rule === "" || products[val].rule === undefined)) {
        formIsValid = false;
        errors[`${val}`] = "Rule is required";
        errors["mainError"] = "Rule is required for each product!";
      } else if (this.state.isAssessment && (products[val].frequency === "" || products[val].frequency === undefined)) {
        formIsValid = false;
        errors[`${val}`] = "Frequency is required";
        errors["mainError"] = "Frequency is required for each product!";
      } else if (this.state.isAssessment && (products[val].area.length === 0 || products[val].area === undefined)) {
        formIsValid = false;
        errors[`${val}`] = "Area is required";
        errors["mainError"] = "Area is required for each product!";
      } else if (this.state.isAssessment && (products[val]?.time === "" || products[val]?.time === undefined)) {
        formIsValid = false;
        errors[`${val}`] = "Time is required";
        errors["mainError"] = "Time is required for each product!";
      }
    })

    const productsQuantity = Object.values(products).reduce((prev, curr) => prev + Number(curr.qty), 0)

    if (productsQuantity < this.state.minProductsQty) {
      formIsValid = false;
      errors['min'] = `Min count of products is ${this.state.minProductsQty}`;
      errors["mainError"] = `Min count of products is ${this.state.minProductsQty}!`;
    } else if (productsQuantity > this.state.maxProductsQty) {
      formIsValid = false;
      errors['max'] = `Max count of products is ${this.state.maxProductsQty}`;
      errors["mainError"] = `Max count of products is ${this.state.maxProductsQty}!`;
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  handleAssessmentSendSubmit = async (e) => {
    e.preventDefault()
    if (window.confirm("Before send, Please make sure if diagnosis is ready for the customer!")) {
      this.setState({ sendDisabled: true });

      if (this.handleValidation()) {
        // update treatment products and update or add new package
        
        const treatmentRef = fb.usersCollection
          .doc(this.state.userId)
          .collection("Treatments")
          .doc(this.state.id);

        await api.post('updateTreatment', {
          id: this.state.id,
          products: this.state.products,
          isTreatment: true,
          mustaches: this.state.mustaches,
          ref: this.state.t_ref,
          additional_comment_results: this.state.additional_comment_results,
          additional_comment_skincare: this.state.additional_comment_skincare,
          version: 3,
          treatmentConfirmedBy: {
            adminId: this.state.admin.uid,
            adminName: this.state.admin.email,
            time: new Date()
          }
        })
          .then((res) => {
            addAdminLog(
              'treatment_updated',
              `Updated the treatment ${this.state.id} for the user ${this.state.userId}`,
              this.state.id,
              this.state.userId,
              this.state.userObj.firstName + ' ' + this.state.userObj.lastName
            )
            this.setState({
              isTreatment: true,
            });
          })
          .catch((err) => {
            console.log('err', err)
            this.setState({ errorMessage: err.message });
          });
        
        const {data: userData} = await api.get('userByID', {params: {userId: this.state.userId}})

        const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: this.state.userId } })
        const treatmentLast = treatmentsByUserId?.sort((a, b) => new Date(a?.time).getTime() < new Date(b?.time).getTime() ? -1 : 1)[0]

        if ((userData && !userData.hasOwnProperty('currentTreatmentID') || window.location.href.includes('review'))) {
          await this.createPackage(userData, treatmentLast, treatmentLast.id)
        } else {
          await this.updatePackageProducts(this.state.products)
        }
        
        await api.post('updateUser', {
          id: this.state.userId,
          currentTreatmentID: treatmentLast.id,
        });
        //update payment status
        let statusUpdate = {
          currentTreatmentID: this.state.currentTreatmentID,
          treatmentRequestReviewed: true
        }
        if (!['active', 'waiting_delivery'].includes(this.state.userObj.status)) statusUpdate['status'] = "waiting_payment"
        api.post('updateUser', {id: this.state.userId, ...statusUpdate})

        if(this.state.answerId) {
          const answerRef = fb.usersCollection
            .doc(this.state.userId)
            .collection("Answers")
            .doc(this.state.answerId);
          await answerRef
            .update({
              treatment: true,
            })
            .then((res) => {
              addAdminLog(
                'answer_updated',
                `Updated the answer ${this.state.answerId} treatment status for the user ${this.state.userId}`,
                this.state.answerId,
                this.state.userId,
                this.state.userObj.firstName + ' ' + this.state.userObj.lastName
              )
              // send emails
              this.saveEmails();
  
              this.setState({
                sendDisabled: false,
                successMessage: "User diagnosis sent successfully!",
              });
            })
            .catch((err) => {
              console.log('err', err)
              this.setState({ errorMessage: err.message });
            });
        }
        this.setState({
          sendDisabled: false,
          successMessage: "",
        });
        window.location.reload();
      } else {
        this.setState({ sendDisabled: false });
      }
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ disabled: true });

    if (this.handleValidation()) {
      const version = this.state.isAssessment ? 3 : 2

      await api.post('updateTreatment', {
          id: this.state.id,
          products: this.state.products,
          mustaches: this.state.mustaches,
          ref: this.state.t_ref,
          additional_comment_results: this.state.additional_comment_results,
          additional_comment_skincare: this.state.additional_comment_skincare,
          matched: true,
          version: version,
          treatmentConfirmedBy: {
            adminId: this.state.admin.uid,
            adminName: this.state.admin.email,
            time: new Date()
          }
        })
        .then((res) => {
          addAdminLog(
            'treatment_updated',
            `Updated the treatment ${this.state.id} for the user ${this.state.userId}`,
            this.state.id,
            this.state.userId,
            this.state.userObj.firstName + ' ' + this.state.userObj.lastName
          )
          this.setState({
            disabled: false,
            successMessage: "User diagnosis saved successfully!",
          });
          setTimeout(() => {
            this.setState({
              successMessage: "",
            });
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          console.log('err', err)
          this.setState({ errorMessage: err.message });
        });
      this.updatePackageProducts(this.state.products)
    } else {
      this.setState({
        disabled: false
      });
    }
  };

  handleSendSubmit = async (e) => {
    e.preventDefault()
    if (
      window.confirm(
        "Before send, Please make sure if diagnosis is ready for the customer!"
      )
    ) {
      this.setState({ sendDisabled: true });
      if (this.handleValidation()) {
        //check doagnosis is sent already or not
        if (this.isBlocked()) { // ON Create new
          //make a copy if it is sent already
          
          await api.post('createTreatment', {
              user_id: this.state.userId,
              answerId: this.state.answerId || "",
              products: this.state.products,
              answer: this.state.treatment_answer || "",
              matched: this.state.matched || "",
              treatment: this.state.treatment || "",
              time: fb.timestamp.serverTimestamp(),
              mustaches: this.state.mustaches,
              ref: this.state.t_ref,
              additional_comment_results: this.state.additional_comment_results,
              additional_comment_skincare: this.state.additional_comment_skincare,
              version: 2
            })
            .then((res) => {
              addAdminLog(
                'created_new_treatment',
                `Created a new treatment ${res.data.insertId} for the user ${this.state.userId}`,
                res.data.insertId,
                this.state.userId,
                this.state.userObj.firstName + ' ' + this.state.userObj.lastName
              )
              this.setState({
                sendDisabled: false,
                successMessage: "User diagnosis copied successfully!",
              });
              setTimeout(() => {
                this.setState({
                  successMessage: "",
                });
                // window.location.reload();
                window.location.href = `/admin/users/diagnosis/${this.state.userId}/${res.data.insertId}?type=t`;
              }, 3000);
            })
            .catch((err) => {
              console.log('err',err)
              this.setState({ errorMessage: err.message });
            });
          this.updatePackageProducts(this.state.products)
        } else { // ON Send
          //update treatment

          await api.post('updateTreatment', {
              id: this.state.id,
              products: this.state.products,
              isTreatment: true,
              mustaches: this.state.mustaches,
              ref: this.state.t_ref,
              additional_comment_results: this.state.additional_comment_results,
              additional_comment_skincare: this.state.additional_comment_skincare,
              version: 2,
              treatmentConfirmedBy: {
                adminId: this.state.admin.uid,
                adminName: this.state.admin.email,
                time: new Date()
              }
            })
            .then((res) => {
              addAdminLog(
                'treatment_updated',
                `Updated the treatment ${this.state.id} for the user ${this.state.userId}`,
                this.state.id,
                this.state.userId,
                this.state.userObj.firstName + ' ' + this.state.userObj.lastName
              )
              this.setState({
                isTreatment: true,
              });
            })
            .catch((err) => {
              console.log('err',err)
              this.setState({ errorMessage: err.message });
            });

          const {data: userData} = await api.get('userByID', {params: {userId: this.state.userId}})
          
          if(userData && !userData.hasOwnProperty('currentTreatmentID')){
            const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: this.state.userId } })
            const treatmentLast = treatmentsByUserId?.sort((a, b) => new Date(a?.time).getTime() < new Date(b?.time).getTime() ? -1 : 1)[0]

            await this.createPackage(userData,treatmentLast,treatmentLast.id)
          }else {
            this.updatePackageProducts(this.state.products)
          }
          //update payment status
          let statusUpdate = {
            currentTreatmentID: this.state.currentTreatmentID,
            treatmentRequestReviewed: true
          }
          if(!['active','waiting_delivery'].includes(this.state.userObj.status)) statusUpdate['status'] = "waiting_payment"
          api.post('updateUser', {id: this.state.userId, ...statusUpdate})

          const answerRef = fb.usersCollection
            .doc(this.state.userId)
            .collection("Answers")
            .doc(this.state.answerId);
          await answerRef
            .update({
              treatment: true,
            })
            .then((res) => {
              addAdminLog(
                'answer_updated',
                `Updated the answer ${this.state.answerId} treatment status for the user ${this.state.userId}`,
                this.state.answerId,
                this.state.userId,
                this.state.userObj.firstName + ' ' + this.state.userObj.lastName
              )
              // send emails
              this.saveEmails();

              this.setState({
                sendDisabled: false,
                successMessage: "User diagnosis sent successfully!",
              });
              setTimeout(() => {
                this.setState({
                  successMessage: "",
                });
                window.location.reload();
              }, 3000);
            })
            .catch((err) => {
              console.log('err',err)
              this.setState({ errorMessage: err.message });
            });
        }
      } else {
        this.setState({
          sendDisabled: false,
        });
      }
    }
  };

  createPackage = async (userData,treatmentData,currentTreatmentID) => {
    try {
      const shiftObj = await fb.metaCollection.doc('shipment').get().then((doc) => {
        if (!doc.exists) return false;
        return doc.data();
      });
      var ship_by = new Date();
      ship_by.setSeconds(ship_by.getSeconds() + shiftObj.ship_by_shift);
      var package_due = new Date();
      package_due.setSeconds(package_due.getSeconds() + shiftObj.package_due_shift);
      let productsSelected = [];
      for (let key in treatmentData.products) {
        if(this.state.isAssessment) {
          productsSelected.push({
            value: key,
            title: treatmentData.products[key].title,
            qty: treatmentData.products[key].hasOwnProperty('qty') ? treatmentData.products[key].qty : 1,
            time: treatmentData.products[key]?.time,
            area: treatmentData.products[key].area,
            frequency: treatmentData.products[key].frequency,
          });
        } else {
          productsSelected.push({
            value: key,
            title: treatmentData.products[key].title,
            qty: treatmentData.products[key].hasOwnProperty('qty') ? treatmentData.products[key].qty : 1,
            rule: treatmentData.products[key].rule,
          });
        }
      }
      var risk = "yellow";
      if(userData.paymentPlanActive) {
        if(userData.paymentPlanActive < Math.floor(Date.now() / 1000)) {
          risk = "red";
        } else {
          risk = "green";
        }
      }
      let obj = {
        ref: 'LD' + Math.floor(Date.now() / 1000),
        userId: userData.id,
        created: fb.timestamp.serverTimestamp(),
        userName: userData.name,
        userEmail: userData.email,
        shippingAddress: userData.shippingAddress,
        status: 'waiting_shipment',
        products: productsSelected,
        package_due: package_due,
        ship_by: ship_by,
        test: userData.test || false,
        risk: risk,
        deleted: false,
        treatmentId: currentTreatmentID,
      };

      obj.shippingAddress.email = userData.email

      const res = await api.post('createPackage', obj)

      addAdminLog(
        'treatment_request_new_package',
        `The treatment request has created a new package ${res.data.insertId} for the user ${userData.id}`,
        res.data.insertId,
        userData.id,
        this.state.userObj.firstName + ' ' + this.state.userObj.lastName
      )

      // const generatePdfTreatment = fb.functions.httpsCallable('generatePdfTreatment');
      // generatePdfTreatment({packageId: res.data.insertId})
    }catch (e) {
      console.log('!!!package_add crash: '+e.message);
    }
  };

  updatePackageProducts = async (products) => {
    try {
      let productsSelected = [];
      for (let key in products) {
        if(this.state.isAssessment) {
          productsSelected.push({
            value: key,
            title: products[key].title,
            qty: products[key].hasOwnProperty('qty') ? products[key].qty : 1,
            time: products[key]?.time,
            frequency: products[key].frequency,
            area: products[key].area,
          });
        } else {
          productsSelected.push({
            value: key,
            title: products[key].title,
            qty: products[key].hasOwnProperty('qty') ? products[key].qty : 1,
            rule: products[key].rule
          });
        }
      }

      const {data: packages} = await api.get('getPackages')
      let doc = packages
        ?.filter(i => i?.treatmentId === this.state.id)
        ?.sort((a, b) => new Date(a?.created).getTime() < new Date(b?.created).getTime() ? -1 : 1)
        ?.[0]

      if(!doc){
        doc = packages
          ?.filter(i => i?.userId === this.state.userId)
          ?.sort((a, b) => new Date(a?.created).getTime() < new Date(b?.created).getTime() ? -1 : 1)
          ?.[0]
      }
      console.log('Package data',doc)

      if(doc){
        console.log('Package has data',doc)

        api.post('updatePackage', {id: doc.id, products: productsSelected})
        // addAdminLog(
        //   'package_updated',
        //   `Updated products in the package ${doc.id} by treatment data for the user ${this.state.userId}`,
        //   doc.id,
        //   this.state.userId,
        //   this.state.userObj.firstName + ' ' + this.state.userObj.lastName
        // )
      }
    }catch (e) {
      console.log('!!!package_add crash: '+e.message);
    }
  };

  //save emails
  saveEmails = async () => {
    try {
      let result = await axios.get(`${this.state.backendDomain}/drip/add?cid=208467500&un=${
          this.state.name
        }&um=${encodeURIComponent(this.state.email)}`
      ).then(function (response) {
      }).catch(function (error) {
      });
      console.log("email result", result);
    } catch (e) {
      this.setState({ errorMessage: e.message });
    }
    try {
      let doc;
      if (this.state.u_language == "EN") {
        doc = "notifications_txt";
      } else if (this.state.u_language == "RU") {
        doc = "notifications_txt_ru";
      }
      const notification = await fb.metaCollection.doc(doc).get();
      let title = encodeURIComponent(
        notification.data().push_notif_diagnosis_ready_title || "Title"
      );
      let message = encodeURIComponent(
        notification.data().push_notif_diagnosis_ready_message || "Description"
      );
      let data = "diagnosis";
      let result1 = await axios.get(
        `${this.state.backendDomain}/s/send?topic=${this.state.userId}d&title=${title}&message=${message}&data=${data}`
      );
      console.log("s/send result", result1);
    } catch (e) {
      this.setState({ errorMessage: e.message });
    }
    // let emailsRef = fb.emailsCollection.doc();
    // await emailsRef.set({
    //   id:emailsRef.id,
    //   userId:this.state.userId,
    //   body:"Your diagnosis is ready, Please go to your app, and open it to view your diagnosis.",
    //   subject:"Diagnosis is ready",
    //   recipients:this.state.email,
    //   sent:fb.timestamp.serverTimestamp()
    // }).then(async snapShot => {
    //   //sent emails
    //   const result =  await axios.get(`https://us-central1-l-d-control.cloudfunctions.net/s/email/${emailsRef.id}`);
    //   console.log('result', result);
    // }).catch(err=> {
    // });
  };

  handleAddProductDialog = (status) => {
    this.setState({ isAddProduct: status });
  };

  fetchTemplate = async () => {
    //template
    await fb.mustachesCollection.doc('basePlan').get().then((doc) => {
      if (!doc.exists) return false;
      this.setState({ basePlan: doc.data() });
    });
    await fb.mustachesCollection.doc('baseResults').get().then((doc) => {
      if (!doc.exists) return false;
      this.setState({ baseResults: doc.data() });
    });
    await fb.mustachesCollection.doc('baseProducts').get().then((doc) => {
      if (!doc.exists) return false;
      this.setState({ baseProducts: doc.data() });
    });
    await fb.metaCollection.doc('mustaches_ui_groups').get().then(res => {
      const data = res.data();
      if(data.hasOwnProperty('groups')){
        let groups = []
        data.groups.forEach((group) => {
          groups.push({
            ...group,
            opened: false
          })
        })
        this.setState({
          mustacheCardUIGroups: groups
        })
      }
    });
    await fb.metaCollection.doc('mustachescard_groups').get().then(res => {
      const data = res.data();
      if(data.hasOwnProperty('groups')){
        let groups = []
        data.groups.forEach((group) => {
          groups.push({
            ...group,
            opened: false
          })
        })
        this.setState({
          mustacheCardGroups: groups
        })
      }
    });
    fb.mustachesCollection.orderBy("ref", "asc").get().then((res) => {
      if (res.docs.length > 0) {
        let allMustachesTemplates = [];
        let allMustachesRecommendationTemplates = [];
        let allMustachesProcedureTemplates = [];
        res.docs.forEach((doc) => {
          const dataObj = doc.data();
          if(dataObj.type === 'recommendation'){
            allMustachesRecommendationTemplates.push(dataObj);
          }
          if(dataObj.type === 'procedure'){
            allMustachesProcedureTemplates.push(dataObj);
          }
          let temp = {
            ref: dataObj.ref,
            title: removeHTMLTags(dataObj.title, 0),
            title_ru: removeHTMLTags(dataObj.title_ru || '', 0),
            body: dataObj.description,
            body_ru: dataObj.description_ru || '',
            type: dataObj.type,
            groupId: dataObj.groupId,
            groupUIId: dataObj.groupUIId || '',
          }
          if(['recommendation','procedure'].includes(dataObj.type)) temp['recommend'] = dataObj.recommend
          allMustachesTemplates.push(temp)
        });
        this.setState({
          allMustachesTemplates: allMustachesTemplates,
          allMustachesRecommendationTemplates: allMustachesRecommendationTemplates,
          allMustachesProcedureTemplates: allMustachesProcedureTemplates
        });
      }
    }).catch((err) => {
      console.log('err',err)
      this.setState({ errorMessage: err.message });
    });
  };

  fetchProducts = async () => {
    const data = await fb.productsCollection.orderBy("order", "asc").get();
    const products = [];
    data.docs.forEach((doc) => {
      const dataObj = doc.data();
      let a = dataObj.ref.replace("p", "");
      products.push({
        id: dataObj.ref,
        ref: dataObj.ref,
        order: dataObj.order,
        title: dataObj.title,
        title_ru: dataObj.title_ru,
        description: this.getProductDescription(dataObj)['en'],
        description_ru: this.getProductDescription(dataObj)['ru'],
        p: a,
      });
    });
    const myData = products.sort(function (a, b) {
      return parseInt(a.p) - parseInt(b.p);
    });
    this.setState({ allProducts: myData });
  };

  setSkincareTabValue = (tab, e) => {
    this.setState({ skincareTab: tab });
  };

  isBlocked = () => {
    return this.state.treatmentNotExist || (this.state.isTreatment && this.state.matched)
    // return false;
  };

  checkSelectedTemplate = (ref) => {
    let item = Object.values(this.state.mustaches).find((item) => item.ref === ref);
    if(item){
      return true;
    }
    return false;
  };

  checkSelectedRecSwitch = (ref) => {
    let selectedItem = Object.values(this.state.mustaches).find((item) => item.ref === ref);
    let defaultItem = Object.values(this.state.allMustachesRecommendationTemplates).find((item) => item.ref === ref);
    if(selectedItem){
      return selectedItem.column
    }else{
      return defaultItem.recommend
    }
  };

  checkSelectedProcSwitch = (ref) => {
    let selectedItem = Object.values(this.state.mustaches).find((item) => item.ref === ref);
    let defaultItem = Object.values(this.state.allMustachesProcedureTemplates).find((item) => item.ref === ref);
    if(selectedItem){
      return selectedItem.column
    }else{
      return defaultItem.recommend
    }
  };

  checkSelectedGroup = (id,field,type) => {
    const tempsOfGroup = this.state.allMustachesTemplates.filter(temp => {
      return (temp[field] === id && temp.type.includes(this.state.skincareTab) && temp.type.includes(type))
    })
    if(!tempsOfGroup.length) return false
    for (const temp of tempsOfGroup){
      if(!this.checkSelectedTemplate(temp.ref)){
        return false
      }
    }
    return true
  };

  checkShowRecommendTable = () => {
    for (const item of this.state.allMustachesRecommendationTemplates) {
      if(this.checkSelectedTemplate(item.ref)){
        return true
      }
    }
    return false
  };

  checkShowProcedureTable = () => {
    for (const item of this.state.allMustachesProcedureTemplates) {
      if(this.checkSelectedTemplate(item.ref)){
        return true
      }
    }
    return false
  };

  hasGroupTemplates = (id,field,type) => {
    const tempsOfGroup = this.state.allMustachesTemplates.filter(temp => {
      return ((temp[field] && temp[field].split(',').includes(id)) && temp.type.includes(this.state.skincareTab) && temp.type.includes(type))
    })
    return Boolean(tempsOfGroup.length)
  };

  toggleMustachesGroup = (event,id) => {
    let index = this.state.mustacheCardGroups.findIndex((group) => group.groupId === id)
    let mustacheCardGroups = this.state.mustacheCardGroups
    mustacheCardGroups[index].opened = !mustacheCardGroups[index].opened
    this.setState({
      mustacheCardGroups: mustacheCardGroups
    })
  };

  toggleMustachesUIGroup = (event,id) => {
    let index = this.state.mustacheCardUIGroups.findIndex((group) => group.groupId === id)
    let mustacheCardUIGroups = this.state.mustacheCardUIGroups
    mustacheCardUIGroups[index].opened = !mustacheCardUIGroups[index].opened
    this.setState({
      mustacheCardUIGroups: mustacheCardUIGroups
    })
  };

  showMustacheCheckbox = (val, key) => {
    return (
      <div key={key}>
        <div className="block_text block_text_div">
          <label
            htmlFor={`mustaches_template_${val.ref}`}
            className="centered_label_checkbox"
          >
            <input
              type="checkbox"
              className="mr-5"
              id={`mustaches_template_${val.ref}`}
              data-name={"mustaches_template_" + val.ref || ""}
              name={
                "mustaches_template_parent_" + val.ref || ""
              }
              value={val.ref}
              checked={this.checkSelectedTemplate(val.ref)}
              onChange={(event) =>
                this.onChangeHandler(
                  event,
                  val.ref
                )
              }
              disabled={this.isBlocked()}
            />
            <span>{val.title}</span>
          </label>
        </div>
      </div>
    )
  };

  productRuleIndicator = (product) => {
    if(product){
      let rule = this.state.isAssessment ? product?.time : product.rule
      if (rule === 'M&E' || rule === 'M, E'){
        return (<span style={{backgroundColor:'#CDD2FF'}}>M,E</span>)
      }else if(rule === 'M'){
        return (<span style={{backgroundColor:'#FFEBCD'}}>M</span>)
      }else if(rule === 'E'){
        return (<span style={{backgroundColor:'#CDE7FF'}}>E</span>)
      }
      // else{
      //   return (<span style={{backgroundColor:'#CDE7FF'}}>E</span>)
      // }
    }else{
      return (<span/>)
    }
  };

  fetchAnswersData = async () => {
    const url = window.location.href;
    let urlArr = url.indexOf('?') !== -1 ? url.slice(0, url.indexOf('?')).split('/') : url.split('/');
    const userId = (urlArr[urlArr.length - 2] === 'assessment' || urlArr[urlArr.length - 2] === 'diagnosis')
      ? urlArr[urlArr.length - 1]
      : urlArr[urlArr.length - 2];

    try {
      let {data: answersArr} = await api.get('getAnswersByUserId', {params: {userId}})

      for (let dataObj of answersArr) {
        answersArr.push({
          id: dataObj.id,
          user_id: dataObj.user_id,
          mark: dataObj.mark,
          event_id: dataObj.event_id,
          form_response: dataObj.form_response,
          treatment: dataObj.treatment,
          created_sec: dataObj?.time.seconds,
          created: convertMilisecondsToDate(new Date(dataObj?.time).getTime())
        })
      }
      this.setState({
        answersList: answersArr,
        // isLoading:false
      });
    } catch (err) {
      console.log('err', err)
      // this.setState({ error: err.message })
    }
  };

  fetchAssessmentById = async () => {
    const url = window.location.href;
    let urlArr = url.indexOf('?') !== -1 ? url.slice(0, url.indexOf('?')).split('/') : url.split('/');
    let id = urlArr[urlArr.length - 1];
    const isTreatment = url.includes('type')

    try {
      const data = await fb.assessmentFormsCollection
        .where(`${isTreatment? 'treatmentId' : 'answerId'}`, '==', id)
        .get()
  
      if(data.docs.length) {
        for (let doc of data.docs) {
          const dataObj = doc.data();
          this.setState({
            initialAssessment: { id: doc.id, ...dataObj },
            assessmentIsDisabled: true,
            answerId: dataObj.answerId ? dataObj.answerId : this.state.answerId
          })
        }
      } else {
        this.setState({ 
          initialAssessment: {
            ['box_composed_by_ld']: 'yes',
            ['questionnaire_passed']: 'offline'
          }, 
          assessmentIsDisabled: false 
        })
      }
    } catch(err) {
      console.log('err', err)
    }
  };

  handleCreateNew = () => {
    this.setState({ assessmentIsDisabled: false })
    // this.setState({ sendDisabled: true })
    // setTimeout(() => {
    //   const hasAf = Object.keys(this.state.initialAssessment).length
    //   this.setState({ 
    //     initialAssessment: {
    //       ...(hasAf && {
    //         sex: this.state.initialAssessment.sex,
    //         age: this.state.initialAssessment.age,
    //         skin_type: this.state.initialAssessment.skin_type,
    //       })
    //     },
    //     sendDisabled: false
    //   })
    // }, 1500)
  }

  render() {
    let {mustaches, additional_comment_results, additional_comment_skincare, userObj} = this.state
    let title_lang = this.state.language === 'RU' ? '_ru' : ''
    let orderedProducts = Object.values(this.state.products).sort((a,b) => {
      let ia = +a.ref.substring(1);
      let ib = +b.ref.substring(1);
      return ia-ib
    })
    const data = {
      additional_comment_results: {
        ref: 'additional_comment_results',
        title: '',
        body:additional_comment_results,
        title_ru: '',
        body_ru:additional_comment_results,
      },
      additional_comment_skincare: {
        ref: 'additional_comment_skincare',
        title: '',
        body:additional_comment_skincare,
        title_ru: '',
        body_ru:additional_comment_skincare,
      },
      orderedProducts: orderedProducts,
      ...userObj,
      ...mustaches
    }

    return (
      <div className="content">
        {this.state.isAddProduct && (
          <AddProductView
            show={this.state.isAddProduct}
            data={{ stateData: this.state }}
            onHide={() => {
              this.handleAddProductDialog(false);
              this.listenNewData();
            }}
          />
        )}
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.successMessage && (
                <Alert bsStyle="success">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.successMessage}</span>
                </Alert>
              )}
              {this.state.errors && this.state.errors.mainError && (
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.errors.mainError}</span>
                </Alert>
              )}
              {this.state.errorMessage && (
                <Alert bsStyle="danger">
                  <span>{this.state.errorMessage}</span>
                </Alert>
              )}
              <Card
                title={<div>
                  Customer Assessments - <Link to={`/admin/users/view/${this.state.userId}`} target="_blank">{this.state.name}</Link> {this.state.email}
                </div>}
                plain={true}
                button={
                  <div>
                    <Link
                      to={`/admin/users/view/${this.state.userId}`}
                      className="btn btn-success libi-admin-btn"
                    >Back</Link>
                    {this.state.id && <Link to={`/admin/assessment/view/${this.state.userId}/${this.state.id}`} target="_blank" className="btn btn-success">Last Assessment: {convertMilisecondsToDate(new Date(this.state.treatmentTime).getTime())}</Link>}
                    {this.state.answerTime && <Link to={`/admin/users/answers/${this.state.userId}/${this.state.answerId}`} target="_blank" className="btn btn-success">Last Questionnaire: {convertMilisecondsToDate(new Date(this.state.answerTime).getTime())}</Link>}
                    {this.state.lastPackageId && <Link to={`/admin/shipment/view/${this.state.lastPackageId}`} target="_blank" className="btn btn-success">Last Package: {convertMilisecondsToDate(new Date(this.state.packageTime).getTime())}</Link>}
                    {this.isBlocked() ? (
                      this.state.isAssessment ?
                        <Button
                          className={
                            this.state.isTreatment
                              ? "pull-right btn btn-danger libi-admin-btn"
                              : "btn-fill pull-right btn btn-info libi-admin-btn"
                          }
                          disabled={this.state.sendDisabled}
                          onClick={this.handleCreateNew}
                        >
                          {this.state.sendDisabled ? "Loading..." : "Create new"}
                        </Button> :
                        <Link
                          to={`#`}
                          className={
                            this.state.isTreatment
                              ? "pull-right btn btn-danger libi-admin-btn"
                              : "btn-fill pull-right btn btn-info libi-admin-btn"
                          }
                          disabled={this.state.sendDisabled}
                          onClick={(event) => this.handleSendSubmit(event)}
                        >
                          {this.state.sendDisabled ? "Loading..." : "Create new"}
                        </Link>
                    ) : (
                      <>
                        <Button
                          bsStyle="info"
                          className="btn-fill pull-right btn btn-info libi-admin-btn"
                          type="button"
                          style={{'marginLeft':'10px'}}
                          disabled={this.state.disabled}
                          onClick={(event) => this.handleSubmit(event)}
                        >
                          {this.state.disabled ? "Loading..." : "Save"}
                        </Button>
                        <Link
                          to={`#`}
                          className="btn-fill pull-right btn btn-info libi-admin-btn"
                          disabled={this.state.sendDisabled}
                          onClick={(event) => {
                            this.state.isAssessment ? 
                              this.handleAssessmentSendSubmit(event) :
                              this.handleSendSubmit(event)
                          }}
                        >
                          {this.state.sendDisabled ? "Loading..." : "Send"}
                        </Link>
                      </>
                    )}
                  </div>
                }
                loader={this.state.isLoading && <PageLoader />}
                content={
                  <form className="Diagnosis-form">
                    <div className="clearfix" />
                    <div className="table-responsive-important">
                      <Table striped hover id="list-datatable">
                        <thead>
                        <tr>
                          {this.state.head &&
                          this.state.head.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          {this.state.answer &&
                          this.state.answer.map((val, key) => {
                            return <td key={key}>{val}</td>;
                          })}
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                    <Row>
                      <div className="col-md-6">
                        {!this.state.isAssessment &&
                          <div className="wrap_border">
                            <div className="skincareTabs another">
                              <div className={`skincareTab ${this.state.skincareTab === "results" ? "active" : ""}`}
                                  onClick={this.setSkincareTabValue.bind(this, "results")}
                              >Assessment</div>
                              <div className={`skincareTab ${this.state.skincareTab === "skincare" ? "active" : ""}`}
                                  onClick={this.setSkincareTabValue.bind(this, "skincare")}
                              >Skincare</div>
                              <div className={`skincareTab ${this.state.skincareTab === "products" ? "active" : ""}`}
                                  onClick={this.setSkincareTabValue.bind(this, "products")}
                              >Products</div>
                            </div>
                          </div>
                        }
                        {this.state.isAssessment &&
                          <div className="wrap_border">
                            <UserAssessmentForm
                              userId={this.state.userId}
                              userObj={this.state.userObj}
                              answerId={this.state.answerId}
                              initialState={this.state.initialAssessment}
                              isDisabled={this.state.assessmentIsDisabled}
                            />
                          </div>
                        }
                        {(!this.state.isAssessment && (this.state.skincareTab === 'results' || this.state.skincareTab === 'skincare')) &&
                          <>
                            <div className="wrap_border">
                              <FormGroup>
                                {this.state.mustacheCardUIGroups &&
                                this.state.mustacheCardUIGroups.filter(el => this.hasGroupTemplates(el.groupId,'groupUIId','simple'))
                                  .map((groupVal,groupKey) => {
                                    return <div className={`mustaches_group ${groupVal.opened ? 'opened' : ''}`} key={groupKey}>
                                      <div className="mustaches_group_title"
                                           onClick={(event) => this.toggleMustachesUIGroup(event,groupVal.groupId)}>
                                        <label
                                          htmlFor={`mustaches_group_ui_${groupVal.groupId}`}
                                          className="centered_label_checkbox"
                                          onClick={e => e.stopPropagation()}
                                        >
                                          <input
                                            type="checkbox"
                                            style={{'opacity':'0'}}
                                            className="mr-5"
                                            id={`mustaches_group_ui_${groupVal.groupId}`}
                                            name={
                                              "mustaches_group_ui_" + groupVal.groupId || ""
                                            }
                                            value={groupVal.groupId}
                                            checked={this.checkSelectedGroup(groupVal.groupId,'groupUIId','simple')}
                                            onChange={(event) =>
                                              this.onChangeHandler(
                                                event,
                                                groupVal.groupId
                                              )
                                            }
                                            disabled={this.isBlocked() || true}
                                          />
                                          <span>{groupVal.groupName}</span>
                                        </label>
                                      </div>
                                      <div className="mustaches_group_body padding">
                                        {this.state.allMustachesTemplates &&
                                        this.state.allMustachesTemplates.filter(el => el.groupUIId === groupVal.groupId)
                                          .map((val, key) => {
                                              if(val.type === 'simple_results' && this.state.skincareTab === "results"){
                                                return this.showMustacheCheckbox(val, key)
                                              }else if(val.type === 'simple_skincare' && this.state.skincareTab === "skincare"){
                                                return this.showMustacheCheckbox(val, key)
                                              }
                                            }
                                          )}
                                      </div>
                                    </div>
                                  })}
                              </FormGroup>
                            </div>
                            <div className="additional_option"
                                 onClick={() => {this.setState({'showAdditionalOptions': !this.state.showAdditionalOptions})}}>
                              {this.state.showAdditionalOptions ? 'Hide' : 'Show'} additional options
                            </div>
                            {this.state.showAdditionalOptions &&
                            <div className="wrap_border">
                              <FormGroup>
                                <FormLabel className="block_heading">
                                  <strong>Additional Templates</strong>
                                </FormLabel>
                                {this.state.mustacheCardGroups &&
                                this.state.mustacheCardGroups.filter(el => this.hasGroupTemplates(el.groupId,'groupId','card'))
                                  .map((groupVal,groupKey) => {
                                    return <div className={`mustaches_group ${groupVal.opened ? 'opened' : ''}`} key={groupKey}>
                                      <div className="mustaches_group_title"
                                           onClick={(event) => this.toggleMustachesGroup(event,groupVal.groupId)}>
                                        <label
                                          htmlFor={`mustaches_group_${groupVal.groupId}`}
                                          className="centered_label_checkbox"
                                          onClick={e => e.stopPropagation()}
                                        >
                                          <input
                                            type="checkbox"
                                            className="mr-5"
                                            id={`mustaches_group_${groupVal.groupId}`}
                                            name={
                                              "mustaches_group_" + groupVal.groupId || ""
                                            }
                                            value={groupVal.groupId}
                                            checked={this.checkSelectedGroup(groupVal.groupId,'groupId','card')}
                                            onChange={(event) =>
                                              this.onChangeHandler(
                                                event,
                                                groupVal.groupId
                                              )
                                            }
                                            disabled={this.isBlocked()}
                                          />
                                          <span>{groupVal.groupName}</span>
                                        </label>
                                      </div>
                                      <div className="mustaches_group_body padding">
                                        {this.state.allMustachesTemplates &&
                                        this.state.allMustachesTemplates.filter(el => (el.groupId && el.groupId.split(',').includes(groupVal.groupId)))
                                          .map((val, key) => {
                                              if(val.type === 'card_results' && this.state.skincareTab === "results"){
                                                return this.showMustacheCheckbox(val, key)
                                              }else if(val.type === 'card_skincare' && this.state.skincareTab === "skincare"){
                                                return this.showMustacheCheckbox(val, key)
                                              }
                                            }
                                          )}
                                      </div>
                                    </div>
                                  })}
                              </FormGroup>
                            </div>
                            }
                            {this.state.skincareTab === 'skincare' &&
                            <div className="wrap_border">
                              <FormGroup className={`mustaches_group ${this.state.mustachesRecommendationOpened ? 'opened' : ''}`}>
                                <FormLabel className="block_heading mustaches_group_title"
                                              onClick={(event) => this.setState({mustachesRecommendationOpened:!this.state.mustachesRecommendationOpened})}>
                                  <strong>Recommendations</strong>
                                </FormLabel>
                                <div className="mustaches_group_body">
                                  <div className="block_subheading">Diet Do & Don’ts
                                    <span style={{'marginLeft': 'auto'}}>
                                      <span className="text_red">Don’t</span> |{' '}
                                      <span className="text_green">Do</span>
                                    </span>
                                  </div>
                                  <div className="block_text">
                                    {this.state.allMustachesRecommendationTemplates &&
                                    this.state.allMustachesRecommendationTemplates.map((recVal, recKey) => {
                                      return <div className="additional_border" key={recKey}>
                                        <label
                                          htmlFor={`rec_${recVal.ref}`}
                                          className="centered_label_checkbox"
                                          style={{'display': 'flex'}}
                                        >
                                          <input
                                            type="checkbox"
                                            className=""
                                            id={`rec_${recVal.ref}`}
                                            name={`rec_${recVal.ref}`}
                                            value={recVal.ref}
                                            checked={this.checkSelectedTemplate(recVal.ref)}
                                            onChange={(event) =>
                                              this.onChangeHandler(
                                                event,
                                                recVal.ref
                                              )
                                            }
                                            disabled={this.isBlocked()}
                                          />
                                          <span>{recVal.title}</span>
                                          <Switch
                                            className="checkbox_switch"
                                            onChange={(event) =>
                                              this.onChangeHandler(
                                                {currentTarget: {name: `rec_s_${recVal.ref}`, value: event}},
                                                recVal.ref
                                              )}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={14}
                                            width={34}
                                            handleDiameter={20}
                                            boxShadow={'0px 1px 3px #00000099'}
                                            activeBoxShadow={'0px 1px 3px #00000099'}
                                            offColor={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref) ? '#acacac' : '#ff0000'}
                                            onColor={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref) ? '#acacac' : '#169200'}
                                            offHandleColor={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref) ? '#acacac' : '#ff0000'}
                                            onHandleColor={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref) ? '#acacac' : '#169200'}
                                            disabled={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref)}
                                            checked={this.checkSelectedRecSwitch(recVal.ref)}
                                          />
                                        </label>
                                      </div>
                                    })}
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                            }
                            {this.state.skincareTab === 'skincare' &&
                            <div className="wrap_border">
                              <FormGroup className={`mustaches_group ${this.state.mustachesProcedureOpened ? 'opened' : ''}`}>
                                <FormLabel className="block_heading mustaches_group_title"
                                              onClick={(event) => this.setState({mustachesProcedureOpened:!this.state.mustachesProcedureOpened})}>
                                  <strong>Procedures</strong>
                                </FormLabel>
                                <div className="mustaches_group_body">
                                  <div className="block_subheading">Procedure Do & Don’ts
                                    <span style={{'marginLeft': 'auto'}}>
                                      <span className="text_red">Don’t</span> |{' '}
                                      <span className="text_green">Do</span>
                                    </span>
                                  </div>
                                  <div className="block_text">
                                    {this.state.allMustachesProcedureTemplates &&
                                    this.state.allMustachesProcedureTemplates.map((recVal, recKey) => {
                                      return <div className="additional_border" key={recKey}>
                                        <label
                                          htmlFor={`proc_${recVal.ref}`}
                                          className="centered_label_checkbox"
                                          style={{'display': 'flex'}}
                                        >
                                          <input
                                            type="checkbox"
                                            className=""
                                            id={`proc_${recVal.ref}`}
                                            name={`proc_${recVal.ref}`}
                                            value={recVal.ref}
                                            checked={this.checkSelectedTemplate(recVal.ref)}
                                            onChange={(event) =>
                                              this.onChangeHandler(
                                                event,
                                                recVal.ref
                                              )
                                            }
                                            disabled={this.isBlocked()}
                                          />
                                          <span>{recVal.title}</span>
                                          <Switch
                                            className="checkbox_switch"
                                            onChange={(event) =>
                                              this.onChangeHandler(
                                                {currentTarget: {name: `proc_s_${recVal.ref}`, value: event}},
                                                recVal.ref
                                              )}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={14}
                                            width={34}
                                            handleDiameter={20}
                                            boxShadow={'0px 1px 3px #00000099'}
                                            activeBoxShadow={'0px 1px 3px #00000099'}
                                            offColor={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref) ? '#acacac' : '#ff0000'}
                                            onColor={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref) ? '#acacac' : '#169200'}
                                            offHandleColor={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref) ? '#acacac' : '#ff0000'}
                                            onHandleColor={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref) ? '#acacac' : '#169200'}
                                            disabled={this.isBlocked() || !this.checkSelectedTemplate(recVal.ref)}
                                            checked={this.checkSelectedProcSwitch(recVal.ref)}
                                          />
                                        </label>
                                      </div>
                                    })}
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                            }
                            <div className="wrap_border">
                              <FormGroup>
                                <FormLabel className="block_heading">
                                  <strong>Additional Comments</strong>
                                </FormLabel>
                                <div className="block_text">
                                  <div className={this.state.skincareTab === 'results' ? '' : 'hidden'}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={this.state.additional_comment_results}
                                      onChange={(event, editor) => this.onChangeHandlerAdditionalCommentResults(editor.getData())}
                                      disabled={this.isBlocked()}
                                      onReady={(editor) => {
                                        editor.plugins.get('FileRepository')
                                          .createUploadAdapter = (loader) => {
                                          return new UploadAdapter(loader)
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className={this.state.skincareTab === 'results' ? 'hidden' : ''}>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={this.state.additional_comment_skincare}
                                      onChange={(event, editor) => this.onChangeHandlerAdditionalCommentSkincare(editor.getData())}
                                      disabled={this.isBlocked()}
                                      onReady={(editor) => {
                                        editor.plugins.get('FileRepository')
                                          .createUploadAdapter = (loader) => {
                                          return new UploadAdapter(loader)
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                          </>
                        }
                      </div>
                      <div className="col-md-6">
                        {this.state.isAssessment ?
                          (this.state.answerId && this.state.answerId !== '?type=t') ? 
                            <>
                              <div style={{ marginBottom: '20px' }}>
                                <select                                
                                  className="form-control"
                                  name="answer_id"
                                  value={this.state.answerId}
                                  onChange={(ev) => this.setState({answerId: ev.target.value})}
                                >
                                  <option value="">Select the answer</option>
                                  {this.state.answersList.map((answer) => (
                                    <option
                                      key={answer.id}
                                      value={answer.id}
                                    >
                                      {answer.created} | {answer.mark.toLowerCase() === 'intro' ? 'Initial' : 'Secondary'}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div style={{margin: '-20px -30px -15px', overflow: 'hidden'}}>
                                <AnswersView
                                  isAssessment
                                  answerId={this.state.answerId}
                                />
                              </div>
                            </> : <div style={{textAlign: 'center'}}>There are no answers to display</div> :
                          <>
                            <FormGroup>
                              <select
                                className="form-control"
                                name="language"
                                value={this.state.language}
                                onChange={(event) => this.onChangeHandler(event)}
                              >
                                <option value="">Select Language</option>
                                <option value="EN">EN</option>
                                <option value="RU">RU</option>
                              </select>
                            </FormGroup>
                            <div className="skincare_block_wrapper">
                              <div className="phone-wrapper"><img src={phoneTransparent} alt=""/></div>
                              <div className="skincare_block">
                                <div className="skincareTabs">
                                  <div className={`skincareTab ${this.state.skincareTab === "results" ? "active" : ""}`}
                                       onClick={this.setSkincareTabValue.bind(this, "results")}
                                  >Assessment</div>
                                  <div className={`skincareTab ${this.state.skincareTab === "skincare" ? "active" : ""}`}
                                       onClick={this.setSkincareTabValue.bind(this, "skincare")}
                                  >Skincare</div>
                                  <div className={`skincareTab ${this.state.skincareTab === "products" ? "active" : ""}`}
                                       onClick={this.setSkincareTabValue.bind(this, "products")}
                                  >Products</div>
                                </div>
                                <div className="skincareContent">
                                  <div className="iframe">
                                    {this.state.skincareTab === "results" &&
                                    <TemplateComponent
                                      template={`${this.state.baseResults[`description${title_lang}`]}`}
                                      data={data} />
                                    }
                                    {this.state.skincareTab === "skincare" &&
                                    <TemplateComponent
                                      template={`${this.state.basePlan[`description${title_lang}`]}`}
                                      data={data} />
                                    }
                                    {this.state.skincareTab === "products" &&
                                    <TemplateComponent
                                      template={`<div id="accordion">${this.state.baseProducts[`description${title_lang}`]}</div>`}
                                      data={data} />
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </Row>
                    <Row className="add_wrap">
                      <div className="col-md-12">
                        {(this.state.isAssessment || this.state.skincareTab === 'products') && <>
                          <div className="wrap_border_product">
                            <FormGroup>
                              <div style={{
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  justifyContent: 'space-between'
                                }}
                              >
                                <FormLabel className="block_heading">
                                  <strong>Products</strong>
                                </FormLabel>
                                {this.state.isAssessment &&
                                  <button
                                    type="button"
                                    style={{marginBottom: '10px'}}
                                    className="btn btn-success libi-admin-btn"
                                    onClick={() => this.setState({ productsIsDisabled: !this.state.productsIsDisabled })}
                                  >
                                    Edit
                                  </button>
                                }
                              </div>
                              {this.state.allProducts &&
                              this.state.allProducts.map((val, key) => {
                                return (
                                  <div key={key}>
                                    <div className="border_bottom">
                                      <Row className="wrap_border_product1">
                                        <div className="product_check">
                                          <label
                                            htmlFor={"product_" + val.ref}
                                            className="centered_label_checkbox"
                                          >
                                            <input
                                              type="checkbox"
                                              className="mr-5 "
                                              id={"product_" + val.ref}
                                              name={"product_" + val.ref}
                                              value={val.ref}
                                              checked={Object.keys(
                                                this.state.products
                                              ).includes(val.ref)}
                                              onChange={(event) =>
                                                this.onChangeHandler(
                                                  event,
                                                  val.ref
                                                )
                                              }
                                              disabled={this.state.productsIsDisabled || this.isBlocked()}
                                            />
                                            <span>{val.title}</span>
                                          </label>
                                        </div>
                                        <div className="product_treatment">
                                          {this.state.isAssessment && <div className="product-title">Time</div>}
                                          <div className="treatment_product_rule_wrapper">
                                            <div className="treatment_product_rule_indicator">
                                              {this.productRuleIndicator(this.state.products[val.ref])}
                                            </div>

                                            <select
                                              className="form-control"
                                              name={this.state.isAssessment ? "time_" + val.ref : "rule_" + val.ref}
                                              value={
                                                this.state.products[val.ref] &&
                                                  (this.state.isAssessment ? this.state.products[val.ref]?.time : this.state.products[val.ref].rule)
                                              }
                                              onChange={(event) =>
                                                this.onChangeHandler(
                                                  event,
                                                  val.ref
                                                )
                                              }
                                              disabled={this.state.productsIsDisabled || this.isBlocked()}
                                            >
                                              {this.state.isAssessment ?
                                                <>
                                                  <option value="">Select Time</option>
                                                  <option value="M">M</option>
                                                  <option value="E">E</option>
                                                  <option value="M&E">M&E</option>
                                                </> :
                                                treatmentTimeCases().map(
                                                  (treatmentTime, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        value={treatmentTime.key}
                                                      >
                                                        {treatmentTime.key}
                                                      </option>
                                                    );
                                                  }
                                                )
                                              }
                                            </select>
                                          </div>
                                        </div>
                                        <div className="product_time">
                                          {this.state.isAssessment && <div className="product-title">Quantity</div>}

                                          <CustomNumberField
                                            onChange={(event) =>
                                              this.onChangeHandler(
                                                event,
                                                val.ref
                                              )
                                            }
                                            min="0"
                                            max="10"
                                            name={"qty_" + val.ref}
                                            value={
                                              this.state.products[val.ref] &&
                                              this.state.products[val.ref].qty
                                            }
                                            disabled={this.state.productsIsDisabled || this.isBlocked()}
                                          />
                                        </div>
                                        {this.state.isAssessment &&
                                          <div className="product_frequency">
                                            {this.state.isAssessment && <div className="product-title">Frequency</div>}

                                            <select
                                              className="form-control"
                                              name={"frequency_" + val.ref}
                                              value={
                                                this.state.products[val.ref] &&
                                                this.state.products[val.ref].frequency
                                              }
                                              onChange={(event) =>
                                                this.onChangeHandler(
                                                  event,
                                                  val.ref
                                                )
                                              }
                                              disabled={this.state.productsIsDisabled}
                                            >
                                              <option value="">Select Frequency</option>
                                              <option>ED</option>
                                              <option>E2D</option>
                                              <option>E3D</option>
                                              <option>E7D</option>
                                              <option>E10D</option>
                                              <option>E14D</option>
                                            </select>
                                          </div>
                                        }
                                      </Row>
                                      {this.state.isAssessment && 
                                        <Row>
                                          <div className="product_area">
                                            <Multiselect
                                              options={areaOptions}
                                              selectedValues={
                                                this.state.products[val.ref] &&
                                                this.state.products[val.ref].area
                                              }
                                              onSelect={(value) => this.onChangeArea(value, val.ref)}
                                              onRemove={(value) => this.onChangeArea(value, val.ref)}
                                              displayValue="val"
                                              showCheckbox={true}
                                              placeholder="Select Area"
                                              disable={this.state.productsIsDisabled}
                                              closeOnSelect={false}
                                            />
                                          </div>
                                        </Row>
                                      }
                                    </div>
                                  </div>
                                );
                              })}
                            </FormGroup>
                          </div>
                        </>}
                      </div>
                    </Row>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserDiagnosisNew;
