import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { Link } from "react-router-dom"; 
import * as fb from '../../firebase';
import {
    addAdminLog,
    createUnixTimestamp
} from "components/Helper/index";

class EditImage extends Component {
    constructor(props){
        super(props);
        this.state = {
            id:"",
            query:"",
            key:"",
            value:"",
            data:[],
            dataList:[],
            picture: {},
            pictureUrl:"",
            disabled : false,
            error:"",
            errors:{}
        }
    }

    onChangeHandler = (event) => {
        if(event.target.files && event.target.files[0]){
            this.setState({picture:event.target.files[0]});
        }
    };

    componentDidMount = async () => {
        const  url = window.location.href;
        const slug = url.substring(url.lastIndexOf('/') + 1);
        const key = slug.split("?")[0];

        const search = this.props.location.search;
        const query = new URLSearchParams(search).get('doc');
        let doc;
        if(query==="text"){
            doc = "txt_img";
        }else if(query==="notification"){
            doc = "notifications_img";
        }
        const metadata = await fb.metaCollection.doc(doc).get();
        this.setState({
            query:query,
            key:key,
            value:metadata.data()[key],
            data:metadata.data(),
            dataList:Object.keys(metadata.data()),
            isLoading:false
        });
    }

    handleValidation = () => {
        let {key, value} = this.state;
        let errors = {};
        let formIsValid = true;

        //Title
        if(!key){
            formIsValid = false;
            errors["key"] = "Key is required";
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this._isMounted = true;
        this.setState({disabled: true});
        if(this.handleValidation(this.state.errors)){
            if(typeof(this.state.picture.name) != "undefined") {
                var timestamp = createUnixTimestamp();
                await fb.storage.child(`meta/${timestamp}-${this.state.picture.name}`).put(this.state.picture)
                    .then(snapshot => snapshot.ref.getDownloadURL()) 
                    .then((url) => { 
                    this.setState({pictureUrl:url}, () => {
                        this.submitData();
                    });
                });
            }else{
                this.submitData(); 
            }
        }else{
            this.setState({
                disabled: false
            });
        }
    }

    submitData = async () => {
        let doc;
        if(this.state.query==="text"){
            doc = "txt_img";
        }else if(this.state.query==="notification"){
            doc = "notifications_img";
        }
        await fb.metaCollection.doc(doc).update({
            [this.state.key]:this.state.pictureUrl
        }).then(snapShot => {
            addAdminLog('image_meta_changed',
              `Changed the image meta ${this.state.key} at the ${doc}`)
            this.props.history.push('/admin/meta')
        }).catch(err=> {
            this.setState({
                error:`Something went wrong-${err}`,
                disabled: false
            });
        });
    }

    render() {
    console.log('render', this.state)
    return (
        <div className="content">
        <Container fluid>
        <Row>
            <Col md={12}>
            {this.state.error &&
                <Alert bsStyle="danger">
                <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                </button>
                <span>{this.state.error}</span>
                </Alert>
            }
            <Card
                title="Edit Meta Image"
                button={
                <Link to={`/admin/meta`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                <form onSubmit={this.handleSubmit}>
                    <Row>
                    <div className="col-md-6">
                        <FormGroup>
                        <FormLabel>Key</FormLabel>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Key"
                            name="key"
                            value= {this.state.key}
                            onChange = {(event) => this.onChangeHandler(event)}
                            disabled={true}
                        />
                        <span className="help-block">{this.state.errors.key}</span>
                        </FormGroup>
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <FormLabel>Picture</FormLabel>
                            <input 
                                type="file" 
                                name="picture" 
                                className="form-control" 
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                        </FormGroup>
                    </div>
                    </Row>
                    <Row>
                    <div className="col-md-6">
                    { this.state.value && <img src={this.state.value}  alt="" style={{height:"auto", width:"200px", display:"block"}}/> }
                    </div>
                    </Row>
                    <Button
                    className="btn-fill pull-right libi-admin-btn"
                    bsStyle="info" 
                    pullRight fill 
                    type="submit"
                    disabled={this.state.disabled}
                    >
                    {this.state.disabled ? 'Loading...' : 'Update'}
                    </Button>
                    <div className="clearfix" />
                </form>
                }
            />
            </Col>
        </Row>
        </Container>
    </div>
    );
    }
}

export default EditImage;
