import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import {addAdminLog, userAccountStatus} from "../../components/Helper/index";
import { Link } from "react-router-dom"; 
import api from "api.js";
import axios from "axios";

class AddUser extends Component {
  constructor(props){
    super(props);
    this.state = {
      firstName:"",
      lastName:"",
      email:"",
      shippingAddress:{
        city:"",
        country:"",
        fullname:"",
        phone:"",
        line1:"",
        line2:"",
        state:"",
        postal_code:""
      },
      dob:"",
      gender:"M",
      picture:"",
      status:"",
      deleted:false,
      role_id:1,
      image: {},
      error:"",
      errors:{},
      disabled : false
    }
    this._isMounted = false;
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'firstName') {
      this.setState({firstName:value});
    }else if(name === 'lastName') {
      this.setState({lastName:value});
    }else if(name === 'email'){
      this.setState({email:value});
    }else if(name === 'fullname'){
      let currentState = this.state.shippingAddress;
      currentState.fullname = value;
      this.setState({currentState:currentState});
    }else if(name === 'postal_code'){
      let currentState = this.state.shippingAddress;
      currentState.postal_code = value;
      this.setState({currentState:currentState});
    }else if(name === 'state'){
      let currentState = this.state.shippingAddress;
      currentState.state = value;
      this.setState({currentState:currentState});
    }else if(name === 'phone'){
      let currentState = this.state.shippingAddress;
      currentState.phone = value;
      this.setState({currentState:currentState});
    }else if(name === 'country'){
      let currentState = this.state.shippingAddress;
      currentState.country = value;
      this.setState({currentState:currentState});
    }else if(name === 'city'){
      let currentState = this.state.shippingAddress;
      currentState.city = value;
      this.setState({currentState:currentState});
    }else if(name === 'line1'){
      let currentState = this.state.shippingAddress;
      currentState.line1 = value;
      this.setState({currentState:currentState});
    }else if(name === 'line2'){
      let currentState = this.state.shippingAddress;
      currentState.line2 = value;
      this.setState({currentState:currentState});
    }else if(name === 'dob'){
      this.setState({dob:value});
    }else if(name === 'gender'){
      this.setState({gender:value});
    }else if(name === 'status'){
    this.setState({status:value});
    }else if(event.target.files && event.target.files[0]){
      this.setState({image:event.target.files[0]});
    }
  };

  handleValidation = () => {
    let {firstName, lastName, email, status} = this.state;
    let errors = {};
    let formIsValid = true;

    //First Name
    if(!firstName){
      formIsValid = false;
      errors["firstName"] = "First name is required";
    }

    //Last Name
    if(!lastName){
      formIsValid = false;
      errors["lastName"] = "Last name is required";
    }

    //Email
    if(!email){
      formIsValid = false;
      errors["email"] = "Please enter a valid email address";
    }

    //Status
    if(!status){
      formIsValid = false;
      errors["status"] = "Account status is required";
    }

    if(typeof email !== "undefined"){
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Please enter a valid email address";
      }
    }  
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this._isMounted = true;
    this.setState({ disabled: true });
    if (this.handleValidation(this.state.errors) && typeof (this.state.image.name) != "undefined") {
      var timestamp = Math.floor(Date.now() / 1000);
      fb.storage.child(`pictures/${timestamp}-${this.state.image.name}`).put(this.state.image)
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then(async (url) => {
          if (url) {
            await this.saveUser()
          }
          if (this._isMounted === true) {
            this.setState({ picture: url });
          }
        });
    } else {
      this.saveUser();
    }
  }

  saveUser = async () => {
    try {
      this.setState({ error: '' })

      const response = await fetch(`${fb.CRM_URL}/s/silentreg/` + encodeURI(this.state.email))
  
      const data = await response.json()
  
      if (data?.code === 'auth/email-already-exists') {
        throw new Error(data?.message)
      }
  
      const ref = await fetch(`${fb.CRM_URL}/s/new_refncode`)
      const refData = await ref.json()
  
      const userObj = {
        id: data?.uid,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        gender: this.state.gender,
        shippingAddress: this.state.shippingAddress,
        status: this.state.status,
        role_id: this.state.role_id,
        dob: this.state.dob,
        picture: this.state.picture,
        deleted: this.state.deleted,
        version: 2,
        checkPayment: -1,
        language: 'EN',
        ref: refData.data.ref,
        invite_code: refData.data.code,
      }

      await api.post('createUser', userObj)
        .then((res) => {
          axios.get(`${fb.CRM_URL}/s/crmadd/31000024064/${data?.uid}`)
            .then((result) => {
  
            }).catch(err => {
              console.log('err', err)
              this.setState({
                error: err.message,
                disabled: false
              })
            })

          // addAdminLog(
          //   'user_data_added', 
          //   `Added the user data for the user ${data?.uid}`, 
          //   data?.uid,
          //   data?.uid,
          //   this.state.firstName + ' ' + this.state.lastName
          // )
          this.props.history.push('/admin/users')
        })
      
    } catch (err) {
      this.setState({
        error: err.message,
        disabled: false
      })
    }
  }
 
  componentWillUnmount = () => {
    if(this._isMounted){
      this._isMounted = false;
    }
  }
  
  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Add Customer"
                button={
                  <Link to={`/admin/users`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>First Name</FormLabel>
                          <input
                            type ="text"
                            className= "form-control"
                            placeholder= "First Name"
                            name="firstName"
                            value= {this.state.firstName}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.firstName}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Last Name</FormLabel>
                          <input
                            type ="text"
                            className= "form-control"
                            placeholder= "Last Name"
                            name="lastName"
                            value= {this.state.lastName}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.lastName}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Email</FormLabel>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value= {this.state.email}
                            onChange ={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.email}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Gender</FormLabel>
                          <select
                            className= "form-control"
                            name= "gender"
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Other</option>
                          </select>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Account Status</FormLabel>
                          <select
                            className="form-control"
                            name="status"
                            value={this.state.status}
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Status</option>
                            { 
                              userAccountStatus().map((status, index) => {
                                return <option key={index} value={status.key}>{status.val}</option>
                              })
                            }
                          </select>
                          <span className="help-block">{this.state.errors.status}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Image</FormLabel>
                          <input 
                            type="file" 
                            name="picture" 
                            className="form-control" 
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <h4>Delivery Address</h4>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Full Name</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            name="fullname"
                            value={this.state.shippingAddress.fullname}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Phone</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                            name="phone"
                            value={this.state.shippingAddress.phone}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Country</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Country"
                            name="country"
                            value={this.state.shippingAddress.country}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>City</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            value={this.state.shippingAddress.city}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>ZIP</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="ZIP"
                            name="postal_code"
                            value={this.state.shippingAddress.postal_code}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>State</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="State"
                            name="state"
                            value={this.state.shippingAddress.state}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Street</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Street"
                            name="line1"
                            value={this.state.shippingAddress.line1}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Appartment</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Appartment"
                            name="line2"
                            value={this.state.shippingAddress.line2}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading...' : 'Add User'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddUser;
