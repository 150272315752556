import React, { Component } from "react";
import SelectSearch from "react-select-search";

class Dropdown extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:""
        }
    }

    dropdownToSelect(choices){
        let options = [];
        for (const choice of choices) {
            options.push({name: choice.label, value: choice.ref})
        }
        return options
    }

    onChangeHandler = async (event) => {
        let choice = this.props.options.find(x => x.ref === event)
        this.props.onChange(choice)
    }

    render() {
        return (
            <SelectSearch
                className="typeFormDropdown"
                options={this.dropdownToSelect(this.props.options)}
                name="dropdown"
                placeholder="Type or select an option"
                search={true}
                onChange={this.onChangeHandler}/>
        );
    }
}

export default Dropdown;
