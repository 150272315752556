import React, { Component } from "react";
import { staticWebImages } from "../../components/Utils/images";
import * as fb from "../../firebase";
import Loading from "./Loading";
import { langText } from "../../components/Utils/helper";


class Disclaimer extends Component {
  constructor(props){
    super(props);
    this.state = {
      userId:"",
      userData: {},
      isTerms:false,
      errors:{},
      disabled: false,
      contentText: '',
      rulesFirst: '',
      submitLabel: '',
      isLoading:false,
    }
  }

  onChangeHandlerIsTerms = async (event) => {
    this.setState({isTerms:!this.state.isTerms});
  };

  async componentDidMount() {
    let uid = localStorage.getItem('userId');
    let user;
    if(!uid){
      this.props.history.push('/')
    }else{
      user = await fb.usersCollection.doc(uid).get()
          .then(user=> {
            if(user.exists){
              if(user.data().status !== 'waiting_delivery' && user.data().status !== 'waiting_diagnose'){
                this.props.history.push('/')
              }
            }else{
              this.props.history.push('/')
            }
            return user;
          });
      this.setState({userData:user.data()});
    }
    let langTextObj = await langText();
    let content = langTextObj.disclaimer_text;
    let name = user.data().shippingAddress.fullname;
    content = content.replace(/\[\[Name\]\]/g, name);
    content = content.replace(/\\n/g, '<br\><br\>');
    this.setState({
      contentText: content,
      rulesFirst: langTextObj.disclaimer_checkbox_title,
      submitLabel: langTextObj.disclaimer_continue_button,
    });
    this.setState({isLoading: true});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let userData = this.state.userData;
    if(userData.status === 'waiting_diagnose'){
      this.props.history.push('/thankyou-diagnose');
    }else{
      this.props.history.push('/after-payment');
    }
  }

  render() {
    console.log('render', this.state)
    return (
        <section className="Subscription" style={{background: 'rgba(238, 221, 211, 0.6)'}}>
          {this.state.isLoading ?
              <div>
                <div className="container_5-fluid">
                  <div className="row_5">
                    <div className="col_5-lg-6 col_5-12" style={{padding: 0}}>
                      <div className="Subscription_cust" style={{background: 'transparent'}}>
                        <img src={staticWebImages.disclaimer} alt="" style={{width: "100%", maxWidth: "530px"}}/>
                      </div>
                    </div>
                    <div className="col_5-lg-6 col_5-12">
                      <div className="subs_mainBox">
                        <div className="subsRightBoxWrapper">
                          <div className="subsRightBox">
                            <div className="main-logo">
                              <img src={staticWebImages.logo} alt=""
                                   className="img-responsive center-block web-logo-hide"/>
                            </div>
                            <p className="shiping_para scroll_wrapper" dangerouslySetInnerHTML={{ __html:this.state.contentText}}></p>
                            <div className="form-section_add">
                              <form onSubmit={this.handleSubmit} className="d-flex justify-content-center align-items-center">
                                <p className="alrt_txt1">{
                                  this.state.isTerms ?
                                      <img
                                          src={staticWebImages.check}
                                          onClick={this.onChangeHandlerIsTerms}
                                          className="alrt-img"
                                          style={{cursor:"pointer"}} />
                                      :
                                      <i
                                          className="fa fa-circle-thin accept-red-color"
                                          onClick={this.onChangeHandlerIsTerms}>
                                      </i>
                                } {this.state.rulesFirst} </p>
                                <button
                                    type="submit"
                                    className="form-control custom-btn-web"
                                    disabled={!this.state.isTerms || this.state.disabled}
                                >
                                  {this.state.disabled ? 'Loading...' : this.state.submitLabel}
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                        <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <Loading />
          }
        </section>
    );
  }
}

export default Disclaimer;
