import React, { Component } from "react";
import { Container, Row, Col, Table, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import * as fb from "../../firebase";
import {
  staticImages
} from "components/Helper/index";
//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import {
  convertFirebseSecondsToDate,
} from "components/Helper/index";

class ListChatAdmin extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      adminList:[],
      staticImages: {},
      errors:{},
      error:""
    }
  }

  componentDidMount = async () => {
    const si = await staticImages()
    this.setState({staticImages:si})
    await this.fetchData();
    var table = $('#list-datatable').DataTable({
      "order": [[ 0, "asc" ]],
      "bStateSave": true,
    }); 
  }
  
  fetchData = async() => {
    try {
      this.setState({isLoading:true});
      const data = await fb.chatAdminsCollection.get();
      const dataArr = [];
      data.docs.forEach(doc=> {
        const dataObj = doc.data();
        dataArr.push({
          id:dataObj.id,
          name:dataObj.name,
          picture:dataObj.picture,
          created: dataObj.created && dataObj.created.seconds ? convertFirebseSecondsToDate(dataObj.created.seconds) : "",
        });
      })
      this.setState({
        adminList:dataArr,
        isLoading:false
      });
    }catch(err){
      this.setState({error:err.message})
    }
  }

  deleteItem = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await fb.chatAdminsCollection.doc(id).delete().then(() => {
        this.fetchData();
      }).catch(function(error) {
        this.setState({error:error.message})
      });
    }
  }

  

  render() {
    // console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="All Chat Admins"
                category=""
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`chat-admin/add`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"> Add </Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Table striped hover id="list-datatable">
                    <thead>
                      <tr>
                        <th>Created</th>
                        <th>Picture</th>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                      <tbody>
                        {this.state.adminList.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.created}</td>
                              <td><img src={val.picture ? val.picture : this.state.staticImages.placeholderImage} alt="" style={{height:"50px", width:"50px"}}/></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td> 
                                <Link to={`chat-admin/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp; 
                                <Link to={`#`} onClick={() => {this.deleteItem(val.id)}}><i className="fa fa-times" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ListChatAdmin;
