import Card from 'components/Card/Card'
import PageLoader from 'components/Loader/PageLoader'
import React, { useEffect, useState } from 'react'
import { Col, Container, FormGroup, Row, Table } from 'react-bootstrap';
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from "../../firebase";
import $ from 'jquery';
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import { Link } from 'react-router-dom';
import moment from 'moment';
import api from 'api.js';
import { convertMilisecondsToDate } from 'components/Helper/index.jsx';

const ordersColumns = ['Order ID', 'Created', 'Name', 'Type', 'Order Value']

const PaymentsView = () => {
  const paymentId = window.location.pathname.split('/').splice(-1)[0]

  const [payment, setPayment] = useState({})
  const [orders, setOrders] = useState([])
  const [acr, setAcr] = useState(0)
  const [consumerTotal, setConsumerTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [created, setCreated] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleClick = async () => {
    if (!isEdit) {
      setIsEdit(true)
      return
    }

    await api.post('updateExpertPayment', {
      id: paymentId,
      acr,
      time: created,
      consumerTotal: consumerTotal * 100,
      total: total * 100
    })

    setIsEdit(false)
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)

      const ordersArr = []
      const {data: expertPayments} = await api.get('getExpertPayments')
      let paymentData = expertPayments.find(i => i.id === paymentId)

      if (paymentData) {
        const {data: partnerData} = await api.get('userByID', {params: {userId: paymentData.expertId}})
        
        if (partnerData) {
          paymentData.partnerName = ((partnerData?.firstName && partnerData?.lastName) && `${partnerData?.firstName} ${partnerData?.lastName}`) || partnerData?.name || '-'
          paymentData.partnerEmail = partnerData.email || '-'
          paymentData.time = convertMilisecondsToDate(new Date(paymentData?.time).getTime()) || '-'
        }
        setPayment(paymentData)
        setCreated(paymentData.time)
        setAcr(paymentData.acr)
        setConsumerTotal(paymentData.consumerTotal / 100)
        setTotal(paymentData.total / 100)

        for (let i = 0; i < paymentData.order_ids.length; i++) {
          const order = paymentData.order_ids[i];

          const {data: orderData} = await api.get('orderByID', { params: { orderId: order.id } })

          if (orderData) {
            ordersArr.push({ 
              ...order, 
              ...orderData,
              created_seconds: new Date(orderData.created).getTime(),
              created: convertMilisecondsToDate(new Date(orderData.created).getTime()), 
            })
          }
        }

        setOrders(ordersArr)

        $('#orders-table').DataTable({
          order: [[1, 'desc']],
        })
      }
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Payments View"
              button={
                <Link to={`/admin/payment`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
              }
              category=""
              ctTableFullWidth
              ctTableResponsive
              loader={isLoading && <PageLoader />}
              content={
                <>
                  <p><b>Partner Name:</b> {payment.partnerName}</p>
                  <p><b>Partner Email:</b> {payment.partnerEmail}</p>
                  <p><b>Payment Type:</b> {payment.payment_type}</p>
                  <p><b>Created: </b>
                    {isEdit ?
                      <FormGroup style={{ 'display': 'inline-block' }}>
                        <input
                          type="date"
                          className="form-control"
                          value={moment(created).format('YYYY-MM-DD')}
                          onChange={(event) => {
                            setCreated(fb.firebase.firestore.Timestamp.fromDate(new Date(event.target.value)))
                          }}
                        />
                      </FormGroup>
                      : created
                        ? created
                        : "N/A"
                    }
                  </p>
                  <p><b>ACR: </b>
                    {!isEdit ? `${acr}%` : (
                      <FormGroup style={{ display: 'inline-block' }}>
                        <input
                          type="number"
                          min="0"
                          step="0.1"
                          className="form-control"
                          value={acr}
                          onChange={ev => setAcr(ev.target.value)}
                        />
                      </FormGroup>
                    )}
                  </p>
                  <p><b>Order Value: </b>
                    {!isEdit ? `${payment.currency === 'eur' ? '€' : '£'}${+consumerTotal.toFixed(2)}` : (
                      <FormGroup style={{ display: 'inline-block' }}>
                        <input
                          type="number"
                          min="0"
                          step="0.1"
                          className="form-control"
                          value={+consumerTotal}
                          onChange={ev => setConsumerTotal(ev.target.value)}
                        />
                      </FormGroup>
                    )}
                  </p>
                  <p><b>Partner Price: </b>
                    {!isEdit ? `${payment.currency === 'eur' ? '€' : '£'}${+total.toFixed(2)}` : (
                      <FormGroup style={{ display: 'inline-block' }}>
                        <input
                          type="number"
                          min="0"
                          step="0.1"
                          className="form-control"
                          value={+total}
                          onChange={ev => setTotal(ev.target.value)}
                        />
                      </FormGroup>
                    )}
                  </p>

                  <Button
                    className="btn-fill libi-admin-btn"
                    style={{ margin: '0 0 20px 0' }}
                    bsStyle="info"
                    fill
                    onClick={handleClick}
                  >
                    {isEdit ? 'Save' : 'Edit'}
                  </Button>

                  <Table striped hover id={'orders-table'}>
                    <thead>
                      <tr>
                        {ordersColumns.map(i => <th key={i}>{i}</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map(i => (
                        <tr key={i.id}>
                          <td><Link to={`/admin/payment/order/${i.id}`}><u>{i?.ref?.replace('LD', '') || '-'}</u></Link></td>
                          <td><span style={{ display: "none" }}>{i.created_seconds}</span>{i.created}</td>
                          <td>{i?.userName || '-'}</td>
                          <td>{i?.type ? i.type.toUpperCase() : '-'}</td>
                          <td>{i?.consumerTotal ? `${payment.currency === 'eur' ? '€' : '£'}${(i.consumerTotal / 100).toFixed(2)}` : '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PaymentsView