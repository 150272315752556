import React, {useState} from "react";
import { 
    Modal, Button,
    Row,
    FormGroup,
    FormLabel  
} from "react-bootstrap";
import axios from 'axios';
import {
    notificationGroup
  } from "../Helper/index";
import api from "api";

function UsersNotificationView(props) {

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [group, setGroup] = useState('');
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(false);

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        if(name === 'title') {
            setTitle(value);
        }else if(name === 'message'){
            setMessage(value);
        }else if(name === 'group'){
            setGroup(value);
        }
    };
    
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        
        //Description
        if(!group){
            formIsValid = false;
            errors["group"] = "Group is required";
        }

        //Title
        if(!title){
          formIsValid = false;
          errors["title"] = "Title is required";
        }
    
        //Description
        if(!message){
          formIsValid = false;
          errors["message"] = "Message is required";
        }
        setErrors(errors);
        return formIsValid; 
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setDisabled(true)
        if(handleValidation()){
            //notification to all users
            try{
                let data = "news";
                let topic = group;
                const result = await api.get(`s/send?topic=${topic}&title=${encodeURIComponent(title)}&message=${encodeURIComponent(message)}&data=${data}`)
                console.log('s/send result', result);
                props.onHide();
            }catch (e) {
                console.error('s/send result', e);
            }
        }else{
            setDisabled(false)
        }
    }

    console.log('group', group)

    return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <h4 style={{textAlign:"center"}}><strong>Notification  {props.data.name}</strong></h4>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
            <Row>
                <div className="col-md-12">
                <FormGroup>
                    <FormLabel>Group</FormLabel>
                    <select
                        className="form-control"
                        name='group'
                        value={group}
                        onChange={(event)=>onChangeHandler(event)}
                    >
                    <option value="">Select Group</option>
                    { 
                        notificationGroup().map((group, index) => {
                        return <option key={index} value={group.key}>{group.val}</option>
                    })
                    }
                    </select>
                    <span className="help-block">{errors.group}</span>
                </FormGroup>
                </div>
            </Row>
            <Row>
                <div className="col-md-12">
                    <FormGroup>
                        <FormLabel>Title</FormLabel>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            name="title"
                            value= {title}
                            onChange = {(event) => onChangeHandler(event)}
                        />
                        <span className="help-block">{errors.title}</span>
                    </FormGroup>
                </div>
            </Row>
            <Row>
                <div className="col-md-12">
                    <FormGroup>
                        <FormLabel>Message</FormLabel>
                        <textarea
                            type="text"
                            className="form-control"
                            placeholder="Message"
                            name="message"
                            value= {message}
                            onChange ={(event)=>onChangeHandler(event)}
                        />
                        <span className="help-block">{errors.message}</span>
                    </FormGroup>
                </div>
            </Row>
            <Button
                className="libi-admin-btn"
                bsStyle="info" 
                type="submit"
                disabled={disabled}
            >
            {disabled ? 'Loading...' : 'Send'}
            </Button>
            <div className="clearfix" />
        </form>
      </Modal.Body>
    </Modal>
  );
}
  
export default UsersNotificationView;
