import React from 'react'
import logo from '../../../assets/img/logo.png'
import picture from '../../../web_assets/img/libby6.png'

const WelcomeScreen = (props) => {
  const {onNext, user, expert, mark} = props

  return (
    <div className="typeFormWelcome">
      <div className="typeFormWelcomeMain">
        <a href="/" className="typeFormWelcomeLogo">
          <img src={logo} alt="Libi&Daughters" />
        </a>

        <p>Dear, {((user?.firstName && user?.lastName) && `${user?.firstName} ${user?.lastName}`) || user?.name || '-'}</p>
        <p>Your dedicated skincare expert, {((expert?.firstName && expert?.lastName) && `${expert?.firstName} ${expert?.lastName}`) || expert?.name || '-'}, has requested additional information about your skin in order to personalize your skincare routine effectively. We highly value this information, as it plays a crucial role in tailoring our approach specifically for you.</p>
        <p>To proceed, kindly follow the instructions below:</p>
        <p>Upload Pictures: Please provide photographs of the following areas:</p>
        <ul>
          <li>Forehead</li>
          <li>Frontal view of your face</li>
          <li>Left cheek</li>
          <li>Right cheek</li>
        </ul>
        <p>Complete the Questionnaire: In addition to the pictures, we kindly request you to fill out the following questionnaire. Your responses will assist us in gaining a comprehensive understanding of your skin's unique needs.</p>
        <p>Your cooperation in this process will greatly contribute to our ability to provide you with a highly personalized skincare routine that addresses your specific concerns and goals. We appreciate your commitment to achieving healthy and radiant skin. If you have any questions or encounter any difficulties during this process, please do not hesitate to reach out to us. We are here to support you every step of the way.</p>
        <p>Thank you for entrusting us with your skincare journey.</p>
        <p>Sincerely, <br /> Libi Care Team</p>
        <button 
          type="button"
          className="typeFormWelcomeButton"
          onClick={onNext}
        >
          Continue
        </button>
      </div>

      <img src={picture} alt="Picture" className="typeFormWelcomeImage" />
    </div>
  )
}

export default WelcomeScreen