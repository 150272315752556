import React, { Component } from "react";
import * as fb from "../../firebase"; 
import Loading from "./Loading"; 


class Questionnaire extends Component { 
  constructor(props){
    super(props);
    this.state = {
      userId:"",
      TURL:"",
      errors:{},
      disabled : false,
      errorMsg : "",
    }
  }

  componentDidMount = async () => {
    //get current user
    const userId = localStorage.getItem('userId');
    if(userId){
      await fb.usersCollection.doc(userId).get()
      .then(async user=> {
        if(user.exists){
          if(user.data().status !== 'questionnaire1'){
            this.props.history.push('/')
          }
        }else{
          this.props.history.push('/')
        }
        let lang_text = user.data().language === 'RU' ? '_ru' : ''
        const questionnaire = await fb.metaCollection.doc('questionnaire'+lang_text).get();
        let url = questionnaire.data().intro;
        if(user.data()){
          this.setState({
            userId:userId,
            TURL:`${url}?uid=${userId}&mark=intro`
          });
        }
      });
    }
  }

  submitQuestionnaire = async() => {
    // if(this.state.vip===true){
    //   const changeUserStatus = fb.functions.httpsCallable('changeUserStatus');
    //   await changeUserStatus({uid:this.state.userId,status:'deposit_vip'}).then(res=> {
    //     this.props.history.push('/deposit-vip');
    //   }).catch(err=> {
    //     this.setState({isLoading:true}); 
    //   });
    // }else{
    // }
    const changeUserStatus = fb.functions.httpsCallable('changeUserStatus');
    await changeUserStatus({uid:this.state.userId,status:'waiting_diagnose'}).then(res=> {
        this.props.history.push('/deposit');
    }).catch(err=> {
      this.setState({isLoading:true}); 
    });
  }

  render() {
    console.log('render', this.state)
    return (
      <section className="Sign_Home">
      { this.state.TURL ? 
         <Loading />
        :
        <Loading />
        }
      </section>
    );
  }
}

export default Questionnaire;
