import React from "react";
import { Modal, Button} from "react-bootstrap";

function VideoView(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <video src={props.src} width="500" height="350" controls></video>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
export default VideoView;
