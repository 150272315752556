import React, { Component } from "react";
import { staticWebImages } from "../../components/Utils/images";
import { Link } from "react-router-dom";
import * as fb from "../../firebase";
import {langText} from "../../components/Utils/helper";
import Loading from "./Loading";
import api from "api";


class Subscribe extends Component {
  constructor(props){
    super(props);
    this.state = {
      customer:"",
      error:"",
      errors:{},
      disabled : false,
      subTab: 12,
      country: 'hu',
      currency: '€',
      selectedPrice: {
        key: '',
        value: '',
        title: '',
        price: '',
        interval: '',
        period: ''
      },
      stripePriceData: null,
      checkTitle: '',
      check1: '',
      check2: '',
      check3: '',
      check4: '',
      check5: '',
      check6: '',
      titleText: '',
      contentText: '',
      submitLabel: '',
      firstTabLabel: '',
      secondTabLabel: '',
      termsLabel: '',
      discount6: '',
      discount12: '',
      monthly12Label: '',
      monthly6Label: '',
      months12Label: '',
      months6Label: '',
      perMonthly6: '',
      perMonthly12: '',
      perMonths6: '',
      perMonths12: '',
      isLoading: false,
      test_page: false,
    }
    this._isMounted = false;
  }

  componentDidMount = async () => {
    let query = await new URLSearchParams(this.props.location.search)
    let test = query.get("test")
    if(test === '1') this.setState({test_page:true});
    let country = localStorage.getItem('country');
    if(country){
      this.setState({country:country});
      if(country === 'uk'){
        this.setState({currency:'£'});
      }else{
        this.setState({currency:'€'});
      }
    }else{
      this.props.history.push('/shipping-address');
    }
    let langTextObj = await langText();
    this.setState({
      checkTitle: langTextObj.pay_plans_screen_title,
      check1: langTextObj.pay_first_check_title,
      check2: langTextObj.pay_second_check_title,
      check3: langTextObj.pay_third_check_title,
      check4: langTextObj.pay_fourth_check_title,
      check5: langTextObj.pay_fifth_check_title,
      check6: langTextObj.pay_sixth_check_title,
      titleText: langTextObj.pay_title,
      contentText: langTextObj.pay_subs_renew_auto,
      submitLabel: langTextObj.pay_subs_now_button,
      firstTabLabel: langTextObj.pay_segment_first,
      secondTabLabel: langTextObj.pay_segment_second,
      termsLabel: langTextObj.pay_terms_of_service_button,
      discount6: langTextObj.pay_discount6,
      discount12: langTextObj.pay_discount12,
      monthly6Label: langTextObj.pay_left_title_for_segment_first,
      monthly12Label: langTextObj.pay_left_title_for_segment_second,
      months12Label: langTextObj.pay_right_title_for_segment_second,
      months6Label: langTextObj.pay_right_title_for_segment_first,
      perMonthly6: langTextObj['pay_6_detail_monthly_'+this.state.country],
      perMonthly12: langTextObj['pay_12_detail_monthly_'+this.state.country],
      perMonths6: langTextObj['pay_6_detail_prepaid_'+this.state.country],
      perMonths12: langTextObj['pay_12_detail_prepaid_'+this.state.country],
    });
    let uid = localStorage.getItem('userId');
    if(!uid){
      this.props.history.push('/')
    }else{
      const user = await fb.usersCollection.doc(uid).get()
          .then(user=> {
            if(user.exists){
              if(user.data().status !== 'waiting_payment' && user.data().status !== 'waiting_diagnose'){
                this.props.history.push('/')
              }
            }else{
              this.props.history.push('/')
            }
          });
      fb.usersCollection.doc(uid).collection('Logs').add({
        'description': 'Opened Subscription page',
        'subscription_payment': 'remaining',
        'time': fb.timestamp.serverTimestamp()
      });
    }
    // if(!fb.firebase.auth().currentUser){
    //   await fb.auth.signInWithEmailAndPassword('2ovob4ehko@ukr.net', 'm1a2x3m1a2x3').then(res => {
    //     localStorage.setItem('userLogin', "yes");
    //     localStorage.setItem('userId', res.user.uid);
    //   }).catch(error => {
    //     console.error("Error signing in with password and email", error);
    //   });
    // }
    api.get("s/products/"+uid)
        .then(res => res.data)
        .then(
            (result) => {
              this.setState({stripePriceData:result});
              try{
                let selectedPrice = JSON.parse(localStorage.getItem('selectedPrice'));
                if(selectedPrice){
                  this.setState({selectedPrice:selectedPrice});
                }
              }catch (e){}
            },
            (error) => {

            }
        )
    this.setState({
      isLoading: true
    });
  }

  generateRandomNDigits = (n) => {
    return Math.floor(Math.random() * (9 * (Math.pow(10, n)))) + (Math.pow(10, n));
  }

  stripePrice = (key) => {
    return this.state.currency+this.state.stripePriceData[key].price/100;
  }

  setSelectedPrice = (key, period, e) => {
    let interval = 1;
    if(key === `${this.state.country}_12_month_prepaid_subscription`){
      interval = 12;
    }else if(key === `${this.state.country}_6_month_prepaid_subscription`){
      interval = 6;
    }
    let priceId = ''
    if (this.state.test_page) {
      if (this.state.country === 'uk') {
        priceId = 'price_1Js3GoBs2yoh5fx0Bw3Dkg4R'
      } else {
        priceId = 'price_1Js3ISFEuTzA8RWlzIa57eLf'
      }
    } else {
      priceId = this.state.stripePriceData[key].priceId
    }
    this.setState({selectedPrice:{
        key: key,
        value: priceId,
        title: this.state.stripePriceData[key].title,
        price: this.state.stripePriceData[key].price/100,
        interval: interval,
        period: period
      }});
  }

  subTab = (name, e) => {
    this.setState({subTab:name});
  }

  submit = (e) => {
    e.preventDefault();
    localStorage.setItem('selectedPrice', JSON.stringify(this.state.selectedPrice));
    // this.props.history.push('/shipping-address');
    this.props.history.push('/confirm-details');
  }

  render() {
    console.log('render', this.state)
    return (
      <section className="Subscription">
        {this.state.isLoading ? <>
        <span className="custm-angle">
          <span className="backBtn" onClick={this.props.history.goBack}><i className="fa fa-angle-left" aria-hidden="true" /></span>
          <span className="mobileTitle">{this.state.checkTitle}</span>
        </span>
        <div className="container_5-fluid">
          <div className="row_5">
            <div className="col_5-lg-6 col_5-12 " style={{padding: 0}}>
              <div className="Subscription_cust">
                <div className="SubsBox">
                  <h2 className="cust_subs_heading">{this.state.checkTitle}</h2>
                  <ul className="list-unstyled">
                    <li><img src={staticWebImages.check} />{this.state.check1}</li>
                    <li><img src={staticWebImages.check} />{this.state.check2}</li>
                    <li><img src={staticWebImages.check} />{this.state.check3}</li>
                    <li><img src={staticWebImages.check} />{this.state.check4}</li>
                    <li><img src={staticWebImages.check} />{this.state.check5}</li>
                    <li><img src={staticWebImages.check} />{this.state.check6}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col_5-lg-6 col_5-12 ">
              <div className="subs_mainBox">
                <div className="subsRightBoxWrapper">
                  <div className="subsRightBox">
                    <div className="main-logo">
                      <img src={staticWebImages.logo} alt="" className="img-responsive center-block web-logo-hide" />
                    </div>
                    <h2 className="main_txt_sign text-center" style={{marginBottom: '30px'}}>{this.state.titleText}</h2>
                    <div className="cust_btn_group">
                      <span className={`btnsubs ${this.state.subTab == 6 ? 'active' : ''}`}
                            onClick={this.subTab.bind(this, 6)}>{this.state.firstTabLabel}</span>
                      <span className={`btnsubs ${this.state.subTab == 12 ? 'active' : ''}`}
                            onClick={this.subTab.bind(this, 12)}>{this.state.secondTabLabel}</span>
                    </div>
                    {
                      this.state.stripePriceData &&
                          (
                            this.state.subTab == 12 ?
                                <div className="row_5 Price_group">
                                  <div className="col_5-6 ">
                                    <div className={`priceBox ${this.state.selectedPrice['key'] == `${this.state.country}_monthly_subscription` ? 'active' : ''}`} onClick={this.setSelectedPrice.bind(this,`${this.state.country}_monthly_subscription`,12)}>
                                      <h6 className="month_subs">{this.state.monthly12Label}</h6>
                                      <h3 className="rate_subs"><strong>{this.stripePrice(`${this.state.country}_monthly_subscription`)}</strong></h3>
                                      <p className="per_subs">{this.state.perMonthly12}</p>
                                    </div>
                                  </div>
                                  <div className="col_5-6 ">
                                    <div className={`priceBox ${this.state.selectedPrice['key'] == `${this.state.country}_12_month_prepaid_subscription` ? 'active' : ''}`} onClick={this.setSelectedPrice.bind(this,`${this.state.country}_12_month_prepaid_subscription`,12)}>
                                      <div className="subs_discount">{this.state.discount12}</div>
                                      <h6 className="month_subs">{this.state.months12Label}</h6>
                                      <h3 className="rate_subs"><strong>{this.stripePrice(`${this.state.country}_12_month_prepaid_subscription`)}</strong></h3>
                                      <p className="per_subs">{this.state.perMonths12}</p>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="row_5 Price_group">
                                  <div className="col_5-6 ">
                                    <div className={`priceBox ${this.state.selectedPrice['key'] == `${this.state.country}_monthly_subscription` ? 'active' : ''}`} onClick={this.setSelectedPrice.bind(this,`${this.state.country}_monthly_subscription`,6)}>
                                      <h6 className="month_subs">{this.state.monthly6Label}</h6>
                                      <h3 className="rate_subs"><strong>{this.stripePrice(`${this.state.country}_monthly_subscription`)}</strong></h3>
                                      <p className="per_subs">{this.state.perMonthly6}</p>
                                    </div>
                                  </div>
                                  <div className="col_5-6 ">
                                    <div className={`priceBox ${this.state.selectedPrice['key'] == `${this.state.country}_6_month_prepaid_subscription` ? 'active' : ''}`} onClick={this.setSelectedPrice.bind(this,`${this.state.country}_6_month_prepaid_subscription`,6)}>
                                      <div className="subs_discount">{this.state.discount6}</div>
                                      <h6 className="month_subs">{this.state.months6Label}</h6>
                                      <h3 className="rate_subs"><strong>{this.stripePrice(`${this.state.country}_6_month_prepaid_subscription`)}</strong></h3>
                                      <p className="per_subs">{this.state.perMonths6}</p>
                                    </div>
                                  </div>
                                </div>
                          )
                    }
                    {
                      !this.state.stripePriceData && <h3 className="main_txt">Loading..</h3>
                    }
                    <div className="form-section form-deposit">
                      <form className="m-auto" onSubmit={this.submit}>
                        <input type="submit" value={this.state.submitLabel} disabled={!this.state.selectedPrice['value']} className="form-control custom-btn-web" />
                      </form>
                    </div>
                    <p className="subs_renew">{this.state.contentText}</p>
                    <p className="subs_renew"><a href="https://libi.com/terms-conditions/">{this.state.termsLabel}</a></p>
                  </div>
                </div>
                <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
              </div>
            </div>
          </div>
        </div>
        </> : <Loading />}
      </section>
    );
  }
}

export default Subscribe;
