import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
  Tab, 
  Tabs
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import {
  addAdminLog,
  createUnixTimestamp
} from "components/Helper/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from "react-router-dom";
import UploadAdapter from '../../components/image-adaptor/UploadAdapter';
import MultiplePhotoField from "../../components/MultiplePhotoField/MultiplePhotoField";
import firebase from "firebase";

class AddProduct extends Component {
  constructor(props){
    super(props);
    this.state = {
      title:"",
      order:"",
      ref:"",
      description:"",
      instruction:"",
      title_ru:"",
      instruction_ru:"",
      description_ru:"",
      picture: {},
      pictureUrl:"",
      pictureName:"",
      disabled : false,
      error:"",
      errors:{},
      pictures: [],
      forUpload: []
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'title') {
      this.setState({title:value});
    }else if(name === 'title_ru'){
      this.setState({title_ru:value});
    }else if(name === 'order'){
      this.setState({order:value});
    }else if(name === 'ref'){
      this.setState({ref:value});
    }else if(name === 'language') {
      this.setState({language:value});
    }else if(name === 'pictures'){
      this.setState({
        pictures: value.pictures,
        forUpload: value.forUpload
      })
    }
  };

  onChangeHandlerDescription = (value) => {
    this.setState({description:value});
  };

  onChangeHandlerInstruction = (value) => {
    this.setState({instruction:value});
  };

  onChangeHandlerDescriptionRu = (value) => {
    this.setState({description_ru:value});
  };

  onChangeHandlerInstructionRu = (value) => {
    this.setState({instruction_ru:value});
  };

  

  handleValidation = () => {
    let {title, description, instruction, title_ru, description_ru, instruction_ru, ref, order} = this.state;
    let errors = {};
    let formIsValid = true;

    //Title
    if(!title){
      formIsValid = false;
      errors["title"] = "Title is required";
    }

    //Title
    if(!title_ru){
      formIsValid = false;
      errors["title_ru"] = "Title ru is required";
    }

    if(!order){
      formIsValid = false;
      errors["order"] = "Order is required";
    }

    //ref
    if(!ref){
      formIsValid = false;
      errors["ref"] = "Ref is required";
    }

    //Description
    if(!description){
      formIsValid = false;
      errors["description"] = "Description is required";
    }

    //Description
    if(!description_ru){
      formIsValid = false;
      errors["description_ru"] = "Description ru is required";
    }

    //Instruction
    if(!instruction){
      formIsValid = false;
      errors["instruction"] = "Instruction is required";
    }

    //Instruction
    if(!instruction_ru){
      formIsValid = false;
      errors["instruction_ru"] = "Instruction ru is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    //disable button
    this.setState({disabled: true});
    if(this.handleValidation(this.state.errors)){
      if(this.state.forUpload.length > 0) {
        let pictures = this.state.pictures
        for (const file of this.state.forUpload) {
          let timestamp = createUnixTimestamp();
          await fb.storage.child(`products/${timestamp}-${file.name}`).put(file)
              .then(snapshot => snapshot.ref.getDownloadURL())
              .then((url) => {
                pictures.push({
                  url: url,
                  name: `products/${timestamp}-${file.name}`,
                  uploaded: firebase.firestore.Timestamp.fromDate(new Date()),
                  order: file.order,
                  type: file.type
                })
              });
        }
        this.setState({pictures: pictures}, () => {
          this.saveUser();
        });
      }else{
        this.saveUser(); 
      }
    }else{
      this.setState({
        disabled: false
      });
    }
  }

  saveUser = async () => {
    let docRef = fb.productsCollection.doc();
    await docRef.set({
      id:docRef.id,
      title:this.state.title,
      order:this.state.order,
      ref:this.state.ref,
      description:this.state.description,
      instruction:this.state.instruction,
      title_ru:this.state.title_ru,
      description_ru:this.state.description_ru,
      instruction_ru:this.state.instruction_ru,
      picture:this.state.pictureUrl,
      pictureName:this.state.pictureName,
      created:createUnixTimestamp(),
      imageUploadedAt:fb.timestamp.serverTimestamp(),
      pictures: this.state.pictures
    }).then(spanShot => {
      addAdminLog(
        'product_created',
        `Created the product ${docRef.id}`,
        docRef.id
      )
      this.props.history.push('/admin/products')
    }).catch(err => {
      this.setState({
        error:`Something went wrong-${err}`,
        disabled: false
      });
    });
  }
  
  render() {
    console.log('render', this.state)
    let obj = {
      array: []
    };
    for (var l=0;l<100;l++){
      obj.array[l] = l+1;
    }
    let optionItems = obj.array.map((item) =>
      <option key={item}>{item}</option>
    );
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Add Product"
                button={
                  <Link to={`/admin/products`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-12">
                        <MultiplePhotoField
                            name="pictures"
                            pictures={this.state.pictures}
                            onChange={(event)=>this.onChangeHandler(event)} />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Order</FormLabel>
                          <select
                            className= "form-control"
                            name= "order"
                            value={this.state.order} 
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Order</option>
                            {optionItems}
                          </select>
                          <span className="help-block">{this.state.errors.order}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Ref</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ref"
                            name="ref"
                            value= {this.state.ref}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.ref}</span>
                        </FormGroup>
                      </div>
                    </Row>
                  <Tabs defaultActiveKey="en" id="uncontrolled-tab-example">
                  <Tab eventKey="en" title="EN">
                  <Row>
                    <div className="col-md-12">
                      <FormGroup>
                        <FormLabel>Title</FormLabel>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          name="title"
                          value= {this.state.title}
                          onChange = {(event) => this.onChangeHandler(event)}
                        />
                        <span className="help-block">{this.state.errors.title}</span>
                      </FormGroup>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-12">
                      <FormGroup controlId="formControlsTextarea">
                        <FormLabel>Instruction</FormLabel>
                        <CKEditor
                          editor={ ClassicEditor }
                          data={this.state.instruction}
                          onChange={ ( event, editor ) => this.onChangeHandlerInstruction(editor.getData())}
                          onReady={(editor) => {
                            editor.plugins.get('FileRepository')
                            .createUploadAdapter = (loader) => {
                              return new UploadAdapter(loader)
                            }
                          }}
                        />
                        <span className="help-block">{this.state.errors.instruction}</span>
                      </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup controlId="formControlsTextarea">
                          <FormLabel>Description</FormLabel>
                          <CKEditor
                            editor={ ClassicEditor }
                            data={this.state.description}
                            onChange={ ( event, editor ) => this.onChangeHandlerDescription(editor.getData())}
                            onReady={(editor) => {
                              editor.plugins.get('FileRepository')
                              .createUploadAdapter = (loader) => {
                                return new UploadAdapter(loader)
                              }
                            }}
                          />
                          <span className="help-block">{this.state.errors.title}</span>
                        </FormGroup>
                      </div>
                    </Row>
                  </Tab>
                  <Tab eventKey="ru" title="RU">
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Title</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            name="title_ru"
                            value= {this.state.title_ru}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.title_ru}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup controlId="formControlsTextarea">
                          <FormLabel>Instruction</FormLabel>
                          <CKEditor
                            editor={ ClassicEditor }
                            data={this.state.instruction_ru}
                            onChange={ ( event, editor ) => this.onChangeHandlerInstructionRu(editor.getData())}
                            onReady={(editor) => {
                              editor.plugins.get('FileRepository')
                              .createUploadAdapter = (loader) => {
                                return new UploadAdapter(loader)
                              }
                            }}
                          />
                          <span className="help-block">{this.state.errors.instruction_ru}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup controlId="formControlsTextarea">
                          <FormLabel>Description</FormLabel>
                          <CKEditor
                            editor={ ClassicEditor }
                            data={this.state.description_ru}
                            onChange={ ( event, editor ) => this.onChangeHandlerDescriptionRu(editor.getData())}
                            onReady={(editor) => {
                              editor.plugins.get('FileRepository')
                              .createUploadAdapter = (loader) => {
                                return new UploadAdapter(loader)
                              }
                            }}
                          />
                          <span className="help-block">{this.state.errors.description_ru}</span>
                        </FormGroup>
                      </div>
                    </Row>
                  </Tab>
                  </Tabs>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info"
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Uploading...' : 'Add Product'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddProduct;
