import React, { useState } from "react";
import bgimgfrom from "../../src/assets/img/lady-background.jpg";
import { NavLink } from "react-router-dom";
import * as fb from "../../src/firebase";
import axios from "axios";
import api from "api";

const Register = ({ history }) => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const createUserWithEmailAndPassword = (event, email, password, name) => {
    event.preventDefault();
    fb.auth.createUserWithEmailAndPassword(email, password).then(res => {
      // create user object in userCollections
      api.post('createUser', {
        id: res.user.uid,
        name: name,
        role_id: 2,
        email: email,
        picture: "",
        approved: false,
        deleted:false,
        created: new Date()
      });
      // axios.get(`https://us-central1-production-lnd.cloudfunctions.net/s/crmadd/31000021531/${res.user.uid}`)
      //   .then(() => {
          history.push("/admin/dashboard");
        // })
    }).catch(error => {
      setError(error.message);
      console.error("Error signing in with password and email", error);
    });
  };

  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'userEmail') {
      setEmail(value);
    }
    else if(name === 'userPassword'){
      setPassword(value);
    }else if(name === 'userName'){
      setName(value);
    }
  };
  
  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          
          <form className="login100-form validate-form">
            <span className="login100-form-title p-b-43">
              Register to Continue
            </span>
            {error !== null && <div className = "py-4 bg-red-600 w-full text-danger  mb-3">{error}</div>}
            <div className="wrap-input100 validate-input" data-validate="name is required">
              <input 
                type="text"
                name="userName"
                placeholder="Name"
                className="input100"
                value = {name}
                id="username"
                onChange = {(event) => onChangeHandler(event)}
              />
              <span className="focus-input100" />
            </div>
            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">  
            <input 
                className="input100" 
                type="email" 
                name="userEmail"
                placeholder="Email" 
                value = {email}
                onChange = {(event) => onChangeHandler(event)}
              />
              <span className="focus-input100" />
            </div>
            <div className="wrap-input100 validate-input" data-validate="Password is required">
              <input 
                type="password"
                name="userPassword"
                placeholder="Password"
                className="input100"
                value = {password}
                id="userPassword"
                onChange = {(event) => onChangeHandler(event)}
                autoComplete="off"
              />
              <span className="focus-input100" />
            </div>
            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick = {(event) => {createUserWithEmailAndPassword(event, email, password, name)}}>
                Register
              </button>
            </div>
            <div className="text-center p-t-46 p-b-20">
              <span className="txt2">
                <NavLink
                  to="/admins"
                  activeClassName="active"
                >
                Back to Log In
                </NavLink>
              </span>
            </div>
          </form>
          <div className="login100-more" style={{backgroundImage: `url(${bgimgfrom})`}}>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
