import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import 'firebase/messaging';
import api from "api";

const firebaseStageConfig = {
  apiKey: "AIzaSyDcbELVkdF4ZzWmBzHwIFqFFVVca9XvvO4",
  authDomain: "l-d-control.firebaseapp.com",
  databaseURL: "https://l-d-control.firebaseio.com",
  projectId: "l-d-control",
  storageBucket: "l-d-control.appspot.com",
  messagingSenderId: "77322294117",
  appId: "1:77322294117:web:6e676fef3835c3a551c78c",
  measurementId: "G-YZCZLM5L9B"
};

var firebaseProdConfig = {
  apiKey: "AIzaSyDjkMzQAbqkOmBvcbG65hjJSswgHUGYh-k",
  authDomain: "production-lnd.firebaseapp.com",
  projectId: "production-lnd",
  storageBucket: "production-lnd.appspot.com",
  messagingSenderId: "672507294475",
  appId: "1:672507294475:web:fd1d9b56c52e9cf5b7280e",
  measurementId: "G-M3BPKX5T6K"
};

export const CRM_URL = process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_CRM_URL_DEV : process.env.REACT_APP_CRM_URL

let secondaryApp;
if(process.env.REACT_APP_ENV === 'development') {
  // Initialize Firebase development
  firebase.initializeApp(firebaseStageConfig);
  //created a secondary for add auth users otherwise it kick off current users
  secondaryApp = firebase.initializeApp(firebaseStageConfig, 'Secondary');
} else {
  // Initialize Firebase production
  firebase.initializeApp(firebaseProdConfig);
  //created a secondary for add auth users otherwise it kick off current users
  secondaryApp = firebase.initializeApp(firebaseProdConfig, 'Secondary');
}

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage().ref();
const timestamp = firebase.firestore.FieldValue;
const functions  = firebase.functions();

let messaging = null;
if (firebase.messaging.isSupported()){
  messaging = firebase.messaging();
}

// collection references
const usersCollection = db.collection('Users')
const chatRoomCollection = db.collection('ChatRoom')
const chatsCollection = db.collection('Chats')
const profilesCollection = db.collection('Profiles')
const productsCollection = db.collection('Products')
const questionnairesCollection = db.collection('Questionnaires')
const answersCollection = db.collection('Answers')
const conditionsCollection = db.collection('Conditions')
const treatmentsCollection = db.collection('Treatments')
const metaCollection = db.collection('Meta')
const templatesCollection = db.collection('Templates')
const emailsCollection = db.collection('Emails')
const stripeCollection = db.collection('Stripe')
const shopifyCollection = db.collection('Shopify')
const InvitedUsersCollection = db.collection('InvitedUsers');
const packagesCollection = db.collection('Packages')
const notificationsCollection = db.collection('Notifications')
const chatAdminsCollection = db.collection('Chat_Admins')
const functionTaskCollection = db.collection('FunctionTasks')
const mustachesCollection = db.collection('Mustaches')
const adminLogsCollection = db.collection('AdminLogs')
const rulesCollection = db.collection('Rules')
const assessmentFormsCollection = db.collection('AssessmentForms')
const docsCollection = db.collection('docs')
const ordersCollection = db.collection('Orders')
const expertsPaymentsCollection = db.collection('ExpertsPayments')





export const getToken = (setTokenFound) => {
  if(!messaging) return
  return messaging.getToken({vapidKey: 'BJ0Uc3hW297DwOhluOvELqPfbXruTvdGhj7pZi8RW6RLgKfwnHG9OskkMHTy7J5HO_7FxLidy818kZaf6k2v2uQ'}).then(async (currentToken) => {
    if (currentToken) {
      //set token in localstorage
      localStorage.setItem('firebaseClientToken', currentToken);
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      let topic = "chat";
      // eslint-disable-next-line
      await api.get(`s/subscribe/${topic}/${currentToken}`)
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});


// export const message = () => {
//   messaging.messaging()
// }



export {
  messaging,
  functions,
  timestamp,
  db,
  auth,
  secondaryApp,
  firebase,
  storage,
  usersCollection,
  chatRoomCollection,
  chatsCollection,
  profilesCollection,
  questionnairesCollection,
  productsCollection,
  answersCollection,
  conditionsCollection,
  treatmentsCollection,
  metaCollection,
  templatesCollection,
  emailsCollection,
  stripeCollection,
  shopifyCollection,
  InvitedUsersCollection,
  packagesCollection,
  notificationsCollection,
  chatAdminsCollection,
  functionTaskCollection,
  mustachesCollection,
  adminLogsCollection,
  rulesCollection,
  assessmentFormsCollection,
  docsCollection,
  ordersCollection,
  expertsPaymentsCollection
}
