import React, { useEffect, useState } from 'react'
import * as fb from '../../firebase';
import { Col, Container, Row, Table } from 'react-bootstrap';
import PageLoader from 'components/Loader/PageLoader';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables.js"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import Card from 'components/Card/Card';
import api from 'api';

const DashboardReport = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api.get('ordersReport').then(res => {
      setData(res.data)
      setIsLoading(false)
      $('#report').DataTable({
        "bStateSave": true
      })
    })
  }, [])

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Orders report"
              ctTableFullWidth
              ctTableResponsive
              loader={isLoading && <PageLoader />}
              content={
                <div>
                  <Table striped hover id="report" style={{ display: 'block', overflow: 'auto' }}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Year</th>
                        <th>Month</th>
                        <th>Day</th>
                        <th>Email</th>
                        <th>Customer Name</th>
                        <th>Customer ID</th>
                        <th>Referral Partner</th>
                        <th>Referral Partner ID</th>
                        <th>Country</th>
                        <th>Country VAT</th>
                        <th>Product</th>
                        <th>Subscription</th>
                        <th>Unit Purchased</th>
                        <th>List Price (Total, VAT inclusive)</th>
                        <th>Discount (Subscription)</th>
                        <th>Discount (Referral)</th>
                        <th>Discount (Loyalty)</th>
                        <th>Commission (VAT inclusive)</th>
                        <th>Amount Received (VAT inclusive)</th>
                        <th>Net Revenue</th>
                        <th>Currency</th>
                        <th>xRate</th>
                        <th>Revenue in GBP</th>
                        <th>Units adjusted</th>
                        <th>Total purchases by ID</th>
                        <th>New</th>
                        <th>Recurring</th>
                        <th>Revived</th>
                        <th>Quarter</th>
                        <th>Previous Q</th>
                        <th>Date</th>
                        <th>Date #</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.orders?.map((i, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{i?.year || '-'}</td>
                          <td>{i?.month || '-'}</td>
                          <td>{i?.day || '-'}</td>
                          <td>{i?.email || '-'}</td>
                          <td>{i?.customer_name || '-'}</td>
                          <td>{i?.customer_id || '-'}</td>
                          <td>{i?.referral_partner || '-'}</td>
                          <td>{i?.referral_partner_id || '-'}</td>
                          <td>{i?.country || '-'}</td>
                          <td>{i?.country_vat || '-'}</td>
                          <td>{i?.product || '-'}</td>
                          <td>{i?.subscription || '-'}</td>
                          <td>{i?.unit_purchased || '-'}</td>
                          <td>{i?.list_price || '-'}</td>
                          <td>{i?.discount_subscription || '-'}</td>
                          <td>{i?.discount_referral || '-'}</td>
                          <td>{i?.discount_loyalty || '-'}</td>
                          <td>{i?.commision || '-'}</td>
                          <td>{i?.amount_received || '-'}</td>
                          <td>{i?.net_revenue || '-'}</td>
                          <td>{i?.currency || '-'}</td>
                          <td>{i?.x_rate || '-'}</td>
                          <td>{i?.gbp_revenue || '-'}</td>
                          <td>{i?.units_adjusted || '-'}</td>
                          <td>{i?.total_purchases_by_id || '-'}</td>
                          <td>{i?.new || '-'}</td>
                          <td>{i?.recurring || '-'}</td>
                          <td>{i?.revived || '-'}</td>
                          <td>{i?.quarter || '-'}</td>
                          <td>{i?.previous_q || '-'}</td>
                          <td>{i?.date || '-'}</td>
                          <td>{i?.date || '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              }
            ></Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DashboardReport