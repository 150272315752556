import Card from "components/Card/Card";
import PageLoader from "components/Loader/PageLoader";
import React from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import { useEffect } from "react";
import api from "api";

const PERMISSIONS_LIST = [
  { val: "New assessment", key: "new_assessment" },
  { val: "New treatment request", key: "new_treatment_request" },
  { val: "Secondary questionnaire", key: "secondary_questionnaire" },
  { val: "Package changes status", key: "package_changes_status" },
  { val: "New chat message", key: "new_chat_message" },
  { val: "New partner registered", key: "new_partner_registered" },
  { val: "New package created", key: "new_package_created" },
];

function EditEmailNotification(props) {
  const [isLoading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissionsError, setPermissionsError] = useState("");

  const onSelect = (selectedList) => {
    setSelectedPermissions(selectedList);
  };
  const onRemove = (selectedList) => {
    setSelectedPermissions(selectedList);
  };
  const handleValidate = () => {
    if (selectedPermissions.length) {
      setPermissionsError("");
      return true;
    }
    if (!selectedPermissions.length) {
      setPermissionsError(true);
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (handleValidate()) {
      api.post('updateUser', {
        id: props.match.params.id,
        emails_permissions: selectedPermissions,
      })
        .then(() => {
          props.history.push("/admin/notifications");
        });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const {data} = await api.get('userByID', {params: {userId: props.match.params.id}})
    setCustomer(data.name + " <" + data.email + ">");
    setSelectedPermissions(data.emails_permissions);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Email Notifications"
              category="User notifications list"
              button={
                <Link
                  to={`/admin/notifications`}
                  className="btn btn-success pull-left card-back-button libi-admin-btn"
                >
                  {" "}
                  Back
                </Link>
              }
              ctTableFullWidth
              loader={isLoading && <PageLoader />}
              content={
                <form onSubmit={onSubmit}>
                  <Row>
                    <div className="col-md-6">
                      <FormGroup>
                        <FormLabel>Customer</FormLabel>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={customer}
                          disabled
                          onChange={() => {}}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <FormLabel>Permissions</FormLabel>
                        <Multiselect
                          options={PERMISSIONS_LIST}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          selectedValues={selectedPermissions}
                          displayValue="val"
                          showCheckbox={true}
                          placeholder="Select Primissions"
                        />
                        {permissionsError && "Please assign a permissions"}
                      </FormGroup>
                    </div>
                  </Row>
                  <Button
                    className="btn-fill pull-right libi-admin-btn"
                    bsStyle="info"
                    type="submit"
                  >
                    Add
                  </Button>
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EditEmailNotification;
