import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import {Multiselect} from "multiselect-react-dropdown";
import SelectSearch from "react-select-search";
import {Link} from "react-router-dom";
import api from "api.js";

class EditPackage extends Component {

  constructor(props){
    super(props);
    this.state = {
      id:"",
      customer:"",
      products:[],
      statusList:[
        {
          name: "Waiting shipment",
          value: "waiting_shipment"
        },
        {
          name: "Shipped",
          value: "shipped"
        },
        {
          name: "Delivered",
          value: "delivered"
        }
      ],
      delivery:{
        appartment:"",
        city:"",
        country:"",
        name:"",
        phone:"",
        street:"",
        zipCode:""
      },
      status:"",
      trackingNumber:"",
      errors:{}
    }
  }

  onChangeHandler = async (event) => {
    const {name, value} = event.currentTarget;
    if (name === 'trackingNumber') {
      this.setState({trackingNumber: value});
    }
  };

  onChangeStatusHandler = async (selectedItem) => {
    this.setState({status: selectedItem});
  };

  componentDidMount = async () => {
    const url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    const { data } = await api.get('getPackageByID', { params: { id: segment } })
    this.setState({
      id: data.id,
      customer: data.userName + " <" + data.userEmail + ">",
      products: data.products,
      delivery: data.delivery ? data.delivery : this.state.delivery,
      status: data.status,
      trackingNumber: data.trackingNumber || ""
    })
  }

  handleValidation = () => {
    let {status, trackingNumber} = this.state;
    let errors = {};
    let formIsValid = true;

    if(!trackingNumber){
      formIsValid = false;
      errors["trackingNumber"] = "Tracking number is required";
    }

    //Status
    if(!status){
      formIsValid = false;
      errors["status"] = "Package status is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      let data = {
        status: this.state.status,
        trackingNumber: this.state.trackingNumber
      };
      if (this.state.status === 'shipped') {
        data.shipped = fb.timestamp.serverTimestamp();
      } else if (this.state.status === 'delivered') {
        data.delivered = fb.timestamp.serverTimestamp();
      }

      await api.post('updatePackage', {
        ...data,
        id: this.state.id
      })
      this.props.history.push('/admin/shipment');
      window.location.reload();
    } else {
      console.log("required errors", this.state.errors)
    }
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Package"
                button={
                  <Link to={`/admin/shipment`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Status</FormLabel>
                          <SelectSearch
                              options={this.state.statusList}
                              value={this.state.status}
                              name="status"
                              placeholder="Select status"
                              onChange={(event)=>this.onChangeStatusHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.status}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Tracking Number</FormLabel>
                          <input
                              type="text"
                              className="form-control"
                              placeholder="Tracking Number"
                              name="trackingNumber"
                              value={this.state.trackingNumber}
                              onChange={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.trackingNumber}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Customer</FormLabel>
                          <input
                            type ="text"
                            className= "form-control"
                            placeholder= "Customer"
                            name= "name"
                            value= {this.state.customer}
                            readOnly={true}
                          />
                          <span className="help-block">{this.state.errors.customer}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Products</FormLabel>
                          <Multiselect
                              options={this.state.products}
                              selectedValues={this.state.products}
                              displayValue="title"
                              disable={true}
                              placeholder="Select Products"
                          />
                          <span className="help-block">{this.state.errors.products}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <h4>Delivery Address</h4>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Name</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="d_name"
                            readOnly={true}
                            value={this.state.delivery ? this.state.delivery.name : ""}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>City</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            readOnly={true}
                            value={this.state.delivery ? this.state.delivery.city : ""}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Phone</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                            name="d_phone"
                            readOnly={true}
                            value={this.state.delivery ? this.state.delivery.phone : ""}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Country</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Country"
                            name="country"
                            readOnly={true}
                            value={this.state.delivery ? this.state.delivery.country : ""}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>ZIP</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="ZIP"
                            name="zipCode"
                            readOnly={true}
                            value={this.state.delivery ? this.state.delivery.zipCode : ""}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Street</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Street"
                            name="street"
                            readOnly={true}
                            value={this.state.delivery ? this.state.delivery.street : ""}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Appartment</FormLabel>
                          <input 
                            type="text"
                            className="form-control"
                            placeholder="Appartment"
                            name="appartment"
                            readOnly={true}
                            value={this.state.delivery ? this.state.delivery.appartment : ""}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Button 
                      bsStyle="info" 
                      pullRight fill 
                      type="submit">
                      Update Package
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditPackage;
