import React from "react";
import * as serviceWorker from './serviceWorker.js';
import ReactDOM from "react-dom";
import App from './App';


//DASHBOARD
import "./assets/public/vendor/animate/animate.css";
import "./assets/public/vendor/css-hamburgers/hamburgers.min.css";
import "./assets/public/vendor/animsition/css/animsition.min.css";
import "./assets/public/css/util.css";
import "./assets/public/css/main.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/custom.css";


import UserProvider from "providers/UserProvider";

ReactDOM.render(<UserProvider><App /></UserProvider>, document.getElementById("root"));
serviceWorker.unregister();
