//Authentication
import Login from "views/Login.jsx";
import Register from "views/Register.jsx";
import ForgotPassword from "views/ForgotPassword.jsx";

//Dashboard
import Dashboard from "views/dashboard/Dashboard.jsx";

//Chats
import Chats from "views/chat/Chat.jsx";

//Users
import AddUser from "views/user/AddUser.jsx";
import UserList from "views/user/UserList.jsx";
import ViewUser from "views/user/ViewUser.jsx";
import UserDiagnosisNew from "views/user/UserDiagnosisNew.jsx";
import UserDiagnosis from "views/user/UserDiagnosisNew.jsx";
import AnswersList from "views/user/AnswersList.jsx";
import Referral from "views/user/Referral.jsx";
import AddReferral from "views/user/AddReferral.jsx";
import Performance from "views/user/Performance.jsx";
import UserNotification from "views/user/Notification.jsx";

//Assessment
import AssessmentList from "views/assessment/AssessmentList.jsx";
import AssessmentView from "views/assessment/AssessmentView.jsx";

//Payment
import PaymentsList from "views/payment/PaymentsList.jsx";
import PaymentsView from "views/payment/PaymentsView.jsx";
import PaymentsOrder from "views/payment/PaymentsOrder.jsx";


//Invited Users
import AddInviteUser from "views/invite-users/AddInviteUser.jsx";
import EditInviteUser from "views/invite-users/EditInviteUser.jsx";
import InviteUserList from "views/invite-users/InviteUserList.jsx";

//Events
import AddEvent from "views/event/AddEvent.jsx";
import EditEvent from "views/event/EditEvent.jsx";
import EventList from "views/event/EventList.jsx";

//Admins
import AddAdmin from "views/admin/AddAdmin.jsx";
import EditAdmin from "views/admin/EditAdmin.jsx";
import AdminList from "views/admin/AdminList.jsx";

//Experts
import EditExpert from "views/expert/EditExpert.jsx";
import ExpertList from "views/expert/ExpertList.jsx";
import PayExpert from "views/expert/PayExpert.jsx";

//Chat admins
import AddChatAdmin from "views/chat-admin/AddChatAdmin.jsx";
import EditChatAdmin from "views/chat-admin/EditChatAdmin.jsx";
import ListChatAdmin from "views/chat-admin/ListChatAdmin.jsx";

//Questionnaires
// import AddQuestionnaires from "views/questionnaires/AddQuestionnaires";
// import QuestionnairesList from "views/questionnaires/QuestionnairesList"; 
// import EditQuestionnaires from "views/questionnaires/EditQuestionnaires";
// import ViewQuestionnaires from "views/questionnaires/ViewQuestionnaires";
// import CloneQuestionnaires from "views/questionnaires/CloneQuestionnaires";

//Answers
//import AnswersList from "views/answers/AnswersList";

//profile
import EditAdminProfile from "views/admin/EditAdminProfile";

//Products
import AddProduct from "views/product/AddProduct"; 
import ProductList from "views/product/ProductList"; 
import EditProduct from "views/product/EditProduct";

//Conditions
// import AddCondition from "views/condition/AddCondition"; 
// import ConditionList from "views/condition/ConditionList"; 
// import EditCondition from "views/condition/EditCondition";

//Treatment
// import TreatmentList from "views/treatment/TreatmentList";

// eslint-disable-next-line
import AddTreatment from "views/treatment/AddTreatment";
import AddAfTreatment from "views/treatment/AddAfTreatment";

//Meta
import AddMeta from "views/meta/AddMeta"; 
import MetaList from "views/meta/MetaList"; 
import EditMeta from "views/meta/EditMeta";
import EditQuestionnaireNew from "views/meta/EditQuestionnaireNew";
import EditShipping from "views/meta/EditShipping";
import EditStripeKey from "views/meta/EditStripeKey";
import EditReferral from "views/meta/EditReferral";
import EditImage from "views/meta/EditImage";



//Products
import AddStripeProduct from "views/stripe-product/AddStripeProduct"; 
import StripeProductList from "views/stripe-product/StripeProductList"; 
import EditStripeProduct from "views/stripe-product/EditStripeProduct";
import EditStripeTestProduct from "views/stripe-product/EditStripeTestProduct";

//Template
import AddTemplate from "views/template/AddTemplate"; 
import TemplateList from "views/template/TemplateList"; 
import EditTemplate from "views/template/EditTemplate";

//Template new 
import AddTemplateNew from "views/template_new/AddTemplate"; 
import TemplateListNew from "views/template_new/TemplateList"; 
import EditTemplateNew from "views/template_new/EditTemplate";

//Emails 
import EmailList from "views/emails/EmailList"; 
import ViewEmail from "views/emails/ViewEmail";

//Shipment
import ShipmentList from "views/shipment/ShipmentList.jsx";
import AddPackage from "views/shipment/AddPackage.jsx";
import EditPackage from "views/shipment/EditPackage.jsx";
import ViewPackage from "views/shipment/ViewPackage.jsx";

//Rules
import RulesList from "views/rules/RulesList"
import AddRule from "views/rules/AddRule"
import EditRule from "views/rules/EditRule"

//AdminLogs
import AdminLogsList from "views/admin-logs/AdminLogsList.jsx"
import ViewAdminLog from "views/admin-logs/ViewAdminLog.jsx"
import EmailNotification from "views/email-notication/EmailNotification";
import AddEmailNotification from "views/email-notication/AddEmailNotifications";
import EditEmailNotification from "views/email-notication/EditEmailNotification";
import StripeShopifyList from "views/stripe-shopify/StripeShopifyList";
import StripeShopifyView from "views/stripe-shopify/StripeShopifyView";
import StripeShopifyEdit from "views/stripe-shopify/StripeShopifyEdit";
import OrderRefund from "views/stripe-shopify/OrderRefund";
import DashboardReport from "views/dashboard/DashboardReport";

export const publicRoutes = [
  {
    path: "/admins/register",
    name: "Register",
    icon: "pe-7s-graph",
    component: Register,
    layout: "/public",
    invisible: true
  },
  {
    path: "/admins",
    name: "Login",
    icon: "pe-7s-graph",
    component: Login,
    layout: "/public",
    invisible: true
  },
  {
    path: "/admins/forgot-password",
    name: "Forgot Password",
    icon: "pe-7s-graph",
    component: ForgotPassword,
    layout: "/public",
    invisible: true
  }
]

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-dashboard",
    component: DashboardReport,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Customers",
    icon: "pe-7s-user",
    component: UserList,
    layout: "/admin"
  },
  {
    path: "/users/add",
    name: "Add User",
    icon: "pe-7s-user",
    component: AddUser,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/add-referral/:id",
    name: "Add Referral",
    icon: "pe-7s-user",
    component: AddReferral,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/performance/:id",
    name: "Customer Performance",
    icon: "pe-7s-user",
    component: Performance,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/view/:id",
    name: "Customer Details",
    icon: "pe-7s-user",
    component: ViewUser,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/referral/:id",
    name: "Customer Referral",
    icon: "pe-7s-user",
    component: Referral,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/diagnosis/:id/:id",
    name: "Customer Diagnosis",
    icon: "pe-7s-user",
    component: UserDiagnosis,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/diagnosis/new/:id/:id",
    name: "New Customer Diagnosis",
    icon: "pe-7s-user",
    component: UserDiagnosisNew,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/answers/:id/:id",
    name: "Customer Answers",
    icon: "pe-7s-user",
    component: AnswersList,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/notification/:id",
    name: "User Notification",
    icon: "pe-7s-user",
    component: UserNotification,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/assessment/:id/:id",
    name: "User Assessment",
    icon: "pe-7s-user",
    component: UserDiagnosis,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/users/assessment/:id",
    name: "User Assessment",
    icon: "pe-7s-user",
    component: UserDiagnosis,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/assessment",
    name: "Assessments",
    icon: "pe-7s-note2",
    component: AssessmentList,
    layout: "/admin"
  },
  {
    path: "/assessment/view/:id/:id",
    name: "Assessment",
    icon: "pe-7s-user",
    component: AssessmentView,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/payment",
    name: "Payments",
    icon: "pe-7s-cash",
    component: PaymentsList,
    layout: "/admin"
  },
  {
    path: "/payment/order/:orderId",
    name: "Order View",
    icon: "pe-7s-cash",
    component: PaymentsOrder,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/payment/view/:paymentId",
    name: "Payment View",
    icon: "pe-7s-cash",
    component: PaymentsView,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/invited",
    name: "Invite Codes",
    icon: "fa fa-user-plus",
    component: InviteUserList,
    layout: "/admin"
  },
  {
    path: "/invited/add",
    name: "Invite Customer",
    icon: "pe-7s-user",
    component: AddInviteUser,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/invited/edit/:id",
    name: "Edit Invite Customer",
    icon: "pe-7s-user",
    component: EditInviteUser,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/event",
    name: "Event List",
    icon: "fa fa-user-plus",
    component: EventList,
    layout: "/admin"
  },
  {
    path: "/event/add",
    name: "Event List",
    icon: "pe-7s-user",
    component: AddEvent,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/event/edit/:id",
    name: "Edit Event List",
    icon: "pe-7s-user",
    component: EditEvent,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/chat-admin",
    name: "Chat Admins",
    icon: "fa fa-users",
    component: ListChatAdmin,
    layout: "/admin"
  },
  {
    path: "/chat-admin/add",
    name: "Add Chat Admin",
    icon: "pe-7s-users",
    component: AddChatAdmin,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/chat-admin/edit/:id",
    name: "Edit Chat Admin",
    icon: "pe-7s-users",
    component: EditChatAdmin,
    layout: "/admin",
    invisible: true
  },
  // {
  //   path: "/questionnaires",
  //   name: "Questionnaires",
  //   icon: "fa fa-question-circle-o",
  //   component: QuestionnairesList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/add-questionnaires",
  //   name: "Add Questionnaires",
  //   icon: "fa fa-question-circle-o",
  //   component: AddQuestionnaires,
  //   layout: "/admin",
  //   invisible: true
  // },
  // {
  //   path: "/edit-questionnaires/:id",
  //   name: "Edit Questionnaires",
  //   icon: "fa fa-question-circle-o",
  //   component: EditQuestionnaires,
  //   layout: "/admin",
  //   invisible: true
  // },
  // {
  //   path: "/clone-questionnaires/:id",
  //   name: "Clone Questionnaires",
  //   icon: "fa fa-question-circle-o",
  //   component: CloneQuestionnaires,
  //   layout: "/admin",
  //   invisible: true
  // },
  // {
  //   path: "/view-questionnaire/:id",
  //   name: "View Questionnaire",
  //   icon: "fa fa-question-circle-o",
  //   component: ViewQuestionnaires,
  //   layout: "/admin",
  //   invisible: true
  // },
  // {
  //   path: "/answers",
  //   name: "Answers",
  //   icon: "fa fa-exclamation",
  //   component: AnswersList,
  //   layout: "/admin"
  // },
  {
    path: "/chat/:id?",
    name: "Chats",
    icon: "pe-7s-chat",
    component: Chats,
    layout: "/admin",
  },
  {
    path: "/masters",
    name: "Admins",
    icon: "fa fa-lock",
    component: AdminList,
    layout: "/admin"
  },
  {
    path: "/masters/add",
    name: "Add Admin",
    icon: "pe-7s-user",
    component: AddAdmin,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/masters/edit/:id",
    name: "Edit Admin",
    icon: "pe-7s-user",
    component: EditAdmin,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/masters/edit-profile/:id",
    name: "Update Profile",
    icon: "pe-7s-user",
    component: EditAdminProfile,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/experts",
    name: "Experts",
    icon: "fa fa-lock",
    component: ExpertList,
    layout: "/admin"
  },
  {
    path: "/experts/edit/:id",
    name: "Edit Expert",
    icon: "pe-7s-user",
    component: EditExpert,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/experts/pay/:id",
    name: "Pay Expert",
    icon: "pe-7s-user",
    component: PayExpert,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/admin-logs",
    name: "Logs",
    icon: "fa fa-history",
    component: AdminLogsList,
    layout: "/admin"
  },
  {
    path: "/admin-logs/:id",
    name: "Log",
    icon: "fa fa-history",
    component: ViewAdminLog,
    layout: "/admin",
    invisible: true
  },
  // {
  //   path: "/treatment",
  //   name: "Treatments",
  //   icon: "fa fa-medkit",
  //   component: AddTreatment,
  //   layout: "/admin"
  // },
  {
    path: "/af_treatment",
    name: "AF Treatments",
    icon: "fa fa-medkit",
    component: AddAfTreatment,
    layout: "/admin"
  },
  {
    path: "/products",
    name: "Products",
    icon: "fa fa-product-hunt",
    component: ProductList,
    layout: "/admin"
  },
  {
    path: "/products/add",
    name: "Add Product",
    icon: "fa fa-product-hunt",
    component: AddProduct,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/products/edit/:id",
    name: "Edit Product",
    icon: "fa fa-product-hunt",
    component: EditProduct,
    layout: "/admin",
    invisible: true
  },
  // {
  //   path: "/conditions",
  //   name: "Conditions",
  //   icon: "fa fa-codiepie",
  //   component: ConditionList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/add-condition",
  //   name: "Add Condition",
  //   icon: "fa fa-product-hunt",
  //   component: AddCondition,
  //   layout: "/admin",
  //   invisible: true
  // },
  // {
  //   path: "/edit-condition/:id",
  //   name: "Edit Condition",
  //   icon: "fa fa-product-hunt",
  //   component: EditCondition,
  //   layout: "/admin",
  //   invisible: true
  // },
  // {
  //   path: "/add-treatment/:id",
  //   name: "Add Treatment",
  //   icon: "fa fa-product-hunt",
  //   component: AddTreatment,
  //   layout: "/admin",
  //   invisible: true
  // },
  {
    path: "/meta",
    name: "Meta",
    icon: "fa fa-text-width",
    component: MetaList,
    layout: "/admin"
  },
  {
    path: "/meta/add",
    name: "Add Meta",
    icon: "fa fa-product-hunt",
    component: AddMeta,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/meta/edit/:cat/:id",
    name: "Edit Meta",
    icon: "fa fa-product-hunt",
    component: EditMeta,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/meta/edit-image/:id",
    name: "Edit Image",
    icon: "fa fa-product-hunt",
    component: EditImage,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/meta/edit-stripe-key/:id",
    name: "Edit Stripe Keys",
    icon: "fa fa-product-hunt",
    component: EditStripeKey,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/meta/edit-questionnaire-new/:id",
    name: "Edit New Questionnaire",
    icon: "fa fa-product-hunt",
    component: EditQuestionnaireNew,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/meta/edit-shipping/:id",
    name: "Edit Shipping",
    icon: "fa fa-product-hunt",
    component: EditShipping,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/meta/edit-referral/:id",
    name: "Edit Referral",
    icon: "fa fa-product-hunt",
    component: EditReferral,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/template",
    name: "Templates",
    icon: "fa fa-themeisle",
    component: TemplateList,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/template/add",
    name: "Add Template",
    icon: "fa fa-themeisle",
    component: AddTemplate,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/template/edit/:id",
    name: "Edit Template",
    icon: "fa fa-themeisle",
    component: EditTemplate,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/template/new",
    name: "M-Templates",
    icon: "fa fa-themeisle",
    component: TemplateListNew,
    layout: "/admin"
  },
  {
    path: "/template/add_new",
    name: "Add M-Templates",
    icon: "fa fa-themeisle",
    component: AddTemplateNew,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/template/edit_new/:id",
    name: "Edit M-Templates",
    icon: "fa fa-themeisle",
    component: EditTemplateNew,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/emails",
    name: "Emails",
    icon: "fa fa-envelope-square",
    component: EmailList,
    layout: "/admin"
  },
  {
    path: "/emails/view/:id",
    name: "View Email",
    icon: "fas fa-envelope-square",
    component: ViewEmail,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "fa fa-bell",
    component: EmailNotification,
    layout: "/admin",
  },
  {
    path: "/notifications/add",
    name: "Notifications",
    icon: "fa fa-bell",
    component: AddEmailNotification,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/notifications/edit/:id",
    name: "Notifications",
    icon: "fa fa-bell",
    component: EditEmailNotification,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/stripe-products",
    name: "Stripe Products",
    icon: "fa fa-cc-stripe",
    component: StripeProductList,
    layout: "/admin"
  },
  {
    path: "/stripe-products/add",
    name: "Add Stripe Product",
    icon: "fa fa-cc-stripe",
    component: AddStripeProduct,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/stripe-products/edit/:id",
    name: "Edit Stripe Product",
    icon: "fa fa-cc-stripe",
    component: EditStripeProduct,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/stripe-products/edit-test/:id",
    name: "Edit Stripe Test Product",
    icon: "fa fa-cc-stripe",
    component: EditStripeTestProduct,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/stripe-shopify",
    name: "Stripe/Shopify",
    icon: "fa fa-cc-stripe",
    component: StripeShopifyList,
    layout: "/admin"
  },
  {
    path: "/stripe-shopify/view/:id",
    name: "View Stripe/Shopify",
    icon: "fa fa-cc-stripe",
    component: StripeShopifyView,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/stripe-shopify/edit/:id",
    name: "View Stripe/Shopify",
    icon: "fa fa-cc-stripe",
    component: StripeShopifyEdit,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/stripe-shopify/order/:id",
    name: "View Refund Order",
    icon: "fa fa-cc-stripe",
    component: OrderRefund,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/shipment",
    name: "Shipment",
    icon: "fa fa-truck",
    component: ShipmentList,
    layout: "/admin"
  },
  {
    path: "/shipment/add",
    name: "Add Package",
    icon: "pe-7s-box1",
    component: AddPackage,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/shipment/edit/:id",
    name: "Edit Package",
    icon: "pe-7s-box1",
    component: EditPackage,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/shipment/view/:id",
    name: "Package Details",
    icon: "pe-7s-box1",
    component: ViewPackage,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/rules",
    name: "Rules",
    icon: "fa fa-briefcase",
    component: RulesList,
    layout: "/admin"
  },
  {
    path: "/rules/add",
    name: "Add Rule",
    icon: "fa fa-briefcase",
    component: AddRule,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/rules/edit/:id",
    name: "Edit Rule",
    icon: "fa fa-briefcase",
    component: EditRule,
    layout: "/admin",
    invisible: true
  },
];

export default dashboardRoutes;
