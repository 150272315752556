import React, {useState, useEffect} from "react";
import { 
    Modal
} from "react-bootstrap";
import * as fb from '../../firebase';


function UserTreatmentView(props) {

    const [products, setProducts] = useState([]);


    const fetchData = async () => {
        let currentDocument = props.data.currentDocument;
        var result = Object.keys(currentDocument.products);
        var productsArr = [];
        Promise.all(result.map(async (key)  => {
            const data = await fb.productsCollection.where('ref', '==', key).get();
            if(!data.empty){
                const dataObj = data.docs[0].data();
                productsArr.push({
                    id:dataObj.id,
                    title:dataObj.title,
                    time:currentDocument.products[key]
                });
                return true;
            }
        })).then(result => {
            setProducts(productsArr)
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const renderList = () => {
        let productObj = [];
        products && products.forEach((val, key) => {
            productObj.push(
                <div key={key}>
                    <p className="black"><b>{`P${++key}. `}</b>{val.title}</p>
                    <p className="text-primary"><b>{val.time}</b></p>
                </div>
            )
        })
        return productObj;
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            >
            <Modal.Header closeButton>
                <h4 style={{textAlign:"center"}}><strong>User Treatment</strong></h4>
            </Modal.Header>
            <Modal.Body>
                { renderList() }
            </Modal.Body>
        </Modal>
    );
}
  
export default UserTreatmentView;
