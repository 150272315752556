import React, { Component } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import * as fb from "../../firebase";
import { CsvToHtmlTable } from './index';
//import { CsvToHtmlTable } from 'react-csv-to-table';
import ReactFileReader from 'react-file-reader';
import "./Treatment.css";

//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

class AddTreatment extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      dataList:"",
      headers:[],
      error:"",
      errors:{}
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#list-datatable').DataTable({
      "bStateSave": true,
      "pageLength": 150,
      "scrollY": 400,
      "scrollX": true
    });
  }
  
  fetchData = async() => {
    try{
      this.setState({isLoading:true});
      const result = await fb.metaCollection.doc('TreatmentMatrix').get();
      if(result.data()){
        this.setState({
          dataList:result.data().csv,
          isLoading:false,
        })
      }else{
        this.setState({
          isLoading:false,
        })
      }
    }catch(e){
      this.setState({error:e.message});
    }
  }
 
  handleFiles = files => {
    try{
      this.setState({ isLoading:true})
      var reader = new FileReader();
      reader.onload =   (e) => {
        var lines = reader.result.split('\n');
        lines.splice(0,1);
        var newtext = lines.join('\n');
        fb.metaCollection.doc('TreatmentMatrix').update({csv:newtext});
        this.setState({
          dataList: newtext
        })
      }
      reader.readAsText(files[0]);
      this.setState({
        isLoading:false
      })
    }catch(e){
      this.setState({error:e.message});
    }
  }

  render() {
    // console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Treatment"
                category="All Treatment List"
                ctTableFullWidth
                ctTableResponsive
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <div>
                    <ReactFileReader 
                      multipleFiles={false}
                      fileTypes={[".csv"]} 
                      handleFiles={this.handleFiles}
                    >
                      <button className='btn libi-admin-btn'>Upload</button>
                    </ReactFileReader>
                    <div className="traet-table">
                      { this.state.dataList && <CsvToHtmlTable
                          data={this.state.dataList}
                          csvDelimiter=","
                          tableClassName="table table-striped table-borderd table-hover"
                        />
                      }
                    </div>
                  </div>   
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddTreatment;
