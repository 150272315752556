import React, { Component } from "react";
import { staticWebImages } from "../../components/Utils/images";
import {langText} from "../../components/Utils/helper";
import Loading from "./Loading";


class WaitingList extends Component {
  constructor(props){
    super(props);
    this.state = {
      customer:"",
      error:"",
      errors:{},
      disabled : false,
      titleText: '',
      contentText: '',
      submitLabel: '',
      isLoading: false,
    }
    this._isMounted = false;
  }

  generateRandomNDigits = (n) => {
    return Math.floor(Math.random() * (9 * (Math.pow(10, n)))) + (Math.pow(10, n));
  }

  async componentDidMount() {
    let langTextObj = await langText();
    this.setState({
      titleText: langTextObj.waiting_list_title,
      contentText: langTextObj.waiting_list_detail,
      submitLabel: langTextObj.waiting_list_button
    });
    this.setState({
      isLoading: true
    });
  }

  render() {
    console.log('render', this.state)
    return (
        <section className="Subscription" style={{background: 'rgba(238, 221, 211, 0.6)'}}>
          {this.state.isLoading ? <>
            <span className="custm-angle" style={{background: '#F5EBE5'}}>
              <span className="backBtn" onClick={this.props.history.goBack}><i className="fa fa-angle-left" aria-hidden="true" /></span>
              <span className="mobileTitle"></span>
            </span>
          <div className="container_5-fluid">
            <div className="row_5">
              <div className="col_5-lg-6 col_5-12" style={{padding: 0}}>
                <div className="Subscription_cust" style={{background: 'transparent'}}>
                  <img src={staticWebImages.waitingList} alt="" style={{width:"100%",maxWidth:"530px"}}/>
                </div>
              </div>
              <div className="col_5-lg-6 col_5-12">
                <div className="subs_mainBox">
                  <div className="subsRightBoxWrapper">
                    <div className="subsRightBox">
                      <div className="main-logo">
                        <img src={staticWebImages.logo} alt="" className="img-responsive center-block web-logo-hide" />
                      </div>
                      <h2 className="main_txt_sign text-center" style={{paddingBottom: '20px'}}>{this.state.titleText}</h2>
                      <p className="shiping_para">{this.state.contentText}</p>
                      <div className="form-section_add">
                        <a href="https://libi.com/register-now/" className="form-control custom-btn-web"
                        style={{width:"100%"}}
                        > {this.state.submitLabel}</a>
                      </div>
                    </div>
                  </div>
                  <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
                </div>
              </div>
            </div>
          </div>
            </>: <Loading />}
        </section>
    );
  }
}

export default WaitingList;
