import Card from "components/Card/Card";
import PageLoader from "components/Loader/PageLoader";
import React from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import { useEffect } from "react";
import SelectSearch from "react-select-search";
import api from "api";

const PERMISSIONS_LIST = [
  { val: "New assessment", key: "new_assessment" },
  { val: "New treatment request", key: "new_treatment_request" },
  { val: "Secondary questionnaire", key: "secondary_questionnaire" },
  { val: "Package changes status", key: "package_changes_status" },
  { val: "New chat message", key: "new_chat_message" },
];

function AddEmailNotification(props) {
  const [isLoading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [permissionsError, setPermissionsError] = useState("");

  const onSelect = (selectedList) => {
    setSelectedPermissions(selectedList);
  };
  const onRemove = (selectedList) => {
    setSelectedPermissions(selectedList);
  };

  const handleValidate = () => {
    if (customer && selectedPermissions.length) {
      setEmailError("");
      setPermissionsError("");
      return true;
    }
    if (!customer) {
      setEmailError(true);
    }
    if (!selectedPermissions.length) {
      setPermissionsError(true);
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (handleValidate()) {
      api.post('updateUser', {
        id: customer,
        emails_permissions: selectedPermissions,
      })
        .then(() => {
          props.history.push("/admin/notifications");
        });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const {data: admins} = await api.get('getAdmins')
    let customersList = [];
    admins.forEach((dataObj) => {
      customersList.push({
        name: dataObj.name + " <" + dataObj.email + ">",
        value: dataObj.id,
      });
    });
    setLoading(false);
    setCustomersList(customersList);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Email Notifications"
              category="User notifications list"
              button={
                <Link
                  to={`/admin/notifications`}
                  className="btn btn-success pull-left card-back-button libi-admin-btn"
                >
                  {" "}
                  Back
                </Link>
              }
              ctTableFullWidth
              loader={isLoading && <PageLoader />}
              content={
                <form onSubmit={onSubmit}>
                  <Row>
                    <div className="col-md-6">
                      <FormGroup>
                        <FormLabel>Customer</FormLabel>
                        <SelectSearch
                          options={customersList}
                          search={true}
                          value={customer}
                          name="customer"
                          placeholder="Select Customer"
                          onChange={(event) => {
                            setCustomer(event);
                          }}
                        />
                        <span className="help-block">
                          {emailError && "Please enter a valid email address"}
                        </span>
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <FormLabel>Permissions</FormLabel>
                        <Multiselect
                          options={PERMISSIONS_LIST}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          displayValue="val"
                          showCheckbox={true}
                          placeholder="Select Primissions"
                        />
                        <span className="help-block">
                          {permissionsError && "Please assign a permissions"}
                        </span>
                      </FormGroup>
                    </div>
                  </Row>
                  <Button
                    className="btn-fill pull-right libi-admin-btn"
                    bsStyle="info"
                    type="submit"
                  >
                    Add
                  </Button>
                  <div className="clearfix" />
                </form>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AddEmailNotification;
