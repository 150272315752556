import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  FormGroup,
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import {
  addAdminLog, convertFirebseSecondsToDate, convertMilisecondsToDate,
} from "../../components/Helper/index";
import { Link } from "react-router-dom";
import axios from "axios";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables.js"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import api from "api.js";

class EditExpert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      agreementID: "",
      firstName: "",
      lastName: "",
      email: "",
      userData: {},
      name: "",
      approved: "",
      tierDiscount: '',
      errors: {},
      disabled: false,
      hasPdf: false,
      isLoading: true,
      commissions: []
    }
  }
  
  componentDidMount = async () => {
    const url = window.location.href
    const segment = url.substring(url.lastIndexOf('/') + 1)

    try {
      this.setState({ isLoading: true })

      const {data: userByID} = await api.get('userByID', {params: {userId: segment}})
      this.setState({
        id: userByID.id,
        agreementID: userByID.agreementID,
        firstName: userByID.firstName,
        lastName: userByID.lastName,
        email: userByID.email,
        approved: userByID.approved,
        tierDiscount: userByID.tierDiscount,
        userData: userByID
      })

      if (this.state.agreementID) {
        await fb.docsCollection.doc(this.state.agreementID).get().then(doc => {
          const data = doc.data()
          const { libiSign, sign, ...rest } = JSON.parse(data.vars)
          this.setState({ hasPdf: !!data.pdf })
        })
      }

      const {data: paymentsData} = await api.get('paymentsByExpertID', {params: {expertId: segment, status: 'complete'}})

      const data = paymentsData.map(i => {
        const commission = i.consumerTotal - i.total
        return {
          ...i,
          created_seconds: i?.time !== 'Invalid date' ? new Date(i.time).getTime() : 0,
          created: i?.time !== 'Invalid date' ? convertMilisecondsToDate(new Date(i.time).getTime()) : '',
          consumerTotal: i.payment_type !== 'paid_out' ? i.consumerTotal : 0,
          commissions: i.payment_type !== 'paid_out' ? commission : i.total,
          cpo: i.payment_type !== 'paid_out' ? i.payment_type === 'stripe' ? commission : 0 : i.total,
          cd: (i.payment_type === 'shopify' || i.payment_type === 'customer') ? commission : 0,
        }
      })

      this.setState({ commissions: data.sort((a, b) => a.created_seconds < b.created_seconds ? 1 : -1) })

      $('#commissions').DataTable({
        "bStateSave": true
      });
    } catch (err) {
      console.log('err', err)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  approveHandler = async () => {
    this.setState({ disabled: true });


    let ref
    const needRef = !this.state.userData?.ref && !this.state.userData?.invite_code

    if(needRef) {
      ref = await api.get('s/new_refncode')
    }

    api.post('updateUser', {
      id: this.state.id,
      approved: true,
      tierDiscount: 5,
      ...(needRef && {
        ref: ref.data.ref,
        invite_code: ref.data.code,
      })
    }).then(async res => {
      // addAdminLog(
      //   'expert_updated', 
      //   `Updated the expert data ${this.state.id}`, 
      //   this.state.id,
      //   this.state.id,
      //   this.state.firstName + ' ' + this.state.lastName
      // )

      if (this.state.agreementID && !this.state.approved && !this.state.hasPdf) {
        api.get(`s/agreement/pdf/${this.state.agreementID}`)
      }

      // axios.get("https://us-central1-production-lnd.cloudfunctions.net/s/crmadd/31000024062/" + this.state.id)
      if(ref?.data?.code) {
        api.get(`sh/create_coupon?code=${ref.data.code}`)
      }
      this.props.history.push('/admin/experts')
    }).catch(err => {
      console.log('err', err)
      this.setState({ disabled: false });
    });
  }

  disapproveHandler = async () => {
    this.setState({ disabled: true });

    api.post('updateUser', {
      id: this.state.id,
      approved: false
    }).then(async res => {
      // addAdminLog(
      //   'expert_updated', 
      //   `Updated the expert data ${this.state.id}`, 
      //   this.state.id,
      //   this.state.id,
      //   this.state.firstName + ' ' + this.state.lastName
      // )

      // axios.get("https://us-central1-production-lnd.cloudfunctions.net/s/crmadd/31000024063/" + this.state.id)
      this.props.history.push('/admin/experts')
    }).catch(err => {
      console.log('err', err)
      this.setState({ disabled: false });
    });
  }

  updateDiscount = async () => {
    api.post('updateUser', {
      id: this.state.id,
      tierDiscount: this.state.tierDiscount
    })
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Expert"
                button={
                  <Link to={`/admin/experts`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                loader={this.state.isLoading && <PageLoader />}
                content={
                  <div>
                    <p><b>Company name:</b>&nbsp; {this.state.userData.companyName || '-'}</p>
                    <p><b>Address:</b>&nbsp; {this.state.userData.address || '-'}</p>
                    <p><b>City:</b>&nbsp; {this.state.userData.city || '-'}</p>
                    <p><b>Country:</b>&nbsp; {this.state.userData.country || '-'}</p>
                    <p><b>Postcode:</b>&nbsp; {this.state.userData.postcode || '-'}</p>
                    <p><b>First name:</b>&nbsp; {this.state.userData.contactFirstName || this.state.firstName || '-'}</p>
                    <p><b>Last name:</b>&nbsp; {this.state.userData.contactLastName || this.state.lastName || '-'}</p>
                    <p><b>Phone:</b>&nbsp; {this.state.userData.contactPhone || '-'}</p>
                    <p><b>Email:</b>&nbsp; {this.state.userData.contactEmail || this.state.email || '-'}</p>
                    <p><b>Patients Discount:</b></p>
                    <Row>
                      <div className="col-md-3">
                        <FormGroup>
                          <input
                            type="number"
                            min={5}
                            className="form-control"
                            placeholder="Patients Discount"
                            name="tierDiscount"
                            value={this.state.tierDiscount}
                            onChange={(event)=> this.setState({tierDiscount: event.target.value})}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-3">
                        <Button
                          className="btn-fill libi-admin-btn"
                          bsStyle="info"
                          fill
                          onClick={this.updateDiscount}
                        >
                          Save
                        </Button>
                      </div>
                    </Row>
                    <div style={{ display: 'flex', gap: '20px' }}>
                      <Button
                        className="btn-fill libi-admin-btn"
                        bsStyle="info"
                        fill
                        disabled={this.state.disabled || this.state.approved}
                        onClick={this.approveHandler}
                      >
                        {this.state.disabled ? 'Loading...' : 'Approve'}
                      </Button>
                      <Button
                        className="btn-fill libi-admin-btn"
                        bsStyle="info"
                        fill
                        // disabled={this.state.disabled || !this.state.approved}
                        onClick={this.disapproveHandler}
                      >
                        {this.state.disabled ? 'Loading...' : 'Disapprove'}
                      </Button>
                    </div>

                    {this.state.commissions.length > 0 &&
                      <div style={{ margin: '40px 0 20px' }}>
                        <Table striped hover id="commissions">
                          <thead>
                            <tr>
                              <th>Created</th>
                              <th>Value</th>
                              <th>ACR</th>
                              <th>Earned</th>
                              <th>Paid Out</th>
                              <th>Due</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.commissions.map((val) => (
                              <tr key={val.id}>
                                <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                                <td>{val.total ? (val.currency && val.currency === 'eur' ? '€ ' : '£ ') + (val.total / 100).toFixed(2) : '-'}</td>
                                <td>{val.acr ? val.acr + '%' : '-'}</td>
                                <td>{val.commissions ? (val.currency && val.currency === 'eur' ? '€ ' : '£ ') + (val.commissions / 100).toFixed(2) : '-'}</td>
                                <td>{val.cpo ? (val.currency && val.currency === 'eur' ? '€ ' : '£ ') + (val.cpo / 100).toFixed(2) : '-'}</td>
                                <td>{val.cd ? (val.currency && val.currency === 'eur' ? '€ ' : '£ ') + (val.cd / 100).toFixed(2) : '-'}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Link to={`/admin/experts/pay/${window.location.href.substring(window.location.href.lastIndexOf('/') + 1)}`} className="btn btn-success pull-left card-back-button libi-admin-btn">
                          Pay
                        </Link>
                      </div>
                    }
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditExpert;
