import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import { style } from "../variables/Variables.jsx";
import routes from "routes.js";
import image from "assets/img/lady-background.jpg";
import * as fb from '../firebase';
import NoAccess from "../views/NoAccess.jsx";
import api from "api";



class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      image: image,
      color: "black",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open",
      fullPage:"",
      currentUserWithRoles:{},
      path:""
    };
    this._isMounted = false;
  }
  handleNotificationClick = position => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to Liby&Daughters Dashboard
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  };
  getRoutes = routes => {
    const { adminRoles = [] } = this.state.currentUserWithRoles;
    return routes.map((prop, key) => {
      return (
        adminRoles && adminRoles.length > 0 && adminRoles.includes(prop.path.split('/')[1]) ?
        <Route
          path={prop.layout + prop.path}
          exact
          render={props => (
            <prop.component
              {...props}
              handleClick={this.handleNotificationClick}
            />
          )}
          key={key}
        />
        :
        <Route
          path={prop.layout + prop.path}
          exact
          render={props => (
            <NoAccess />
          )}
          key={key}
        />
      );
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }else{
        let segment = this.props.location.pathname.split('/');
        let paths = routes[i].layout+"/"+segment[2]+"/:id";
        if(paths.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1){
          return routes[i].name;
        }
      }
    }
    return "Dashboard";
  };

  getCurrentUserData = async (id) => {
    await api.get('userByID', {params: {userId: id}}).then( async querySnapshot => {
      if(querySnapshot.data){
        this.setState({
          currentUserWithRoles: querySnapshot.data
        }, async () => {    
          this.setState({isLoading:false});
        })
      }
    });
  }

  setCurrentUser =  async () => {
    fb.auth.onAuthStateChanged(async (user) => {
      if (user != null) {
        await this.getCurrentUserData(user.uid);
      }
    });
  }
 
  componentDidMount() {

    this.setCurrentUser();

    this.setState({ _notificationSystem: this.refs.notificationSystem });
    var _notificationSystem = this.refs.notificationSystem;
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
      break;
      default:
      break;
    }
    _notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-bell" />,
      message: (
        <div> Welcome to  {this.getBrandText(this.props.location.pathname)} </div>
      ),
      level: level,
      position: "tr",
      autoDismiss: 1
    });

  }

  componentWillReceiveProps = async (newProps) => {
    if (newProps) {
      this.setCurrentUser();
    }
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    let path = e.history.location.pathname.split("/")[2];
    
    if(this._isMounted){
      this.setState({path:path})
      if(path==="add-questionnaires" || path === "edit-questionnaires" || path==="view-questionnaire" || path==="clone-questionnaires"){
        
      // this.setCurrentUser();
        this.setState({fullPage:"full-page"});
        this._isMounted = false;
      }else{
        
      // this.setCurrentUser();
        this.setState({fullPage:""});
        this._isMounted = false;
      }
    }else{
      this._isMounted = true;
    }
  }

  componentWillUnmount(){
    if(this._isMounted){
      this._isMounted = false;
    }
  }

  render() {
    // console.log('admin render', this.state)
    let { adminRoles = [] } = this.state.currentUserWithRoles;
    // console.log('adminRoles', adminRoles, this.state)
    return (
      <div className={`wrapper ${this.state.fullPage}`}>
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} 
          roles={adminRoles} 
          routes={routes} 
          path={this.state.path}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
        />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Admin;
