import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import {
  addAdminLog,
  adminRoles,
  adminRolesValues
} from "../../components/Helper/index";
import { Link } from "react-router-dom"; 
import { Multiselect } from 'multiselect-react-dropdown';
import api from "api.js";

class AddAdmin extends Component {

  constructor(props){
    super(props);
    this.state = {
      firstName:"",
      lastName:"",
      password:"",
      email:"",
      role_id:2,
      picture:"",
      image: {},
      approved:false,
      error:"",
      errors:{},
      disabled : false,
      selectedOptions:[]
    }
    this._isMounted = false;
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'password') {
      this.setState({password:value});
    }else if(name === 'email'){
      this.setState({email:value});
    }else if(name === 'firstName'){
      this.setState({firstName:value});
    }else if(name === 'lastName'){
      this.setState({lastName:value});
    }else if(event.target.files && event.target.files[0]){
      this.setState({image:event.target.files[0]});
    }
  };

  handleValidation = () => {
    let {firstName, lastName, email, password, selectedOptions} = this.state;
    let errors = {};
    let formIsValid = true;

    //First Name
    if(!firstName){
      formIsValid = false;
      errors["firstName"] = "First name is required";
    }

    //Last Name
    if(!lastName){
      formIsValid = false;
      errors["lastName"] = "Last name is required";
    }
    //Email
    if(!email){
      formIsValid = false;
      errors["email"] = "Please enter a valid email address";
    }

    //Roles
    if(selectedOptions.length < 1){
      formIsValid = false;
      errors["admin_role"] = "Please assign a role";
    }


    if(typeof email !== "undefined"){
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Please enter a valid email address";
      }
    }  
    //Password
    if(!password){
      formIsValid = false;
      errors["password"] = "Password is required";
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({disabled: true});
    this._isMounted = true;
    if(this.handleValidation(this.state.errors) &&  typeof(this.state.image.name) != "undefined") {
      var timestamp = Math.floor(Date.now() / 1000);
      fb.storage.child(`pictures/${timestamp}-${this.state.image.name}`).put(this.state.image)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then((url) => { 
        url && this.saveUser();   
        if(this._isMounted === true){
          this.setState({picture:url});
        }
      });
    }else{
      this.saveUser(); 
    }
  }

  saveUser = () => {
    fb.secondaryApp.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then(res => {
      // create user object in userCollections
      api.post('createUser', {
        id: res.user.uid,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        picture: this.state.picture,
        role_id: this.state.role_id,
        adminRoles:this.state.selectedOptions.map(val=>val.key),
        approved: this.state.approved,
      }).then(res1 => {
        // addAdminLog(
        //   'admin_created',
        //   `Created a new admin data ${res.user.uid}`,
        //   res.user.uid,
        //   res.user.uid,
        //   this.state.firstName + ' ' + this.state.lastName
        // )
        this.props.history.push('/admin/masters')
      }).catch(err=> {
        this.setState({
          disabled: false
        });
      });
    }).catch(err => {
      this.setState({
        disabled: false
      });
    });
  }

  componentWillUnmount = () => {
    if(this._isMounted){
      this._isMounted = false;
    }
  }

  onSelect = (selectedList, selectedItem) => {
    this.setState({selectedOptions:selectedList})
  }

  onRemove = (selectedList, removedItem) => {
    this.setState({selectedOptions:selectedList})
  }

  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
            {this.state.error &&
              <Alert bsStyle="danger">
                <button type="button" aria-hidden="true" className="close">
                  &#x2715;
                </button>
                <span>{this.state.error}</span>
              </Alert>
            }
              <Card
                title="Add Admin"
                button={
                  <Link to={`/admin/masters`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>First name</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First name"
                            name="firstName"
                            value={this.state.firstName}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.firstName}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Last name</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last name"
                            name="lastName"
                            value={this.state.lastName}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.lastName}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Email</FormLabel>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value= {this.state.email}
                            onChange ={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.email}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Password</FormLabel>
                          <input 
                            type="text"
                            className= "form-control"
                            placeholder="password"
                            name="password"
                            value={this.state.password}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.password}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Image</FormLabel>
                          <input 
                            type="file" 
                            name="picture" 
                            className="form-control" 
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Roles</FormLabel>
                          <Multiselect
                              options={adminRoles()}
                              selectedValues={this.state.selectedOptions}
                              onSelect={this.onSelect}
                              onRemove={this.onRemove} 
                              displayValue="val"
                              showCheckbox={true}
                              placeholder="Select Role"
                          />
                          <span className="help-block">{this.state.errors.admin_role}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading...' : 'Add'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddAdmin;
