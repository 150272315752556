import Card from 'components/Card/Card'
import PageLoader from 'components/Loader/PageLoader'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Col, FormLabel, FormGroup, Container, Row } from 'react-bootstrap'
import Button from "../../components/CustomButton/CustomButton.jsx";
import { Link } from 'react-router-dom'
import SelectSearch from 'react-select-search'
import api from 'api'

const StripeShopifyEdit = () => {
  const id = window.location.pathname.split('/')[4]
  const type = new URLSearchParams(window.location.search).get('type')
  
  const [isLoading, setIsLoading] = useState(true)
  const [order, setOrder] = useState({})
  const [users, setUsers] = useState([])
  const [experts, setExperts] = useState([])
  const [selectedUser, setSelectedUser] = useState('')
  const [selectedExpert, setSelectedExpert] = useState('')
  const [saveIsLoading, setSaveIsLoading] = useState(false)

  const saveData = async () => {
    try {
      setSaveIsLoading(true)

      await api.post('updateOrder', {
        id: order.id,
        userId: selectedUser,
        expertId: selectedExpert
      })
      window.location.href = `/admin/stripe-shopify/view/${id}?type=${type}`

    } catch (err) {
      console.log('err', err)
    } finally {
      setSaveIsLoading(false)
    }
  }

  const getData = async () => {
    try {
      setIsLoading(true)

      const {data: orders} = await api.get('getOrders')
      const order = orders?.find(i => i?.paymentId === `${type}/${id}`)

      if(order) {
        setOrder(order)
        setSelectedUser(order.user_id || '')
        setSelectedExpert(order.expertId || '')
      }

      const { data: users } = await api.get('getUsers')
      setUsers(users.map(j => {
        const name = ((j.first_name && j.last_name) && `${j.first_name} ${j.last_name}`) ||
          ((j.firstName && j.lastName) && `${j.firstName} ${j.lastName}`) ||
          j.name || '-'

        return {
          name: name + " <" + j.email + ">",
          value: j.id
        }
      }))

      const {data: experts} = await api.get('expertsList')
      setExperts(experts.map(j => {
        const name = ((j.first_name && j.last_name) && `${j.first_name} ${j.last_name}`) ||
          ((j.firstName && j.lastName) && `${j.firstName} ${j.lastName}`) ||
          j.name || '-'

        return {
          name: name + " <" + j.email + ">",
          value: j.id
        }
      }))
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getData() }, [])

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Order Edit"
              button={
                <Link to={`/admin/stripe-shopify/view/${id}?type=${type}`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
              }
              category=""
              ctTableFullWidth
              ctTableResponsive
              loader={isLoading && <PageLoader />}
              content={
                <div>
                  <Row>
                    <div className="col-md-6">
                      <FormGroup>
                        <FormLabel>Customer</FormLabel>
                        <SelectSearch
                          options={users}
                          search={true}
                          value={selectedUser}
                          name="user"
                          placeholder="Select Customer"
                          onChange={ev => setSelectedUser(ev)}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <FormLabel>Expert</FormLabel>
                        <SelectSearch
                          options={experts}
                          search={true}
                          value={selectedExpert}
                          name="expert"
                          placeholder="Select Expert"
                          onChange={ev => setSelectedExpert(ev)}
                        />
                      </FormGroup>
                    </div>
                  </Row>

                  <Button
                    className="btn-fill libi-admin-btn"
                    bsStyle="info" 
                    fill
                    disabled={saveIsLoading}
                    onClick={saveData}
                  >
                    Save
                  </Button>
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StripeShopifyEdit