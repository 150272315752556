import React, { Component } from "react";
import {ReactComponent as TypeFormUploadIcon} from "../../../web_assets/img/typeFormUpload.svg";

class FileUpload extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:"",
            preview: ''
        }
    }

    click = () => {
        // this.fileUploadInput.click()
        this.simulateMouseClick(this.fileUploadInput)
    }
    simulateMouseClick = (element) => {
        ['mousedown', 'click', 'mouseup'].forEach(mouseEventType =>
          element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
          )
        );
    }
    focus = () => {
        console.log('fileInput focus')
        this.fileUploadInput.focus()
    }

    onChangeHandler = async (event) => {
        const {name, value} = event.currentTarget
        if(event.target.files[0]){
            let filesize = ((event.target.files[0].size/1024)/1024).toFixed(4) // Mb
            if(filesize <= 10){
                this.setState({
                    preview: URL.createObjectURL(event.target.files[0])
                })
                this.props.onChange(event.target.files[0],'file')
            }else{
                this.props.onError('The file is too large.')
                this.props.onChange(null)
                this.setState({preview: ''})
            }
        }else{
            this.props.onChange(null)
            this.setState({preview: ''})
        }
    }

    render() {
        return (
            <div className="typeFormFileUploader">
                <div className="backgroundUpload" />
                {this.state.preview &&
                    <img alt="" className="previewUpload" src={this.state.preview}/>
                }
                {!this.state.preview &&
                    <>
                        <TypeFormUploadIcon/>
                        <div className="fileLabel">Choose file or drag here</div>
                        <div className="descr">Size limit: 10MB</div>
                    </>
                }
                <input type="file" accept="image/*"
                       id="fileUploadInput"
                       ref={instance => { this.fileUploadInput = instance; }}
                       onDrop={this.onChangeHandler}
                       onChange={this.onChangeHandler} />
            </div>
        );
    }
}

export default FileUpload;
