import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
  Table
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import { Link } from "react-router-dom"; 
import axios from 'axios';
import PageLoader from "components/Loader/PageLoader.jsx";

//Datatable Modules
import $ from 'jquery'; 
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import api from "api.js";


class Notification extends Component {
  constructor(props){
    super(props);
    this.state = {
      user_id:"",
      name:"",
      email:"",
      title: "",
      message: "",
      notifications:[],
      successMessage:"",
      errorMessage:"",
      error:"",
      errors:{},
      disabled : false
    }
    this._isMounted = false;
  }

  componentDidMount = async () => {
    try {
      const  url = window.location.href;
      const segment = url.substring(url.lastIndexOf('/') + 1);
      const {data: userByID} = await api.get('userByID', {params: {userId: segment}})
      this.setState({
        user_id: userByID.id,
        name: userByID.name,
        email: userByID.email
      })
      //notification
      await this.fetchNotification();
      $('#notification-datatable').DataTable({
        "bStateSave": true,
        "order": [[ 0, "desc" ]]
      });
    }catch(err){
      this.setState({error:err.message})
    }
  }

  fetchNotification = async() => {
    try {
      this.setState({isLoading:true});
      let queryChain = [];
      const data1 = await fb.notificationsCollection.doc(this.state.user_id+'d').collection('FCM').get();
      queryChain.push(data1);
      const data2 = await fb.notificationsCollection.doc(this.state.user_id+'s').collection('FCM').get();
      queryChain.push(data2);
      const data3 = await fb.notificationsCollection.doc(this.state.user_id+'n').collection('FCM').get();
      queryChain.push(data3);
      const data4 = await fb.notificationsCollection.doc(this.state.user_id+'q').collection('FCM').get();
      queryChain.push(data4);
      const data5 = await fb.notificationsCollection.doc(this.state.user_id+'r').collection('FCM').get();
      queryChain.push(data5);
      const notificationArr = [];
      if(queryChain.length > 0 ){
        for(var i=0; i<queryChain.length; i++){
          queryChain[i].docs.forEach(doc=> {
            const dataObj = doc.data();
            notificationArr.push({
              body:dataObj.notification.body,
              title:dataObj.notification.title
            });
          })
          this.setState({
            notifications:notificationArr,
            isLoading:false
          });
        }
      }
    }catch(err){
      this.setState({error:err.message})
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'title') {
      this.setState({title:value});
    }else if(name === 'message'){
      this.setState({message:value});
    }
  };

  handleValidation = () => {
    let {title, message} = this.state;
    let errors = {};
    let formIsValid = true;

    //Title
    if(!title){
      formIsValid = false;
      errors["title"] = "Title is required";
    }

    //Message
    if(!message){
      formIsValid = false;
      errors["message"] = "Message is required";
    } 
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this._isMounted = true;
    this.setState({disabled: true});
    if(this.handleValidation()){
        try{
          let {title, message} = this.state;
          let data = "notification";
          const result = await api.get(`s/send?topic=${topic}&title=${encodeURIComponent(title)}&message=${encodeURIComponent(message)}&data=${data}`)
          console.log('s/send result', result);
          let topic = `${this.state.user_id}n`;
          this.setState({
            disabled: false,
            successMessage:"User notificaton sent successfully!"
          });
          setTimeout(() => {
            this.setState({
              title: '',
              message: '',
              successMessage: ''
            });
          }, 3000)
        }catch (e) {
          console.error('s/send result', e);
          this.setState({errorMessage:e.message})
        }
    }else{
      this.setState({disabled: false});
    }
  }
  
  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              {this.state.errorMessage && 
                <Alert bsStyle="danger">
                  <span>{this.state.errorMessage}</span>
                </Alert>
              }
              {this.state.successMessage &&
                <Alert bsStyle="success">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.successMessage}</span>
                </Alert>
              }
              <Card
                title="User Notification"
                button={
                  <Link to={`/admin/users`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                        <div className="col-md-12">
                            <FormGroup>
                                <p>Name: {this.state.name}</p>
                                <p>Email: {this.state.email}</p>
                                <FormLabel>Title</FormLabel>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Title"
                                    name="title"
                                    value= {this.state.title}
                                    onChange = {(event) => this.onChangeHandler(event)}
                                />
                                <span className="help-block">{this.state.errors.title}</span>
                            </FormGroup>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <FormGroup>
                                <FormLabel>Message</FormLabel>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Message"
                                    name="message"
                                    value= {this.state.message}
                                    onChange ={(event)=>this.onChangeHandler(event)}
                                />
                                <span className="help-block">{this.state.errors.message}</span>
                            </FormGroup>
                        </div>
                    </Row>
                    <Button
                        className="btn-fill pull-right libi-admin-btn"
                        bsStyle="info" 
                        type="submit"
                        disabled={this.state.disabled}
                    >
                    {this.state.disabled ? 'Loading...' : 'Send'}
                    </Button>
                    <div className="clearfix" />
                </form>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="Customer Notifications"
                category=""
                ctTableFullWidth
                ctTableResponsive
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Table striped hover id="notification-datatable">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Body</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.notifications.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.title}</td>
                            <td>{val.body}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Notification;
