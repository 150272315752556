import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
  Button
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import * as fb from '../../firebase';
import { Link } from "react-router-dom";
import SelectSearch from "react-select-search";
import {Multiselect} from "multiselect-react-dropdown";
import { areaOptions } from "../rules/AddRule";
import axios from 'axios';

import {
  addAdminLog,
  convertFirebseSecondsToDate,
  treatmentTimeCases
} from "components/Helper/index";
import CustomNumberField from "../../components/CustomNumberField/CustomNumberField";
import moment from "moment";
import PackagePdf from "./PackagePdf.jsx";
import api from "api.js";
import { convertMilisecondsToDate } from "components/Helper/index.jsx";

class ViewPackage extends Component {

  constructor(props){
    super(props);
    this.state = {
      id:"",
      userId:"",
      customerName: '',
      userVersion: 0,
      customer:"",
      language:"EN",
      products:[],
      statusList:[
        {
          name: "Waiting Confirmation",
          value: "waiting_shipment",
          disabled: true
        },
        {
          name: "To be shipped",
          value: "waiting_shipment_confirmed"
        },
        {
          name: "Transit",
          value: "shipped"
        },
        {
          name: "Delivered",
          value: "delivered"
        }
      ],
      shippingAddress:{
        email:"",
        city:"",
        country:"",
        name:"",
        phone:"",
        line1:"",
        line2:"",
        postal_code:""
      },
      editedShippingAddress:{
        email:"",
        city:"",
        country:"",
        name:"",
        phone:"",
        line1:"",
        line2:"",
        postal_code:""
      },
      status:"",
      trackingNumber:"",
      ref:"",
      successMessage:"",
      errors:{},
      error:"",
      isLoading:false,
      editPackageInfo:false,
      editDeliveryInfo:false,
      editProductInfo:false,
      productsList:[],
      isTracking:true,
      delivered: '',
      totalCustomerPayment:"",
      totalCustomerPackage:"",
      isPaymentConfirmed:false,
      isProductChanged:false,
      admin_id:"",
      document: {},
      document_url: "",
      currentTreatmentID:"",
      // treatmentConfirmedByAdminId:"",
      // treatmentConfirmedByAdminName:"",
      prevPackage: {},
      paymentConfirmedOverride: {},
      domain: '',
      minProductsQty: 0,
      maxProductsQty: 0,
      showPdf: false,
      type: ''
    }
    this._isMounted = false;
  }

  downloadImage = (url) => {
    window.open(url, '_blank').focus()
    // saveAs(url, 'image.jpg') // Put your image url here.
  }

  renderRiskColor = (risk) => {
    let color= "green";
    if(risk==="yellow"){
      color= "yellow";
      return(
        <i className="fa fa-circle" aria-hidden="true"  style={{color:color}}></i>
      )
    }else if(risk==="red"){
      color= "red";
      return(
        <i className="fa fa-circle" aria-hidden="true"  style={{color:color}}></i>
      )
    }else {
      return(
        <i className="fa fa-circle" aria-hidden="true"  style={{color:color}}></i>
      )
    }
  }

  printDocument() {
    window.print();
  }

  onChangeHandler = async (event, ref = 0) => {
    const {name, value} = event.currentTarget;
    if (name === 'trackingNumber') {
      this.setState({trackingNumber: value});
    } else if (name === 'payment_confirmed') {
      if(window.confirm(`Are you sure you want to ${this.state.isPaymentConfirmed ? 'unconfirm' : 'confirm'} the payment?`)){
        await this.setState({isPaymentConfirmed: !this.state.isPaymentConfirmed});
        if(this.state.isPaymentConfirmed){
          this.readyShipment()
        }
      }
    } else if (name === 'd_name') {
      this.setState({editedShippingAddress: {
        ...this.state.editedShippingAddress,
        fullname: value,
        name: value,
      }})
    } else if (name === 'city') {
      this.setState({editedShippingAddress: {
        ...this.state.editedShippingAddress,
        city: value
      }})
    } else if (name === 'd_phone') {
      this.setState({editedShippingAddress: {
        ...this.state.editedShippingAddress,
        phone: value
      }})
    } else if (name === 'country') {
      this.setState({editedShippingAddress: {
        ...this.state.editedShippingAddress,
        country: value
      }})
    } else if (name === 'postal_code') {
      this.setState({editedShippingAddress: {
        ...this.state.editedShippingAddress,
        postal_code: value
      }})
    } else if (name === 'line1') {
      this.setState({editedShippingAddress: {
        ...this.state.editedShippingAddress,
        line1: value
      }})
    } else if (name === 'line2') {
      this.setState({editedShippingAddress: {
        ...this.state.editedShippingAddress,
        line2: value
      }})
    } else if (name === "product_" + ref) {
      //manage product changed or not
      this.setState({isProductChanged: true});
      const {products} = this.state;
      var masterProduct = this.state.productsList.find(
        (item) => item.ref === ref
      );
      //check check or uncheck
      var productExist = products.find((item) => item.value === ref);
      if (productExist) {
        products.splice(products.findIndex(p => p.value === ref), 1)
        this.setState({products: products});
      } else {
        products.push({
          value: ref,
          title: masterProduct?.title,
          rule: '',
          time: '',
          frequency: '',
          qty: 1,
          area: []
        });
        this.setState({products: products});
      }
    } else if (name === "rule_" + ref) {
      //manage product changed or not
      this.setState({isProductChanged: true});
      const {products} = this.state;
      var masterProduct = this.state.productsList.find((item) => item.ref === ref);
      let productIndex = products.findIndex((obj => obj.value == ref));
      if (productIndex >= 0) {
        products[productIndex].rule = value;
      } else {
        products.push({
          value: ref,
          rule: value,
          title: masterProduct?.title,
          time: '',
          frequency: products[productIndex].frequency ? products[productIndex].frequency : '',
          qty: products[productIndex].qty ? products[productIndex].qty : 1,
          area: products[productIndex].area ? products[productIndex].area : []
        });
      }
      this.setState({products: products});
    } else if (name === "time_" + ref) {
      this.setState({ isProductChanged: true });
      const { products } = this.state;
      var masterProduct = this.state.productsList.find((item) => item.ref === ref);
      let productIndex = products.findIndex((obj => obj.value == ref));
      if (productIndex >= 0) {
        products[productIndex].time = value;
      } else {
        products.push({
          value: ref,
          rule: '',
          title: masterProduct?.title,
          time: value,
          frequency: products[productIndex].frequency ? products[productIndex].frequency : '',
          qty: products[productIndex].qty ? products[productIndex].qty : 1,
          area: products[productIndex].area ? products[productIndex].area : []
        });
      }
      this.setState({ products: products });
    } else if (name === "qty_" + ref) {
      const {products} = this.state;
      var masterProduct = this.state.productsList.find((item) => item.ref === ref);
      let productIndex = products.findIndex((obj => obj.value == ref));
      if (productIndex >= 0) {
        products[productIndex].qty = value;
      } else {
        products.push({
          value: ref,
          title: masterProduct?.title,
          qty: value,
          frequency: products[productIndex].frequency ? products[productIndex].frequency : '',
          area: products[productIndex].area ? products[productIndex].area : [],
          time: products[productIndex].time ? products[productIndex].time : '',
          rule: products[productIndex].rule ? products[productIndex].rule : '',
        });
      }
      this.setState({products: products});
    } else if (name === "frequency_" + ref) {
      const { products } = this.state;
      var masterProduct = this.state.productsList.find((item) => item.ref === ref);
      let productIndex = products.findIndex((obj => obj.value == ref));
      if (productIndex >= 0) {
        products[productIndex].frequency = value;
      } else {
        products.push({
          value: ref,
          title: masterProduct?.title,
          frequency: value,
          qty: products[productIndex].qty ? products[productIndex].qty : 1,
          area: products[productIndex].area ? products[productIndex].area : [],
          time: products[productIndex].time ? products[productIndex].time : '',
          rule: products[productIndex].rule ? products[productIndex].rule : '',
        });
      }
      this.setState({ products: products });
    }
  };

  onChangeArea = (value, ref) => {
    const { products } = this.state;
    var masterProduct = this.state.productsList.find((item) => item.ref === ref);
    let productIndex = products.findIndex((obj => obj.value == ref));
    if (productIndex >= 0) {
      products[productIndex].area = value;
    } else {
      products.push({
        value: ref,
        title: masterProduct?.title,
        area: value,
        qty: products[productIndex].qty ? products[productIndex].qty : 1,
        frequency: products[productIndex].frequency ? products[productIndex].frequency : '',
        time: products[productIndex].time ? products[productIndex].time : '',
        rule: products[productIndex].rule ? products[productIndex].rule : '',
      });
    }
    this.setState({ products: products });
  };

  onChangeHandlerDocument = (event) => {
    const {name, value} = event.currentTarget;
    if(event.target.files && event.target.files[0]){
      this.setState({document:event.target.files[0]});
    }
  };

  onChangeStatusHandler = async (selectedItem) => {
    this.setState({status: selectedItem});
  };

  handleValidation = () => {
    let {status, trackingNumber, isTracking, products} = this.state;
    let errors = {};
    let formIsValid = true;

    if(!trackingNumber && !isTracking && ['shipped','delivered'].includes(status)){
      formIsValid = false;
      errors["trackingNumber"] = "Tracking number is required";
    }

    //Status
    if(!status){
      formIsValid = false;
      errors["status"] = "Package status is required";
    }

    if(this.state.type === 'rb') {
      products.forEach((val, key) => {
        if (this.state.userVersion != 2 && (val.rule==="" || val.rule===undefined)){
          formIsValid = false;
          errors[`${val.value}`] = "Rule is required";
          errors['mainError'] = "Rule is required for each product!";
        } else if (this.state.userVersion == 2 && (val.frequency === "" || val.frequency === undefined)) {
          formIsValid = false;
          errors[`${val.value}`] = "Frequency is required";
          errors["mainError"] = "Frequency is required for each product!";
        } else if (this.state.userVersion == 2 && (val.area.length === 0 || val.area === undefined)) {
          formIsValid = false;
          errors[`${val.value}`] = "Area is required";
          errors["mainError"] = "Area is required for each product!";
        } else if (this.state.userVersion == 2 && (val.time === "" || val.time === undefined)) {
          formIsValid = false;
          errors[`${val.value}`] = "Time is required";
          errors["mainError"] = "Time is required for each product!";
        }
      })
  
      const productsQuantity = products.reduce((prev, curr) => prev + Number(curr.qty), 0)
  
      if (productsQuantity < this.state.minProductsQty) {
        formIsValid = false;
        errors['min'] = `Min count of products is ${this.state.minProductsQty}`;
        errors["mainError"] = `Min count of products is ${this.state.minProductsQty}!`;
      } else if (productsQuantity > this.state.maxProductsQty) {
        formIsValid = false;
        errors['max'] = `Max count of products is ${this.state.maxProductsQty}`;
        errors["mainError"] = `Max count of products is ${this.state.maxProductsQty}!`;
      }
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmitPackageInfo = async (event) => {
    event.preventDefault();
    if(!this.state.editPackageInfo){
      console.log('1');
      this.setState({editPackageInfo:!this.state.editPackageInfo});
    }else{
      if(this.handleValidation()) {
        this.setState({editPackageInfo:!this.state.editPackageInfo});
        let data = {
          status:this.state.status,
          trackingNumber:this.state.trackingNumber,
          products: this.state.products,
          ...(this.state.package_due && {package_due: moment(this.state.package_due).format('YYYY-MM-DD HH:mm:ss')}),
          ...(this.state.ship_by && {ship_by: moment(this.state.ship_by).format('YYYY-MM-DD HH:mm:ss')}),
          ...(this.state.created && {created: moment(this.state.created).format('YYYY-MM-DD HH:mm:ss')}),
          ...(this.state.shipped && {shipped: moment(this.state.shipped).format('YYYY-MM-DD HH:mm:ss')}),
          ...(this.state.delivered && {delivered: moment(this.state.delivered).format('YYYY-MM-DD HH:mm:ss')}),
        };
        if(this.state.trackingNumber) {
          await api.get(`/s/klaviyo/${this.state.userId}`)
        }
        if(this.state.status === 'shipped'){
          let trackingURL = "";
          //tracking code to tracking URL 
          try{
            // update order status
            if(this.state.orderId) {
              await api.post('updateOrder', {
                id: this.state.orderId,
                status: 'delivery_in_process'
              })
            }
            //update user
            const customer = await api.post('updateUser', {
              id: this.state.userId,
              waitingPackage:this.state.id
            });

            let tracking = await axios.get(`${this.state.domain}/s/tracking/${this.state.trackingNumber}`);
            trackingURL = tracking.data;
            console.log('tracking result', tracking);
          }catch (e) {
            console.log('e', e)
            this.setState({error:e.message})
          }

          //send notification
          try{
            let doc;
            if(this.state.language=="EN"){
              doc = "notifications_txt";
            }else if(this.state.language=="RU"){
              doc = "notifications_txt_ru";
            }
            console.log('doc', doc)
            const notification = await fb.metaCollection.doc(doc).get();
            let title = encodeURIComponent(notification.data().push_notif_package_shipped_title || "Title");
            let message = encodeURIComponent(notification.data().push_notif_package_shipped_message || "Description");
            let data = `shipping,${encodeURI(trackingURL)}`;
            let topic = `${this.state.userId}s`;

            let result1 = await axios.get(`${this.state.domain}/s/send?topic=${topic}&title=${title}&message=${message}&data=${data}`);
            console.log('s/send result', result1);
          }catch (e) {
            this.setState({error:e.message})
            console.error('s/send result', e);
          }
          //end notification

          // data.shipped = fb.timestamp.serverTimestamp();
          // data.risk = "";
          data.shipped = data.shipped || moment().format('YYYY-MM-DD HH:mm:ss')
          this.setState({shipped: this.state.shipped || new Date()})
        }else if(this.state.status === 'delivered'){
          // update order status
          if(this.state.orderId) {
            await api.post('updateOrder', {
              id: this.state.orderId,
              status: 'delivered'
            })
          }

          //send notification
          if(!this.state.delivered){
            try{
              let doc;
              if(this.state.language=="EN"){
                doc = "notifications_txt";
              }else if(this.state.language=="RU"){
                doc = "notifications_txt_ru";
              }
              const notification = await fb.metaCollection.doc(doc).get();
              let title = encodeURIComponent(notification.data().push_notif_package_delivered_title || "Title");
              let message = encodeURIComponent(notification.data().push_notif_package_delivered_message || "Description");
              let m_data = "shipping";
              let topic = `${this.state.userId}s`;
              let result1 = await axios.get(`${this.state.domain}/s/send?topic=${topic}&title=${title}&message=${message}&data=${m_data}`);
              console.log('s/send result1', result1);
              // let title_chat = encodeURIComponent(notification.data().push_notif_chat_title || "Title");
              // let message_chat = encodeURIComponent(notification.data().push_notif_package_delivered_chat_message.replace("[[Name]]", this.state.shippingAddress.name) || "Description");
              // let result2 = await axios.get(`${this.state.domain}/s/send_chat_message?uid=${this.state.userId}&title=${title_chat}&message=${message_chat}`);
              // console.log('s/send result2', result2);
            }catch (e) {
              this.setState({error:e.message})
              console.error('s/send result', e);
            }
          }
          //end notification
          data.delivered = data.delivered || moment().format('YYYY-MM-DD HH:mm:ss')
          this.setState({delivered: this.state.delivered || new Date()})
          // data.risk = "";
        } else if (this.state.status === 'delivery_in_process') {
          data.shipped = data.shipped || moment().format('YYYY-MM-DD HH:mm:ss')
          this.setState({shipped: this.state.shipped || new Date()})
        }
        await api.post('updatePackage', {
          ...data,
          id: this.state.id
        })
        // addAdminLog(
        //   'package_updated',
        //   `Changed the package ${this.state.id} for the user ${this.state.userId}`,
        //   this.state.id,
        //   this.state.userId,
        //   this.state.customerName
        // )
        // Send email admin notification
        // let admin_emails = []
        // const chat_admins = await fb.metaCollection.doc('chat_admins').get().then(async doc => {
        //   if (doc.exists) {
        //     for (const id of doc.data().admins) {
        //       const userObj = await fb.usersCollection.doc(id).get().then((doc) => {
        //         if (!doc.exists) return false;
        //         admin_emails.push(doc.data().email)
        //         return doc.data();d
        //       });
        //     }
        //     return doc.data().admins
        //   } else {
        //     return false
        //   }
        // })
        let {data: admins_collection} = await api.get('getUsers')
        admins_collection = admins_collection?.filter(i => i?.emails_permissions)

        const newAdminsArr = admins_collection.filter((el) => {
          return !!el.emails_permissions.filter(item => item.key === "package_changes_status").length
        }).map(el => el.email)
        const templates = await fb.metaCollection.doc('notifications_txt').get().then(res => res.data());
        const {email_package_changed_status_subtitle, email_package_changed_status_body} = templates;
        const newEmailTemplate =  email_package_changed_status_body
          .replace("{userId}", this.state.userId)
          .replace("${this.state.shippingAddress.fullname}", this.state.shippingAddress.name || this.state.shippingAddress.fullname )
          .replace('${this.state.status}', this.state.status);
        let emailsRef = fb.emailsCollection.doc();
        await emailsRef.set({
          id:emailsRef.id,
          userId:this.state.userId,
          body: newEmailTemplate,
          subject: email_package_changed_status_subtitle,
          recipients: newAdminsArr.join(','),
          send:fb.timestamp.serverTimestamp(),
          sent: false,
        }).then(async snapShot => {
          //sent emails
          console.log(emailsRef.id);
          // const result =  await axios.get(`${this.state.domain}/s/email/${emailsRef.id}`);
          // console.log('result', result);
        }).catch(err=> {
          console.log(err);
        });
      }else{
        console.log("required errors", this.state.errors)
      }
    }
  }

  handleSubmitProductInfo = async (event) => {
    event.preventDefault();
    if(!this.state.editProductInfo){
      console.log('1');
      this.setState({editProductInfo:!this.state.editProductInfo});
    }else{
      if(this.handleValidation()) {
        this.setState({editProductInfo: !this.state.editProductInfo});
        let data = {
          products: this.state.products
        }
        if(this.state.isProductChanged){
          this.onCustomerSelect();
        }
        api.post('updatePackage', {
          ...data,
          id: this.state.id
        })
        const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: this.state.userId } })
        const treatment = treatmentsByUserId?.find(i => i.id === this.state.currentTreatmentID)
        const newTreatmentProducts = Object.fromEntries(Object.entries(treatment.products).map(i => [i[0], {
          ...i[1],
          ...data.products.find(j => j.value === i[0].replace('.', ''))
        }]))
        api.post('updateTreatment', {
          ...treatment,
          id: this.state.currentTreatmentID,
          products: newTreatmentProducts
        })
        // addAdminLog(
        //   'package_updated',
        //   `Changed the package ${this.state.id} for the user ${this.state.userId}`,
        //   this.state.id,
        //   this.state.userId,
        //   this.state.customerName
        // )
      }else{
        console.log("required errors", this.state.errors)
      }
    }
  }

  handleSubmitDeliveryInfo = (event) => {
    event.preventDefault();
    if(!this.state.editDeliveryInfo) {
      this.setState({editDeliveryInfo: true})
      return
    }

    const originalData = Object.values(this.state.shippingAddress)
    const editedData = Object.values(this.state.editedShippingAddress)
    let isEqual = editedData.every((i, index) => i === originalData[index])

    if(isEqual) {
      this.setState({editDeliveryInfo: false})
      return
    }

    if(window.confirm('Do you want to save edited Shipping address to the User?')) {
      api.post('updateUser', {
        id: this.state.userId,
        shippingAddress: this.state.editedShippingAddress
      }).then(() => {
        addAdminLog(
          'user_shipping_address_updated',
          `Changed the shipping address for the user ${this.state.userId}`,
          this.state.userId,
          this.state.userId,
          this.state.customerName
        )
      }).catch(err=> {
        console.log('err', err)
        this.setState({error:err.message})
      })
    }

    api.post('updatePackage', {
      id: this.state.id,
      shippingAddress: this.state.editedShippingAddress
    }).then(() => {
      this.setState({shippingAddress: {...this.state.editedShippingAddress}})
      this.setState({editDeliveryInfo: false})
      // addAdminLog(
      //   'package_updated',
      //   `Changed the package ${this.state.id} for the user ${this.state.userId}`,
      //   this.state.id,
      //   this.state.userId,
      //   this.state.customerName
      // )
    }).catch(err=> {
      console.log('err', err)
      this.setState({error:err.message})
    })
  }

  handleUploadSubmit = async (event) => {
    event.preventDefault();
    this.setState({disabled: true});
    this._isMounted = true;
    var timestamp = Math.floor(Date.now() / 1000);
    if(this.state.document && this.state.document.name){
      fb.storage.child(`package_document/${timestamp}-${this.state.document.name}`).put(this.state.document)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then((url) => { 
        url && this.updatePackageFile(url);   
        if(this._isMounted === true){
          this.setState({document_url:url});
        }
      });
    }else{
      alert('Please upload document file first!');
      this.setState({disabled: false});
    }
  }

  updatePackageFile = (url) => {
    api.post('updatePackage', {
      id: this.state.id,
      document_url: url
    }).then(res => {
      // addAdminLog(
      //   'package_updated',
      //   `Changed the package ${this.state.id} for the user ${this.state.userId}`,
      //   this.state.id,
      //   this.state.userId,
      //   this.state.customerName
      // )
      this.setState({disabled: false});
    });
  }

  componentWillUnmount = () => {
    if(this._isMounted){
      this._isMounted = false;
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevState.status && prevState.status!==this.state.status){
      let {trackingNumber} = this.state;
      if(!trackingNumber){
        this.setState({isTracking: false});
      }
    }
  }

  componentDidMount = async () => {
    this.setState({isLoading:true});

    this.setState({domain: fb.CRM_URL})

    fb.metaCollection.doc('assessment_form').get().then(res => {
      this.setState({
        minProductsQty: res.data().min_products_quantity,
        maxProductsQty: res.data().max_products_quantity,
      })
    })

    //set current admin
    this.setCurrentUser();

    const url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    await api.get('getPackageByID', {params: {id: segment}}).then(async res => {
      const dataObj = res.data;
      if (dataObj) {
        let treatmentRequestData = {}
        if(dataObj.hasOwnProperty('treatmentRequestId')){
          await fb.usersCollection.doc(dataObj.user_id)
            .collection('TreatmentRequests').doc(dataObj.treatmentRequestId).get().then(async res => {
            if(res.exists){
              treatmentRequestData = res.data()
              await fb.usersCollection.doc(treatmentRequestData.treatmentRequestDoneBy).get().then(async res => {
                if(res.exists){
                  let adminData = res.data()
                  treatmentRequestData = {
                    ...treatmentRequestData,
                    adminName: adminData.name
                  }
                }
              })
            }
          })
        }

        this.setState({
          orderId: dataObj.orderId,
          id: dataObj.id,
          customerName: dataObj.userName,
          risk: dataObj.risk || "",
          document_url: dataObj.document_url || "",
          userId: dataObj.user_id,          
          customer: dataObj.userName + " <" + dataObj.userEmail + ">",
          products: !!dataObj?.products?.length ? dataObj.products.map(i => ({ ...i, ref: typeof i?.ref === 'string' ? i?.ref : i?.ref?.ref })) : [],
          shippingAddress: dataObj.shippingAddress ? dataObj.shippingAddress : this.state.shippingAddress,
          editedShippingAddress: dataObj.shippingAddress ? dataObj.shippingAddress : this.state.editedShippingAddress,
          status: dataObj.status,
          ref: dataObj.ref || "",
          trackingNumber: dataObj.trackingNumber || "",
          created: dataObj.created,
          ship_by: dataObj.ship_by,
          package_due: dataObj.package_due,
          shipped: dataObj.shipped,
          delivered: dataObj.hasOwnProperty('delivered') ? dataObj.delivered : '',
          paymentConfirmedOverride: dataObj.paymentConfirmedOverride,
          treatmentRequestId: dataObj.treatmentRequestId,
          treatmentRequestData: treatmentRequestData,
          type: dataObj.type || "rb",
          qcTitle: dataObj.qcTitle || "",
          qcAlergy: dataObj.qcAlergy || ""
        })

        //customer payment
        await this.fetchPaymentInformationData();
        //customer package
        await this.fetchCustomerPackages();

        if (dataObj.paymentConfirmedOverride && dataObj.paymentConfirmedOverride.status === true) {
          const {data: admin} = await api.get('userByID', {params: {userId: dataObj.paymentConfirmedOverride.admin}})
          
          this.setState({
            isPaymentConfirmed: true,
            paymentConfirmedOverride: {
              ...this.state.paymentConfirmedOverride,
              adminName: admin.name
            }
          });
        }else if(!dataObj.paymentConfirmedOverride){
          if(this.state.totalCustomerPayment >= this.state.totalCustomerPackage){
            this.setState({isPaymentConfirmed:true});
            this.readyShipment()
          }
        }
      }
    }).catch(err=> {
      console.log('err', err)
      this.setState({error:err.message})
    });

    //get all the products
    const allProduct = await fb.productsCollection.orderBy("order", "asc").get();
    const productMasterArr = [];
    allProduct.docs.forEach(doc=> {
      const dataObj = doc.data();
      let p = dataObj.ref.replace("p", "");
      productMasterArr.push({
        ref:dataObj.ref,
        title:dataObj?.title,
        p:p
      })
    });
    const sortedProduct = productMasterArr.sort(function (a, b) {
      return parseInt(a.p) - parseInt(b.p);
    });
    this.setState({
      productsList:sortedProduct
    })

    //get user language
    const { data: userByID } = await api.get('userByID', { params: { userId: this.state.userId } })
    this.setState({
      userVersion: userByID.version || '2',
      language: userByID.language ? userByID.language : this.state.language,
      currentTreatmentID: userByID.currentTreatmentID ? userByID.currentTreatmentID : this.state.currentTreatmentID,
    })

    //show who created tretment plan admin
    // if(this.state.currentTreatmentID){
    //   const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: this.state.userId } })
    //   const treatment = treatmentsByUserId.find(i => i.id === this.state.currentTreatmentID)
    //   if(treatment) {
    //     this.setState({
    //       treatmentConfirmedByAdminId: treatment.treatmentConfirmedBy.adminId,
    //       treatmentConfirmedByAdminName: treatment.treatmentConfirmedBy.adminName,
    //       treatmentConfirmedByTime: treatment.treatmentConfirmedBy.time 
    //         ? convertMilisecondsToDate(new Date(treatment.treatmentConfirmedBy.time).getTime()) 
    //         : ""
    //     })
    //   }
    // }
    
    this.setState({isLoading:false});
  }

  getStatusName = () => {
    let array = this.state.statusList.filter(e => e.value == this.state.status);
    if(array.length > 0){
      return array[0].name;
    }else{
      return '';
    }
  }

  onSelect = (selectedList, selectedItem) => {
    this.setState({products:selectedList})
  }

  onRemove = (selectedList, removedItem) => {
    this.setState({products:selectedList})
  }

  checkSelectedProduct = (products, ref) => {
    let DBProduct = (products && products.length) ? products.find((item) => item.value === ref) : products[ref];
    if(DBProduct){
      return true;
    }
    return false;
  }

  checkSelectedRule = (products, ref) => {
    let DBProduct = (products && products.length) ? products.find((item) => item.value === ref) : products[ref];
    if(DBProduct){
      return this.state.userVersion == 2 ? DBProduct.time : DBProduct.rule;
    }
    return "";
  }

  checkQty = (products, ref) => {
    let DBProduct = (products && products.length) ? products.find((item) => item.value === ref) : products[ref];
    if(DBProduct && DBProduct.qty){
      return DBProduct.qty;
    }
  }

  checkFrequency = (products, ref) => {
    let DBProduct = (products && products.length) ? products.find((item) => item.value === ref) : products[ref];
    if(DBProduct && DBProduct.frequency){
      return DBProduct.frequency;
    }
  }

  checkArea = (products, ref) => {
    let DBProduct = (products && products.length) ? products.find((item) => item.value === ref) : products[ref];
    if(DBProduct && DBProduct.area){
      return DBProduct.area;
    }
  }

  //waiting_shipment
  readyShipment = async() => {
    const {admin_id} = this.state;
    await api.post('updatePackage', {
      id: this.state.id,
      paymentConfirmedOverride:{
        admin:admin_id,
        status:true,
        time:fb.timestamp.serverTimestamp(),
      },
      status:"waiting_shipment_confirmed",
    }).then(res=> {
      // addAdminLog(
      //   'package_updated',
      //   `Changed the package ${this.state.id} for the user ${this.state.userId}`,
      //   this.state.id,
      //   this.state.userId,
      //   this.state.customerName
      // )
      this.setState({status:"waiting_shipment_confirmed"});
      alert('Package status changes to waiting shipment confirmed');
    })
  }

  fetchPaymentInformationData = async() => {
    let payment_url = `${this.state.domain}/s/payments/${this.state.userId}`
    try{
      const result = await axios.get(payment_url)
      let payment = 0;
      if(result.data.data){
        for (let doc of result.data.data) {
          payment = payment+doc.amount / 100;
        }
      }
      this.setState({
        totalCustomerPayment: payment
      });
    }catch(err){
      console.log('err',err)
      this.setState({error:err.message})
    }
  }

  fetchCustomerPackages = async () => {
    const cp = await api.get(`s/package/list/${this.state.userId}`)
    if (cp.status === 200) {
      let cpList = [];
      cp.data.list.forEach(pack => {
        cpList.push({
          id: pack.id,
          created: pack.created,
          risk: pack.risk || "",
          products: pack.products
            ? pack.products.length
              ? pack.products.map(p => p.value).join(', ')
              : Object.values(pack.products).map(p => p.value).join(', ')
            : []
        });
      })
      let currentIndex = cpList
        .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
        .findIndex((el) => el.id === this.state.id)

      if (currentIndex > 0) {
        this.setState({ prevPackage: cpList[currentIndex - 1] })
      }
      let multiplier = this.state.shippingAddress.country === 'GB' ? 700 : 800
      this.setState({ totalCustomerPackage: cpList.length * multiplier });
    }
  }

  setCurrentUser =  async () => {
    fb.auth.onAuthStateChanged(async (user) => {
      if (user != null) {
        this.setState({admin_id:user.uid});
      }
    });
  }

  deletePackage = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await api.post('updatePackage', {
        id: this.state.id,
        deleted:true
      });
      // addAdminLog(
      //   'package_deleted',
      //   `Deleted the package ${this.state.id} for the user ${this.state.userId}`,
      //   this.state.id,
      //   this.state.userId,
      //   this.state.customerName
      // )
      this.props.history.push('/admin/shipment')
    }
  }

  onCustomerSelect = async () => {
    let { userId, products } = this.state;
    const { data: customer } = await api.get('userByID', { params: { userId } })
    if (customer) {
      let currentTreatmentID = customer?.currentTreatmentID;
      let treatments;
      if (currentTreatmentID) {
        const { data: treatmentsByUserId } = await api.get('getTreatmentsByUserId', { params: { userId } })
        treatments = treatmentsByUserId.find(i => i.id === currentTreatmentID)
      } else {
        const { data: treatmentsByUserId } = await api.get('getTreatmentsByUserId', { params: { userId } })
        treatments = treatmentsByUserId.filter(i => i?.products)
      }
      if (treatments) {
        let pro = {};
        for (let key in products) {
          const data1 = await fb.productsCollection
            .where("ref", "==", products[key].value)
            .get();
          if (data1.docs.length > 0 && data1.docs[0].data()) {
            const dataObj1 = data1.docs[0].data();
            pro[dataObj1.ref] = {
              ref: dataObj1.ref,
              title: dataObj1?.title,
              title_ru: dataObj1?.title_ru,
              order: Number(dataObj1.order) || 1,
              rule: products[key].rule || "",
              time: products[key].time || "",
              qty: products[key].qty || 1,
              frequency: products[key].frequency || '',
              area: products[key].area || [],
              description: this.getProductDescription(dataObj1)['en'],
              description_ru: this.getProductDescription(dataObj1)['ru'],
            };
          }
        }
        let t = treatments
        // delete t.id;
        t.products = pro;
        api.post('updateTreatment', {
          ...t,
          id: t.id
        }).then(async (res) => {
          addAdminLog(
            'treatment_changed',
            `Changed the treatment ${t.id} products for the user ${this.state.userId}`,
            t.id,
            this.state.userId,
            this.state.customerName
          )
          await api.post('updateUser', {
            id: userId,
            currentTreatmentID: t.id
          });
          this.setState({
            sendDisabled: false,
            successMessage: "User diagnosis copied successfully!",
          });
          setTimeout(() => {
            this.setState({
              successMessage: "",
            });
            // window.location.reload();
          }, 3000);
        }).catch((err) => {
          console.log('err', err)
          this.setState({ errorMessage: err.message });
        });
      } else {
        this.setState(({ products: [] }));
      }
    }
  }

  getProductDescription = (product_data) => {
    console.log('product_data',product_data)
    let picture_slider = ''
    if((product_data.hasOwnProperty('pictures') && product_data.pictures.length > 0) ||
      (product_data.hasOwnProperty('picture') && product_data.picture !== '')){
      picture_slider += '<div className="picture_slider">';
      if (product_data.hasOwnProperty('pictures')) {
        for (const picture of product_data.pictures.sort((a, b) => {
          return a.order - b.order
        })) {
          if (picture.type && picture.type.includes('video')) {
            picture_slider += `<div><video controls><source src="${picture.url}" type="${picture.type}"/></video></div>`;
          } else {
            picture_slider += `<div><img src="${picture.url}" /></div>`;
          }
        }
      } else {
        picture_slider += `<div><img src="${product_data.picture}" /></div>`;
      }
      picture_slider += '</div>';
    }
    return {
      en: `${product_data?.title}${picture_slider} ${product_data.description}<p><b>Instruction:</b></p>${product_data.instruction}`,
      ru: `${product_data?.title_ru}${picture_slider} ${product_data.description_ru}<p><b>Инструкция:</b></p>${product_data.instruction_ru}`
    }
  }

  productRuleIndicator = (products,ref) => {
    let product =  products.length ? products.find((item) => item.value === ref) : products[ref];
    if(product){
      let rule = this.state.userVersion == 2 ? product.time : product.rule
      if (rule === 'M&E' || rule === 'M, E'){
        return (<span style={{backgroundColor:'#CDD2FF'}}>M,E</span>)
      }else if(rule === 'M'){
        return (<span style={{backgroundColor:'#FFEBCD'}}>M</span>)
      }else{
        return (<span style={{backgroundColor:'#CDE7FF'}}>E</span>)
      }
    }else{
      return (<span/>)
    }
  }

  handleGenerateDocument = (event) => {
    this.setState({ isLoading: true });
    const generatePdfTreatment = fb.functions.httpsCallable('generatePdfTreatment');
    generatePdfTreatment({ packageId: this.state.id })
      .then(async (result) => {
        const { data } = await api.get('getPackageByID', { params: { id: this.state.id } })
        if (data.hasOwnProperty('document_url') && data?.document_url !== this.state.document_url) {
          window.location.reload()
        }
      });
  }

  render() {
    // console.log('render', this.state)
    return (
      <div className="content customer-details">
      <Link to={`/admin/shipment`} className="btn btn-success users-back-btn libi-admin-btn">Back</Link>
      <Link to={`#`} onClick={this.printDocument } className="btn btn-success users-back-btn libi-admin-btn">Download PDF</Link>
        {this.state.prevPackage.hasOwnProperty('id') && <Link to={`/admin/shipment/view/${this.state.prevPackage.id}`} target="_blank" className="btn btn-success">Previous package: {convertMilisecondsToDate(new Date(this.state.prevPackage.created).getTime())}</Link>}
      {/*{this.state.isPaymentConfirmed && <Link to={`#`} onClick={(event) => {this.readyShipment(event)}} className="btn btn-success"> Ready for Shipment </Link> }*/}

      {this.state.errors && this.state.errors.mainError &&
        <Alert bsStyle="danger">
          <span>{this.state.errors.mainError}</span>
        </Alert>
      }
      <Link to={`#`} onClick={(event) => {this.deletePackage(event)}} className="btn btn-danger shipment-delete-btn pull-right libi-admin-btn red"> Delete </Link>
        <Container fluid> 
          <Row id="divToPrint">
            <Col md={6}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Package Information"
                button={
                  <Link to={`#`} onClick={(event) => {this.handleSubmitPackageInfo(event)}} className="btn btn-success custom-btn edit-btn pull-right btn-right libi-admin-btn">
                    {this.state.editPackageInfo ? "Save": "Edit"} 
                  </Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  // this.state.editPackageInfo ?
                  //   <form onSubmit={this.handleSubmit}>
                  //     <Row>
                  //       <div className="col-md-12">
                  //         <FormGroup>
                  //           <FormLabel> <input
                  //             type="checkbox"
                  //             className=""
                  //             // style={{'margin':'0 0 0 10px'}}
                  //             name="payment_confirmed"
                  //             value="payment_confirmed"
                  //             checked={this.state.isPaymentConfirmed}
                  //             onChange={(event) =>this.onChangeHandler(event)}
                  //           /> <span style={{'margin':'0 0 0 10px'}}>Payment Confirmed</span> </FormLabel>
                  //           <span className="help-block">{this.state.errors.customer}</span>
                  //         </FormGroup>
                  //       </div>
                  //     </Row>
                  //     <Row>
                  //       <div className="col-md-12">
                  //         <FormGroup>
                  //           <FormLabel>Status</FormLabel>
                  //           <SelectSearch
                  //               options={this.state.statusList}
                  //               value={this.state.status}
                  //               name="status"
                  //               placeholder="Select status"
                  //               onChange={(event)=>this.onChangeStatusHandler(event)}
                  //               disabled={this.state.status=="waiting_shipment"? true : false}
                  //           />
                  //           <span className="help-block">{this.state.errors.status}</span>
                  //         </FormGroup>
                  //       </div>
                  //     </Row>
                  //     {this.state.status != "waiting_shipment" && <Row>
                  //       <div className="col-md-12">
                  //         <FormGroup>
                  //           <FormLabel>Tracking Number</FormLabel>
                  //           <input
                  //             type="text"
                  //             className="form-control"
                  //             placeholder="Tracking Number"
                  //             name="trackingNumber"
                  //             value={this.state.trackingNumber}
                  //             onChange={(event)=>this.onChangeHandler(event)}
                  //           />
                  //           <span className="help-block">{this.state.errors.trackingNumber}</span>
                  //         </FormGroup>
                  //       </div>
                  //     </Row>}
                  //     <Row>
                  //       <div className="col-md-12">
                  //         <FormGroup>
                  //           <FormLabel>Customer</FormLabel>
                  //           <input
                  //             type ="text"
                  //             className="form-control"
                  //             placeholder= "Customer"
                  //             name= "name"
                  //             value= {this.state.customer}
                  //             readOnly={true}
                  //           />
                  //           <span className="help-block">{this.state.errors.customer}</span>
                  //         </FormGroup>
                  //       </div>
                  //     </Row>
                  //     <Row>
                  //       <div className="col-md-12">
                  //         Confirmed by:
                  //       </div>
                  //     </Row>
                  //     <Row>
                  //       <div className="col-md-12">
                  //         Treatment request:
                  //       </div>
                  //     </Row>
                  //   </form>
                  // :
                  <div>
                    <div className="">
                      <h5>
                        <span className="category">Status</span> 
                        {this.state.editPackageInfo ?
                          <FormGroup style={{'display':'inline-block'}}>
                            <SelectSearch
                              options={this.state.statusList}
                              value={this.state.status}
                              name="status"
                              placeholder="Select status"
                              onChange={(event)=>this.onChangeStatusHandler(event)}
                              // disabled={this.state.status=="waiting_shipment"? true : false}
                            />
                            {this.state.errors.status && <span className="help-block">{this.state.errors.status}</span>}
                          </FormGroup>
                          :
                          this.getStatusName()
                        }
                      </h5>
                      {/* {this.state.editPackageInfo && (!this.state.isPaymentConfirmed || !this.state.treatmentRequestId) &&
                      <div className="form_notification">Status of the package can not be changed because: Payment is not confirmed, Treatment request is not satisfied</div>
                      } */}
                    </div>
                    {['shipped','delivered','waiting_shipment_confirmed'].includes(this.state.status) && <div className="">
                      <h5>
                        <span className="category">Tracking Number</span> {this.state.editPackageInfo ?
                        <FormGroup style={{'display':'inline-block'}}>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Tracking Number"
                            name="trackingNumber"
                            value={this.state.trackingNumber}
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                          {this.state.errors.trackingNumber && <span className="help-block">{this.state.errors.trackingNumber}</span>}
                        </FormGroup>
                        :
                        <>{this.state.trackingNumber ? this.state.trackingNumber : "N/A" }</>}
                      </h5>
                    </div>}
                    <div className="">
                      <h5>
                        <span className="category">Ref</span> {this.state.ref}
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Customer</span> <Link to={`/admin/users/view/${this.state.userId}`}>{this.state.customerName}</Link>
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Language</span> {this.state.language}
                      </h5>
                    </div>

                    {/* <div className="">
                      <h5>
                        <span className="category">Payment</span> {this.state.editPackageInfo ?
                        <FormGroup style={{'display':'inline-block'}}>
                           <input
                             type="checkbox"
                             className=""
                             name="payment_confirmed"
                             value="payment_confirmed"
                             checked={this.state.isPaymentConfirmed}
                             onChange={(event) =>this.onChangeHandler(event)}
                           />
                        </FormGroup>
                        :
                        <>{this.state.isPaymentConfirmed ? `
                        ${(this.state.paymentConfirmedOverride && this.state.paymentConfirmedOverride.hasOwnProperty('adminName')) ? `Confirmed by ${this.state.paymentConfirmedOverride.adminName}` : 'Autoconfirmed'}
                        ${(this.state.paymentConfirmedOverride && this.state.paymentConfirmedOverride.hasOwnProperty('time')) ? convertFirebseSecondsToDate(this.state.paymentConfirmedOverride.time.seconds) : ''}` : "Not confirmed"}</>}
                      </h5>
                    </div> */}
                    <div className="">
                      <h5>
                        <span className="category">Risk Status</span> {  this.state.risk && this.renderRiskColor(this.state.risk)}
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Shipment Date</span>
                        {this.state.editPackageInfo ?
                          <FormGroup style={{'display':'inline-block'}}>
                            <input
                              type="date"
                              className="form-control"
                              name="shipped"
                              value={this.state.shipped && moment(this.state.shipped).format('YYYY-MM-DD')}
                              onChange={(event) => {
                                this.setState({shipped: event.target.value})
                              }}
                            />
                          </FormGroup>
                          : this.state.shipped
                            ? moment(this.state.shipped).format('DD MMM YYYY')
                            : "N/A"
                        }
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Created Date</span>
                        {this.state.editPackageInfo ?
                          <FormGroup style={{'display':'inline-block'}}>
                            <input
                              type="date"
                              className="form-control"
                              name="created"
                              value={this.state.created && moment(this.state.created).format('YYYY-MM-DD')}
                              onChange={(event) => {
                                this.setState({created: event.target.value})
                              }}
                            />
                          </FormGroup>
                          : this.state.created
                            ? moment(this.state.created).format('DD MMM YYYY')
                            : "N/A"
                        }
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Ship By</span>
                        {this.state.editPackageInfo ?
                          <FormGroup style={{'display':'inline-block'}}>
                            <input
                              type="date"
                              className="form-control"
                              name="ship_by"
                              value={this.state.ship_by && moment(this.state.ship_by).format('YYYY-MM-DD')}
                              onChange={(event) => {
                                this.setState({ship_by: event.target.value})
                              }}
                            />
                          </FormGroup>
                          : this.state.ship_by
                            ? moment(this.state.ship_by).format('DD MMM YYYY')
                            : "N/A"
                        }
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Package Due</span>
                        {this.state.editPackageInfo ?
                          <FormGroup style={{'display':'inline-block'}}>
                            <input
                              type="date"
                              className="form-control"
                              name="package_due"
                              value={this.state.package_due && moment(this.state.package_due).format('YYYY-MM-DD')}
                              onChange={(event) => {
                                this.setState({package_due: event.target.value})
                              }}
                            />
                          </FormGroup>
                          : this.state.package_due
                            ? moment(this.state.package_due).format('DD MMM YYYY')
                            : "N/A"
                        }
                      </h5>
                    </div>
                    {this.state.delivered && 
                      <div className="">
                        <h5>
                          <span className="category">Delivered</span>
                          {this.state.editPackageInfo ?
                            <FormGroup style={{'display':'inline-block'}}>
                              <input
                                type="date"
                                className="form-control"
                                name="delivered"
                                value={this.state.delivered && moment(this.state.delivered).format('YYYY-MM-DD')}
                                onChange={(event) => {
                                  this.setState({delivered: event.target.value})
                                }}
                              />
                            </FormGroup>
                            : this.state.delivered
                              ? moment(this.state.delivered).format('DD MMM YYYY')
                              : "N/A"
                          }
                        </h5>
                      </div>
                    }
                    <div>
                      <h5>
                        <span className="category">Treatment request</span>{(this.state.treatmentRequestId && this.state.treatmentRequestData.treatmentRequestDoneDate) ?
                        `Satisfied ${convertFirebseSecondsToDate(this.state.treatmentRequestData.treatmentRequestDoneDate.seconds)}` :
                        <>{this.state.treatmentRequestData && this.state.treatmentRequestData.treatmentRequest ? `${convertFirebseSecondsToDate(this.state.treatmentRequestData.treatmentRequest.seconds)} - Not satisfied` : 'N/A'}</>}
                      </h5>
                    </div>
                    <div className="">
                        {/* {
                          this.state.treatmentConfirmedByAdminName 
                          ? 
                          <h5>
                            <span className="category">Treatment Plan</span> <Link to={`/admin/assessment/view/${this.state.userId}/${this.state.currentTreatmentID}`}>{this.state.treatmentConfirmedByTime} <strong>By</strong> {this.state.treatmentConfirmedByAdminName}</Link>
                          </h5> 
                          : 
                        } */}
                        <h5>
                          <span className="category">Treatment Plan</span> <Link to={`/admin/assessment/view/${this.state.userId}/${this.state.currentTreatmentID}`}>{this.state.created ? convertMilisecondsToDate(new Date(this.state.created).getTime()) : "N/A"} </Link>
                        </h5>
                    </div>
                    {['qc', 'ds'].includes(this.state.type) && 
                      <>
                        <div>
                          <h5>
                            <span className="category">Title</span> {this.state.qcTitle}
                          </h5>
                        </div>
                        <div>
                          <h5>
                            <span className="category">Alergy: </span> {this.state.qcAlergy}
                          </h5>
                        </div>
                      </>
                    }
                  </div>
                }
              />
            </Col>
            <Col md={6}>
              <Card
                title="Delivery Address"
                button={
                  <Link
                    to={`#`}
                    onClick={this.handleSubmitDeliveryInfo}
                    className="btn btn-success custom-btn edit-btn pull-right btn-right libi-admin-btn"
                  >
                    { this.state.editDeliveryInfo ? 'Save' : 'Edit' }
                  </Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <>{this.state.editDeliveryInfo ?
                    <form onSubmit={this.handleSubmit}>
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Full Name</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                name="d_name"
                                value={this.state.editedShippingAddress.fullname || this.state.editedShippingAddress.name}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Phone</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                                name="d_phone"
                                value={this.state.editedShippingAddress.phone}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Street</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Street"
                                name="line1"
                                value={this.state.editedShippingAddress.line1}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Apt (Optional)</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Apartment"
                                name="line2"
                                value={this.state.editedShippingAddress.line2}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>City</FormLabel>
                            <input 
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              value={this.state.editedShippingAddress.city}
                              onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Postal Code</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Postal Code"
                                name="postal_code"
                                value={this.state.editedShippingAddress.postal_code}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Country</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                name="country"
                                value={this.state.editedShippingAddress.country}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                    </form>
                    :
                    <div>
                      <div className="">
                        <h5>
                          <span className="category">Full Name</span> {this.state.editedShippingAddress.fullname || this.state.editedShippingAddress.name}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Phone</span> {this.state.editedShippingAddress.phone}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Street Address</span> {this.state.editedShippingAddress.line1}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Apt (Optional)</span> {this.state.editedShippingAddress.line2}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">City</span> {this.state.editedShippingAddress.city}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Postal Code</span> {this.state.editedShippingAddress.postal_code}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Country</span> {this.state.editedShippingAddress.country}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Email</span> <div style={{'display':'inline','wordBreak':'break-word'}}>{this.state.shippingAddress.email}</div>
                        </h5>
                      </div>
                    </div>}
                    <form onSubmit={this.handleUploadSubmit}>
                      <Row>
                        {this.state.document_url && <>
                          <div className="col-md-3">
                            <FormGroup>
                              <button onClick={() => this.downloadImage(this.state.document_url)}
                                      type="button"
                                      className="btn btn-success" style={{}}>Download
                              </button>
                            </FormGroup>
                          </div>
                          <div className="col-md-2"
                            style={{
                            marginTop: "10px",
                            fontWeight: "900"
                          }}>
                            or upload
                          </div>
                        </>}
                        <div className="col-md-4">
                          <FormGroup>
                          <input
                          type="file"
                          className="form-control"
                          name="document"
                          onChange={(event)=>this.onChangeHandlerDocument(event)}
                          />
                          </FormGroup>
                        </div>
                        <div className="col-md-2">
                          <FormGroup>
                          <Button
                            className="btn-fill libi-admin-btn"
                            bsStyle="info"
                            type="submit"
                            disabled={this.state.disabled}
                          >
                            {this.state.disabled ? 'Loading...' : 'Upload'}
                          </Button>
                          </FormGroup>
                        </div>
                      </Row>
                    </form>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                        {this.state.type === 'rb' &&
                          <Button
                            // onClick={this.handleGenerateDocument}
                            onClick={() => this.setState({showPdf: true})}
                            className="btn-fill libi-admin-btn"
                            bsStyle="info"
                            type="button"
                          >
                            Generate Document
                          </Button>
                        }
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <Button
                          onClick={() => api.get(`/s/klaviyo/${this.state.userId}`)}
                          className="btn-fill libi-admin-btn mt-2"
                          bsStyle="info"
                          type="button"
                        >
                          Update Klaviyo
                        </Button>
                      </Col>
                    </Row>
                  </>
                  }
              />
            </Col>
          </Row>
        
          {this.state.showPdf && <PackagePdf />}

          {this.state.type === 'rb' ?
            <Row id="divToPrint" className="print_product">
              <Col md={12}>
                <Card
                  title="Product Information"
                  button={
                    <Link to={`#`} onClick={(event) => {this.handleSubmitProductInfo(event)}} className="btn btn-success custom-btn edit-btn pull-right btn-right libi-admin-btn">
                      {this.state.editProductInfo ? "Save": "Edit"}
                    </Link>
                  }
                  loader={ this.state.isLoading && <PageLoader />}
                  content={
                    <form onSubmit={this.handleSubmit}>
                      <Row>
                        <div className="col-md-12">
                          {this.state.productsList &&
                          this.state.productsList.map((val, key) => {
                            return (
                              <div key={key}>
                                <div className="border_bottom">
                                  <Row className="wrap_border_product1">
                                    {/* <div className="col-md-6"> */}
                                    <div className="product_check">
                                      {" "}
                                      <input
                                        type="checkbox"
                                        className="mr-5 "
                                        id={"product_" + val.ref}
                                        name={"product_" + val.ref}
                                        value={val.ref}
                                        disabled={!this.state.editProductInfo}
                                        checked={this.checkSelectedProduct(this.state.products, val.ref)}
                                        onChange={(event) =>
                                          this.onChangeHandler(
                                            event,
                                            val.ref
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={"product_" + val.ref}
                                        className="template_type label_wrap"
                                      >
                                        {val?.title}
                                      </label>
                                    </div>
                                    {/* <div className="col-md-4"> */}
                                    <div className="product_treatment">
                                      {this.state.userVersion == 2 && <div className="product-title">Time</div>}

                                      <div className="treatment_product_rule_wrapper">
                                        <div className="treatment_product_rule_indicator">
                                          {this.productRuleIndicator(this.state.products,val.ref)}
                                        </div>
                                        <select
                                          className="form-control"
                                          name={this.state.userVersion == 2 ? "time_" + val.ref : "rule_" + val.ref}
                                          disabled={!this.state.editProductInfo}
                                          value={this.checkSelectedRule(this.state.products, val.ref)}
                                          onChange={(event) =>
                                            this.onChangeHandler(
                                              event,
                                              val.ref
                                            )
                                          }
                                        >
                                          {this.state.userVersion == 2 ? 
                                            <>
                                              <option value="">Select Time</option>
                                              <option value="M">M</option>
                                              <option value="E">E</option>
                                              <option value="M&E">M&E</option>
                                            </> :
                                            treatmentTimeCases().map(
                                              (treatmentTime, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={treatmentTime.key}
                                                  >
                                                    {treatmentTime.key}
                                                  </option>
                                                );
                                              }
                                            )
                                          }
                                        </select>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-2"> */}
                                    <div className="product_time">
                                      {this.state.userVersion == 2 && <div className="product-title">Quantity</div>}

                                      <CustomNumberField
                                        onChange={(event) =>
                                          this.onChangeHandler(
                                            event,
                                            val.ref
                                          )
                                        }
                                        min="0"
                                        max="10"
                                        disabled={!this.state.editProductInfo}
                                        name={"qty_" + val.ref}
                                        value={
                                          this.checkQty(this.state.products, val.ref)  && this.checkQty(this.state.products, val.ref)
                                        }
                                      />
                                    </div>
                                    {this.state.userVersion == 2 &&
                                      <div className="product_frequency">
                                        {this.state.userVersion == 2 && <div className="product-title">Frequency</div>}

                                        <select
                                          className="form-control"
                                          name={"frequency_" + val.ref}
                                          value={
                                            this.checkFrequency(this.state.products, val.ref) && this.checkFrequency(this.state.products, val.ref)
                                          }
                                          onChange={(event) =>
                                            this.onChangeHandler(
                                              event,
                                              val.ref
                                            )
                                          }
                                          disabled={!this.state.editProductInfo}
                                        >
                                          <option value="">Select Frequency</option>
                                          <option>ED</option>
                                          <option>E2D</option>
                                          <option>E3D</option>
                                          <option>E7D</option>
                                          <option>E10D</option>
                                          <option>E14D</option>
                                        </select>
                                      </div>
                                    }
                                  </Row>
                                  {this.state.userVersion == 2 &&
                                    <Row>
                                      <div className="product_area">
                                        <Multiselect
                                          options={areaOptions}
                                          selectedValues={
                                            this.checkArea(this.state.products, val.ref) && this.checkArea(this.state.products, val.ref)
                                          }
                                          onSelect={(value) => this.onChangeArea(value, val.ref)}
                                          onRemove={(value) => this.onChangeArea(value, val.ref)}
                                          displayValue="val"
                                          showCheckbox={true}
                                          placeholder="Select Area"
                                          disable={!this.state.editProductInfo}
                                          closeOnSelect={false}
                                        />
                                      </div>
                                    </Row>
                                  }
                                  {this.state.errors[val.ref] && <span className="help-block">{this.state.errors[val.ref]}</span>}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Row>
                    </form>
                  }
                />
              </Col>
            </Row>
            : (this.state.products.length > 0 && this.state.productsList.length > 0) ? (
              <Card
                title="Products"
                content={
                  <table className="products-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Ref</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.products.map(i => (
                        <tr key={i.value || i.ref}>
                          <td>{i?.title || this.state.productsList.find(j => j.ref === i.ref)?.title}</td>
                          <td>{i.value || i.ref}</td>
                          <td>{i.qty || i.quantity}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              />
            ) : null
          }
        </Container>
      </div>
    );
  }
}

export default ViewPackage;
