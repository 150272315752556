import PageLoader from 'components/Loader/PageLoader';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Tab, Table, Tabs } from 'react-bootstrap';
import $ from 'jquery';
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import Card from 'components/Card/Card';
import { Link } from 'react-router-dom';
import api from 'api';
import { convertMilisecondsToDate } from 'components/Helper';

const paymentsColumns = ['Payment ID', 'Created', 'Status', 'OrderValue', 'Paid', 'Actions']
const ordersColumns = ['Order ID', 'Created', 'User Name', 'Type', 'Actions']

const PaymentsList = () => {
  const [payments, setPayments] = useState([])
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  const fetchData = async () => {
    try {
      setIsLoading(true)

      const { data: expertPayments } = await api.get('getExpertPayments')

      if (expertPayments) {
        const paymentsArr = expertPayments.map(dataObj => ({
          ...dataObj,
          created_seconds: new Date(dataObj.time).getTime(),
          created: convertMilisecondsToDate(new Date(dataObj.time).getTime()),
        }))
        setPayments(paymentsArr)
      }

      const { data: expertOrders } = await api.get('getOrders')

      if (expertOrders) {
        const ordersArr = expertOrders.map(dataObj => ({
          ...dataObj,
          created_seconds: new Date(dataObj.created).getTime(),
          created: convertMilisecondsToDate(new Date(dataObj.created).getTime()),
        }))
        setOrders(ordersArr)
      }

      $('#payments-table').DataTable({
        order: [[1, 'desc']],
      })
      $('#orders-table').DataTable({
        order: [[1, 'desc']],
      })
    } catch (err) {
      console.log('err', err)
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Payments"
              category=""
              ctTableFullWidth
              ctTableResponsive
              loader={isLoading && <PageLoader />}
              content={
                <Tabs defaultActiveKey="payments">
                  <Tab eventKey={'payments'} title={'Payments'}>
                    <Table striped hover id={'payments-table'}>
                      <thead>
                        <tr>
                          {paymentsColumns.map(i => <th key={i}>{i}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {payments.map(i => (
                          <tr key={i.id}>
                            <td>{i?.ref?.replace('LD', '') || '-'}</td>
                            <td><span style={{ display: "none" }}>{i.created_seconds}</span>{i.created}</td>
                            <td>{i?.status ? (i?.status.charAt(0).toUpperCase() + i?.status.slice(1)) : 'Abandoned'}</td>
                            <td>{i?.consumerTotal ? `${i?.currency === 'eur' ? '€' : '£'}${(i.consumerTotal / 100).toFixed(2)}` : '-'}</td>
                            <td>{i?.total ? `${i?.currency === 'eur' ? '€' : '£'}${(i.total / 100).toFixed(2)}` : '-'}</td>
                            <td>
                              <Link to={`/admin/payment/view/${i.id}`}><i className="fa fa-eye" /></Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab>

                  <Tab eventKey={'orders'} title={'Orders'}>
                    <Table striped hover id={'orders-table'}>
                      <thead>
                        <tr>
                          {ordersColumns.map(i => <th key={i}>{i}</th>)}
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map(i => (
                          <tr key={i.id}>
                            <td>{i?.ref?.replace('LD', '') || '-'}</td>
                            <td><span style={{ display: "none" }}>{i.created_seconds}</span>{i.created}</td>
                            <td>{i?.userName || '-'}</td>
                            <td>{i?.type ? i.type.toUpperCase() : '-'}</td>
                            <td>
                              <Link to={`/admin/payment/order/${i.id}`}><i className="fa fa-eye" /></Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PaymentsList