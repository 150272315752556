import React, { Component } from "react";
import { Container, Row, Col, Table, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import { templateListThArray } from "variables/Variables.jsx";
import * as fb from "../../firebase";
import {
  removeHTMLTags
} from "components/Helper/index";

//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { convertFirebseSecondsToDate } from "components/Helper";

class TemplateList extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      baseTemplates:[],
      textTemplates:[],
      errors:{}
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#base-en-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
    $('#base-ru-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
    $('.text-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
  }
  
  fetchData = async() => {
    const baseTemplateArr = [];
    const textTemplateArr = [];
    this.setState({isLoading:true});
    const data = await fb.mustachesCollection.get();
    data.docs.forEach(doc=> {
      const dataObj = doc.data();
      if(doc.id){
        console.log("test", doc.data());
        if(dataObj.type=="base"){
          baseTemplateArr.push({
            id:doc.id,
            ref:dataObj.ref,
            title:dataObj.title,
            description:dataObj.description,
            title_ru:dataObj.title_ru || "",
            description_ru:dataObj.description_ru || "",
            products:dataObj.products,
            created: dataObj.created && dataObj.created.seconds ? convertFirebseSecondsToDate(dataObj.created.seconds) : "",
          });
        }else {
          textTemplateArr.push({
            id:doc.id,
            ref:dataObj.ref,
            title:dataObj.title,
            type: dataObj.type,
            description:dataObj.description,
            title_ru:dataObj.title_ru || "",
            description_ru:dataObj.description_ru || "",
            products:dataObj.products,
            created: dataObj.created && dataObj.created.seconds ? convertFirebseSecondsToDate(dataObj.created.seconds) : "",
          });
        } 
      }
    })
    this.setState({
      baseTemplates:baseTemplateArr,
      textTemplates:textTemplateArr,
      isLoading:false
    });
  }

  deleteTemplate = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await fb.mustachesCollection.doc(id).delete().then(() => {
        this.fetchData();
      }).catch(function(error) {
        console.error("Error removing document: ", error);
      });
    }
  }

  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="M-Base Templates"
                category="Email templates: structure of information which will be sent to users by emails and also will be available in the mobile app"
                ctTableFullWidth
                ctTableResponsive
                
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Tabs defaultActiveKey="en" id="uncontrolled-tab-example">
                    <Tab eventKey="en" title="EN">
                      <Table striped hover id="base-en-datatable">
                        <thead>
                          <tr>
                            {templateListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                          <tbody>
                            {this.state.baseTemplates.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{ val.ref }</td>
                                  <td>{ val.created}</td>
                                  <td>{ removeHTMLTags(val.title, 50) }</td>
                                  <td>
                                    <Link to={`/admin/template/edit_new/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp; 
                                    <Link to={`#`} onClick={() => {this.deleteTemplate(val.id)}}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                      </Table>
                    </Tab>
                    <Tab eventKey="ru" title="RU">
                      <Table striped hover id="base-ru-datatable">
                        <thead>
                          <tr>
                            {templateListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                          <tbody>
                            {this.state.baseTemplates.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{ val.ref }</td>
                                  <td>{ val.created}</td>
                                  <td>{ removeHTMLTags(val.title_ru, 50) }</td>
                                  <td>
                                    <Link to={`/admin/template/edit_new/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp; 
                                    <Link to={`#`} onClick={() => {this.deleteTemplate(val.id)}}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="M-Text Templates"
                category="Email templates: structure of information which will be sent to users by emails and also will be available in the mobile app"
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`/admin/template/add_new`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"> Add M-Template</Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Tabs defaultActiveKey="simple_results" id="uncontrolled-tab-example">
                    {Object.entries({
                      simple_results: 'Simple Results',
                      simple_skincare: 'Simple Skincare',
                      card_results: 'Card Results',
                      card_skincare: 'Card Skincare',
                      recommendation: 'Recommendation',
                      procedure: 'Procedure'
                    }).map((tabVal, tabKey) => {
                      return <Tab eventKey={tabVal[0]} title={tabVal[1]} key={tabKey}>
                      <Table striped hover className="text-datatable">
                        <thead>
                          <tr>
                            {templateListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                          <tbody>
                            {this.state.textTemplates.filter(el => el.type === tabVal[0])
                              .map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{ val.ref }</td>
                                  <td>{ val.created}</td>
                                  <td>{ val.title && removeHTMLTags(val.title, 50) }</td>
                                  <td>
                                    <Link to={`/admin/template/edit_new/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => {this.deleteTemplate(val.id)}}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                      </Table>
                    </Tab>
                    })}
                  </Tabs>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default TemplateList;
