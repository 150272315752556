import React, { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import { inviteUsersListThArray, inviteCustomerListThArray } from "variables/Variables.jsx";
import * as fb from "../../firebase";

//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import {
  convertFirebseSecondsToDate,
  userAccountStatus
} from "components/Helper/index";
import "./invite.css";
import api from "api";
import { convertMilisecondsToDate } from "components/Helper";

class InviteUserList extends Component {
  constructor(props){
    super(props)
    this.state = {
      pause:false,
      isLoading:false,
      dataList:[],
      usersList:[],
      errors:{}
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    await this.fetchUserData();
    $('#list-datatable').DataTable({
      "order": [[ 0, "desc" ]]
    });
    $('#customer-list-datatable').DataTable({
      "order": [[ 0, "desc" ]],
    });
  }
  
  fetchData = async() => {
    this.setState({isLoading:true});
    const data = await fb.InvitedUsersCollection.get();
    const dataArr = [];
    data.docs.forEach(doc=> {
      const dataObj = doc.data();
      dataArr.push({
        id:doc.id,
        name:dataObj.name,
        number:dataObj.number,
        pause:dataObj.pause,
        created:dataObj.created && dataObj.created.seconds ? convertFirebseSecondsToDate(dataObj.created.seconds) : "",
        created_seconds:dataObj.created && dataObj.created.seconds
      });
    })
    this.setState({
      dataList:dataArr,
      isLoading:false
    });
  }

  fetchUserData = async () => {
    this.setState({ isLoading: true });
    try {
      const { data: users } = await api.get('getUsers')
      let allUsers = [];
      users.forEach(dataObj => {
        let item = userAccountStatus().find(item => item.key === dataObj.status);
        if (!dataObj.deleted && dataObj.deleted !== true && dataObj.invite_code) {
          //all
          allUsers.push({
            id: dataObj.id,
            name: dataObj.name || dataObj.email,
            email: dataObj.email,
            address: dataObj.address,
            ref: dataObj.ref,
            dob: dataObj.dob,
            gender: dataObj.gender,
            test: dataObj.test,
            invite_code: dataObj.invite_code,
            status: item ? item.val : "",
            deleted: dataObj.deleted,
            created: convertMilisecondsToDate(new Date(dataObj.created).getTime()),
            created_seconds: new Date(dataObj.created).getTime()
          });
        }
      })
      this.setState({
        usersList: allUsers,
        isLoading: false
      });
    } catch (err) {
      this.setState({ error: err.message })
    }
  }

  deleteItem = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await fb.InvitedUsersCollection.doc(id).delete().then(() => {
        this.fetchData();
      }).catch(function(error) {
        console.error("Error removing document: ", error);
      });
    }
  }

  pauseCode = async (id) => {
    await fb.InvitedUsersCollection.doc(id).get().then(function(doc){
      if (doc.exists) {
        return doc.ref.update({ pause: !doc.data().pause });
      }
    }).then(() => {
      this.fetchData();
    }).catch(function(error) {
      console.error("Error removing document: ", error);
    });
  }

  customerPauseCode = async (id) => {
    if (window.confirm("Coming soon..")) {
    }
  }

  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Invite Code"
                category=""
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`/admin/invited/add`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn">Generate Invite Code</Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <div>
                  <h4><b>Admin Invite Code</b></h4>
                  <Table striped hover id="list-datatable">
                    <thead>
                      <tr>
                        {inviteUsersListThArray.map((val, key) => {
                          return <th key={key}>{val}</th>;
                        })}
                      </tr>
                    </thead>
                      <tbody>
                        {this.state.dataList.map((val, key) => {
                          return (
                            <tr key={key} id={key}>
                              <td><span style={{display:"none"}}>{val.created_seconds}</span>{ val.created }</td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{ val.number}</td>
                              <td>
                                <Link to={`#`} onClick={() => {this.deleteItem(val.id)}}><i className="fa fa-times invite-fafa-size" /></Link>&nbsp;&nbsp; 
                                {/* <Link to={`/admin/invited/edit/${val.id}`}><i className="fa fa-edit invite-fafa-size" /></Link>&nbsp;&nbsp; */}
                                <Link to={`#`} onClick={() => {this.pauseCode(val.id)}}>
                                { val.pause ? <i className="fa fa-play-circle invite-play invite-fafa-size" aria-hidden="true"></i> :  <i className="fa fa-pause-circle invite-pause invite-fafa-size" aria-hidden="true"></i>}
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <h4><b>Customers Invite Code</b></h4>
                    <Table striped hover id="customer-list-datatable">
                      <thead>
                        <tr>
                          {inviteCustomerListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                        <tbody>
                          {this.state.usersList.map((val, key) => {
                            return (
                              <tr key={key} id={key}>
                                <td><span style={{display:"none"}}>{val.created_seconds}</span>{ val.created }</td>
                                <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                                <td><Link to={`/admin/users/referral/${val.id}`}>{ val.invite_code}</Link></td>
                                <td>
                                  <Link to={``} onClick={() => {this.customerPauseCode(val.id)}}>
                                  { val.pause ? <i className="fa fa-play-circle invite-play invite-fafa-size" aria-hidden="true"></i> :  <i className="fa fa-pause-circle invite-pause invite-fafa-size" aria-hidden="true"></i>}
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                    </Table>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default InviteUserList;
