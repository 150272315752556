import React, { Component } from "react"
import * as fb from "../../firebase";

class Logout extends Component {
    constructor(props){
        super(props)
        this.state = {
            error:"",
            errors:"",
            disabled: false,
            isLoading: false
        }
    }

    componentDidMount = async () => {
        fb.auth.signOut().then(() => {
            localStorage.clear()
            this.props.history.push('/')
        }).catch((error) => {
            alert(error)
        });
    }

    render() {
        return <></>
    }
}

export default Logout
