import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
  Tab, 
  Tabs
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { Link } from "react-router-dom"; 
import * as fb from '../../firebase';
import firebase from "firebase/app";
import {
  createUnixTimestamp,
  convertFirebseSecondsToDate, addAdminLog
} from "components/Helper/index";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./Product.css";
import UploadAdapter from '../../components/image-adaptor/UploadAdapter';
import MultiplePhotoField from "../../components/MultiplePhotoField/MultiplePhotoField";

class EditProduct extends Component {

  constructor(props){
    super(props);
    this.state = {
      id:"",
      title:"",
      order:"",
      ref:"",
      description:"",
      instruction:"",
      title_ru:"",
      instruction_ru:"",
      description_ru:"",
      picture: {},
      pictureUrl:"",
      imageUploadedAt:"",
      pictureName:"",
      disabled : false,
      disabledSave:false,
      successMessage:"",
      error:"",
      errors:{},
      pictures: [],
      forUpload: []
    }
    this.multiplePhotoField = React.createRef();
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'title') {
      this.setState({title:value});
    }else if(name === 'title_ru'){
      this.setState({title_ru:value});
    }else if(name === 'order'){
      this.setState({order:value});
    }else if(name === 'ref') {
      this.setState({ref: value});
    }else if(name === 'pictures'){
      this.setState({
        pictures: value.pictures,
        forUpload: value.forUpload
      })
    }
  };

  onChangeHandlerDescription = (value) => {
    this.setState({description:value});
  };

  onChangeHandlerInstruction = (value) => {
    this.setState({instruction:value});
  };

  onChangeHandlerDescriptionRu = (value) => {
    this.setState({description_ru:value});
  };

  onChangeHandlerInstructionRu = (value) => {
    this.setState({instruction_ru:value});
  };

  componentDidMount = async () => {
    const  url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    fb.productsCollection.doc(segment).get().then(async res => {
      const dataObj = res.data();
      this.setState({
        id: dataObj.id,
        title: dataObj.title,
        title_ru: dataObj.title_ru || "",
        order: dataObj.order ? dataObj.order : this.state.order,
        ref: dataObj.ref,
        description: dataObj.description,
        instruction: dataObj.instruction || "",
        instruction_ru: dataObj.instruction_ru || "",
        description_ru: dataObj.description_ru || "",
        pictureUrl: dataObj.picture,
        created: dataObj.created,
        imageUploadedAt: dataObj.imageUploadedAt || "",
        pictureName: dataObj.pictureName || ""
      });
      if (dataObj.hasOwnProperty('pictures')) {
        this.setState({pictures: dataObj.pictures})
      }else{
        if (dataObj.hasOwnProperty('picture') && dataObj.picture) {
          this.setState({
            pictures: [{
              url: dataObj.picture,
              name: dataObj.pictureName || '',
              uploaded: dataObj.imageUploadedAt || ''
            }]
          })
        }
      }
    });
  }

  handleValidation = () => {
    let {title, description, instruction, title_ru, description_ru, instruction_ru, ref, order} = this.state;
    let errors = {};
    let formIsValid = true;

    //Title
    if(!title){
      formIsValid = false;
      errors["title"] = "Title is required";
    }

    //Title
    if(!title_ru){
      formIsValid = false;
      errors["title_ru"] = "Title ru is required";
    }

    if(!order){
      formIsValid = false;
      errors["order"] = "Order is required";
    }

    //Title
    if(!ref){
      formIsValid = false;
      errors["ref"] = "Ref is required";
    }

    //Instruction
    if(!instruction){
      formIsValid = false;
      errors["instruction"] = "Instruction is required";
    }

    //Instruction
    if(!instruction_ru){
      formIsValid = false;
      errors["instruction_ru"] = "Instruction ru is required";
    }

    //Description
    if(!description){
      formIsValid = false;
      errors["description"] = "Description is required";
    }

    //Description
    if(!description_ru){
      formIsValid = false;
      errors["description_ru"] = "Description ru is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this._isMounted = true;
    this.setState({disabled: true});
    if(this.handleValidation(this.state.errors)){
      if(this.state.forUpload.length > 0) {
        let pictures = this.state.pictures
        for (const file of this.state.forUpload) {
          let timestamp = createUnixTimestamp();
          await fb.storage.child(`products/${timestamp}-${file.name}`).put(file)
              .then(snapshot => snapshot.ref.getDownloadURL())
              .then((url) => {
                pictures.push({
                  url: url,
                  name: `products/${timestamp}-${file.name}`,
                  uploaded: firebase.firestore.Timestamp.fromDate(new Date()),
                  order: file.order,
                  type: file.type
                })
              });
        }
        this.setState({pictures: pictures}, () => {
          this.submitData();
        });
      }else{
        this.submitData(); 
      }
    }else{
      this.setState({
        disabled: false
      });
    }
  }

  submitData = async () => {
    await fb.productsCollection.doc(this.state.id).update({
      title:this.state.title,
      ref:this.state.ref,
      order:this.state.order,
      description:this.state.description,
      instruction:this.state.instruction,
      title_ru:this.state.title_ru,
      description_ru:this.state.description_ru,
      instruction_ru:this.state.instruction_ru,
      //picture:this.state.pictureUrl,
      //pictureName:this.state.pictureName,
      //imageUploadedAt:this.state.picture.name ? fb.timestamp.serverTimestamp() : this.state.imageUploadedAt,
      pictures: this.state.pictures
    }).then(snapShot => {
      addAdminLog(
        'product_updated',
        `Updated the product ${this.state.id}`,
        this.state.id
      )
      this.props.history.push('/admin/products')
    }).catch(err=> {
      this.setState({
        error:`Something went wrong-${err}`,
        disabled: false
      });
    });
  }

  handleSave = async () => {
    // event.preventDefault();
    this._isMounted = true;
    this.setState({disabledSave: true});
    if(this.handleValidation(this.state.errors)){
      if(this.state.forUpload.length > 0) {
        let pictures = this.state.pictures
        for (const file of this.state.forUpload) {
          let timestamp = createUnixTimestamp();
          await fb.storage.child(`products/${timestamp}-${file.name}`).put(file)
              .then(snapshot => snapshot.ref.getDownloadURL())
              .then((url) => {
                pictures.push({
                  url: url,
                  name: `products/${timestamp}-${file.name}`,
                  uploaded: firebase.firestore.Timestamp.fromDate(new Date()),
                  order: file.order,
                  type: file.type
                })
              });
        }
        this.setState({pictures: pictures}, () => {
          this.saveData();
          this.multiplePhotoField.current.clearField()
        });
      }else{
        this.saveData(); 
      }
    }else{
      this.setState({
        disabledSave: false
      });
    }
  }

  saveData = async () => {
    await fb.productsCollection.doc(this.state.id).update({
      title:this.state.title,
      ref:this.state.ref,
      order:this.state.order,
      description:this.state.description,
      instruction:this.state.instruction,
      title_ru:this.state.title_ru,
      description_ru:this.state.description_ru,
      instruction_ru:this.state.instruction_ru,
      //picture:this.state.pictureUrl,
      //pictureName:this.state.pictureName,
      //imageUploadedAt:this.state.picture.name ? fb.timestamp.serverTimestamp() : this.state.imageUploadedAt,
      pictures: this.state.pictures
    }).then(snapShot => {
      addAdminLog(
        'product_updated',
        `Updated the product ${this.state.id}`,
        this.state.id
      )
      this.setState({
        successMessage:"Product updated successfully!"
      });
      setTimeout(() => {
        this.setState({
          successMessage: '',
          disabledSave: false
        });
      }, 3000)
    }).catch(err=> {
      this.setState({
        error:`Something went wrong-${err}`,
        disabledSave: false
      });
    });
  }

  render() {
    console.log('render', this.state)
    let obj = {
      array: []
    };
    for (var l=0;l<100;l++){
      obj.array[l] = l+1;
    }
    let optionItems = obj.array.map((item) =>
      <option key={item}>{item}</option>
    );
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              {this.state.successMessage &&
                <Alert bsStyle="success">
                  <span>{this.state.successMessage}</span>
                </Alert>
              }
              <Card
                title="Edit Product"
                button={
                  <Link to={`/admin/products`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-12">
                        <MultiplePhotoField
                            ref={this.multiplePhotoField}
                            name="pictures"
                            pictures={this.state.pictures}
                            onChange={(event)=>this.onChangeHandler(event)} />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Ref</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ref"
                            name="ref"
                            value= {this.state.ref}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.ref}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Order</FormLabel>
                          <select
                            className= "form-control"
                            name= "order"
                            value={this.state.order} 
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Order</option>
                            {optionItems}
                          </select>
                          <span className="help-block">{this.state.errors.order}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Tabs defaultActiveKey="en" id="uncontrolled-tab-example">
                      <Tab eventKey="en" title="EN">
                        <Row>
                          <div className="col-md-12">
                            <FormGroup>
                              <FormLabel>Title</FormLabel>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Title"
                                name="title"
                                value= {this.state.title}
                                onChange = {(event) => this.onChangeHandler(event)}
                              />
                              <span className="help-block">{this.state.errors.title}</span>
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-12">
                            <FormGroup controlId="formControlsTextarea">
                              <FormLabel>Instruction</FormLabel>
                              <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.instruction}
                                onChange={ ( event, editor ) => this.onChangeHandlerInstruction(editor.getData())}
                                onReady={(editor) => {
                                  editor.plugins.get('FileRepository')
                                  .createUploadAdapter = (loader) => {
                                    return new UploadAdapter(loader)
                                  }
                                }}
                              />
                              <span className="help-block">{this.state.errors.instruction}</span>
                            </FormGroup>
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-12">
                              <FormGroup controlId="formControlsTextarea">
                                <FormLabel>Description</FormLabel>
                                <CKEditor
                                  editor={ ClassicEditor }
                                  data={this.state.description}
                                  onChange={ ( event, editor ) => this.onChangeHandlerDescription(editor.getData())}
                                  onReady={(editor) => {
                                    editor.plugins.get('FileRepository')
                                    .createUploadAdapter = (loader) => {
                                      return new UploadAdapter(loader)
                                    }
                                  }}
                                />
                                <span className="help-block">{this.state.errors.title}</span>
                              </FormGroup>
                            </div>
                          </Row>
                        </Tab>
                        <Tab eventKey="ru" title="RU">
                          <Row>
                            <div className="col-md-12">
                              <FormGroup>
                                <FormLabel>Title</FormLabel>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Title"
                                  name="title_ru"
                                  value= {this.state.title_ru}
                                  onChange = {(event) => this.onChangeHandler(event)}
                                />
                                <span className="help-block">{this.state.errors.title_ru}</span>
                              </FormGroup>
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-12">
                              <FormGroup controlId="formControlsTextarea">
                                <FormLabel>Instruction</FormLabel>
                                <CKEditor
                                  editor={ ClassicEditor }
                                  data={this.state.instruction_ru}
                                  onChange={ ( event, editor ) => this.onChangeHandlerInstructionRu(editor.getData())}
                                  onReady={(editor) => {
                                    editor.plugins.get('FileRepository')
                                    .createUploadAdapter = (loader) => {
                                      return new UploadAdapter(loader)
                                    }
                                  }}
                                />
                                <span className="help-block">{this.state.errors.instruction_ru}</span>
                              </FormGroup>
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-12">
                              <FormGroup controlId="formControlsTextarea">
                                <FormLabel>Description</FormLabel>
                                <CKEditor
                                  editor={ ClassicEditor }
                                  data={this.state.description_ru}
                                  onChange={ ( event, editor ) => this.onChangeHandlerDescriptionRu(editor.getData())}
                                  onReady={(editor) => {
                                    editor.plugins.get('FileRepository')
                                    .createUploadAdapter = (loader) => {
                                      return new UploadAdapter(loader)
                                    }
                                  }}
                                />
                                <span className="help-block">{this.state.errors.description_ru}</span>
                              </FormGroup>
                            </div>
                          </Row>
                        </Tab>
                      </Tabs>
                    <Row></Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info"
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading..' : 'Update'}
                    </Button>
                    <Link 
                      to={`#`} 
                      className="btn-fill pull-right btn btn-info libi-admin-btn"
                      onClick={() => this.handleSave()}
                      style={{marginRight: '18px'}}
                    > 
                    {this.state.disabledSave ? 'Loading..' : 'Save'} 
                    </Link>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditProduct;
