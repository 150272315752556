import React, { Component } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Table, 
  Tab,
  Tabs  
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card.jsx";
import { adminListThArray } from "../../variables/Variables.jsx"; 
import PageLoader from "../../components/Loader/PageLoader.jsx";
import {adminRolesValues} from "../../components/Helper/index";

//Datatable Modules
import $ from 'jquery'; 
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import "./Admin.css";
import api from "api.js";

class AdminList extends Component {

  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      adminList:[],
      inactiveAdminList:[],
      errors:{}
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#active-admin-datatable').DataTable({
      "bStateSave": true
    });
    $('#inactive-admin-datatable').DataTable({
      "bStateSave": true
    });
  }

  fetchData = async () => {
    this.setState({ isLoading: true });
    const admin = [];
    const inactiveAdminArr = [];
    const { data: admins } = await api.get('getAdmins')

    admins.forEach(i => {
      const adminObj = {
        id: i.id,
        name: i.name,
        email: i.email,
        adminRoles: i.adminRoles && Array.isArray(i.adminRoles) ? adminRolesValues(i.adminRoles).map(v => v.val).join(', ') : "",
        approved: i.approved,
        deleted: i.deleted
      }

      if (i.deleted) {
        inactiveAdminArr.push(adminObj)
      } else {
        admin.push(adminObj)
      }
    })

    this.setState({
      adminList: admin,
      inactiveAdminList: inactiveAdminArr,
      isLoading: false
    });
  }

  deleteUser = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await api.post('updateUser', { id, deleted: true})
      window.location.reload();
      // await this.fetchData(); 
    }
  }

  activeUser = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await api.post('updateUser', { id, deleted: false})
      window.location.reload();
      // await this.fetchData(); 
    }
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Admins"
                category="All Admin List"
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`masters/add`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"> Add Admin</Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Tabs defaultActiveKey="active-admins" id="uncontrolled-tab-example">
                    <Tab eventKey="active-admins" title="Present" >
                      <Table striped hover id="active-admin-datatable">
                        <thead>
                          <tr>
                            {adminListThArray
                              .filter(i => i !== "Approved Status")
                              .map((val, key) => <th key={key}>{val}</th>)
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.adminList.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{++key}</td>
                                <td>{val.adminRoles}</td>
                                <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                                <td>{val.email}</td>
                                {/* <td>{(val.approved === true) ? 'Approved' : 'Pending'}</td> */}
                                <td>
                                  <Link to={`masters/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;
                                  <Link to={`#`} onClick={() => {this.deleteUser(val.id)}}><i className="fa fa-times" /></Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Tab>
                    <Tab eventKey="inactive-admins" title="Deleted" >
                      <Table striped hover id="inactive-admin-datatable">
                        <thead>
                          <tr>
                            {adminListThArray
                              .filter(i => i !== "Approved Status")
                              .map((val, key) => <th key={key}>{val}</th>)
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.inactiveAdminList.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{++key}</td>
                                <td>{val.adminRoles}</td>
                                <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                                <td>{val.email}</td>
                                {/* <td>{(val.approved === true) ? 'Approved' : 'Pending'}</td> */}
                                <td>
                                  <Link to={`masters/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;
                                  <Link to={`#`} onClick={() => {this.activeUser(val.id)}}><i className="fa fa-times" /></Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AdminList;
