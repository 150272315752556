import React, { Component } from "react";
import { staticWebImages } from "../../components/Utils/images";
import { actionCodeSettings, langText } from "../../components/Utils/helper";
import * as fb from "../../firebase";
import Loading from "./Loading";

class SignUp extends Component {
  constructor(props){
    super(props);
    this.state = {
      email:"",
      errors:{},
      disabled: false,
      titleText: '',
      contentText: '',
      emailPlaceholder: '',
      submitLabel: '',
      isLoading: false,
    }
  }

  onChangeHandler = async (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'email'){
      this.setState({email:value});
    }
  };

  handleValidation = () => {
    let {email} = this.state;
    let errors = {};
    let formIsValid = true;

    if(!email){
      formIsValid = false;
      errors["email"] = "Email address is required";
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  async componentDidMount() {
    let langTextObj = await langText();
    this.setState({
      titleText: langTextObj.auth_email_title,
      contentText: langTextObj.auth_email_detail_signin,
      emailPlaceholder: langTextObj.auth_email_textfield_placeholder,
      submitLabel: langTextObj.auth_email_button_signin
    });
    let uid = localStorage.getItem('userId');
    if(uid){
      const user = await fb.usersCollection.doc(uid).get();
      if(user.exists) {
        //here needs to check the current status of existing user
        this.doRoute(user.data())
      }else{
        localStorage.removeItem('userId');
        localStorage.removeItem('userLogin');
      }
    }else{
      localStorage.removeItem('userId');
      localStorage.removeItem('userLogin');
    }
    this.setState({
      isLoading: true
    });
  }

  doRoute = (user_data) => {
    switch(user_data.status){
      case 'registered':
        this.props.history.push('/accept-privacy');
        break;

      case 'waiting_list':
        this.props.history.push('/invitation');
        break;

      case 'questionnaire1':
        this.props.history.push('/questionnaire')
        break;

      case 'deposit_vip':
        this.props.history.push('/deposit-vip')
        break;

      case 'waiting_diagnose':
        if(user_data.paymentPlanActive){
          this.props.history.push('/thankyou-diagnose')
        }else{
          this.props.history.push('/deposit')
        }
        break;

      case 'waiting_delivery':
          this.props.history.push('/after-payment')
        break;

      case 'waiting_payment':
        this.props.history.push('/skincare-plan')
        break;

      default:
        alert("Something went wrong! Please try after some time.")
        break;
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if(this.handleValidation()){
      this.setState({
        disabled: true
      });
      const {email} = this.state;
      fb.auth.sendSignInLinkToEmail(email, actionCodeSettings())
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        this.props.history.push('/popup')
      }).catch((error) => {
        this.setState({
          disabled: false
        });
        console.log('error.message', error.message)
      });
    }
  }

  render() {
    return (
        <section className="Subscription" style={{background: 'rgba(238, 221, 211, 0.6)'}}>
          {this.state.isLoading ? <>
              <span className="custm-angle">
                <span className="backBtn" onClick={this.props.history.goBack}><i className="fa fa-angle-left" aria-hidden="true" /></span>
                <span className="mobileTitle">{this.state.checkTitle}</span>
              </span>
          <div className="container_5-fluid">
            <div className="row_5">
              <div className="col_5-lg-6 col_5-12 hidden-xs hidden-sm" style={{padding: 0}}>
                <div className="Subscription_cust" style={{background: 'transparent'}}>
                  <img src={staticWebImages.depost} alt="" style={{width:"100%",maxWidth:"530px"}}/>
                </div>
              </div>
              <div className="col_5-lg-6 col_5-12">
                <div className="subs_mainBox">
                  <div className="subsRightBoxWrapper">
                    <div className="subsRightBox">
                      <div className="main-logo">
                        <img src={staticWebImages.logo} alt="" className="img-responsive center-block web-logo-hide" />
                      </div>
                      <h2 className="main_txt_sign text-center" style={{paddingBottom: '20px'}}>{this.state.titleText}</h2>
                      <p className="shiping_para">{this.state.contentText}</p>
                      <div className="form-section_add">
                        <form onSubmit={this.handleSubmit}>
                          <div className="row">
                            <div className="col-md-8 col-lg-8 col-sm-8 m-0 p-0">
                              <input
                                  type="text"
                                  className="form-control text-Custom"
                                  placeholder={this.state.emailPlaceholder}
                                  name="email"
                                  value={this.email}
                                  onChange={this.onChangeHandler}
                              />
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-4 m-0 p-0">
                              <input
                                  type="submit"
                                  className=" form-control custom-btn-web"
                                  disabled={this.state.disabled}
                                  value={this.state.disabled ? 'Loading...' : this.state.submitLabel}
                              />
                            </div>
                          </div>
                        </form>
                        {
                          this.state.errors && this.state.errors.email &&
                          <p className="alert">
                            <img src={staticWebImages.alertCircle}/> {this.state.errors.email }
                          </p>
                        }
                      </div>
                    </div>
                  </div>
                  <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
                </div>
              </div>
            </div>
          </div>
          </> : <Loading />}
        </section>
    );
  }
}

export default SignUp;
