import React, { Component } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'

class TypeFormPhone extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:""
        }
    }

    onChangeHandler = async (event) => {
        if(typeof event !== 'undefined' && isValidPhoneNumber(event)){
            this.props.onChange(event)
        }else{
            this.props.onError('That phone number isn\'t valid')
            this.props.onChange(null)
        }
    }

    render() {
        return (
            <PhoneInput
                defaultCountry="GB"
                placeholder="074 001 234 56"
                onChange={this.onChangeHandler} />
        );
    }
}

export default TypeFormPhone;
