import React, { Component } from "react";
import { Container, Row, Col, Table, Alert, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import { productsListThArray } from "variables/Variables.jsx";
import * as fb from "../../firebase";
import {
  staticImages,
  removeHTMLTags
} from "components/Helper/index";
//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

class ProductList extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      productList:[],
      ru_productList:[],
      staticImages: {},
      errors:{},
      error:""
    }
  }

  componentDidMount = async () => {
    const si = await staticImages()
    this.setState({staticImages:si})
    await this.fetchData();
    $('#list-datatable').DataTable({
      "order": [[ 0, "asc" ]],
      "bStateSave": true,
    }); 
    
    $('#oru-datatable').DataTable({
      "order": [[ 0, "asc" ]],
      "bStateSave": true,
    }); 
  }

  
  
  fetchData = async() => {
    try {
      this.setState({isLoading:true});
      const data = await fb.productsCollection.get();
      const productsArr = [];
      data.docs.forEach(doc=> {
        const dataObj = doc.data();
        productsArr.push({
          id:dataObj.id,
          title:dataObj.title,
          title_ru:dataObj.title_ru || "",
          order:dataObj.order,
          ref:dataObj.ref,
          description:dataObj.description,
          description_ru:dataObj.description_ru || "",
          picture:dataObj.picture,
          created:dataObj.created,
          pictures: dataObj.pictures || []
        });
      })
      this.setState({
        productList:productsArr,
        isLoading:false
      });
    }catch(err){
      this.setState({error:err.message})
    }
  }

  deleteProduct = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await fb.productsCollection.doc(id).delete().then(() => {
        this.fetchData();
      }).catch(function(error) {
        this.setState({error:error.message})
      });
    }
  }

  

  render() {
    // console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title=""
                category="All Products List"
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`products/add`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"> Add Product</Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Tabs defaultActiveKey="en" id="uncontrolled-tab-example">
                    <Tab eventKey="en" title="EN">
                      <Table striped hover id="list-datatable">
                        <thead>
                          <tr>
                            {/*productsListThArray.map((val, key) => {
                              return  val !== RefDisplayNone && <th key={key}>{val}</th> 
                            })*/}
                            <th style={{display:"none"}}>RefNoneForSorting</th>
                            <th>Ref</th>
                            <th>Order</th>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.productList.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td style={{display:"none"}}>{Number(val.ref.substring(1))}</td>
                                <td>{val.ref}</td>
                                <td>{val.order}</td>
                                <td><img src={(val.hasOwnProperty('pictures') && val.pictures.length > 0) ? val.pictures[0].url : this.state.staticImages.placeholderImage} alt="" style={{height:"50px", width:"50px"}}/></td>
                                <td>{val.title}</td>
                                <td>{removeHTMLTags(val.description, 50)}</td>
                                <td> 
                                  <Link to={`products/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp; 
                                  <Link to={`#`} onClick={() => {this.deleteProduct(val.id)}}><i className="fa fa-times" /></Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Tab>
                    <Tab eventKey="ru" title="RU">
                      <Table striped hover id="oru-datatable" style={{width:"100%"}}>
                        <thead>
                          <tr>
                            {/*productsListThArray.map((val, key) => {
                              return  val !== RefDisplayNone && <th key={key}>{val}</th> 
                            })*/}
                            <th style={{display:"none"}}>RefNoneForSorting</th>
                            <th>Ref</th>
                            <th>Order</th>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                          <tbody>
                            {this.state.productList.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td style={{display:"none"}}>{Number(val.ref.substring(1))}</td>
                                  <td>{val.ref}</td>
                                  <td>{val.order}</td>
                                  <td><img src={(val.hasOwnProperty('pictures') && val.pictures.length > 0) ? val.pictures[0].url : this.state.staticImages.placeholderImage} alt="" style={{height:"50px", width:"50px"}}/></td>
                                  <td>{val.title_ru}</td>
                                  <td>{removeHTMLTags(val.description_ru, 50)}</td>
                                  <td> 
                                    <Link to={`products/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp; 
                                    <Link to={`#`} onClick={() => {this.deleteProduct(val.id)}}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ProductList;
