import api from "api";
import defaultAvtar from "../../assets/img/default-avatar.png";
import placeholderImage from "../../assets/img/image-placeholder.png";
import * as fb from "../../firebase";


export const timeConverter = (t) => {     
  var a = new Date(t * 1000);
  var today = new Date();
  var yesterday = new Date(Date.now() - 86400000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  if (a.setHours(0,0,0,0) === today.setHours(0,0,0,0))
    return 'Today, ' + hour + ':' + min;
  else if (a.setHours(0,0,0,0) === yesterday.setHours(0,0,0,0))
    return 'Yesterday, ' + hour + ':' + min;
  else if (year === today.getFullYear())
    return date + ' ' + month + ', ' + hour + ':' + min;
  else
    return date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;
}

export const staticImages = async () => {
  const adminDefaultAvatar = await fb.metaCollection.doc('chat_admins').get().then((doc) => {
    if (!doc.exists) return false;
    return doc.data().defaultAdminImg || "https://firebasestorage.googleapis.com/v0/b/production-lnd.appspot.com/o/chat-admin%2F1667289177-LajosFarkas.jpg?alt=media&token=49be2a8b-f022-4b0b-9f10-6505faf97580";
  });
  return {
    defaultAvtar: defaultAvtar,
    placeholderImage: placeholderImage,
    defaultAdminAvtar: adminDefaultAvatar
  }
}

export const createUnixTimestamp = () => {     
  return Math.floor(Date.now() / 1000); 
}

export const  convertUnixTimestamp = (unixtimestamp) => {
 
  // Months array
  var months_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
 
  // Convert timestamp to milliseconds
  var date = new Date(unixtimestamp*1000);
 
  // Year
  var year = date.getFullYear();
 
  // Month
  var month = months_arr[date.getMonth()];
 
  // Day
  var day = date.getDate();
 
  // Hours
  var hours = date.getHours();
 
  // Minutes
  var minutes = "0" + date.getMinutes();
 
  // Seconds
  var seconds = "0" + date.getSeconds();
 
  // Display date time in MM-dd-yyyy h:m:s format
  var convdataTime = month+'-'+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  
  return convdataTime;
  
 }

export const  convertUnixTimestampToDate = (unixtimestamp) => {
 
  // Months array
  var months_arr = ['Jan','Feb','Mar','Apr','May','June','Jul','Aug','Sep','Oct','Nov','Dec'];
 
  // Convert timestamp to milliseconds
  var date = new Date(unixtimestamp*1000);
 
  // Year
  var year = date.getFullYear();
 
  // Month
  var month = months_arr[date.getMonth()];
 
  // Day
  var day = date.getDate();

  // Display date time in DD-MM-YYYY format
  var convdataTime = day+' '+month+' '+year;
  return convdataTime;
}

export const convertFirebaseTimestampToDate = (serverTimestamp) => {
  return serverTimestamp.toDate().toDateString()
}

export const checkPaymentStatusToShown = () => {
  return [
    "waiting_payment", "waiting_delivery", "active", "Canceled"
  ]
}


function accountStatusArr(){
  return [
    { 
      key:"registered",
      val: "Registered"
    },
    { 
      key:"waiting_list",
      val: "Waiting List"
    },
    { 
      key:"questionnaire1",
      val: "Questionnaire1"
    },
    // {
    //   key:"deposit_vip",
    //   val: "Deposit VIP"
    // },
    { 
      key:"waiting_diagnose",
      val: "Waiting Diagnosis"
    },
    { 
      key:"waiting_payment",
      val: "Diagnosis Sent"
    },
    { 
      key:"waiting_delivery",
      val: "Waiting Delivery"
    },
    { 
      key:"active",
      val:"Active"
    },
    { 
      key:"lost_customer",
      val:"Lost Customer"
    }
  ]
}

function packageStatusArr(){
  return [
    { 
      key:"waiting_shipment",
      val: "To be shipped"
    },
    { 
      key:"shipped",
      val: "Transit"
    },
    { 
      key:"delivered",
      val: "Delivered"
    },
    { 
      key:"confirmed",
      val: "Confirmed"
    },
  ]
}

export const  staticRuleArr = () => {
  return [
    { 
      key:"e_m_rule_description",
      val: "M, E"
    },
    { 
      key:"e_m_rule_description",
      val: "M&E"
    },
    { 
      key:"m_rule_description",
      val: "M"
    },
    { 
      key:"e_rule_description",
      val: "E"
    },
    { 
      key:"e_rod_rule_description",
      val: "E EoD"
    },
    { 
      key:"e_2of3_days_rule_description",
      val: "E 2of3 days"
    },
    { 
      key:"eoe_alt_75_rule_description",
      val: "EoE (alt w/75)"
    },
    { 
      key:"eoe_alt_bc_rule_description",
      val: "EoE (alt w/BC)"
    },
    { 
      key:"e_every_third_day_no_bc_rule_description",
      val: "E Every third day (no BC)"
    },
    { 
      key:"1_pw_rule_description",
      val: "1pW (2xBottles)"
    },
    { 
      key:"2_am_end_wk_2_4_rule_description",
      val: "2aM (end wk2,4)"
    },
    { 
      key:"e_5_7_days_per_week_alt_75_rule_description",
      val: "E 5/7 days per week (alt 75)"
    },
    { 
      key:"2_pw_alt_w_bc",
      val: "2pW (alt w/BC)"
    },
    { 
      key:"e_6_7_days_per_week_alt_75_rule_description",
      val: "E 6/7 days per week (alt 75)"
    },
    { 
      key:"1_pw_alt_w_bc",
      val: "1pW (alt w/BC)"
    },
    {
      key:"e_3x_rule_description",
      val: "E 3xW"
    },
    {
      key:"m_3x_rule_description",
      val: "M 3xW"
    },
    {
      key:"e_3x_alt_bc_rule_description",
      val: "E 3xW (alt BC)"
    },
    {
      key:"m_3x_alt_bc_rule_description",
      val: "M 3xW (alt BC)"
    },
    {
      key:"e_4x_alt_75_rule_description",
      val: "E 4xW (alt 75)"
    },
    {
      key:"m_4x_alt_75_rule_description",
      val: "M 4xW (alt 75)"
    },
  ]
}

export const packageStatusLabel = (key) => {
  let item = packageStatusArr().find(item => item.key === key);
  if(item){
    return item.val;
  }
}

export const userAccountStatus = () => {
  return accountStatusArr();
}

export const getUserStatusOption = (key) => {
  let item = accountStatusArr().find(item => item.key === key);
  if(item){
    return item.val;
  }
}

export const removeHTMLTags = (string, length=0) => {
  let text = string.replace(/<[^>]+>/g, '');
  if(length > 0){
    return text.substring(0, length);
  }
  return text;
}

export const treatmentTimeCases = () => {
  return [
    { 
      key:"1 month",
      val: "Review the plan after 1 month, usually the first",
      rrule:"FREQ=MONTHLY;BYSETPOS=1;BYDAY=SU,MO,TU,WE,TH,FR,SA;INTERVAL=1"
    },
    { 
      key:"1 month - add 75, alt with BC",
      val: "After 1 month, add product 75 and use Evenings alternating with the base cream",
      rrule:""
    },
    { 
      key:"1pW (2xBottles)",
      val: "Once per week (plan has 2 bottles per month instead of usual 1) [Evening implied]",
      rrule:"FREQ=WEEKLY;BYDAY=MO,TU;INTERVAL=1"
    },
    { 
      key:"1pW (alt w/BC)",
      val: "Once per week (alternate with Base Cream) [Evening implied]",
      rrule:"FREQ=WEEKLY;BYDAY=MO,TU;INTERVAL=1"
    },
    { 
      key:"2aM (end wk2,4)",
      val: "Twice a month at the end of the 2nd and 4th week  [Evening implied]",
      rrule:"FREQ=MONTHLY;BYSETPOS=3;BYDAY=SU;INTERVAL=1"
    },
    { 
      key:"2pW (alt w/BC)",
      val: "Twice per week (alternate with Base Cream) [Evening implied]",
      rrule:"FREQ=WEEKLY;BYDAY=MO,FR;INTERVAL=1"
    },
    { 
      key:"E",
      val: "Evening",
      rrule:"FREQ=DAILY;INTERVAL=1"
    },
    { 
      key:"E 1xW (not w/56)",
      val: "Evening one time per week, and not to be used with 56",
      rrule:"FREQ=DAILY;INTERVAL=7"
    },
    { 
      key:"E 2of3 days",
      val:"Evening 2 of every 3 days",
      rrule:"FREQ=WEEKLY;BYDAY=MO,TU,TH,FR;INTERVAL=1"
    },
    { 
      key:"E 2of3 days (alt 75)",
      val:"Evening 2 of every 3 days (alternate with product 75)",
      rrule:"FREQ=WEEKLY;BYDAY=MO,TU,TH,FR;INTERVAL=1"
    },
    { 
      key:"E 2xW",
      val:"Evening two times per week",
      rrule:"FREQ=DAILY;INTERVAL=3"
    },
    { 
      key:"E 5/7 days per week (alt 75)",
      val:"Evening 5 of 7 days per week (alternate with product 75)",
      rrule:"FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR;INTERVAL=1	"
    },
    { 
      key:"E 6/7 days per week (alt 75)",
      val:"Evening 6 of 7 days per week (alternate with product 75)",
      rrule:"FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR,SA;INTERVAL=1"
    },
    { 
      key:"E EoD",
      val:"Evening every other day (most likely no longer to be used)",
      rrule:"FREQ=DAILY;INTERVAL=2"
    },
    { 
      key:"E Every third day (no BC)",
      val:"Evening every third day (do not use the Base Cream on those days)",
      rrule:"FREQ=DAILY;INTERVAL=3"
    },
    { 
      key:"E T Zone",
      val:"Evening T Zone only",
      rrule:"FREQ=DAILY;INTERVAL=1"
    },
    { 
      key:"EoE (alt w/75)",
      val:"Every other Evening (alternate with product 75)",
      rrule:"FREQ=DAILY;INTERVAL=2"
    },
    { 
      key:"EoE (alt w/BC)",
      val:"Every other Evening (alternate with the base cream)",
      rrule:"FREQ=DAILY;INTERVAL=2"
    },
    { 
      key:"EoE (alt w/BC) T Zone",
      val:"Every other Evening on the T Zone (alternate with the base cream)",
      rrule:"FREQ=DAILY;INTERVAL=2"
    },
    { 
      key:"M",
      val:"Morning",
      rrule:"FREQ=DAILY;INTERVAL=1"
    },
    { 
      key:"M, E",
      val:"Morning and Evening",
      rrule:"FREQ=DAILY;INTERVAL=1"
    },
    { 
      key:"BC",
      val:"Base cream - creams 61-66",
      rrule:""
    },
    {
      key:"E 3xW",
      val:"Evening 3 times per week",
      rrule:""
    },
    {
      key:"M 3xW",
      val:"Morning 3 times per week",
      rrule:""
    },
    {
      key:"E 3xW (alt BC)",
      val:"Evening 3 times per week, alternate with BC",
      rrule:""
    },
    {
      key:"M 3xW (alt BC)",
      val:"Morning 3 times per week, alternate with BC",
      rrule:""
    },
    {
      key:"E 4xW (alt 75)",
      val:"Evening 3 times per week, alternate with 75",
      rrule:""
    },
    {
      key:"M 4xW (alt 75)",
      val:"Morning 3 times per week, alternate with 75",
      rrule:""
    }
  ]
}

export const notificationGroup = () => {
  return [
    {
      key:"news",
      val:"All users",
    }, 
    {
      key:"registered",
      val:"Registered",
    },
    {
      key:"waiting_list",
      val:"Waiting List",
    },
    {
      key:"questionnaire1",
      val:"Intro Questionnaire",
    },
    {
      key:"waiting_diagnose",
      val:"Waiting Diagnosis",
    }, 
    {
      key:"waiting_payment",
      val:"Diagnosis Sent",
    },
    {
      key:"waiting_delivery",
      val:"Waiting Delivery",
    },
    {
      key:"active",
      val:"Active",
    },
    {
      key:"active_full",
      val:"Active Full",
    },
    {
      key:"active_starter",
      val:"Active Starter",
    }
  ]
}

export const typeFormAgeGroup = () => {
  return [
    { 
      label:"0-15",
    },
    { 
      label:"16-29",
    },
    { 
      label:"30-49"
    },
    { 
      label:"50+"
    }
  ]
}

function rawAdminRoles(){
  return [
    {
      key:"masters",
      val: "Admins"
    },
    {
      key:"experts",
      val: "Experts"
    },
    {
      key:"dashboard",
      val: "Dashboard"
    },
    {
      key:"users",
      val: "Customers"
    },
    {
      key:"assessment",
      val: "Assessment"
    },
    {
      key:"invited",
      val: "Invite Codes"
    },
    {
      key:"chat",
      val: "Chats"
    },
    {
      key:"treatment",
      val: "Treatments"
    },
    {
      key:"af_treatment",
      val: "AF Treatments"
    },
    {
      key:"products",
      val:"Products"
    },
    {
      key:"meta",
      val:"Meta"
    },
    {
      key:"template",
      val:"Templates"
    },
    {
      key:"stripe-products",
      val:"Stripe Products"
    },
    {
      key:"stripe-shopify",
      val:"Stripe/Shopify"
    },
    {
      key:"chat-admin",
      val:"Chat Admin"
    },
    {
      key:"administrative",
      val:"Administrative"
    },
    {
      key:"skincare",
      val:"Skincare"
    },
    { 
      key:"emails",
      val:"Emails"
    },
    {
      key:"shipment",
      val:"Shipment"
    },
    {
      key:"event",
      val:"Event"
    },
    {
      key:"admin-logs",
      val:"Admin Logs"
    },
    {
      key:"payment",
      val:"Payment"
    },
    {
      key:"notifications",
      val:"Notifications"
    },
    {
      key:"rules",
      val:"Rules"
    }
  ]
}


export const adminRoles = () => {
  return rawAdminRoles();
}

export const adminRolesValues = (roles) => {
  let arr = [];
  roles.forEach(role=> {
    let item = adminRoles().find(item => item.key === role);
    if(item){
      arr.push({
        key:item.key,
        val:item.val
      })
    }
  })
  return arr;
}

function toDateTime(secs) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}

export const convertFirebseSecondsToDate = (seconds) => {
  try {
    const today = toDateTime(seconds);
    let t = new Intl.DateTimeFormat('en-ZA', {year: 'numeric', day: '2-digit', month: 'short'}).format(today);
    return t;
  } catch(e) {
    console.log("convertFirebseSecondsToDate",e, seconds);
    return new Date(seconds * 1000);
  }
}

export const convertMilisecondsToDate = (ms) => {
  try {
    const today = toDateTime(ms / 1000);
    let t = new Intl.DateTimeFormat('en-ZA', {year: 'numeric', day: '2-digit', month: 'short'}).format(today);
    return t;
  } catch(e) {
    console.log("convertFirebseSecondsToDate",e, ms);
    return new Date(ms);
  }
}

export const convertFirebseSecondsToDateShorted = (seconds) => {
  const today = toDateTime(seconds);
  let t = new Intl.DateTimeFormat('en-ZA', {year: 'numeric', day: '2-digit', month: '2-digit'}).format(today);
  return t;
}

export const convertFirebseSecondsToTime = (seconds) => {
  const today = toDateTime(seconds);
  let t = new Intl.DateTimeFormat('en-ZA', {hour: '2-digit', minute:'2-digit'}).format(today);
  return t;
}

export const arrayChunks = (itemArr) => {
  var perChunk = 10 // items per chunk    
  var inputArray = itemArr;

  var result = inputArray.reduce((resultArray, item, index) => { 
  const chunkIndex = Math.floor(index/perChunk)

  if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
  }
  resultArray[chunkIndex].push(item)
  return resultArray
  }, [])
  return result;
}

export const replaceDiagnosisTags = (text) => {
  const textwithoutag = text.replace('/3/</p>', '</p>/3/').replace('<p>/2/', '/2/<p>').replace('/1/</p> /1/', '/1/').replace('<p>/1/', '/1/').replace('/1/<p>', '/1/').replace('</p>/1/', '/1/').replace('/1/</p>', '/1/')
  return textwithoutag;
}


export const eveningProducts = () => {
  return [
    "1 month", 
    "1 month - add 75", 
    "alt with BC", 
    "1pW (2xBottles)", 
    "1pW (alt w/BC)", 
    "2aM (end wk2,4)", 
    "2pW (alt w/BC)", 
    "E", 
    "E 1xW (not w/56)", 
    "E 2of3 days", 
    "E 2of3 days (alt 75)", 
    "E 2xW", 
    "E 5/7 days per week (alt 75)", 
    "E 6/7 days per week (alt 75)", 
    "E EoD", 
    "E Every third day (no BC)", 
    "E T Zone", 
    "EoE (alt w/75)", 
    "EoE (alt w/BC)", 
    "EoE (alt w/BC) T Zone", 
    "BC",
    "M, E",
    "M&E",
    "E 3xW",
    "E 3xW (alt BC)",
    "E 4xW (alt 75)"
  ]
}

export const morningProducts = () => {
  return ["M", "M&E", "M, E","M 3xW","M 3xW (alt BC)","M 4xW (alt 75)"]
}

// start event
function eventTypeArr(){
  return [
    { 
      key:"peeling_notification",
      val: "Peeling Notification"
    },
    { 
      key:"no_activities",
      val: "No Activities"
    }
  ]
}

export const eventTpeDropDown = () => {
  return eventTypeArr();
}

export const getSelectedEventType = (key) => {
  let item = eventTypeArr().find(item => item.key === key);
  if(item){
    return item.val;
  }
}
//end

// start task
function eventTaskTypeArr(){
  return [
    { 
      key:"one_time",
      val: "One Time"
    },
    { 
      key:"repetitive",
      val: "Repetitive"
    }
  ]
}

export const eventTaskDropDown = () => {
  return eventTaskTypeArr();
}

export const getSelectedEventTask = (key) => {
  let item = eventTaskTypeArr().find(item => item.key === key);
  if(item){
    return item.val;
  }
}

export const addAdminLog = async (title, description, editedId, customerId, customerName) => {
  const admin = fb.firebase.auth().currentUser

  const {data: userData} = await api.get('userByID', {params: {userId: admin.uid}})

  api.post('createAdminLog', {
    userId: admin.uid,
    userName: userData ? userData.name : '',
    userEmail: admin.email,
    title,
    description,
    editedId: editedId || '',
    customerId: customerId || '',
    customerName: customerName || '',
  })
}
//end




