import React, { Component } from "react";
import { Container } from "react-bootstrap";
import version from "version";
class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Container fluid>
          <p className="copyright footer-brand">
            &copy; {new Date().getFullYear()}{" "}
              Libi&Daughters - All Rights Reserved - v.{version} ~ {process.env.REACT_APP_ENV}
          </p>
        </Container>
      </footer>
    );
  }
}

export default Footer;
