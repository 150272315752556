import React, {Component} from "react";
import {Container, Row, Col, Table, Alert} from "react-bootstrap";
import {Link} from "react-router-dom";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import * as fb from "../../firebase";
import {
  removeHTMLTags
} from "components/Helper/index";
//Datatable Modules
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

class RulesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      rules: [],
      errors: {},
      error: ""
    }
  }

  componentDidMount = async () => {
    await this.fetchData()
    $('#rules-table').DataTable()
  }

  fetchData = async () => {
    try {
      this.setState({isLoading: true});
      const data = await fb.rulesCollection.get();
      const rulesArr = [];
      data.docs.forEach(doc => {
        const dataObj = doc.data();
        rulesArr.push({
          id: dataObj.id,
          product: dataObj.product,
          prescription: dataObj.prescription,
          time: dataObj.time,
          priority: dataObj.priority,
          frequency: dataObj.frequency,
          area: dataObj.area,
        });
      })
      this.setState({
        rules: rulesArr,
        isLoading: false
      });
    } catch (err) {
      this.setState({error: err.message})
    }
  }

  deleteRule = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await fb.rulesCollection.doc(id).delete().then(() => {
        this.fetchData();
      }).catch(function (error) {
        this.setState({error: error.message})
      });
    }
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
              <Alert bsStyle="danger">
                <span>{this.state.error}</span>
              </Alert>
              }
              <Card
                title=""
                category="All Rules List"
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`rules/add`}
                        className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"> Add Rule</Link>
                }
                loader={this.state.isLoading && <PageLoader/>}
                content={
                  <Table striped hover id="rules-table">
                    <thead>
                      <tr>
                        {/* <th style={{display: "none"}}>RefNoneForSorting</th> */}
                        <th>Product</th>
                        <th>Prescription</th>
                        <th>Time</th>
                        <th>Priority</th>
                        <th>Frequency</th>
                        <th>Area</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rules.map((val) => (
                        <tr key={val.id}>
                          {/* <td style={{display: "none"}}>{Number(val.product.substring(1))}</td> */}
                          <td>{val.product}</td>
                          <td>{removeHTMLTags(val.prescription, 50)}</td>
                          <td>{val.time}</td>
                          <td>{val.priority}</td>
                          <td>{val.frequency}</td>
                          <td style={{maxWidth: '170px'}}>
                            {val.area.map((item, index) => (
                              <>{item.val} {val.area.length - 1 !== index && ', '}</>
                            ))}
                          </td>
                          <td>
                            <Link to={`rules/edit/${val.id}`}><i className="fa fa-edit"/></Link>&nbsp;&nbsp;
                            <Link to={`#`} onClick={() => {
                              this.deleteRule(val.id)
                            }}><i className="fa fa-times"/></Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default RulesList;
