import React, { useState } from 'react';
import './User.css'
import * as fb from '../../firebase'
import firebase from "firebase";
import { Alert } from "react-bootstrap";
import { useLayoutEffect } from 'react';
import axios from 'axios';
import { addAdminLog } from 'components/Helper';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UploadAdapter from '../../components/image-adaptor/UploadAdapter';
import api from 'api';

export const assessmentFields = [
  {
    title: 'Questionnaire passed',
    key: 'questionnaire_passed',
    variants: [
      {
        label: 'Online',
        value: 'online'
      },
      {
        label: 'Offline',
        value: 'offline'
      },
    ]
  },
  {
    title: 'Sex',
    key: 'sex',
    variants: [
      {
        label: 'Male',
        value: 'male'
      },
      {
        label: 'Female',
        value: 'female'
      },
      {
        label: 'Other',
        value: 'other'
      },
    ]
  },
  {
    title: 'Age range',
    key: 'age',
    variants: [
      {
        label: '9 – 15',
        value: '9–15'
      },
      {
        label: '16 – 29',
        value: '16–29'
      },
      {
        label: '30 – 49',
        value: '30–49'
      },
      {
        label: '50+',
        value: '50+'
      }
    ]
  },
  {
    title: 'Skin type',
    key: 'skin_type',
    variants: [
      {
        label: 'Dry',
        value: 'dry'
      },
      {
        label: 'Oily',
        value: 'oily'
      },
      {
        label: 'Normal',
        value: 'normal'
      },
      {
        label: 'Combined',
        value: 'combined'
      }
    ]
  },
  {
    title: 'Acne',
    key: 'acne',
    variants: [
      {
        label: 'Severe',
        value: 'severe'
      },
      {
        label: 'Moderate',
        value: 'moderate'
      },
      {
        label: 'Mild',
        value: 'mild'
      },
      {
        label: 'None',
        value: 'none'
      }
    ]
  },
  {
    title: 'Acne problem area',
    key: 'acne_problem_area',
    multi: true,
    variants: [
      {
        label: 'Face',
        value: 'face'
      },
      {
        label: 'Chest',
        value: 'chest'
      },
      {
        label: 'Back',
        value: 'back'
      },
      {
        label: 'Other',
        value: 'other'
      }
    ]
  },
  {
    title: 'Rosacea',
    key: 'rosacea',
    variants: [
      {
        label: 'Severe',
        value: 'severe'
      },
      {
        label: 'Moderate',
        value: 'moderate'
      },
      {
        label: 'Mild',
        value: 'mild'
      },
      {
        label: 'None',
        value: 'none'
      }
    ]
  },
  {
    title: 'Seborrhea',
    key: 'seborrhea',
    variants: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      }
    ]
  },
  {
    title: 'Seborrhea problem area',
    key: 'seborrhea_problem_area',
    multi: true,
    variants: [
      {
        label: 'Scalp',
        value: 'scalp'
      },
      {
        label: 'Face',
        value: 'face'
      },
      {
        label: 'Chest',
        value: 'chest'
      },
      {
        label: 'Groin',
        value: 'groin'
      },
    ]
  },
  {
    title: 'Hyperpigmentation',
    key: 'hyperpigmentation',
    variants: [
      {
        label: 'Melasma',
        value: 'melasma'
      },
      {
        label: 'Solar or age spots',
        value: 'solar_or_age_spots'
      },
      {
        label: 'Post-inflammatory',
        value: 'post_inflammatory'
      },
      {
        label: 'None',
        value: 'none'
      },
    ]
  },
  {
    title: 'Hyperpigmentation problem area',
    key: 'hyperpigmentation_problem_area',
    multi: true,
    variants: [
      {
        label: 'Face',
        value: 'face'
      },
      {
        label: 'Body',
        value: 'body'
      },
    ]
  },
  {
    title: 'Ageing',
    key: 'ageing',
    variants: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
    ]
  },
  {
    title: 'Dark circles',
    key: 'dark_circles',
    variants: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
    ]
  },
  {
    title: 'Allergy',
    key: 'allergy',
    variants: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
    ]
  },
  {
    title: 'Sensitivity',
    key: 'sensitivity',
    variants: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
    ]
  },
  {
    title: 'Box composed by L&D',
    key: 'box_composed_by_ld',
    variants: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
    ]
  },
  {
    title: 'Subscription frequency recommended (days)',
    key: 'subscription_frequency_recommended',
    variants: [
      {
        label: '30',
        value: '30'
      },
      {
        label: '45',
        value: '45'
      },
    ]
  },
]

const UserAssessmentForm = ({
  userId, 
  userObj,
  answerId, 
  initialState = {
    ['box_composed_by_ld']: 'yes',
    ['questionnaire_passed']: 'offline'
  },
  isDisabled
}) => {
  const [formState, setFormState] = useState({})
  const [validateError, setValidateError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  
  const {sex, age, skin_type, ...rest} = initialState
  const notEmpty = isDisabled && Object.keys(rest).length

  const checkDisable = (key) => {
    if (key === 'acne_problem_area') {
      return !formState.hasOwnProperty('acne') || formState.acne === 'none'
    }
    if (key === 'seborrhea_problem_area') {
      return !formState.hasOwnProperty('seborrhea') || formState.seborrhea === 'no'
    }
    if (key === 'hyperpigmentation_problem_area') {
      return !formState.hasOwnProperty('hyperpigmentation') || formState.hyperpigmentation === 'none'
    }
  }

  const changeHandler = (key, value) => {
    if (key === 'acne' && value === 'none') {
      setFormState({ ...formState, acne_problem_area: [], [key]: value})
    } else if (key === 'seborrhea' && value === 'no') {
      setFormState({ ...formState, seborrhea_problem_area: [], [key]: value })
    } else if (key === 'hyperpigmentation' && value === 'none') {
      setFormState({ ...formState, hyperpigmentation_problem_area: [], [key]: value })
    } else if (key === 'acne_problem_area' || key === 'seborrhea_problem_area' || key === 'hyperpigmentation_problem_area') {
      const newState = Array.isArray(formState[key]) 
        ? formState[key].includes(value)
          ? formState[key].filter(i => i !== value)
          : [...formState[key], value]
        : [value]
      setFormState({ ...formState, [key]: newState})
    }  else {
      setFormState(prev => ({ ...prev, [key]: value }))
    }
  }

  const submitHandler = async ev => {
    ev.preventDefault()
    setIsLoading(true)
    const err = assessmentFields.some(field => !formState.hasOwnProperty(field.key))

    if (err) {
      setValidateError(true)
      setIsLoading(false)
      return
    }

    if (!notEmpty) {
      try {
        const assessmentRef = fb.assessmentFormsCollection.doc()
        await assessmentRef.set({
          ...formState,
          userId,
          answerId: answerId && answerId !== '?type=t' ? answerId : '',
          expertId: fb.firebase.auth().currentUser.uid,
          time: firebase.firestore.Timestamp.fromDate(new Date())
        })

        const res = await api.get(`s/parseAF/${assessmentRef.id}`)
        addAdminLog(
          'treatment_created', 
          `Created the treatment ${res.data} for the user ${userId}`, 
          res.data,
          userId,
          userObj.firstName + ' ' + userObj.lastName
        )

        await fb.usersCollection.doc(userId).collection('TreatmentRequests')
          .get().then((snap) => {
            if (snap.empty) {
              fb.usersCollection.doc(userId).collection('TreatmentRequests').add({
                treatmentRequest: firebase.firestore.Timestamp.fromDate(new Date()),
                treatmentRequestDone: false
              })
            }
          })

        setValidateError(false)
        setIsSuccess(true)
        setTimeout(() => {
          setIsSuccess(false)
          const isReview = window.location.href.includes('review')
          window.location.href = `/admin/users/assessment/${userId}/${res.data}?type=t${isReview ? '&review=true' : ''}`;
        }, 3000)
      } catch(err) {
        console.log('err', err)
        setIsError(true)
        setTimeout(() => {
          setIsError(false)
        }, 3000)
      } finally {
        setIsLoading(false)
      }
    }
  }

  useLayoutEffect(() => {
    setFormState(initialState)
  }, [initialState])

  return (
    <>
      {isSuccess &&
        <Alert bsStyle="success" className="assessment-success">
          <button
            type="button"
            aria-hidden="true"
            className="close"
            onClick={() => setIsSuccess(false)}
          >
            &#x2715;
          </button>
          Success
        </Alert>
      }
      {isError &&
        <Alert bsStyle="danger" className="assessment-error">
          <button
            type="button"
            aria-hidden="true"
            className="close"
            onClick={() => setIsError(false)}
          >
            &#x2715;
          </button>
          <span>Something went wrong</span>
        </Alert>
      }
      <form>
        {assessmentFields.map(field => (
          <div
            key={field.key}
            className="assessment-field"
          >
            <div className="assessment-title">{field.title}</div>
            <div className="assessment-variants">
              {field.variants.map((variant, index) => (
                <label
                  key={variant.value}
                  className="assessment-label"
                >
                  {variant.label}
                  <input
                    disabled={notEmpty || checkDisable(field.key)}
                    type={field.multi ? "checkbox" : "radio"}
                    name={field.key}
                    value={variant.value}
                    checked={
                      (field.multi && Array.isArray(formState[field.key]))
                        ? formState[field.key].includes(variant.value)
                        : formState[field.key] === variant.value
                    }
                    onChange={() => changeHandler(field.key, variant.value)}
                    style={{marginLeft: '5px'}}
                  />
                </label>
              ))}
            </div>
          </div>
        ))}
        <div>
          <span className="assessment-title assessment-textarea-title">Comment for customer:</span>
          {/* <textarea
            disabled={notEmpty}
            className="form-control assessment-textarea"
            name="recommendation"
            cols="30"
            rows="2"
            value={formState.recommendation || ''}
            onChange={(ev) => changeHandler('recommendation', ev.target.value)}
          /> */}
          <CKEditor
            editor={ ClassicEditor }
            data={formState.recommendation || ''}
            onChange={( event, editor ) => changeHandler('recommendation', editor.getData())}
            onReady={(editor) => {
              editor.plugins.get('FileRepository')
              .createUploadAdapter = (loader) => {
                return new UploadAdapter(loader)
              }
            }}
          />
        </div>
        {validateError && <p className="help-block">All fields are required</p>}
        <button
          type="submit"
          className="btn btn-success libi-admin-btn"
          style={{marginTop: '10px'}}
          disabled={notEmpty || isLoading}
          onClick={submitHandler}
        >
          {isLoading ? 'Loading...' : 'Submit'}
        </button>
      </form>
    </>
  );
};

export default UserAssessmentForm;