import React, {useContext, useEffect, useState} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import PublicLayout from "layouts/Public.jsx";
import { UserContext } from "../src/providers/UserProvider";
import {
    Elements
} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";

//WEB PAGES
import SignUp from '../src/views/web/SignUp'; 
import Invitation from '../src/views/web/Invitation'; 
import WaitingList from '../src/views/web/WaitingList'; 
import AcceptPrivacy from '../src/views/web/AcceptPrivacy'; 
import Download from '../src/views/web/Download'; 
import Popup from '../src/views/web/Popup'; 
import StartOver from '../src/views/web/StartOver';
import DepositVip from '../src/views/web/DepositVip'; 
import Deposit from '../src/views/web/Deposit'; 
import DepositConfirm from '../src/views/web/DepositConfirm';
import Questionnaire from '../src/views/web/Questionnaire';
import Subscribe from '../src/views/web/Subscribe';
import ShippingAddress from '../src/views/web/ShippingAddress';
import BillingAddress from '../src/views/web/BillingAddress';
import ConfirmDetails from '../src/views/web/ConfirmDetails';
import AfterPayment from '../src/views/web/AfterPayment';
import SkincarePlan from '../src/views/web/SkincarePlan';
import ThankyouDiagnose from '../src/views/web/ThankyouDiagnose';
import Language from '../src/views/web/Language';
import Disclaimer from '../src/views/web/Disclaimer';
import TypeForm from '../src/views/web/TypeForm';
import Logout from '../src/views/web/Logout';
import 'bootstrap/dist/css/bootstrap.min.css';


import {getToken, metaCollection, onMessageListener} from "./firebase";
import {Button} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom"; 
import 'react-toastify/dist/ReactToastify.css';
import * as fb from "./firebase";
import UploadPhotos from "views/web/TypeForm/UploadPhotos";

function App(props) {

    const [isTokenFound, setTokenFound] = useState(false);
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: '', action: ''});


    const action = () => {
        window.open(notification.action, "_blank");
    }

    const toastJSX = () => (
        <Link to={`#`} onClick={action}>
            <h4><p>{notification.title}</p></h4>
            <h6>{notification.body}</h6></Link>
    )

    const notify = () => {
        toast(toastJSX);
    }

    // if (process.env.NODE_ENV !== 'production') {
    //     if (country === 'uk') {
    //         stripeKey = 'pk_live_51HVZ6kBs2yoh5fx0OvvpNoyMOY7g8ugjgmV6T1mwXjy3VqldYggk525QXdjlvcnlMZlINQHYtcePXVx73t6hp49X00rZNKQ38H';
    //     } else {
    //         stripeKey = 'pk_live_51I5r3wFEuTzA8RWlc76rw8rLaOeC1D6C8WunQ1UXWNN4xl89zEU8EJLKF2ttrJAArulYUEl9ngrnSOiBoq4g2361003A79siEj';
    //     }
    // } else {
    //     if (country === 'uk') {
    //         stripeKey = 'pk_test_51HVZ6kBs2yoh5fx0HCNPH7XU6ph73xZ9vDs6u2rT7BBuAmLH4UokHLSakMf5Kgw6HlUzR0fgdPEvqwSIGZDkr8FW00l4ZpbQKL';
    //     } else {
    //         stripeKey = 'pk_test_51I5r3wFEuTzA8RWlQxM43cHam6EyYoglMvzxQGQt3zsJjC8nijAqeMoTO1fKh2InFEwSLptwd5hxhqv4NwjpOVrK00F6z5kFdH';
    //     }
    // }

    const user = useContext(UserContext);
    let islogin = localStorage.getItem('checkUser');
    let userLogin = localStorage.getItem('userLogin');
    let routes;
    if ((islogin === "yes") && userLogin !== "yes") {
        console.log("app private routes")
        getToken(setTokenFound);
        onMessageListener().then(payload => {
            setShow(true);
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
                action: payload.data.message
            })
            console.log(payload);
        }).catch(err => console.log('failed: ', err));
        routes = (
            <Switch>
                <Route path="/admin" render={props => <AdminLayout {...props} />}/>
                <Route exact path="/logout" component={Logout}/>
                <Redirect to="/admin/dashboard"/>
            </Switch>
        );
    } else {
        console.log("app public routes")
        routes = (
            <Switch>
                <Route path="/admins" render={props => <PublicLayout {...props} />}/>
                <Route exact path="/" component={Language}/>
                {/*<Route exact path="/signup" component={SignUp}/>*/}
                {/*<Route exact path="/popup" component={Popup}/>*/}
                {/*<Route exact path="/invitation" component={Invitation}/>*/}
                {/*<Route exact path="/waiting-list" component={WaitingList}/>*/}
                {/*<Route exact path="/deposit-vip" component={DepositVip}/>*/}
                {/*<Route exact path="/deposit" component={Deposit}/>*/}
                {/*<Route exact path="/accept-privacy" component={AcceptPrivacy}/>*/}
                {/*<Route exact path="/download" component={Download}/>*/}
                {/*<Route exact path="/start-over" component={StartOver}/>*/}
                {/*<Route exact path="/deposit-confirm" component={DepositConfirm}/>*/}
                {/*<Route exact path="/questionnaire" component={Questionnaire}/>*/}
                {/*<Route exact path="/subscribe" component={Subscribe}/>*/}
                {/*<Route exact path="/shipping-address" component={ShippingAddress}/>*/}
                {/*<Route exact path="/billing-address" component={BillingAddress}/>*/}
                {/*<Route exact path="/confirm-details" component={ConfirmDetails}/>*/}
                {/*<Route exact path="/after-payment" component={AfterPayment}/>*/}
                {/*<Route exact path="/skincare-plan" component={SkincarePlan}/>*/}
                {/*<Route exact path="/thankyou-diagnose" component={ThankyouDiagnose}/>*/}
                {/*<Route exact path="/disclaimer" component={Disclaimer}/>*/}
                <Route exact path="/type-form" component={TypeForm}/>
                <Route exact path="/upload-photos/:userId" component={UploadPhotos}/>
                {/*<Route exact path="/logout" component={Logout}/>*/}
                <Redirect to="/"/>
            </Switch>
        );
    }
    return (
        <div>
            <BrowserRouter>
                {routes}
                {notification.title && notification.title && notify()}
                <ToastContainer/>
            </BrowserRouter>
        </div>
    );
}

export default App; 
