import React, { Component } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Table,  
  Tab,
  Tabs,
  Alert
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card.jsx";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import * as fb from "../../firebase";
import {
  convertFirebseSecondsToDate,
} from "components/Helper/index";

//Datatable Modules
import $ from 'jquery'; 
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import api from "api.js";

class Referral extends Component {

    constructor(props){
        super(props)
        this.state = {
            user_id: "",
            isLoading:false,
            allReferrels:[],
            usedReferrels:[],
            addedReferrels:[],
            credit:"",
            debit:"",
            errors:{},
            error:""
        }
    }

    componentDidMount = async () => {
        const  url = window.location.href;
        const segment = url.substring(url.lastIndexOf('/') + 1);
        await this.fetchData(segment);
        $('#all-datatable').DataTable({
            "bStateSave": true,
            "order": [[ 0, "desc" ]],
        });
        $('#used-datatable').DataTable({
            "bStateSave": true,
            "order": [[ 0, "desc" ]]
        });
        $('#added-datatable').DataTable({
            "bStateSave": true,
            "order": [[ 0, "desc" ]]
        });
    }
  
    fetchData = async(segment) => {
        this.setState({isLoading:true});
        try {
            let all = [];
            let used = [];
            let added = [];
            let credit = 0;
            let debit = 0;
            const data = await fb.usersCollection
                .doc(segment)
                .collection('Referral')
                .get();
            let i = 0
            for (const doc of data.docs) {
                let dataObj = doc.data();
                const user =  await this.getUser(dataObj.refId);
                if(dataObj){
                    if(dataObj.type==="used"){
                        debit = debit+ (parseInt(dataObj.credits));
                        used.push({
                            credits:dataObj.credits,
                            type:"Used",
                            created:dataObj.time && dataObj.time.seconds || "",
                        });
                    }else if(dataObj.type==="added"){
                        credit = credit+ (parseInt(dataObj.credits));
                        added.push({
                            refName:dataObj.refName,
                            amount:dataObj.amount,
                            currency:dataObj.currency,
                            credits:dataObj.credits,
                            type:"Added",
                            created:dataObj.time && dataObj.time.seconds || "",
                        });
                    }
                    all.push({
                        user:user,
                        refName:dataObj.refName,
                        amount:dataObj.amount,
                        currency:dataObj.currency,
                        credits:dataObj.credits,
                        type:dataObj.type==="used" ? "Used" : "Added",
                        created:dataObj.time && dataObj.time.seconds || "",
                    });
                }
                i++
            }
            this.setState({
                user_id:segment,
                allReferrels:all,
                usedReferrels:used,
                addedReferrels:added,
                credit:credit,
                debit:debit,
                isLoading:false
            });
        }catch(err) {
            this.setState({error:err.message,isLoading:false})
        }
    }

    getUser = async (id) => {
        let user = {};
        const {data: result} = await api.get('userByID', {params: {userId: id}})
        
        if(result){
            user = {
                id:result.id,
                name:result.name ? result.name : "",
                email:result.email ? result.email : "",
                invited_time: new Date(result.invited_time).getTime() || "",
            }
        }
        return user;
    };

    deleteUser = async (id) => {
        if (window.confirm("Do you want to delete the item?")) {
            try {
                await api.post('updateUser', {
                    id,
                    deleted: true
                });
                await this.fetchData(); 
            }catch(err) {
                this.setState({error:err.message})
            }
        }
    }


  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Customer Referrals"
                category=""
                ctTableFullWidth
                ctTableResponsive
                button={
                    <div className="notification-button">
                        <Link to={`/admin/users/add-referral/${this.state.user_id}`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"> Add Referrel </Link>
                        <Link to={`/admin/users/view/${this.state.user_id}`} className="btn btn-success libi-admin-btn"> Back </Link>
                    </div>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                    <Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
                        <Tab eventKey="all" title="All" >
                            <br/>
                            <p>Added : { this.state.credit }</p>
                            <p>Used : { this.state.debit }</p>
                            <p>Total Credits : {this.state.credit-this.state.debit}</p>
                            <Table striped hover id="all-datatable">
                                <thead>
                                <tr>
                                    <th>Invited Date</th>
                                    <th>Invitee Name</th>
                                    <th>Invitee Email</th>
                                    <th>Status</th>
                                    <th>Paid Date</th>
                                    <th>Amount</th>
                                    <th>Credit</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.allReferrels.map((val, key) => {
                                    return (
                                    <tr key={key}>
                                        <td>{val.user.invited_time && <><span style={{display:"none"}}>{val.user.invited_time}</span>{convertFirebseSecondsToDate(val.user.invited_time)}</>}</td>
                                        <td>{val.user.name}</td>
                                        <td>{val.user.email}</td>
                                        <td>{val.type}</td>
                                        <td>{val.created && <><span style={{display:"none"}}>{val.created}</span>{convertFirebseSecondsToDate(val.created)}</>}</td>
                                        <td>{val.amount} EUR</td>
                                        <td>{val.credits} EUR</td>
                                    </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="used" title="Used" >
                            <Table striped hover id="used-datatable">
                                <thead>
                                <tr>
                                    <th>Credits</th>
                                    <th>Type</th>
                                    <th>Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.usedReferrels.map((val, key) => {
                                    return (
                                    <tr key={key} id={key}>
                                        <td>{val.credits}</td>
                                        <td>{val.type}</td>
                                        <td>{val.created && <><span style={{display:"none"}}>{val.created}</span>{convertFirebseSecondsToDate(val.created)}</>}</td>
                                    </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="added" title="Added" >
                            <Table striped hover id="added-datatable">
                                <thead>
                                <tr>
                                    <th>Ref Name</th>
                                    <th>Credits</th>
                                    <th>Amount</th>
                                    <th>Currency</th>
                                    <th>Type</th>
                                    <th>Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.addedReferrels.map((val, key) => {
                                    return (
                                    <tr key={key} id={key}>
                                        <td>{val.refName}</td>
                                        <td>{val.credits}</td>
                                        <td>{val.amount}</td>
                                        <td>{val.currency}</td>
                                        <td>{val.type}</td>
                                        <td>{val.created && <><span style={{display:"none"}}>{val.created}</span>{convertFirebseSecondsToDate(val.created)}</>}</td>
                                    </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Referral;
