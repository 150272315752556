import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { Link } from "react-router-dom"; 
import * as fb from '../../firebase';
import {
  addAdminLog,
  createUnixTimestamp
} from "components/Helper/index";
import "./ChatAdmin.css";

class EditChatAdmin extends Component {

  constructor(props){
    super(props);
    this.state = {
      id:"",
      name:"",
      picture: {},
      pictureUrl:"",
      disabled : false,
      disabledSave:false,
      successMessage:"",
      error:"",
      errors:{}
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'name') {
      this.setState({name:value});
    }else if(event.target.files && event.target.files[0]){
      this.setState({picture:event.target.files[0]});
    }
  };

  componentDidMount = async () => {
    const  url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    await fb.chatAdminsCollection.doc(segment).get().then(res => {
      const dataObj = res.data();
      this.setState({
        id:dataObj.id,
        name:dataObj.name,
        pictureUrl:dataObj.picture,
        created:dataObj.created
      });
    });
  }

  handleValidation = () => {
    let {name} = this.state;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!name){
      formIsValid = false;
      errors["name"] = "Title is required";
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this._isMounted = true;
    this.setState({disabled: true});
    if(this.handleValidation(this.state.errors)){
      if(typeof(this.state.picture.name) != "undefined") {
        var timestamp = createUnixTimestamp();
        await fb.storage.child(`chat-admin/${timestamp}-${this.state.picture.name}`).put(this.state.picture)
        .then(snapshot => snapshot.ref.getDownloadURL()) 
        .then((url) => {
          this.setState({pictureUrl:url}, () => {
            this.saveData();
          });
        });
      }else{
        this.saveData(); 
      }
    }else{
      this.setState({
        disabled: false
      });
    }
  }

  saveData = async () => {
    await fb.chatAdminsCollection.doc(this.state.id).update({
      name:this.state.name,
      picture:this.state.pictureUrl
    }).then(snapShot => {
      addAdminLog('chat_admin_updated',
        `Updated the chat admin ${this.state.id}`)
      this.setState({
        successMessage:"Chat admin updated successfully!"
      });
      setTimeout(() => {
        this.setState({
          successMessage: '',
          disabled: false
        });
      }, 3000)
    }).catch(err=> {
      this.setState({
        error:`Something went wrong-${err}`,
        disabled: false
      });
    });
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              {this.state.successMessage &&
                <Alert bsStyle="success">
                  <span>{this.state.successMessage}</span>
                </Alert>
              }
              <Card
                title="Edit Chat Admin"
                button={
                  <Link to={`/admin/chat-admin`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Name</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            value= {this.state.name}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.title}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Picture</FormLabel>
                          <input 
                            type="file" 
                            name="picture" 
                            className="form-control" 
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        { this.state.pictureUrl && <img src={this.state.pictureUrl}  alt="" style={{height:"100px", width:"100px"}}/> }
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <Button
                          className="btn-fill libi-admin-btn"
                          bsStyle="info" 
                          fill 
                          type="submit"
                          disabled={this.state.disabled}
                        >
                          {this.state.disabled ? 'Loading..' : 'Update'}
                        </Button>
                      </div>
                    </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditChatAdmin;
