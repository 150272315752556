import React from "react";
import { Modal, Button} from "react-bootstrap";

function UserDailiesView(props) {
  const renderMng = () => {
    let mngObj = [];
    props.data.dailies && props.data.dailies.forEach((val, key) => {
      if(val.code==="M"){
        mngObj.push(
          <blockquote key={key}>
            <p className="black"><b>Title: </b>{val.title}</p>
            <p className="black"><b>Status: </b>{val.done ? <i class="fa fa-check-square-o" aria-hidden="true"></i> : <i class="fa fa-times" aria-hidden="true"></i>} </p>
            <p className="black"><b>Code: </b>{val.code} </p>
          </blockquote>
        )
      }
    })
    return mngObj;
  }

  const renderEvng = () => {
    let evngObj = [];
    props.data.dailies && props.data.dailies.forEach((val, key) => {
      if(val.code==="E"){
        evngObj.push(
          <blockquote key={key}>
            <p className="black"><b>Title: </b>{val.title}</p>
            <p className="black"><b>Status: </b>{val.done ? <i class="fa fa-check-square-o" aria-hidden="true"></i> : <i class="fa fa-times" aria-hidden="true"></i>} </p>
            <p className="black"><b>Code: </b>{val.code} </p>
          </blockquote>
        )
      }
    })
    return evngObj;
  }

  return (
    <div>
    <h4><b>Morning:</b></h4>
    {renderMng()}
    <h4><b>Evening:</b></h4>
    {renderEvng()}
    </div>
  );
}
  
export default UserDailiesView;
