import React, { Component } from "react";

class LongText extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:""
        }
    }

    onChangeHandler = async (event) => {
        const {name, value} = event.currentTarget
        this.props.onChange(value)
    }

    render() {
        return (
            <textarea
                className="inputText"
                onChange={this.onChangeHandler}
                placeholder="Type your answer here..."></textarea>
        );
    }
}

export default LongText;
