import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Tab,
  Tabs,
  Alert
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card.jsx";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import { userListThArray } from "../../variables/Variables.jsx";
import UsersNotificationView from "components/Model/UsersNotificationView";
import {
  convertFirebseSecondsToDate,
  convertFirebseSecondsToDateShorted,
  userAccountStatus
} from "components/Helper/index";

//Datatable Modules
import $ from 'jquery';
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import { staticDashImages } from "components/DashUtils/images.jsx";
import axios from "axios";
import api from "api.js";
import { convertMilisecondsToDate } from "components/Helper/index.jsx";

class UserList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      users: [],
      usersList: [],
      waitingDiagnosis: [],
      activeUsers: [],
      activeFullUsers: [],
      activeStarterUsers: [],
      waitingPayment: [],
      testUsers: [],
      userData: {},
      waitingDeliveryUser: [],
      questionnaire1User: [],
      lostCustomers: [],
      isOpenUsersModel: false,
      errors: {},
      error: ""
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#live-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
    $('#active-full-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
    $('#active-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
    $('#waiting-delivery-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
    $('#questionnaire1-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
    $('#users-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
    $('#waiting-for-diagnosis-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
    $('#waiting-payment-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });

    $('#test-customers-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
    $('#all-users-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
    $('#lost-customer-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
  }

  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      const {data} = await api.get('getUsers')
      let activeUserArr = [];
      let activeFullUserArr = [];
      let activeStarterUserArr = [];
      let users = [];
      let allUsers = [];
      let waitingDiagnosisArr = [];
      let waitingPaymentArr = [];
      let testUsersArr = [];
      let waitingDeliveryUserArr = [];
      let questionnaire1UserArr = [];
      let lostCustomerArr = [];

      data.forEach(dataObj => {
        let item = userAccountStatus().find(item => item.key === dataObj.status);
        if (!dataObj.deleted && dataObj.deleted !== true) {
          //customers
          if (dataObj.test !== true) {
           
            // if(dataObj.invite_code) {
            //   api.get(`sh/create_coupon?code=${dataObj.invite_code}&percentage=0`);
            // } else {
            //   console.log("+++ customers:",dataObj);
            // }
            users.push({
              ...dataObj,
              status: item ? item.val : "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000
            });
          }

          //waiting dignosis
          if (dataObj.status === "waiting_diagnose" && dataObj.test !== true) {
            waitingDiagnosisArr.push({
              ...dataObj,
              id: dataObj.id,
              status: item ? item.val : "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000
            });
          }
          if (dataObj.status === "waiting_payment" && dataObj.test !== true) {
            waitingPaymentArr.push({
              ...dataObj,
              id: dataObj.id,
              status: item ? item.val : "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000
            });
          }
          if (dataObj.paymentPlanActive && dataObj.test !== true) {
            let activeItem = {
              ...dataObj,
              id: dataObj.id,
              status: item ? item.val : "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000
            }
            activeUserArr.push(activeItem);
            if (dataObj.hasOwnProperty('packageType') && dataObj.packageType === 'starter') {
              activeStarterUserArr.push(activeItem);
            } else {
              activeFullUserArr.push(activeItem);
            }
          }
          if (dataObj.status === "waiting_delivery" && dataObj.test !== true) {
            waitingDeliveryUserArr.push({
              ...dataObj,
              id: dataObj.id,
              status: item ? item.val : "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000
            });
          }
          if (dataObj.status === "questionnaire1" && dataObj.test !== true) {
            questionnaire1UserArr.push({
              ...dataObj,
              id: dataObj.id,
              status: item ? item.val : "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000
            });
          }
          if (dataObj.status === "lost_customer" && dataObj.test !== true) {
            lostCustomerArr.push({
              ...dataObj,
              id: dataObj.id,
              status: item ? item.val : "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000
            });
          }
          if (dataObj.test === true) {
            testUsersArr.push({
              ...dataObj,
              id: dataObj.id,
              status: item ? item.val : "",
              created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
              created_seconds: new Date(dataObj.created).getTime() / 1000
            });
          }
          //all
          allUsers.push({
            ...dataObj,
            id: dataObj.id,
            status: item ? item.val : "",
            created: dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
            created_seconds: new Date(dataObj.created).getTime() / 1000
          });
        }
      })
      this.setState({
        activeUsers: activeUserArr,
        activeFullUsers: activeFullUserArr,
        activeStarterUsers: activeStarterUserArr,
        users: users,
        usersList: allUsers,
        waitingDiagnosis: waitingDiagnosisArr,
        waitingPayment: waitingPaymentArr,
        testUsers: testUsersArr,
        questionnaire1User: questionnaire1UserArr,
        waitingDeliveryUser: waitingDeliveryUserArr,
        lostCustomers: lostCustomerArr,
        isLoading: false
      });
    } catch (err) {
      this.setState({ error: err.message })
    }
  }

  deleteUser = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      try {
        await api.post('updateUser', {
          id,
          deleted: true
        });
        window.location.reload();
        // await this.fetchData(); 
      } catch (err) {
        this.setState({ error: err.message })
      }
    }
  }

  handleShowUsersDialog = (status, userData) => {
    this.setState({ isOpenUsersModel: status, userData: userData })
  };

  render() {
    // console.log('render', this.state)
    return (
      <div className="content">
        {this.state.isOpenUsersModel &&
          <UsersNotificationView
            show={this.state.isOpenUsersModel}
            data={{ userData: this.state.userData }}
            onHide={() => this.handleShowUsersDialog(false, this.state.userData)}
          />
        }
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }

              <Card
                title=""
                category=""
                ctTableFullWidth
                ctTableResponsive
                button={
                  <div className="notification-button">
                    <Link to={`#`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn" onClick={() => { this.handleShowUsersDialog(true, this.state.usersList) }}>
                      Create Push Notification
                    </Link>
                    <Link to={`users/add`} className="btn btn-success libi-admin-btn">
                      Add Customer
                    </Link>
                  </div>
                }
                loader={this.state.isLoading && <PageLoader />} >
                
                <Tabs defaultActiveKey="active" id="uncontrolled-tab-example">
     
                  <Tab eventKey="active" title="Live Customers" >
                    <Table striped hover id="live-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.activeUsers.map((val, key) => {
                          return (
                            <tr key={key} id={key} className={val.test ? 'shipment-danger' : ''}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" title="View" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" title="Chat" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell} title="Notification"></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" title="Delete" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab>
                  {/* <Tab eventKey="active-full" title="Active Full" >
                    <Table striped hover id="active-full-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.activeFullUsers.map((val, key) => {
                          return (
                            <tr key={key} id={key} className={val.test ? 'shipment-danger' : ''}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" title="View" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" title="Chat" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell} title="Notification"></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" title="Delete" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab> */}
                  {/* <Tab eventKey="active-starter" title="Active Starter" >
                    <Table striped hover id="active-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.activeStarterUsers.map((val, key) => {
                          return (
                            <tr key={key} id={key} className={val.test ? 'shipment-danger' : ''}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" title="View" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" title="Chat" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell} title="Notification"></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" title="Delete" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab> */}
                  {/* <Tab eventKey="waiting-delivery" title="Waiting Delivery" >
                    <Table striped hover id="waiting-delivery-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.waitingDeliveryUser.map((val, key) => {
                          return (
                            <tr key={key} id={key} className={val.test ? 'shipment-danger' : ''}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" title="View" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" title="Chat" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell} title="Notification"></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" title="Delete" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab> */}
                  {/* <Tab eventKey="waiting-for-diagnosis" title="Waiting Diagnosis" >
                    <Table striped hover id="waiting-for-diagnosis-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.waitingDiagnosis.map((val, key) => {
                          return (
                            <tr key={key} id={key}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.paymentPlanActive ? <span className="badge badge-secondary">Paid</span> : ""}{val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell}></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab> */}
                  {/* <Tab eventKey="waiting-payment" title="Diagnosis Sent" >
                    <Table striped hover id="waiting-payment-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.waitingPayment.map((val, key) => {
                          return (
                            <tr key={key} id={key}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell}></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab> */}
                  <Tab eventKey="users" title="Customers" >
                    <Table striped hover id="users-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.users.map((val, key) => {
                          return (
                            <tr key={key} id={key} className={val.test ? 'shipment-danger' : ''}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status === "Waiting Diagnosis" && val.paymentPlanActive ? <span className="badge badge-secondary">Paid</span> : ""} {val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" title="View" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" title="Chat" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell} title="Notification"></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" title="Delete" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab>
                  {/* <Tab eventKey="questionnaire1" title="Questionnaire1" >
                    <Table striped hover id="questionnaire1-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.questionnaire1User.map((val, key) => {
                          return (
                            <tr key={key} id={key} className={val.test ? 'shipment-danger' : ''}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" title="View" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" title="Chat" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell} title="Notification"></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" title="Delete" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab> */}
                  <Tab eventKey="test-customers" title="Test Customers" >
                    <Table striped hover id="test-customers-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.testUsers.map((val, key) => {
                          return (
                            <tr key={key} id={key}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell}></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey="lost-users" title="Lost Customer" >
                    <Table striped hover id="lost-customer-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.lostCustomers.map((val, key) => {
                          return (
                            <tr key={key} id={key} className={val.test ? 'shipment-danger' : ''}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell}></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey="all-users" title="All" >
                    <Table striped hover id="all-users-datatable">
                      <thead>
                        <tr>
                          {userListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.usersList.map((val, key) => {
                          return (
                            <tr key={key} id={key} className={val.test ? 'shipment-danger' : ''}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                              <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                              <td>{val.email}</td>
                              <td>{val.status === "Waiting Diagnosis" && val.paymentPlanActive ? <span className="badge badge-secondary">Paid</span> : ""} {val.status}</td>
                              <td className="users-list-fafa">
                                {val.currentTreatmentID && <Link to={`assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="pe-7s-note2" /></Link>}&nbsp;&nbsp;
                                <Link to={`users/view/${val.id}`}> <i className="fa fa-eye" /></Link>&nbsp;&nbsp;
                                <Link to={`chat/${val.id}`}> <i className="fa fa-comment" /></Link>&nbsp;&nbsp;
                                <Link to={`users/notification/${val.id}`}><img src={staticDashImages.bell}></img></Link>&nbsp;&nbsp;
                                <Link to={`#`} onClick={() => { this.deleteUser(val.id) }}><i className="fa fa-times" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserList;
