import React, { Component } from "react";
import { Link } from "react-router-dom";

import { staticWebImages } from "../../components/Utils/images";
import {langText} from "../../components/Utils/helper";
import * as fb from "../../firebase";
import Loading from "./Loading";


class Popup extends Component {
  constructor(props){
    super(props);
    this.state = {
      customer:"",
      products:[],
      productsList:[],
      customersList:[],
      delivery:{
        appartment:"",
        city:"",
        country:"",
        name:"",
        phone:"",
        street:"",
        zipCode:""
      },
      error:"",
      errors:{},
      disabled : false,
      titleText: '',
      contentText: '',
      submitLabel: '',
      isLoading: false,
    }
    this._isMounted = false;
  }

  async componentDidMount() {
    let langTextObj = await langText();
    this.setState({
      titleText: langTextObj.auth_popup_title,
      contentText: langTextObj.auth_popup_detail,
      submitLabel: langTextObj.auth_popup_button
    });
    this.setState({
      isLoading: true
    });
  }

  render() {
    return (
        <section className="Subscription">
          {this.state.isLoading ?
          <div className="container_5-fluid">
            <div className="row_5">
              <div className="col_5-lg-6 col_5-12" style={{padding: 0}}>
                <div className="Subscription_cust" style={{background: 'transparent'}}>
                  <img src={staticWebImages.popupImage} alt="" style={{width:"100%",maxWidth:"530px"}}/>
                </div>
              </div>
              <div className="col_5-lg-6 col_5-12">
                <div className="subs_mainBox">
                  <div className="subsRightBoxWrapper">
                    <div className="subsRightBox">
                      <div className="main-logo">
                        <img src={staticWebImages.logo} alt="" className="img-responsive center-block web-logo-hide" />
                      </div>
                      <h2 className="main_txt_sign text-center" style={{paddingBottom: '20px'}}>{this.state.titleText}</h2>
                      <p className="shiping_para">{this.state.contentText}</p>
                      <p className="shiping_para"><Link to="/" className="web-custom-link margin-t-30">{this.state.submitLabel}</Link></p>
                      <div className="form-section_add">

                      </div>
                    </div>
                  </div>
                  <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
                </div>
              </div>
            </div>
          </div>
              : <Loading />}
        </section>
    );
  }
}

export default Popup;
